import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type PutFeedbackParams = {
    siderId?: string;
    organisationId?: string;
    feedbackId?: string;
    data: {
        siderId: string;
        taskId: string;
        typeId: string;
        organisationId: string;
        managerId: string;
        workAgain: 1 | 0 | -1;
        pro: 1 | 0 | -1;
        perf: 1 | 0 | -1;
        comment: string;
    };
};
export async function putFeedback(params: PutFeedbackParams): Promise<string> {
    debug.addBreadcrumb({
        message: `Update a feedback`,
        category: `action`,
        data: {
            ...params.data,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/v1/feedback/${params.feedbackId}`, {
                method: `PUT`,
                body: JSON.stringify({
                    ...params.data,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("PutFeedback"));
        }
        const json = await resp.json();
        return json;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("PutFeedback"));
    }
}
