import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

type GetSidersParams = { taskId: string };
type GetSidersResponse = {
    siders: Sider[];
};

export type Sider = {
    id: string;
    firstName: string;
    lastName: string;
    pictureUrl: string;
    phoneNumber: string;
    lastOrganisationName: string;
    source: string;
    lastWorkedDate?: string;
    isRecommended: boolean;
};
export async function getSiders(params: GetSidersParams): Promise<GetSidersResponse> {
    debug.addBreadcrumb({
        message: `Get getSiders`,
        category: `action`,
    });
    try {
        const resp = await fetch(
            withAuthHeaders(
                withURLParam(`${apiConf.BACK_URL}/tasks/${params.taskId}/siders`, {
                    ...params,
                }),
                {
                    method: `GET`,
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("getSiders"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("getSiders"));
    }
}
