const normalizeLegalInfo = (data) => {
    const legalInfo = {
        name: data.name,
        businessName: data.businessName,
        siret: data.siret,
        apeCode: data.apeCode,
    };
    return legalInfo;
};

export default normalizeLegalInfo;
