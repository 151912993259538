import * as backend from "../lib/api";
import { handleError } from "../lib/utils/error";

import { normalizeJobTypes } from "./normalizers/jobTypes";
import type { Action, JobType, State } from "./types/jobTypes";
import type { Dispatch } from "./types";

//------------------------------------
// Constants
//------------------------------------

export const SET_JOB_TYPES_LOADING_STATUS = `SET_JOB_TYPES_LOADING_STATUS` as const;
export const SET_JOB_TYPES = `SET_JOB_TYPES` as const;

//------------------------------------
// Actions
//------------------------------------

export const pendingJobTypes = (state: boolean): Action => ({
    type: SET_JOB_TYPES_LOADING_STATUS,
    state,
});

export const setJobTypes = (jobTypes: { [key: string]: JobType }): Action => ({
    type: SET_JOB_TYPES,
    jobTypes,
});

export const getJobTypes = () => async (dispatch: Dispatch) => {
    localStorage.getItem(`side_team_activeOrganisationId`);
    dispatch(pendingJobTypes(true) as any);
    let res;
    let json = {};
    try {
        res = await backend.getJobTypes();
        json = await res.json();
    } catch (e) {
        handleError(e.toString());
        dispatch(pendingJobTypes(false) as any);
    }
    const normalizedData = normalizeJobTypes(json);
    setTimeout(() => {
        dispatch(setJobTypes(normalizedData) as any);
    }, 1000);
};

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    jobTypes: {},
    isLoading: false,
};

const jobTypesReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case SET_JOB_TYPES_LOADING_STATUS:
            return { ...state, isLoading: action.state };
        case SET_JOB_TYPES:
            return {
                ...state,
                jobTypes: action.jobTypes,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default jobTypesReducer;
