import { useEffect, useRef, useState } from "react";

export function useFormInput<T>(init: T, validation: (v: T) => string | null = () => null) {
    const isInit = useRef(true);

    useEffect(() => {
        isInit.current = false;
    }, []);

    const [formInput, setFormInput] = useState({
        value: init,
        error: isInit ? "" : validation(init),
        touched: false,
        dirty: false,
    });

    const handleChange = (value: T, shouldDirtify = true) => {
        let dirty = false;
        let touched = false;
        if (shouldDirtify) {
            dirty = value !== init;
            touched = true;
        }
        const error = validation(value);

        setFormInput({
            value,
            error,
            touched,
            dirty,
        });
    };

    return {
        formInput,
        handleChange,
    };
}
