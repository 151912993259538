import { useEffect, useState } from "react";

export const LocalStorageKeys = {
    NetworkID: "side_team_groupId",
    ActiveOrganisationID: "side_team_activeOrganisationId",
} as const;

export function useLocalStorage(key: string, defaultValue = null) {
    const [value, setValue] = useState(() => {
        let currentValue: string | null;

        try {
            currentValue = localStorage.getItem(key) || defaultValue;
        } catch (error) {
            currentValue = defaultValue;
        }

        return currentValue;
    });

    useEffect(() => {
        if (value === null) {
            localStorage.removeItem(key);
            return;
        }

        localStorage.setItem(key, value);
    }, [value, key]);

    return [value, setValue] as const;
}
