import { Component, createRef } from "react";
import i18n from "i18n-js";
import {
    CardCVCElement,
    CardExpiryElement,
    CardNumberElement,
    injectStripe,
} from "react-stripe-elements";
import { BlockFeedback } from "side-ui";

import "./StripeForms.scss";

const createOptions = () => ({
    style: {
        base: {
            "fontSize": "20px",
            "color": "var(--sui-grey-600)",
            "padding": "13px",
            "fontFamily": "Calibre",
            "::placeholder": {
                color: "var(--sui-grey-400)",
                fontStyle: "italic",
            },
        },
        invalid: {
            color: "var(--sui-red-600)",
        },
    },
});

class BankCardForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            cardCvc: false,
            cardNumber: false,
            cardExpiry: false,
            disabled: true,
            message: null,
            change: null,
        };
        this.formSubmited = createRef(false);
    }

    handleChange = (change) => {
        if (change.error) {
            // set error in message field because it's not a submit error but a field issue
            this.setState({
                message: change.error.message,
                disabled: false,
            });
        } else if (change.complete) {
            // if field is complete we want to know it in the state
            this.setState({
                error: undefined,
                message: undefined,
                disabled: false,
                [change.elementType]: true,
                change,
            });
        }
        this.formSubmited.current = false;
    };

    componentDidUpdate() {
        const { cardCvc, cardNumber, cardExpiry, disabled } = this.state;

        // if all the fields are marked as complete in the state, we trigger the submit
        if (cardCvc && cardExpiry && cardNumber && !disabled && !this.formSubmited.current) {
            this.formSubmited.current = true;
            this.handleSubmit(this.state.change);
        }
    }

    handleSubmit = (ev) => {
        const { stripe, setPaymentMethodId, clientSecret } = this.props;
        if (ev.preventDefault) ev.preventDefault();
        if (ev.stopPropagation) ev.stopPropagation();
        if (stripe) {
            stripe.handleCardSetup(clientSecret).then((payload) => {
                if (payload.error) {
                    this.setState({
                        error: payload.error.message,
                        disabled: false,
                    });
                } else {
                    const paymentMethodId = payload.setupIntent.payment_method;
                    setPaymentMethodId(paymentMethodId);
                }
            });
            this.setState({ disabled: true, error: undefined });
        } else {
            this.setState({
                error: i18n.t(`settings_payment_error_card_check`),
                disabled: false,
            });
        }
    };

    render() {
        const { disabled, error, message } = this.state;
        return (
            <form onSubmit={disabled || this.formSubmited.current ? () => null : this.handleSubmit}>
                <div className='row split-form'>
                    <div className='col-sm-14 col-xs-14'>
                        <label className='input-label'>
                            {i18n.t(`settings_payment_method_card_number`)}
                            <CardNumberElement
                                {...createOptions()}
                                placeholder={i18n.t(
                                    `settings_payment_method_card_number_placeholder`,
                                )}
                                onChange={this.handleChange}
                            />
                        </label>
                    </div>
                </div>
                <div className='row split-form'>
                    <div className='col-sm-7 col-xs-14'>
                        <label className='input-label'>
                            {i18n.t(`settings_payment_method_card_exp_date`)}
                            <CardExpiryElement
                                {...createOptions()}
                                placeholder={i18n.t(
                                    `settings_payment_method_card_exp_date_placeholder`,
                                )}
                                onChange={this.handleChange}
                            />
                        </label>
                    </div>
                    <div className='col-sm-7 col-xs-14'>
                        <label className='input-label'>
                            {i18n.t(`settings_payment_method_card_cvv`)}
                            <CardCVCElement
                                {...createOptions()}
                                placeholder={i18n.t(`settings_payment_method_card_cvv_placeholder`)}
                                onChange={this.handleChange}
                            />
                        </label>
                    </div>
                </div>
                {error && <BlockFeedback content={error} type='error' withIcon={true} />}
                {message && <BlockFeedback type='warning' content={message} />}
            </form>
        );
    }
}
export default injectStripe(BankCardForm);
