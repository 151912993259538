import fetch from "isomorphic-fetch";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type UpdateCompanyPaymentInfoParams = {
    organisationId: string;
    data: {
        wantOrderForm?: boolean;
        paymentMethod?: {
            type: "card" | "sepa";
            last4?: string;
            expirationDate?: string;
            cvv?: string;
            currency?: string;
            country?: string;
            tos?: string;
        };
        accountingInfo?: {
            firstName: string;
            lastName: string;
            email: string;
            phoneNumber: string;
        };
        tvaNumber?: string;
        billingAddress?: {
            street: string;
            postalCode: string;
            city: string;
            country: string;
        };
        clientSecret?: string;
    };
};
export function updateCompanyPaymentInfo(params: UpdateCompanyPaymentInfoParams) {
    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/payment-informations`,
            {
                method: `PATCH`,
                body: JSON.stringify(params.data),
            },
        ),
    );
}
