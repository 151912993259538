import fetch from "isomorphic-fetch";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type UpdateCompanyLegalInfoParams = {
    organisationId: string;
    data: { name?: string; businessName?: string; siret?: string; apeCode?: string };
};
export function updateCompanyLegalInfo(params: UpdateCompanyLegalInfoParams) {
    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/legal-informations`,
            {
                method: `PATCH`,
                body: JSON.stringify(params.data),
            },
        ),
    );
}
