import { Component } from "react";
import i18n from "i18n-js";
import { IbanElement, injectStripe } from "react-stripe-elements";
import { BlockFeedback } from "side-ui";

import "./StripeForms.scss";

const createOptions = () => {
    return {
        style: {
            base: {
                "fontSize": "20px",
                "color": "var(--sui-grey-600)",
                "padding": "13px",
                "fontFamily": "Calibre",
                "::placeholder": {
                    color: "var(--sui-grey-400)",
                    fontStyle: "italic",
                },
            },
            invalid: {
                color: "var(--sui-red-600)",
            },
        },
        placeholderCountry: "FR",
    };
};

class IbanForm extends Component {
    state = {
        error: null,
        disabled: true,
        message: null,
    };

    handleChange = (change) => {
        if (change.error) {
            // set error in message field because it's not a submit error but a field issue
            this.setState({
                message: change.error.message,
                disabled: false,
            });
        } else if (change.complete) {
            // if field is complete we want to hide errors
            this.setState({
                error: undefined,
                message: undefined,
                disabled: false,
            });
            setTimeout(() => {
                this.setState({ disabled: true });
                this.handleSubmit(change);
            }, 500);
        }
    };

    handleSubmit = (ev) => {
        const { stripe, values, setSourceId } = this.props;

        ev.preventDefault && ev.preventDefault();
        ev.stopPropagation && ev.stopPropagation();
        if (stripe) {
            stripe
                .createSource({
                    type: "sepa_debit",
                    currency: "eur",
                    owner: {
                        name: values.billingName,
                    },
                })
                .then((payload) => {
                    if (payload.error) {
                        this.setState({
                            error: payload.error.message,
                            disabled: false,
                        });
                    } else {
                        const sourceId = payload.source.id;
                        setSourceId(sourceId);
                    }
                    this.setState({ disabled: true, error: undefined });
                });
        } else {
            this.setState({
                error: i18n.t(`settings_payment_error_iban_format`),
                disabled: false,
            });
        }
    };

    render() {
        const { error, message, disabled } = this.state;
        return (
            <form onSubmit={disabled ? () => null : this.handleSubmit}>
                <div className='row'>
                    <div className='col-sm-14 col-xs-14'>
                        <label className='input-label'>
                            {i18n.t(`settings_payment_method_sepa`)}
                            <IbanElement
                                id='iban-element'
                                supportedCountries={["SEPA"]}
                                onChange={this.handleChange}
                                {...createOptions()}
                            />
                        </label>
                    </div>
                </div>
                {error && <BlockFeedback content={error} type='error' withIcon={true} />}
                {message && <BlockFeedback type='warning' content={message} />}
            </form>
        );
    }
}
export default injectStripe(IbanForm);
