import I18n from "i18n-js";

import { globalLayoutRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import { Preselection } from "./Preselection";

export const preselectionRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `preselection/$taskId`,
    beforeLoad: () => ({ getTitle: () => I18n.t("doc_title_preselection") }),
    component: Preselection,
});
