import { useContext, useEffect } from "react";

import { JobStep } from "./JobStep/JobStep";
import { LocationStep } from "./LocationStep/LocationStep";
import { SalaryStep } from "./SalaryStep/SalaryStep";
import { SkillsStep } from "./SkillsStep/SkillsStep";
import { WorkConditionsStep } from "./WorkConditionsStep/WorkConditionsStep";
import { JobDescriptionContext } from "./JobDescriptionContext";

export function JobDescriptionCreation({
    activeStep,
    jobDescription,
    updateJobDescription,
    setDataToUpdate,
    disableSubmit,
    companyManagers,
    fieldToEdit,
}) {
    const { panelContentRef } = useContext(JobDescriptionContext);

    useEffect(() => {
        if (panelContentRef?.current) {
            panelContentRef.current.scrollTop = 0;
        }
    }, [activeStep]);

    // scroll to field to edit
    useEffect(() => {
        // only run this code if we are trying to edit a field
        if (!fieldToEdit) return;

        const elementToScrollTo: HTMLElement | null = document.querySelector(
            `[class*=${fieldToEdit}]`,
        );
        const panelHeader: HTMLElement | null = document.querySelector(".panel-header");

        if (elementToScrollTo) {
            // the component is built in a way that the content is relative to the entire panel
            // so we need to subtract the header height to get the proper offsetTop value
            setTimeout(() => {
                if (panelContentRef?.current) {
                    panelContentRef.current.scrollTop =
                        elementToScrollTo?.offsetTop - (panelHeader?.clientHeight ?? 0);
                }
            }, 0);
        }
    }, [fieldToEdit]);

    function displayActiveStep() {
        switch (activeStep) {
            case "job":
                return (
                    <JobStep
                        updateJobDescription={updateJobDescription}
                        jobDescription={{
                            ...jobDescription,
                            equipment: jobDescription?.workConditions?.devices?.concat(
                                jobDescription?.workConditions?.safetyEquipmentList,
                            ),
                            riskIds: jobDescription?.workConditions?.risks?.map((risk) => risk.id),
                            workConditions: jobDescription?.workConditions?.aboutRisks,
                            purpose: jobDescription?.workConditions?.context,
                            dressCode: jobDescription?.workConditions?.dressCode,
                        }}
                        disableSubmit={disableSubmit}
                    />
                );
            case "work-conditions":
                return (
                    <WorkConditionsStep
                        jobDescription={jobDescription}
                        setDataToUpdate={(workConditionsStepData) =>
                            setDataToUpdate(workConditionsStepData)
                        }
                        disableSubmit={disableSubmit}
                    />
                );
            case "skills":
                return (
                    <SkillsStep
                        jobDescription={jobDescription}
                        setDataToUpdate={(skillsStepData) => setDataToUpdate(skillsStepData)}
                        disableSubmit={disableSubmit}
                    />
                );
            case "location":
                return (
                    <LocationStep
                        currentJobDescription={jobDescription}
                        setDataToUpdate={(locationStepData) => setDataToUpdate(locationStepData)}
                        companyManagers={companyManagers}
                    />
                );
            case "salary":
                return (
                    <SalaryStep
                        disableSubmit={disableSubmit}
                        jobDescription={jobDescription}
                        setDataToUpdate={(salaryStepData) => setDataToUpdate(salaryStepData)}
                    />
                );
            default:
                return (
                    <JobStep
                        updateJobDescription={updateJobDescription}
                        jobDescription={jobDescription}
                    />
                );
        }
    }

    return (
        <div className='job-description__creation'>
            <div className='job-description__creation__scroller'>{displayActiveStep()}</div>
        </div>
    );
}
