import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const JobDescriptionJobTitlesSchema = z.array(
    z.object({
        id: z.string(),
        name: z.string(),
        subtaskIds: z.array(z.string()).optional(),
        industries: z.array(z.string()),
        industryId: z.string(),
        requiredDiplomaIds: z.array(z.string()),
        jobTypeId: z.string(),
        usageCount: z.number().int(),
        romeCodeId: z.string(),
    }),
);
export type GetJobDescriptionJobTitlesResponse = z.infer<typeof JobDescriptionJobTitlesSchema>;

const message = "Get job-titles for job description form";
export async function getJobDescriptionJobTitles() {
    debug.addBreadcrumb({
        message,
        category: `action`,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/job-descriptions/job-titles`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return JobDescriptionJobTitlesSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
