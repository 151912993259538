import { memo } from "react";

import { padZeroBeforeMinutes } from "../../../../utils";

type DurationCellProps = {
    duration: string;
    conflict: any;
    siderDidntWork: boolean;
};

const DurationCell = memo(function DurationCell({
    // eslint-disable-line
    duration,
    conflict,
    siderDidntWork,
}: DurationCellProps) {
    return (
        <span
            className={`
        timesheets-table__cell
        timesheets-table__cell--duration
        ${conflict ? "conflicting" : ""}
        ${siderDidntWork ? "--sider-didnt-work" : ""}
      `}
        >
            {padZeroBeforeMinutes(duration)}
        </span>
    );
});

export default DurationCell;
