/**
 * @param {Array} data a two dimensions array of data
 */
export function convertDataToCSV(data, fileName) {
    const rowArray = data.map((arrayItem, index) => {
        const row = arrayItem.join(",");

        if (index === 0) {
            return `data:text/csv;charset=utf-8,${row}`;
        }

        return row;
    });

    const csv = rowArray.join("");
    const encodedURI = encodeURI(csv);

    // for the file to bear a name we need to trigger
    // a click on a hidden link
    const hiddenHref = document.createElement("a");
    hiddenHref.href = encodedURI;
    hiddenHref.target = "_blank";
    hiddenHref.rel = "noopener noreferrer";
    // name it after our filename
    hiddenHref.download = fileName;

    hiddenHref.click();
    // remove it from the DOM
    hiddenHref.remove();
}

/**
 * for csv conversion (column headers)
 * @param {Date} date date to parse
 * @param {String} locale 'en' or 'fr'
 * @returns {String} e.g. mardi 02/06 or tuesday 06/02
 */
export function convertToReadableDate(date, locale = "fr") {
    const safeDate = date instanceof Date ? date : new Date(date);

    if (!(safeDate instanceof Date)) {
        throw new Error("date argument must be a valid date or a date converted to string");
    }

    const dayName = date.toLocaleDateString(locale, { weekday: "long" });
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (locale === "en") {
        return `${dayName} ${month}/${day}`;
    }

    return `${dayName} ${day}/${month}`;
}

/**
 * @param {Any} array An array to test
 * @returns {Boolean}
 */
export function isArray(array) {
    return Array.isArray(array);
}

/**
 * @param {Array} data a matrix [[...dates], [[], [], [], [], [], [], []] ];
 * @returns {Array} a flattened bi-dimension array ['data', 'data', ['data', 'data' ...etc] ];
 */
export function flattenCSVData(data, locale) {
    if (!data) {
        throw new Error("Les données ne sont pas ");
    }

    const flattenedData = data.reduce((acc, data, index) => {
        // eslint-disable-line
        if (index === 0) {
            const dates = data.map((date) => convertToReadableDate(date, locale));

            acc.push(dates);
        } else {
            const flattenedRow = data.flatMap((row) => {
                // we still want an empty space if array is empty
                if (!row.length) {
                    return "";
                }

                return row;
            });

            acc.push(flattenedRow);
        }

        return acc;
    }, []);

    return flattenedData;
}
