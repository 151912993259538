import { queryClient } from "@App";
import {
    CreateJobDescription,
    createJobDescription,
    deleteJobDescription,
    updateJobDescription,
} from "@lib/api";
import { queries } from "@lib/queries";
import { normalizeJobDescriptions } from "@lib/queries/normalizeJobDescriptions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { jobDescriptionsRoute } from "../route";

export function useJobDescriptions(
    deleteJobDescriptionModalRef?: React.RefObject<HTMLDialogElement>,
) {
    const navigate = useNavigate();
    const { jdid } = jobDescriptionsRoute.useSearch();
    const { data: jobDescriptions = [], isLoading } = useQuery(queries.jobDescriptions.list());

    const jobDescription = jobDescriptions.find((jobDescription) => jobDescription.id === jdid);

    const setJobDescription = (jdid?: string | undefined) => navigate({ search: { jdid } });

    // CREATE JOB DESCRIPTION
    const { mutate: createJobDescriptionMutation, isPending: isCreatePending } = useMutation({
        mutationFn: (arg?: CreateJobDescription) => createJobDescription(arg),
        onMutate: () => {
            const tempId = "creating";
            setJobDescription(tempId);
            queryClient.setQueryData(
                queries.jobDescriptions.list().queryKey,
                (oldJobDescriptions: ReturnType<typeof normalizeJobDescriptions>) => {
                    return [...oldJobDescriptions, { id: tempId }];
                },
            );
        },
        onSuccess: (data) => {
            setJobDescription(data.id);
            // Mutate the last element of the jobDescriptions list to replace the tempId with the real id
            // To avoid the flickering of the new job description card
            queryClient.setQueryData(
                queries.jobDescriptions.list().queryKey,
                (oldJobDescriptions: ReturnType<typeof normalizeJobDescriptions>) => {
                    oldJobDescriptions[oldJobDescriptions.length - 1] = { id: data.id } as any;
                    return oldJobDescriptions;
                },
            );
        },
        onError: () => {
            setJobDescription();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.jobDescriptions.list());
        },
    });

    // DUPLICATE JOB DESCRIPTION
    const { mutate: duplicateJobDescriptionMutation } = useMutation({
        mutationFn: createJobDescription,
        onMutate: () => {
            queryClient.setQueryData(
                queries.jobDescriptions.list().queryKey,
                (oldJobDescriptions: ReturnType<typeof normalizeJobDescriptions>) => {
                    setJobDescription();
                    return [
                        ...oldJobDescriptions,
                        {
                            ...jobDescription,
                            id: jobDescription?.id + "duplication",
                        },
                    ];
                },
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.jobDescriptions.list());
        },
    });

    // DELETE JOB DESCRIPTION
    const { mutate: deleteJobDescriptionMutation, isPending: isDeletePending } = useMutation({
        mutationFn: deleteJobDescription,
        onMutate: async () => {
            queryClient.setQueryData(
                queries.jobDescriptions.list().queryKey,
                (old: ReturnType<typeof normalizeJobDescriptions>) => {
                    if (old?.length) {
                        return old.filter((jobDesc) => jobDesc.id !== jdid);
                    }
                },
            );
            setJobDescription();
            deleteJobDescriptionModalRef?.current?.close();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.jobDescriptions.list());
        },
    });

    // UPDATE JOB DESCRIPTION
    const { mutate: updateJobDescriptionMutation } = useMutation({
        mutationFn: updateJobDescription,
        onSettled: () => {
            queryClient.invalidateQueries(queries.jobDescriptions.list());
        },
    });

    return {
        jdid,
        jobDescriptions,
        jobDescription,
        isLoading,
        isCreatePending,
        isDeletePending,
        setJobDescription,
        createJobDescriptionMutation,
        duplicateJobDescriptionMutation,
        deleteJobDescriptionMutation,
        updateJobDescriptionMutation,
    };
}
