import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type InviteUserReq = {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
};

export async function inviteUser(req: InviteUserReq) {
    debug.addBreadcrumb({
        message: `InviteUser`,
        category: `action`,
        data: {
            email: req.email,
            firstName: req.firstName,
            lastName: req.lastName,
            role: req.role,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisations/users`, {
                method: `POST`,
                credentials: "omit",
                body: JSON.stringify(req),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
