import fetch from "isomorphic-fetch";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

export function searchUser(params: { query: string }) {
    return fetch(
        withAuthHeaders(withURLParam(`${apiConf.BACK_URL}/users/search`, { query: params.query })),
    );
}
