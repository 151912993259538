import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import * as backend from "../lib/api";
import checkResForError from "../lib/utils/checkResForError";
import { handleError } from "../lib/utils/error";
import isAuth from "../lib/utils/isAuth";

import normalizeSider from "./normalizers/siders";
import type { Action, State } from "./types/siders";
import type { Dispatch } from "./types";

//------------------------------------
// Constants
//------------------------------------

export const SET_SIDER_LOADING_STATUS = `SET_SIDER_LOADING_STATUS` as const;
export const SET_SELECTED_SIDER = `SET_SELECTED_SIDER` as const;
export const RESET_SELECTED_SIDER = `RESET_SELECTED_SIDER` as const;

//------------------------------------
// Actions
//------------------------------------

export const pendingRequest = (state: boolean): Action => ({
    type: SET_SIDER_LOADING_STATUS,
    state,
});

export const setSelectedSider = (
    siderId: string,
    data: ReturnType<typeof normalizeSider>,
): Action => ({
    type: SET_SELECTED_SIDER,
    siderId,
    data,
});

export const resetSelectedSider = (): Action => ({
    type: RESET_SELECTED_SIDER,
});

//------------------------------------
// Specialized Action Creator
//------------------------------------

export const getSiderById = (
    siderId: string,
    withOngoing?: boolean,
    withTasks?: boolean,
    withExperiences?: boolean,
    withEducations?: boolean,
) =>
    async function (dispatch: Dispatch) {
        if (!isAuth()) {
            debug.warn(`User doesn't have auth info in localStorage`);
            if (window.location.pathname !== "/signin") {
                router.navigate({ to: `/signin` });
            }
            return;
        }
        // Start fecthing invoices
        dispatch(pendingRequest(true));
        let res;
        try {
            res = await backend.getSider({
                siderId,
                withOngoing,
                withTasks,
                withExperiences,
                withEducations,
            });
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
            dispatch(pendingRequest(false));
        }
        if (res) {
            if (checkResForError(res)) {
                dispatch(pendingRequest(false));
                return;
            }
            const json = await res.json();
            const normalizedSider = normalizeSider(json);

            dispatch(setSelectedSider(siderId, normalizedSider));
            dispatch(pendingRequest(false));
        }
    };

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    sidersList: [],
    selectedSider: undefined,
    isLoading: false,
};

const sidersReducer = (state: State = initialState, action: Action) => {
    switch (action.type) {
        case SET_SIDER_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.state,
            };
        case SET_SELECTED_SIDER:
            return {
                ...state,
                selectedSider: {
                    ...action.data,
                    id: action.siderId,
                },
            };
        case RESET_SELECTED_SIDER:
            return {
                ...state,
                selectedSider: {},
            };
        default:
            return state;
    }
};

export default sidersReducer;
