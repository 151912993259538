import i18n from "i18n-js";
import { Button } from "side-ui";

import ActionBar from "../../../../components/ActionBar";
// import './SaveBar.scss';

const SaveBar = ({ resetAction, saveAction, canSave }) => (
    <ActionBar maxWidth='800px'>
        <div className='settings__action'>
            <div className='settings__action__content'>{i18n.t(`settings_actions_content`)}</div>
            <div className='settings__action__buttons'>
                {resetAction && (
                    <button
                        type='button'
                        className='settings__action__discard'
                        onClick={resetAction}
                    >
                        {i18n.t(`settings_actions_discard`)}
                    </button>
                )}
                <Button color='light' iconAfter='Checkmark' action={saveAction} disabled={!canSave}>
                    {i18n.t(`settings_actions_save`)}
                </Button>
            </div>
        </div>
    </ActionBar>
);

export default SaveBar;
