import fetch from "isomorphic-fetch";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GetCompanyLegalInfoParams = { organisationId: string };

export type GetCompanyLegalInfoResponse = {
    name: string;
    businessName: string;
    siret: string;
    apeCode: string;
};
export function getCompanyLegalInfo(params: GetCompanyLegalInfoParams) {
    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/legal-informations`,
        ),
    );
}
