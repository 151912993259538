import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

// Allows to batch post shifts
// cf https://www.notion.so/side/Need-posting-Posting-part-Technical-Specifications-9ba7e7b5c785416c89950addad87243e#f4988e1dea5b448d9a050a8244a9fd8d

type PatchShiftsParams = {
    taskId: string;
    shifts: {
        id: string;
        taskId: string;
        startDate: string;
        endDate: string;
        breakTime: number;
        attendancesIds: Array<string>;
        unplanned: boolean;
    }[];
};
export async function patchShifts(params: PatchShiftsParams) {
    debug.addBreadcrumb({
        message: `patchShifts`,
        category: `action`,
        data: {
            id: params?.taskId,
            shifts: params?.shifts,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${params?.taskId}/shifts`, {
                method: `PATCH`,
                body: JSON.stringify(params.shifts),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return {};
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return {};
    }
}
