import { memo } from "react";
import { format } from "date-fns";
import { IconChevronRightXL } from "side-ui";

import { getLocale } from "@lib/utils/getLocale";

import { convertMinutesToHours } from "../../../../utils/dates";

type TaskCellProps = {
    taskName: string;
    startDate: string;
    endDate: string;
    breakSum: number;
    attendancesLength: number;
};

const TaskCell = memo(function TaskCell({
    // eslint-disable-line
    taskName,
    startDate,
    endDate,
    breakSum,
    attendancesLength,
}: TaskCellProps) {
    const locale = getLocale();
    return (
        <div
            className='timesheets-table__cell__hoverable__wrapper'
            style={{
                height: `${attendancesLength * 56}px`,
            }}
        >
            <div key={taskName} className='timesheets-table__cell timesheets-table__cell--mission'>
                <span>{taskName}</span>
            </div>

            <div className='timesheets-table__cell__hoverable__tooltip'>
                <span className='timesheets-table__cell__hoverable__tooltip__title'>
                    {taskName}
                </span>

                <div className='timesheets-table__cell__hoverable__tooltip__content'>
                    <div className='timesheets-table__cell__hoverable--flex'>
                        <span className='timesheets-table__cell__hoverable__tooltip__content--shuttle-grey'>
                            {format(new Date(startDate), "HH:mm", { locale })}
                        </span>
                        <IconChevronRightXL />
                        <span className='timesheets-table__cell__hoverable__tooltip__content--shuttle-grey'>
                            {format(new Date(endDate), "HH:mm", { locale })}
                        </span>
                        &nbsp;&nbsp;
                        <span>
                            {breakSum > 0 ? (
                                <span className='timesheets-table__cell__slot__break'>
                                    ({convertMinutesToHours(breakSum).replace(" minutes", "min")})
                                </span>
                            ) : null}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default TaskCell;
