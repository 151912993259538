import { useEffect, useRef, useState } from "react";

import { normalizeJobDescription } from "@lib/queries/normalizeJobDescriptions";

import { JobDescriptionName } from "../JobDescriptionName/JobDescriptionName";
import { JobStep } from "../JobStep/JobStep";

import styles from "./JobDescriptionEdit.module.css";

type JobDescriptionEditProps = {
    formToEdit: string;
    jobDescription?: ReturnType<typeof normalizeJobDescription>;
    updateJobDescription: any;
    setDataToUpdate: any;
    disableSubmit: (disabled: boolean) => void;
    companyManagers: any;
};

export function JobDescriptionEdit({
    formToEdit,
    jobDescription,
    updateJobDescription,
    setDataToUpdate,
    disableSubmit,
}: JobDescriptionEditProps) {
    const scrollRef = useRef(null);

    const [suffix, setSuffix] = useState(jobDescription?.suffix ? jobDescription.suffix : "");
    useEffect(() => {
        setDataToUpdate({ suffix });
    }, [suffix]);

    function displayFormToEdit() {
        switch (formToEdit) {
            case "name":
                return (
                    <JobDescriptionName
                        suffix={suffix}
                        prefix={jobDescription?.job?.jobTitle?.name}
                        setSuffix={setSuffix}
                    />
                );
            default:
                return (
                    <JobStep
                        updateJobDescription={updateJobDescription}
                        jobDescription={jobDescription}
                        disableSubmit={disableSubmit}
                    />
                );
        }
    }

    return (
        <div className={styles.edit}>
            <div className={styles.scroller} ref={scrollRef}>
                {displayFormToEdit()}
            </div>
        </div>
    );
}
