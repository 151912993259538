import { debug } from "@side.co/client-debug";

import env, { ENVIRONMENT } from "../env";
export const apiConf = {
    BACK_URL: `${env.API_URL}${env.DOMAIN}`,
};

debug.init({
    environment: ENVIRONMENT,
    dsn: "https://44aa6ead597f429980114d33c5305b72@sentry.io/211065",
});

export * from "./activateAccount";
export * from "./addShift";
export * from "./addSiderProfile";
export * from "./apiHelpers";
export * from "./checkGoogleAuthentication";
export * from "./confirmHoursTypes";
export * from "./confirmIndemnities";
export * from "./confirmTask";
export * from "./createAccount";
export * from "./createJobDescription";
export * from "./createLocation";
export * from "./createTask";
export * from "./deactivateUser";
export * from "./deleteJobDescription";
export * from "./deleteShift";
export * from "./deleteTask";
export * from "./downloadCSV";
export * from "./downloadInvoices";
export * from "./duplicateTask";
export * from "./getAttendances";
export * from "./getCompanyInfo";
export * from "./getCompanyLegalInfo";
export * from "./getCompanyPaymentInfo";
export * from "./getCompanyRemunerationInfo";
export * from "./getCompanySiders";
export * from "./getContractsBatches";
export * from "./getFullFeedback";
export * from "./getHolidays";
export * from "./getInvoices";
export * from "./getJobDescriptionJobTitles";
export * from "./getJobDescriptionOptions";
export * from "./getJobDescriptions";
export * from "./getJobDescriptionSubtasks";
export * from "./getJobTypes";
export * from "./getLegalValues";
export * from "./getLocations";
export * from "./getNumberOfAttendances";
export * from "./getNumberOfContractsBatches";
export * from "./getOrganisationCompletionStatus";
export * from "./getOrganisationProfile";
export * from "./getOrganisationUsers";
export * from "./getPlanning";
export * from "./getQuotation";
export * from "./getSider";
export * from "./getSiders";
export * from "./getTask";
export * from "./getTasks";
export * from "./getTimesheets";
export * from "./getUserInfo";
export * from "./googleActivateAccount";
export * from "./googleLogin";
export * from "./googleSignUp";
export * from "./index";
export * from "./inviteUser";
export * from "./login";
export * from "./patchOrganisationProfile";
export * from "./patchOrganisationUser";
export * from "./patchProfile";
export * from "./patchShifts";
export * from "./postFeedback";
export * from "./putFeedback";
export * from "./putSignature";
export * from "./regenerateDocuments";
export * from "./resendActivationLink";
export * from "./searchJobTitles";
export * from "./searchOrganisation";
export * from "./searchUser";
export * from "./sendContestation";
export * from "./sendDispute";
export * from "./signup";
export * from "./updateCompanyHoursTypes";
export * from "./updateCompanyIndemnities";
export * from "./updateCompanyLegalInfo";
export * from "./updateCompanyPaymentInfo";
export * from "./updateJobDescription";
export * from "./updateLocation";
export * from "./updateTask";
export * from "./updateShift";
export * from "./updateTask";
export * from "./updateUser";
export * from "./uploadOrganisationPicture";
export * from "./validateAttendances";
