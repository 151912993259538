import moment from "moment";

const getWeekStart = (week, year) => {
    let weekStart = moment().second(0).minute(0).hour(0);
    if (year) weekStart = weekStart.isoWeekYear(year);
    if (week !== null && week !== undefined) weekStart = weekStart.isoWeek(week);
    return weekStart.isoWeekday(1);
};

export default getWeekStart;
