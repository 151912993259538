import clsx from "clsx";
import i18n from "i18n-js";
import { ModalsService } from "side-ui";
import { Breadcrumb, Button, Cross, fonts, Information, Tag } from "sui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";

import { taskFormRoute } from "./route";
import OrderDetailsModal from "./TaskDetailsModal";

import styles from "./TaskFormHeader.module.css";

export function TaskFormHeader() {
    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useQuery(queries.task.detail(taskId));
    const orderType = task?.type;

    const navigate = useNavigate();

    function displayOrderDetails() {
        ModalsService.openModal({
            id: `ORDER_DETAILS`,
            content: (
                <OrderDetailsModal
                    order={task}
                    hideModal={() => ModalsService.closeModal(`ORDER_DETAILS`)}
                />
            ),
        });
    }

    return (
        <div className={styles.header}>
            <div className={styles.details}>
                <p className={clsx(styles.name, fonts.sans22Medium)}>{task?.name}</p>
                <Tag color={orderType === "delegation" ? "blue" : "purple"}>{orderType}</Tag>
                <Button
                    icon={<Information />}
                    onClick={() => displayOrderDetails()}
                    shape='outlined'
                    intention='secondary'
                />
            </div>
            <div className={styles.breadcrumbWrapper}>
                <Breadcrumb>
                    <Link
                        to={`/taskPosting/$taskId/motive`}
                        params={{ taskId }}
                        className={styles.breadcrumbItem}
                    >
                        {i18n.t("order_stepper_motive")}
                    </Link>
                    <Link
                        to={`/taskPosting/$taskId/shifts`}
                        params={{ taskId }}
                        disabled={!(Boolean(task?.motive) || Boolean(task?.shifts?.length))}
                        className={styles.breadcrumbItem}
                    >
                        {i18n.t("order_stepper_shifts")}
                    </Link>
                    <Link
                        to={`/taskPosting/$taskId/details`}
                        params={{ taskId }}
                        disabled={!(Boolean(task?.motive) && Boolean(task?.shifts?.length))}
                        className={styles.breadcrumbItem}
                    >
                        {i18n.t("order_stepper_details")}
                    </Link>
                    <Link
                        to={`/taskPosting/$taskId/workers`}
                        params={{ taskId }}
                        disabled={
                            !(
                                Boolean(task?.motive) &&
                                Boolean(task?.shifts?.length) &&
                                Boolean(task?.location?.address)
                            )
                        }
                        className={styles.breadcrumbItem}
                    >
                        {i18n.t("order_stepper_workers")}
                    </Link>
                    <Link
                        to={`/taskPosting/$taskId/summary`}
                        params={{ taskId }}
                        disabled={
                            !(
                                Boolean(task?.motive) &&
                                Boolean(task?.shifts?.length) &&
                                Boolean(task?.location?.address)
                            )
                        }
                        className={styles.breadcrumbItem}
                    >
                        {i18n.t("order_stepper_summary")}
                    </Link>
                </Breadcrumb>
                <Button
                    icon={<Cross />}
                    onClick={() => navigate({ to: `/job-descriptions` })}
                    shape='outlined'
                    intention='secondary'
                />
            </div>
        </div>
    );
}
