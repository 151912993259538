import { useEffect, useState } from "react";
import i18n from "i18n-js";
import { Button, Dialog, IconButton, Loader, ModalsService, ToastsService } from "side-ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { deactivateUser } from "@lib/api/deactivateUser";
import { getOrganisationUsers } from "@lib/api/getOrganisationUsers";
import { inviteUser } from "@lib/api/inviteUser";
import { patchOrganisationUser } from "@lib/api/patchOrganisationUser";
import { resendActivationLink } from "@lib/api/resendActivationLink";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import AddUserModal from "./AddUserModal/AddUserModal";
import EditUserModal from "./EditUserModal/EditUserModal";

import "./Team.scss";

const Team = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const { data: user } = useQuery(queries.user.detail());

    const organisationID = localStorage.getItem(`side_team_activeOrganisationId`);

    useEffect(() => {
        setLoading(true);
        getOrganisationUsers()
            .then((res) => {
                if (res) {
                    setUsers(res.users);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='team-settings-team'>
            <div className='team-settings-team__header'>
                <h2 className='settings__heading'>{i18n.t(`settings_team_title`)}</h2>
                {user?.role === "admin" && (
                    <Button
                        color='blue'
                        iconBefore='UserAdd'
                        action={() => {
                            ModalsService.openModal({
                                id: "AddUserModal",
                                content: (
                                    <AddUserModal
                                        addUser={(usr) => {
                                            inviteUser(usr).then((res) => {
                                                if (res) {
                                                    setUsers(res.users);
                                                    ToastsService.addToast({
                                                        id: `addUserSuccess`,
                                                        icon: `Checkmark`,
                                                        content: i18n.t(
                                                            `settings_team_add_user_success`,
                                                        ),
                                                        type: `confirmation`,
                                                    });
                                                    ModalsService.closeModal("AddUserModal");
                                                }
                                            });
                                        }}
                                        hideModal={() => ModalsService.closeModal("AddUserModal")}
                                    />
                                ),
                            });
                        }}
                    >
                        {i18n.t(`settings_team_add_user`)}
                    </Button>
                )}
            </div>

            <div className='team-settings-team__table'>
                <div className='team-settings-team__table__header'>
                    <div className='team-settings-team__table__header__cell'>
                        {i18n.t(`settings_team_user`)}
                    </div>
                    <div className='team-settings-team__table__header__cell'>
                        {i18n.t(`settings_team_role`)}
                    </div>
                    <div className='team-settings-team__table__header__cell' />
                </div>
                <div className='team-settings-team__table__body'>
                    {users.map((u) => (
                        <div key={u.id} className='team-settings-team__table__body__row'>
                            <div className='team-settings-team__table__body__cell team-settings-team__table__body__cell--user'>
                                <div className='team-settings-team__table__body__cell--user__name'>
                                    {u.firstName}
                                    &nbsp;
                                    {u.lastName}
                                </div>
                                <div className='team-settings-team__table__body__cell--user__email'>
                                    {u.email}
                                </div>
                            </div>
                            <div className='team-settings-team__table__body__cell team-settings-team__table__body__cell--role'>
                                {i18n.t(`settings_team_role_${u.role}`)}
                            </div>
                            <div className='team-settings-team__table__body__cell team-settings-team__table__body__cell--actions'>
                                {user?.role === "admin" &&
                                    !u.validated &&
                                    u.sourceOrganisationId === organisationID && (
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <IconButton
                                                    icon='Send'
                                                    color='grey'
                                                    action={() => {
                                                        resendActivationLink(u.id).then((res) => {
                                                            if (res) {
                                                                ToastsService.addToast({
                                                                    id: `reinviteUserSuccess`,
                                                                    icon: `Checkmark`,
                                                                    content: i18n.t(
                                                                        `settings_team_reinvited_success`,
                                                                    ),
                                                                    type: `confirmation`,
                                                                });
                                                            }
                                                        });
                                                    }}
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent type='action'>
                                                {i18n.t(`settings_team_resend_activation_link`)}
                                            </TooltipContent>
                                        </Tooltip>
                                    )}
                                {user?.role === "admin" &&
                                    (!u.sourceOrganisation ||
                                        u.sourceOrganisation.id === organisationID) &&
                                    u.role !== "admin" &&
                                    u.validated && (
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <IconButton
                                                    icon='PenXL'
                                                    color='grey'
                                                    action={() => {
                                                        ModalsService.openModal({
                                                            id: "EditUserModal",
                                                            content: (
                                                                <EditUserModal
                                                                    user={u}
                                                                    editUser={(req) => {
                                                                        patchOrganisationUser(
                                                                            u.id,
                                                                            req,
                                                                        ).then((res) => {
                                                                            if (res) {
                                                                                setUsers(res.users);
                                                                                ToastsService.addToast(
                                                                                    {
                                                                                        id: `editUserSuccess`,
                                                                                        icon: `Checkmark`,
                                                                                        content:
                                                                                            i18n.t(
                                                                                                `settings_team_edit_user_success`,
                                                                                            ),
                                                                                        type: `confirmation`,
                                                                                    },
                                                                                );
                                                                                ModalsService.closeModal(
                                                                                    "EditUserModal",
                                                                                );
                                                                            }
                                                                        });
                                                                    }}
                                                                    hideModal={() =>
                                                                        ModalsService.closeModal(
                                                                            "EditUserModal",
                                                                        )
                                                                    }
                                                                />
                                                            ),
                                                        });
                                                    }}
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent type='action'>
                                                {i18n.t(`settings_team_edit_role`)}
                                            </TooltipContent>
                                        </Tooltip>
                                    )}
                                {user?.role === "admin" &&
                                    (!u.sourceOrganisation ||
                                        u.sourceOrganisation.id === organisationID) &&
                                    u.role !== "admin" && (
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <IconButton
                                                    icon='CrossXL'
                                                    color='grey'
                                                    action={() => {
                                                        ModalsService.openModal({
                                                            id: "DeleteUserDialog",
                                                            content: (
                                                                <Dialog
                                                                    title={i18n.t(
                                                                        "settings_team_deactivate_user",
                                                                    )}
                                                                    validateLabel={i18n.t(
                                                                        "confirm",
                                                                    )}
                                                                    cancelLabel={i18n.t("cancel")}
                                                                    cancelAction={() =>
                                                                        ModalsService.closeModal(
                                                                            "DeleteUserDialog",
                                                                        )
                                                                    }
                                                                    validateAction={() => {
                                                                        deactivateUser(u.id).then(
                                                                            (res) => {
                                                                                if (res) {
                                                                                    setUsers(
                                                                                        res.users,
                                                                                    );
                                                                                    ToastsService.addToast(
                                                                                        {
                                                                                            id: `deactivateUserSuccess`,
                                                                                            icon: `Checkmark`,
                                                                                            content:
                                                                                                i18n.t(
                                                                                                    `settings_team_deactivate_user_success`,
                                                                                                ),
                                                                                            type: `confirmation`,
                                                                                        },
                                                                                    );
                                                                                }
                                                                            },
                                                                        );
                                                                        ModalsService.closeModal(
                                                                            "DeleteUserDialog",
                                                                        );
                                                                    }}
                                                                >
                                                                    {i18n.t(
                                                                        "settings_team_deactivate_user_dialog_content",
                                                                        {
                                                                            name: `${u.firstName} ${u.lastName}`,
                                                                        },
                                                                    )}
                                                                    <br />
                                                                </Dialog>
                                                            ),
                                                        });
                                                    }}
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent type='action'>
                                                {i18n.t(`settings_team_deactivate_user`)}
                                            </TooltipContent>
                                        </Tooltip>
                                    )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;
