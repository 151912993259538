import { useEffect } from "react";
import i18n from "i18n-js";

import { useLocation } from "@lib/hooks/useLocation";

import "./NotFound.scss";

const NotFound = () => {
    useEffect(() => {
        document.title = i18n.t(`doc_title_not_found`);
    }, []);
    const { pathname } = useLocation();

    return (
        <div className='not-found'>
            <h1>Not found</h1>
            <h3>
                No match for
                <code>{pathname}</code>
            </h3>
        </div>
    );
};

export default NotFound;
