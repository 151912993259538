import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type ConfirmHoursTypesParams = {
    alias?: string;
    organisationId: string;
};
export function confirmHoursTypes(params: ConfirmHoursTypesParams) {
    debug.addBreadcrumb({
        message: `Confirm hours types`,
        category: `action`,
        data: {
            alias: params?.alias,
        },
    });
    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/confirm_hours-types`,
            { method: `PUT` },
        ),
    );
}
