import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { format, set } from "date-fns";
import { ComboBox } from "sui";

type TimeFieldProps = {
    interval?: number;
    value: Date;
    onChange: (value: Date) => void;
    placeholder?: string;
    error?: string | boolean;
    disabled?: boolean;
    label?: string;
    className?: string;
};
export function TimeField({
    interval = 15,
    value,
    onChange,
    placeholder = "08:00",
    error,
    disabled,
    label = "",
    className,
}: TimeFieldProps) {
    const options = useMemo(() => generateTimeArray(interval), [interval]);
    const [inputValue, setInputValue] = useState(format(value, "HH:mm"));

    useEffect(() => {
        if (inputValue.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
            const time = inputValue.split(":");
            onChange(set(value, { hours: Number(time[0]), minutes: Number(time[1]) }));
        }
    }, [inputValue]);
    return (
        <ComboBox
            className={className}
            label={label}
            options={options}
            placeholder={placeholder}
            portal={false}
            inputValue={inputValue}
            onInputChange={(value) => handleChange(value, setInputValue)}
            selection={
                options.find((option) => option.label === inputValue) || { label: "", value: "" }
            }
            onChange={() => {}}
            size='small'
            openOnInitialClick={true}
            error={!!error}
            disabled={disabled}
            filterMethod='none'
            allowCustomEntry={true}
        />
    );
}

function generateTimeArray(interval: number) {
    const timeArray: { label: string; value: Date }[] = [];

    for (let hours = 0; hours < 24; hours++) {
        for (let minutes = 0; minutes < 60; minutes += interval) {
            const formattedHours = hours.toString().padStart(2, "0");
            const formattedMinutes = minutes.toString().padStart(2, "0");
            const timeLabel = `${formattedHours}:${formattedMinutes}`;
            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);

            timeArray.push({ label: timeLabel, value: date });
        }
    }

    return timeArray;
}

function handleChange(value: string, setInputValue: Dispatch<SetStateAction<string>>) {
    // filter characters that are not a number of ":"
    value = value.replace(/[^0-9:]/g, "");
    // replace the "h" char with ":"
    value = value.replace(/h/g, ":");
    // limit to 5 char
    value = (() => {
        if (value.indexOf(":") !== -1) {
            const [hours, minutes] = value.split(":");
            // use only the last 2 char for the values and limit to 23 for hours and 59 for minutes
            const hoursValue = Number(hours.slice(-2)) > 23 ? "23" : hours.slice(-2);
            const minutesValue = Number(minutes.slice(-2)) > 59 ? "59" : minutes.slice(-2);
            return `${hoursValue}:${minutesValue}`;
        }
        // or limit to 5 char
        return value.slice(0, 5);
    })();
    // if the first character is > 2, add leading 0
    value = value.replace(/^([3-9])/, "0$1");
    // if there are more than 2 char not separated by ":", add the separator
    value = value.replace(/^([0-9]{2})([0-9]{1,})$/, "$1:$2");

    setInputValue(value);
}
