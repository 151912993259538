export function withURLParam(url: string, values: { [key: string]: any }): string {
    const params = new URLSearchParams();
    Object.keys(values)
        .map((key) => ({ key, value: values[key] })) // unpack
        .map(({ key, value }) => ({ key, value: String(value) })) // to string
        .filter(({ value }) => value && value.length)
        .forEach(({ key, value }) => params.append(key, value));
    const result = params.toString();
    return `${url}${result.length ? `?${result}` : ""}`;
}

export function withAuthHeaders(input: string | Request, init?: RequestInit) {
    return new Request(input, {
        credentials: "omit",
        headers: {
            token: localStorage.getItem(`Meteor.loginToken`) || ``,
            userId: localStorage.getItem(`Meteor.userId`) || ``,
            logasId: localStorage.getItem(`side_team_logasId`) || ``,
            organisationId: localStorage.getItem(`side_team_activeOrganisationId`) || ``,
        },
        ...init,
    });
}

export function withGroupAuthHeaders(input: string | Request, init?: RequestInit) {
    return new Request(input, {
        credentials: "omit",
        headers: {
            token: localStorage.getItem(`Meteor.loginToken`) || ``,
            userId: localStorage.getItem(`Meteor.userId`) || ``,
            logasId: localStorage.getItem(`side_team_logasId`) || ``,
            organisationId: localStorage.getItem(`side_team_groupId`) || ``,
        },
        ...init,
    });
}

export function authHeaders() {
    return {
        token: localStorage.getItem(`Meteor.loginToken`) || ``,
        userId: localStorage.getItem(`Meteor.userId`) || ``,
        logasId: localStorage.getItem(`side_team_logasId`) || ``,
        organisationId: localStorage.getItem(`side_team_activeOrganisationId`) || ``,
    } satisfies HeadersInit;
}
