import clsx from "clsx";
import I18n from "i18n-js";
import { Add, Button, fonts, TaskCategorySalesrep } from "sui";

import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import styles from "./Navigation.module.css";
export function MenuMainItem({ isShrinked }) {
    const { data: company } = useQuery(queries.company.detail());

    return (
        <div className={styles.mainItem}>
            <Link
                to='/horizon'
                onClick={() => {
                    trackEvent({
                        name: `bbhorizon - user clicks tabbutton`,
                        params: {
                            logAs: !!localStorage.getItem("side_team_logas_email"),
                            companyId: company?.organisation.id,
                            companyName: company?.organisation.name,
                            network: !!company?.parent,
                            industry: company?.organisation.industry || '',
                            networkName: company?.parent,
                        },
                    });
                }}
            >
                <button className={clsx(styles.horizonButton, isShrinked && styles.shrinked)}>
                    <TaskCategorySalesrep />
                    {!isShrinked ? (
                        <div className={styles.text}>
                            <div className={clsx(fonts.sans18Medium, styles.horizonTitle)}>
                                {I18n.t(`horizon_nav_button_title`)}
                            </div>
                            <div className={clsx(fonts.sans14Regular, styles.horizonText)}>
                                {I18n.t(`horizon_nav_button_desc`)}
                            </div>
                        </div>
                    ) : null}
                </button>
            </Link>
            <Link to='/job-descriptions'>
                <Button className={styles.mainButton} icon={<Add />} iconDisposition='left'>
                    {!isShrinked ? I18n.t(`header_cta_task`) : null}
                </Button>
            </Link>
        </div>
    );
}
