// These are the ones that were also on the JP
// I'm sure there's a better way to write them,
// but they work :shrug:

export const normalizeCreditCardNumber = (val, prevVal) => {
    if (!val) {
        return val;
    }
    const onlyNums = val.replace(/[^\d]/g, ``);
    if (!prevVal || val.length > prevVal.length) {
        // typing forward
        if (onlyNums.length === 4) {
            return onlyNums;
        }
        if (onlyNums.length === 8) {
            return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)} `;
        }
        if (onlyNums.length === 12) {
            return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(8)} `;
        }
    }
    if (onlyNums.length <= 4) {
        return onlyNums;
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`;
    }
    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(8)}`;
    }
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
        8,
        12,
    )} ${onlyNums.slice(12, 16)}`;
};

export const normalizeExpDate = (value) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, ``);
    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`;
};

export const normalizeCvv = (value) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, ``);
    if (onlyNums.length <= 4) {
        return onlyNums;
    }
    return onlyNums.slice(0, 4);
};

export const normalizeTvaNumber = (value, prevVal) => {
    if (!value) {
        return value;
    }
    const noSpaces = value.replace(` `, ``);
    if (!prevVal || value.length > prevVal.length) {
        // typing forward
        if (noSpaces.length === 2) {
            return noSpaces;
        }
    }
    if (noSpaces.length <= 2) {
        return noSpaces;
    }
    return `${noSpaces.slice(0, 2)} ${noSpaces.slice(2)}`;
};

export const normalizeCurrency = (value, oldValue) => {
    if (!value) {
        return value;
    }

    let match = value.match(/^[0-9]+(([.,])([0-9]{1,2})?)?$/);
    if (match) {
        match = match[0].replace(`,`, `.`);
    }
    return match || oldValue;
};

export const normalizeNumber = (value) => {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d]/g, ``);
    return onlyNums.length ? `${parseInt(onlyNums, 10)}` : "";
};

export const normalizeFloat = (value, oldValue) => {
    if (!value) {
        return value;
    }

    let match = value.match(/^[0-9]+(([.,])([0-9]+)?)?$/);
    if (match) {
        match = match[0].replace(`,`, `.`);
    }
    return match || oldValue;
};

export const normalizeSiret = (value) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, ``);

    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3)}`;
    }
    if (onlyNums.length <= 9) {
        return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6)}`;
    }
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(
        6,
        9,
    )} ${onlyNums.slice(9)}`;
};

export const normalizeAPE = (value) => {
    if (!value) {
        return value;
    }
    const APEValue = value.replace(` `, ``);

    if (APEValue.length <= 4) {
        return APEValue;
    }
    return `${APEValue.slice(0, 4)} ${APEValue.slice(4)}`;
};
