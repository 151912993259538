import { useEffect, useState } from "react";
import i18n from "i18n-js";
import { Loader, TabItem, TabMenu } from "side-ui";

import { Link, Outlet } from "@tanstack/react-router";

import trackEvent from "../../../lib/trackers";

import "./Contracts.scss";

function Contracts({ isLoading, contractsBatchesToSignTotal }) {
    useEffect(() => {
        trackEvent({
            name: `teamcontract - company opened main view`,
            params: {
                organisationId: localStorage.getItem("side_team_activeOrganisationId"),
            },
        });
    }, []);
    const [active, setActive] = useState("contracts");

    const items = [
        <TabLink
            key='contracts'
            to='/contracts'
            label={i18n.t(`contracts_top_menu_filter_to_sign`)}
            activeOnlyWhenExact={true}
            contractsBatchesToSignTotal={contractsBatchesToSignTotal}
            isActive={active === "contracts"}
            onClick={() => setActive("contracts")}
        />,
        <TabLink
            key='contractsArchives'
            to='/contracts/archives'
            label={i18n.t(`contracts_top_menu_filter_signed`)}
            activeOnlyWhenExact={true}
            isActive={active === "contractsArchives"}
            onClick={() => setActive("contractsArchives")}
        />,
    ];

    return (
        <div className='contracts'>
            <div className='contracts__header'>
                <TabMenu items={items} />
            </div>

            {isLoading ? (
                <div className='loader--centered'>
                    <Loader />
                </div>
            ) : (
                <div className='contracts__container'>
                    <Outlet />
                </div>
            )}
        </div>
    );
}

const TabLink = ({
    label,
    to,
    activeOnlyWhenExact,
    contractsBatchesToSignTotal,
    onClick,
    isActive,
}) => (
    <TabItem
        active={isActive}
        customClass={contractsBatchesToSignTotal > 0 ? `tab-menu__link--contracts` : ``}
    >
        <Link
            to={to}
            activeOptions={{ exact: activeOnlyWhenExact }}
            dangerouslySetInnerHTML={{ __html: label }}
            onClick={onClick}
        />
        {contractsBatchesToSignTotal > 0 && (
            <div className='tab-menu__link--contracts-container'>
                {contractsBatchesToSignTotal > 9 ? <p>9+</p> : <p>{contractsBatchesToSignTotal}</p>}
            </div>
        )}
    </TabItem>
);

export default Contracts;
