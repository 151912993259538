import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { GetOrganisationUsersResponse } from "./getOrganisationUsers";
import { apiConf } from "./index";

export type PatchOrganisationUserReq = {
    role: string;
};

export async function patchOrganisationUser(
    id: string,
    req: PatchOrganisationUserReq,
): Promise<GetOrganisationUsersResponse | null> {
    debug.addBreadcrumb({
        message: `PatchOrganisationUser`,
        category: `action`,
        data: req,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisations/users/${id}`, {
                method: `PATCH`,
                credentials: "omit",
                body: JSON.stringify(req),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
