import { useState } from "react";
import { format, sub } from "date-fns";
import i18n from "i18n-js";
import { Button, ComboBox, Modal, ModalContent, ModalFooter, ModalHeader, Select } from "sui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

type FiltersModalProps = {
    activeFilters: any;
    setActiveFilters: (filters: any) => void;
    modalRef: React.RefObject<HTMLDialogElement>;
};

export function FiltersModal({ activeFilters, setActiveFilters, modalRef }: FiltersModalProps) {
    const [filters, setFilters] = useState(activeFilters);
    const { data: jobTitlesOptions = [] } = useQuery(queries.jobDescriptionJobTitle.list());
    const [jobTitlesSearch, setJobTitlesSearch] = useState("");

    const recommendedOptions = [
        {
            value: true,
            label: i18n.t("order_workers_filters_modal_isrecommended_true"),
        },
        {
            value: false,
            label: i18n.t("order_workers_filters_modal_isrecommended_false"),
        },
    ];
    const hiredAfterOptions = [
        {
            value: `${format(sub(new Date(), { months: 3 }), "yyyy-MM-dd")}T23:59:59.999Z`,
            label: i18n.t("order_workers_filters_modal_last-task_3"),
        },
        {
            value: `${format(sub(new Date(), { months: 1 }), "yyyy-MM-dd")}T23:59:59.999Z`,
            label: i18n.t("order_workers_filters_modal_last-task_1"),
        },
        {
            value: 0,
            label: i18n.t("order_workers_filters_modal_last-task_all"),
        },
    ];

    return (
        <Modal
            ref={modalRef}
            onSubmit={() => {
                setActiveFilters(filters);
                modalRef.current?.close();
            }}
        >
            <ModalHeader
                title={i18n.t("order_workers_filters_modal_title")}
                description={i18n.t("order_workers_filters_modal_subtitle")}
            />
            <ModalContent>
                <div className='order-form__workers-step__filters'>
                    <Select
                        size='small'
                        label={i18n.t("order_workers_filters_modal_isrecommended_label")}
                        id='isRecommendedDropdown'
                        placeholder={i18n.t(
                            "order_workers_filters_modal_isrecommended_placeholder",
                        )}
                        onChange={(option) => {
                            setFilters({ ...filters, recommended: option?.value });
                        }}
                        options={recommendedOptions}
                        selection={
                            recommendedOptions.find(
                                (option) => option?.value === filters?.recommended,
                            ) ?? null
                        }
                    />

                    <Select
                        size='small'
                        label={i18n.t("order_workers_filters_modal_last-task_label")}
                        id='lastTaskDropdown'
                        placeholder={i18n.t("order_workers_filters_modal_last-task_placeholder")}
                        onChange={(option) => {
                            if (option?.value !== 0) {
                                setFilters({ ...filters, hiredAfter: option?.value });
                            } else {
                                setFilters({
                                    recommended: filters?.recommended,
                                    jobTitleId: filters.jobTitleId,
                                });
                            }
                        }}
                        options={hiredAfterOptions}
                        selection={
                            hiredAfterOptions.find(
                                (option) => option?.value === filters?.hiredAfter,
                            ) ?? null
                        }
                    />

                    <ComboBox
                        size='small'
                        label={i18n.t("order_workers_filters_modal_experience_label")}
                        id='jobTitleDropdown'
                        placeholder={i18n.t("order_workers_filters_modal_experience_placeholder")}
                        onChange={(option) => {
                            setFilters({
                                ...filters,
                                jobTitleId: option?.value,
                            });
                        }}
                        options={jobTitlesOptions}
                        selection={
                            jobTitlesOptions?.find(
                                (option) => option?.value === filters?.jobTitleId,
                            ) ?? null
                        }
                        inputValue={jobTitlesSearch}
                        onInputChange={setJobTitlesSearch}
                        openOnInitialClick
                    />
                </div>
            </ModalContent>
            <ModalFooter
                leftButtons={
                    <Button
                        shape='outlined'
                        intention='danger'
                        onClick={() => {
                            setActiveFilters({});
                            setFilters({});
                        }}
                    >
                        {i18n.t("order_workers_filters_modal_button_delete")}
                    </Button>
                }
                mainButton={<Button>{i18n.t("action_confirm")}</Button>}
                cancelButtonLabel={i18n.t("action_cancel")}
            />
        </Modal>
    );
}
