import i18n from "i18n-js";
import { ToastsService } from "side-ui";
import { v4 as uuidv4 } from "uuid";

import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import { clearLogas } from "./clearLogas";

// eslint-disable-next-line arrow-body-style
export const handleErrorAsString = (err) => {
    return i18n.t(err?.key?.replace(/\./g, "_"), {
        defaultValue: err.message && err.details ? err.message : i18n.t("errors_generic"),
        ...(err.metadata ? err.metadata : {}),
    });
};

export const handleError = (err, statusCode = 500) => {
    console.error(err);
    debug.catch(err);
    if (statusCode === 401) {
        ToastsService.addToast({
            id: uuidv4(),
            icon: "Warning",
            content: handleErrorAsString(err),
            isClosable: true,
            type: "warning",
        });
        clearLogas();
        router.navigate({ to: `/` });
        return;
    }

    if (statusCode === 403) {
        ToastsService.addToast({
            id: uuidv4(),
            icon: "Warning",
            content: handleErrorAsString(err),
            isClosable: true,
            type: "warning",
        });
        return;
    }

    if (statusCode <= 400) {
        ToastsService.addToast({
            id: uuidv4(),
            icon: "Warning",
            content: handleErrorAsString(err),
            isClosable: true,
            type: "warning",
        });

        return;
    }

    ToastsService.addToast({
        id: uuidv4(),
        icon: "Cross",
        content: handleErrorAsString(err),
        isClosable: true,
        type: "error",
    });
};
