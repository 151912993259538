/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */

import { UserWithTag } from "side-ui";

import "./UsersList.scss";

export function UsersList({ users }) {
    return (
        <div className='team-users-container'>
            {users?.map((user, i) => (
                <UserWithTag
                    key={i}
                    pictureUrl={user.pictureURL}
                    placeholderIcon='User'
                    userName={`${user.firstName} ${user.lastName}`}
                />
            ))}
        </div>
    );
}
