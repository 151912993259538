import { connect } from "react-redux";

import { getManagerIdFilter } from "../../../store/selectors/display";
import {
    getHasNoTasks,
    getPaginationByStatuses,
    getTaskHasFinishedLoading,
    getTasksByStatuses,
    getTasksStatuses,
} from "../../../store/selectors/tasks";
import { getHomeTasks, getStatuses } from "../../../store/tasks";
import TasksListHome from "../components/TasksListHome";

const mapStateToProps = (state) => ({
    statuses: getTasksStatuses(state),
    hasNoTasks: getHasNoTasks(state),
    finishedLoading: getTaskHasFinishedLoading(state),
    tasksByStatuses: getTasksByStatuses(state),
    paginationByStatuses: getPaginationByStatuses(state),
    managerIdFilter: getManagerIdFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
    getHomeTasks: () => dispatch(getHomeTasks()),
    getStatuses: () => dispatch(getStatuses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksListHome);
