import { Component } from "react";
import i18n from "i18n-js";
import { BlockFeedback, InputText, ModalSimple, ModalsService } from "side-ui";

import trackEvent from "../../../lib/trackers";

import "./RenameModal.scss";

class RenameModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            value: props.taskName.replace(/^[^-]+(?:- )?([^-].*)?()$/, "$1"),
        };
    }

    sendForm(event) {
        if (event.key === `Enter`) {
            event.preventDefault();
            this.submitForm();
        }
    }

    submitForm() {
        const { renameTask, taskId, jobtitle } = this.props;

        const { value } = this.state;

        if (!value || !value.length) {
            trackEvent({
                name: `taskslist - renamed task`,
            });

            renameTask(taskId, jobtitle);

            this.setState({ error: undefined });
        } else {
            trackEvent({
                name: `taskslist - renamed task`,
            });

            renameTask(taskId, `${jobtitle} - ${value}`);

            this.setState({ error: undefined });
        }
    }

    render() {
        const { error } = this.state;
        const { value } = this.state;

        return (
            <ModalSimple
                action={() => this.submitForm()}
                validateLabel={i18n.t(`rename`)}
                cancelLabel={i18n.t(`cancel`)}
                title={i18n.t(`modal_rename_title`)}
                hideModal={() => ModalsService.closeModal(`RENAME_TASK`)}
            >
                <div className='rename-modal'>
                    <BlockFeedback
                        type='neutral'
                        content={i18n.t(`modal_rename_description`)}
                        withIcon={false}
                    />
                    <form>
                        <p className='rename-modal__label'>{i18n.t(`modal_rename_field_label`)}</p>
                        <div className='rename-modal__field'>
                            <InputText
                                name='renameTask'
                                id='rename_task'
                                placeholder={i18n.t(`modal_rename_field_placeholder`)}
                                smallInput={true}
                                onKeyPress={(event) => this.sendForm(event)}
                                error={error}
                                onChange={(event) => this.setState({ value: event.target.value })}
                                value={value}
                            />
                        </div>
                    </form>
                    <p className='rename-modal__tip'>{i18n.t(`modal_rename_field_tip`)}</p>
                </div>
            </ModalSimple>
        );
    }
}

export default RenameModal;
