import * as React from "react";

import "./ActionBar.scss";

const ActionBar = ({ children, maxWidth = null }) => (
    <div className='actionbar'>
        <div
            className='actionbar__inner'
            style={{
                maxWidth,
            }}
        >
            {children}
        </div>
    </div>
);

export default ActionBar;
