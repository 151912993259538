import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";

import { encodeUrlParams } from "../../routes/Timesheets/utils/filters";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export const attendanceStatus = ["att.validated", "att.disputed", "att.waiting"] as const;
export const attendanceTypes = ["matching", "conflicting"] as const;
export const attendanceModes = ["day", "week", "month"] as const;
export const searchBy = ["sider", "manager"] as const;

export type GetAttendancesParams = {
    startDate?: string;
    endDate?: string;
    status?: Array<(typeof attendanceStatus)[number]>;
    type?: Array<(typeof attendanceTypes)[number]>;
    mode?: (typeof attendanceModes)[number];
    siderId?: string;
    managerId?: string;
    limit?: number;
    offset?: number;
    pageNumber?: number;
};

export type GetAttendancesResponse = {
    attendances: Attendance[];
    offset: number;
    limit: number;
    total: number;
};

export type Attendance = {
    id: string;
    shiftId: string;
    task: Task;
    sider: Sider;
    status: string;
    history: History[];
    feedback: Feedback;
};

type Feedback = {
    comment: string;
    id?: string;
    siderId: string;
    taskId: string;
    workAgain: number;
    pro: number;
    perf: number;
};

type History = {
    startDate: string;
    endDate: string;
    breaks?: Break[];
    origin: string;
    updatedAt: string;
    actions: Action[];
    updatedBy?: UpdatedBy;
};

type Action = {
    type: string;
    origin: string;
    updatedBy?: UpdatedBy;
    updatedAt: string;
};

type UpdatedBy = {
    id: string;
    firstName: string;
    lastName: string;
};

type Break = {
    startDate: string;
    endDate: string;
};

type Sider = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    pictureUrl: string;
};

type Task = {
    id: string;
    name: string;
    manager: Manager;
    jobTypeId: string;
};

type Manager = {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
};

export async function getAttendances(
    params: GetAttendancesParams,
): Promise<GetAttendancesResponse> {
    // params are filtered to pass only what's necessary to the backend
    const cleanParams = encodeUrlParams({
        startDate: params?.startDate,
        endDate: params?.endDate,
        status: params?.status,
        type: params?.type,
        siderId: params?.siderId,
        managerId: params?.managerId,
        limit: params?.limit,
        offset: (params?.limit ?? 0) * Math.abs((params?.pageNumber ?? 1) - 1), // do not send negative value
    });

    try {
        const resp = await fetch(withAuthHeaders(`${apiConf.BACK_URL}/attendances?${cleanParams}`));

        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("GetAttendances"));
        }
        const json = await resp.json();
        return json;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("GetAttendances"));
    }
}
