import moment from "moment";

import "moment/min/locales";

const addMomentCustom = () => {
    moment.updateLocale("fr", {
        longDateFormat: {
            LL: "dddd D MMMM",
        },
        dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
        ordinal(number) {
            return number + (number === 1 ? "er" : "e");
        },
        meridiemParse: /PD|MD/,
        isPM(input) {
            return input.charAt(0) === "M";
        },
        // In case the meridiem units are not separated around 12, then implement
        // this function (look at locale/id.js for an example).
        // meridiemHour : function (hour, meridiem) {
        //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
        // },
        meridiem(hours) {
            return hours < 12 ? "PD" : "MD";
        },
    });
    moment.updateLocale("en", {
        longDateFormat: {
            LL: "dddd, MMMM Do",
        },
    });
};

export default addMomentCustom;
