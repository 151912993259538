import I18n from "i18n-js";

import { rootRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import SignUp from "./SignUp";

export const signUpRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `signup`,
    component: SignUp,
    beforeLoad: () => ({ getTitle: () => I18n.t(`sign_up_title`) }),
});
