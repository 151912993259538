import { Component } from "react";
import { Formik } from "formik";
import i18n from "i18n-js";
import { InputText, Loader, NavigationService } from "side-ui";

import { normalizeAPE, normalizeSiret } from "../../../lib/utils/inputsNormalizers";
import { legalInfoValidationSchema, submitLegalInfo } from "../logic/LegalInformation";

import SaveBar from "./partials/SaveBar";
import { showUnsavedChangesModal } from "./partials/UnsavedChangesModal";

import "./LegalInformation.scss";

const onFieldChange = (fieldName, value, setFieldValue) => {
    let newValue = value;
    switch (fieldName) {
        case "siret":
            newValue = normalizeSiret(value);
            break;
        case "apeCode":
            newValue = normalizeAPE(value);
            break;
        default:
            break;
    }
    setFieldValue(fieldName, newValue);
};

class LegalInformation extends Component {
    componentDidMount() {
        const { getLegalInfo } = this.props;
        getLegalInfo();
    }

    render() {
        const {
            isLoading,
            legalInfo: { name, businessName, siret, apeCode },
            updateCompanyLegalInfo,
        } = this.props;

        if (isLoading) {
            return <Loader />;
        }
        const initialVal = {
            name: name || "",
            businessName: businessName || "",
            siret: normalizeSiret(siret) || "",
            apeCode: normalizeAPE(apeCode) || "",
        };

        return (
            <Formik
                initialValues={initialVal}
                onSubmit={(values, actions) =>
                    submitLegalInfo(values, actions, updateCompanyLegalInfo)
                }
                validationSchema={legalInfoValidationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                render={({
                    values,
                    errors,
                    dirty,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    initialValues,
                    setFieldValue,
                }) => {
                    NavigationService.watch(
                        "legal-informations",
                        "/settings/legal",
                        i18n.t(`settings_warning_save_dialog_content`),
                        (confirm) => showUnsavedChangesModal(dirty, confirm, handleSubmit),
                        () => dirty,
                    );

                    return (
                        <div className='settings-legal-information'>
                            <h2 className='settings__heading'>{i18n.t(`settings_legal_title`)}</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='settings__section'>
                                    <p className='settings__teasing'>
                                        {i18n.t(`settings_legal_subtitle`)}
                                    </p>
                                    <fieldset>
                                        <div className='row'>
                                            <div className='col-xs-14'>
                                                <InputText
                                                    id='name'
                                                    label={i18n.t(`settings_legal_name`)}
                                                    placeholder={i18n.t(
                                                        `settings_legal_name_placeholder`,
                                                    )}
                                                    smallInput={true}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                    error={errors.name}
                                                    customClass='input-text-full-width'
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xs-14'>
                                                <InputText
                                                    id='businessName'
                                                    label={i18n.t(`settings_legal_business_name`)}
                                                    labelDetail={i18n.t(
                                                        `settings_legal_business_name_detail`,
                                                    )}
                                                    placeholder={i18n.t(
                                                        `settings_legal_business_name_placeholder`,
                                                    )}
                                                    tooltipContent={i18n.t(
                                                        `settings_legal_business_name_tip`,
                                                    )}
                                                    smallInput={true}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.businessName}
                                                    error={errors.businessName}
                                                    customClass='input-text-full-width'
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xs-14'>
                                                <InputText
                                                    id='siret'
                                                    label={i18n.t(`settings_legal_siret`)}
                                                    placeholder={i18n.t(
                                                        `settings_legal_siret_placeholder`,
                                                    )}
                                                    tooltipContent={i18n.t(
                                                        `settings_legal_siret_tip`,
                                                    )}
                                                    smallInput={true}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            "siret",
                                                            e.target.value,
                                                            setFieldValue,
                                                        )
                                                    }
                                                    onBlur={handleBlur}
                                                    value={values.siret}
                                                    error={errors.siret}
                                                    customClass='input-text-full-width'
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xs-14'>
                                                <InputText
                                                    id='apeCode'
                                                    label={i18n.t(`settings_legal_ape`)}
                                                    placeholder={i18n.t(
                                                        `settings_legal_ape_placeholder`,
                                                    )}
                                                    tooltipContent={i18n.t(
                                                        `settings_legal_ape_tip`,
                                                    )}
                                                    smallInput={true}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            "apeCode",
                                                            e.target.value,
                                                            setFieldValue,
                                                        )
                                                    }
                                                    onBlur={handleBlur}
                                                    value={values.apeCode}
                                                    error={errors.apeCode}
                                                    customClass='input-text-full-width'
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </form>
                            {dirty && (
                                <SaveBar
                                    resetAction={() => resetForm(initialValues)}
                                    saveAction={handleSubmit}
                                    canSave={!isSubmitting}
                                />
                            )}
                        </div>
                    );
                }}
            />
        );
    }
}

export default LegalInformation;
