import i18n from "i18n-js";
import moment from "moment";

import * as backend from "../api";

const changeLocale = async (locale) => {
    try {
        await backend.updateUser({
            locale,
        });
    } catch (e) {
        // Raven.captureException(e);
    }
    i18n.locale = locale;
    moment.locale(locale);
    localStorage.setItem(`side_team_locale`, locale);
    window.location.reload(false);
};

export default changeLocale;
