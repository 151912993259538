import { useState } from "react";
import i18n from "i18n-js";
import { CopyableText, IconCross, IconUser, ToastsService } from "side-ui";

import "./SiderDetails.scss";

const SiderDetails = ({
    firstName,
    lastName,
    pictureUrl = "",
    phoneNumber = "",
    emailAddress = "",
    closeFunction,
}) => {
    const [errorLoadingImg, setErrorLoadingImg] = useState(false);

    const copyTextSuccessCallback = (message) => {
        ToastsService.addToast({
            id: Date.now(),
            icon: "Checkmark",
            content: message,
            isClosable: true,
            type: "confirmation",
        });
    };

    return (
        <div className='sider-details'>
            <div className='sider-details__informations'>
                <div className='sider-details__picture__wrapper'>
                    {!errorLoadingImg && pictureUrl && pictureUrl.startsWith("https://") ? (
                        <>
                            <img
                                className='sider-details__picture'
                                alt={`${firstName} ${lastName}`}
                                src={pictureUrl}
                                onError={() => setErrorLoadingImg(true)}
                            />
                        </>
                    ) : (
                        <div className='sider-details__picture sider-details__picture--placeholder'>
                            <IconUser />
                        </div>
                    )}
                </div>
                <div className='sider-details__content'>
                    <div className='sider-details__content__name'>
                        <p className='sider-details__content__name__firstname'>
                            {firstName}
                            &nbsp;
                        </p>
                        <p className='sider-details__content__name__lastname'>{lastName}</p>
                    </div>
                    <div className='sider-details__content__contact'>
                        <p className='sider-details__content__contact__phonenumber'>
                            {phoneNumber}
                        </p>
                        <CopyableText
                            value={emailAddress}
                            title={i18n.t(`sider_profile_copy_email`)}
                            callback={() =>
                                copyTextSuccessCallback(i18n.t(`sider_profile_email_copied`))
                            }
                        />
                    </div>
                </div>
            </div>
            {closeFunction && (
                <div className='sider-details__actions'>
                    {closeFunction && (
                        <div className='sider-details__actions--close' onClick={closeFunction}>
                            <IconCross />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SiderDetails;
