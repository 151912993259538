import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { SubtasksSchema } from "./getJobDescriptions";
import { apiConf } from "./index";

const message = "Get subtasks for job description form";
export async function getJobDescriptionSubtasks(jobTitleId: string) {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: { jobTitleId },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/job-descriptions/job-titles/${jobTitleId}/subtasks`,
                {
                    method: `GET`,
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return SubtasksSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
