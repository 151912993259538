import { connect } from "react-redux";

import { handleError } from "../../../lib/utils/error";
import { getCompanyLegalInfo, updateCompanyLegalInfo } from "../../../store/company";
import {
    getCompanyLegalInfoData,
    getCompanyLegalInfoStatus,
} from "../../../store/selectors/company";
import LegalInformation from "../components/LegalInformation";

const mapStateToProps = (state) => ({
    legalInfo: getCompanyLegalInfoData(state),
    isLoading: getCompanyLegalInfoStatus(state),
});
const mapDispatchToProps = (dispatch) => ({
    getLegalInfo: () => dispatch(getCompanyLegalInfo()),
    updateCompanyLegalInfo: (dataToSend, dataToStore) =>
        dispatch(updateCompanyLegalInfo(dataToSend, dataToStore)),
    setError: (error, title) => handleError(error, title),
});

export default connect(mapStateToProps, mapDispatchToProps)(LegalInformation);
