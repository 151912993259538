import { useEffect, useState } from "react";
import clsx from "clsx";
import i18n from "i18n-js";
import { Button, Chip, IconPenXL, Overlay, SectionHeader, SlidingWrapper } from "side-ui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import { keyPressHandler } from "../../../utils";

import { EditLicences } from "./EditLicences";

import styles from "../../FormWithSlidingWrapper.module.css";

export function LicencesForm({ currentLicences, submitLicences }) {
    const [displayPanel, setDisplayPanel] = useState(false);
    const [displayRecapModal, setDisplayRecapModal] = useState(false);
    const [selectedLicences, setSelectedLicences] = useState(currentLicences);

    const { data: licences = [] } = useQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) => data?.licences,
    });

    // re-render when currentLicences are being submitted
    useEffect(() => {
        setSelectedLicences(currentLicences);
    }, [currentLicences]);

    function selectLicence(licence) {
        if (selectedLicences.find(({ id }) => id === licence.id)) {
            return setSelectedLicences((prevState) =>
                prevState.filter(({ id }) => id !== licence.id),
            );
        }

        return setSelectedLicences((prevState) => prevState.concat(licence));
    }

    return (
        <div className={clsx(styles.section, "licences")}>
            <div className={styles.actions}>
                {!selectedLicences?.length ? (
                    <p className={styles.title}>
                        {i18n.t(`job-descriptions_creation_skills_licence_label`)}
                    </p>
                ) : (
                    <div
                        className={styles.edit}
                        role='button'
                        tabIndex={0}
                        onClick={() => setDisplayRecapModal(true)}
                        onKeyDown={(event) =>
                            keyPressHandler(event, "Enter", () => setDisplayRecapModal(true))
                        }
                    >
                        <p className={clsx(styles.title)}>
                            {i18n.t(`job-descriptions_creation_skills_licence_label`)}
                            <IconPenXL customClass={styles.icon} />
                        </p>

                        {selectedLicences?.length
                            ? selectedLicences.map((selectedLicence) => (
                                  <div key={selectedLicence.id} className={styles.list} role='list'>
                                      <div className={styles.item} role='listitem'>
                                          {i18n.t(selectedLicence.name)}
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                )}

                <Button action={() => setDisplayPanel(true)} iconAfter='PlusXL' color='blue'>
                    {i18n.t(`job-descriptions_creation_skills_licence_add_button`)}
                </Button>
            </div>

            <Overlay isVisible={displayPanel} toggleVisibility={() => setDisplayPanel(false)} />
            <SlidingWrapper isVisible={displayPanel} position='bottom'>
                {displayPanel ? (
                    <>
                        <SectionHeader
                            title={i18n.t(`job-descriptions_creation_skills_licence_title`)}
                            subtitle={i18n.t(`job-descriptions_creation_skills_licence_subtitle`)}
                            icon='IDCard'
                            closeAction={() => {
                                setDisplayPanel(false);
                            }}
                        />

                        <div className={clsx(styles.selected, styles.content)}>
                            {selectedLicences?.length ? (
                                <div className={styles.wrapper}>
                                    <ul className={styles.list}>
                                        {selectedLicences.map((licence) => (
                                            <li key={licence?.id} className={styles.item}>
                                                <Button
                                                    color='light'
                                                    action={() => {
                                                        selectLicence(licence);
                                                    }}
                                                    iconAfter='CrossXL'
                                                >
                                                    {i18n.t(licence?.name)}
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null}

                            <div className={styles.list} role='list'>
                                {/* eslint-disable-next-line max-len */}
                                {licences
                                    ?.filter(
                                        ({ id }) =>
                                            !selectedLicences?.find((licence) => licence.id === id),
                                    )
                                    .map(({ id, name }) => (
                                        <div key={id} className={styles.item} role='listitem'>
                                            <Chip
                                                id={id}
                                                label={i18n.t(name)}
                                                name={name}
                                                value={name}
                                                checked={
                                                    !!selectedLicences?.find((t) => t.name === name)
                                                }
                                                onChange={(event) => {
                                                    // important to prevent re-render
                                                    event.preventDefault();

                                                    setSelectedLicences(
                                                        selectedLicences?.concat({ id, name }),
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <footer className={styles.footer}>
                            <Button action={() => setDisplayPanel(false)} color='transparent'>
                                {i18n.t(`cancel`)}
                            </Button>

                            <Button
                                action={() => {
                                    setDisplayPanel(false);
                                    submitLicences(selectedLicences);
                                }}
                                color='blue'
                                disabled={!selectedLicences?.length}
                            >
                                {i18n.t(`save`)}
                            </Button>
                        </footer>
                    </>
                ) : null}
            </SlidingWrapper>

            {displayRecapModal && selectedLicences?.length ? (
                <EditLicences
                    hideModal={() => setDisplayRecapModal(false)}
                    selectedLicences={selectedLicences}
                    submitLicences={submitLicences}
                />
            ) : null}
        </div>
    );
}
