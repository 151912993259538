import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import i18n from "i18n-js";
import { ToastsService } from "side-ui";
import {
    Button,
    FileDropZone,
    FileInput,
    FileItem,
    FilesShelf,
    FileUpload,
    fonts,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from "sui";

import { queryClient } from "@App";
import { GetOrganisationResponse } from "@lib/api";
import { postAttendances, PostAttendancesError } from "@lib/api/postAttendances";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { timesheetsRoute } from "@routes/Timesheets/route";
import { useMutation, useQuery } from "@tanstack/react-query";

import { FileImportErrors, useCsvToJson } from "./useCsvToJson";

import styles from "./TimesheetsFileImportModal.module.css";
export const TimesheetsFileImportModal = forwardRef<HTMLDialogElement, any>(function (props, ref) {
    const filters = timesheetsRoute.useSearch();
    const { data: company } = useQuery(queries.company.detail());
    const { file, setFile, errors, reset, json, setErrors } = useCsvToJson();
    const [isSubmited, setIsSubmited] = useState(false);
    const [resetFile, setResetFile] = useState(0);

    const maxSize = 1024 * 1024 * 1; // 1MB
    const formatedErrors = formatErrors(errors, maxSize, file?.[0]?.size || 0);
    const trackEventFn = getTrackEventFn(company!.organisation!, maxSize);

    const { mutate: sendAttendances, isPending } = useMutation({
        mutationFn: () => postAttendances({ attendances: json }),
        onSuccess: () => {
            ToastsService.addToast({
                id: 1,
                icon: "Checkmark",
                content: i18n.t("ts_file_import_modal_import_success", { count: json.length }),
                isClosable: false,
                type: "confirmation",
            });
            queryClient.invalidateQueries(queries.attendance.list(filters));
            trackEventFn(errors, file?.[0]?.size || 0, json);
            onClose();
        },
        onError: (err: PostAttendancesError) => {
            const apiErrors = Object.entries(err.metadata).reduce((acc, [key, value]) => {
                if (!value) return acc;
                return {
                    ...acc,
                    [key]: value
                        .split(",")
                        .map((v) => parseInt(v, 10) + 1)
                        .toSorted((a, b) => a - b),
                };
            }, {} as FileImportErrors);

            setErrors(apiErrors);
            trackEventFn(apiErrors, file?.[0]?.size || 0, json);
        },
    });

    useEffect(() => {
        if (!file || file.length === 0) {
            setResetFile((prev) => prev + 1);
            setIsSubmited(false);
        }
    }, [file]);

    function onClose() {
        reset();
        if (ref && "current" in ref) {
            ref?.current?.close();
        }
    }

    return (
        <Modal ref={ref} onClose={onClose}>
            <ModalHeader
                title={i18n.t("ts_file_import_modal_title")}
                description={
                    <>
                        <p>{i18n.t("ts_file_import_modal_description")}</p>
                        <p>
                            <a
                                href='https://docs.google.com/spreadsheets/d/1wGfDSltwgYvro3eo8Ml_1mmE1WFSVMH_U_ow6g_thoM'
                                target='_blank'
                                className={clsx(fonts.sans18Medium, styles.link)}
                                rel='noreferrer'
                            >
                                {i18n.t("ts_file_import_modal_template_link")}
                            </a>
                        </p>
                    </>
                }
            />
            <ModalContent>
                <FileUpload key={resetFile}>
                    <FileDropZone
                        text={i18n.t("ts_file_import_dropzone_text")}
                        hint={i18n.t("ts_file_import_dropzone_hint")}
                    >
                        <FileInput onChange={setFile} accept='text/csv'>
                            {i18n.t("ts_file_import_dropzone_button")}
                        </FileInput>
                    </FileDropZone>
                    {!!(file?.length && file[0]) && (
                        <FilesShelf>
                            <FileItem
                                file={file[0]}
                                error={
                                    isSubmited && formatedErrors?.length ? (
                                        <ul>
                                            {formatedErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    ) : null
                                }
                            />
                        </FilesShelf>
                    )}
                </FileUpload>
            </ModalContent>
            <ModalFooter
                mainButton={
                    <>
                        <Button intention='secondary' shape='outlined' onClick={onClose}>
                            {i18n.t("ts_file_import_modal_cancel")}
                        </Button>
                        <Button
                            disabled={
                                isPending || !json.length || !!(formatedErrors.length && isSubmited)
                            }
                            loading={isPending}
                            onClick={() => {
                                trackEventFn(errors, file?.[0]?.size || 0, json);
                                setIsSubmited(true);
                                if (!formatedErrors.length) {
                                    sendAttendances();
                                }
                            }}
                        >
                            {i18n.t("ts_file_import_modal_import")}
                        </Button>
                    </>
                }
            />
        </Modal>
    );
});

function getTrackEventFn(company: GetOrganisationResponse["organisation"], maxSize: number) {
    return function (errors: FileImportErrors | null, fileSize = 0, json: any[]) {
        const trackingErrors = Object.entries(errors || {}).reduce(
            (acc, [key, value]) => {
                if (!value) return acc;
                return {
                    ...acc,
                    [`${key}ErrorCount`]: value.length,
                };
            },
            { ...(fileSize > maxSize ? { maxSizeError: 1 } : {}) },
        );
        const failedLineCount =
            [...new Set(Object.values(errors || {}).reduce((acc, value) => [...acc, ...value], []))]
                .length || undefined;

        const uniqueSiderCount = json.length
            ? [...new Set(json.reduce((acc, { siderId }) => [...acc, siderId], []))].length
            : undefined;

        trackEvent({
            name: "timesheet - user import attendances",
            params: {
                organisationId: company?.id ?? "",
                companyName: company?.name ?? "",
                industry: company?.industry ?? "",
                success: !errors,
                importedAttendanceCount: !errors && json.length,
                uniqueSiderCount,
                failedLineCount,
                ...trackingErrors,
            },
        });
    };
}

function formatErrors(errors: FileImportErrors | null, maxSize: number, fileSize = 0) {
    const errorArray: string[] = [];
    if (fileSize > maxSize) {
        errorArray.push(i18n.t("error_file_size", { maxSize: maxSize / 1024 / 1024 }));
    }

    [
        "column",
        "empty",
        "format",
        "siderId",
        "endBeforeStart",
        "fileAttDuplicate",
        "dbAttDuplicate",
        "shiftsNotFound",
    ].forEach((key) => {
        if (errors?.[key]?.length) {
            const translationKey = key
                .split(/(?=[A-Z])/)
                .join("_")
                .toLowerCase();
            errorArray.push(
                `${i18n.t(`ts_file_import_modal_${translationKey}_error`)} ${errors?.[key]?.join(
                    ", ",
                )}`,
            );
        }
    });

    return errorArray;
}
