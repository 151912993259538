import fetch from "isomorphic-fetch";

import { HoursTypes } from "../../store/types/company";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export function convertHoursTypesToAPI(hoursTypes: HoursTypes) {
    return {
        extra: {
            thresholds:
                hoursTypes && hoursTypes.extra
                    ? hoursTypes.extra.thresholds.map((threshold) => ({
                          threshold: threshold.threshold,
                          increase: threshold.increase,
                      }))
                    : [],
        },
        night: (hoursTypes || {}).night
            ? {
                  startHour: hoursTypes.night.startHour,
                  endHour: hoursTypes.night.endHour,
                  increase: hoursTypes.night.increase,
                  seniority: hoursTypes.night.seniority && {
                      daysThreshold: hoursTypes.night.seniority.daysThreshold,
                      increase: hoursTypes.night.seniority.increase,
                  },
              }
            : {},
        sundays: (hoursTypes || {}).sundays
            ? {
                  increase: hoursTypes.sundays.increase,
                  seniority: hoursTypes.sundays.seniority && {
                      daysThreshold: hoursTypes.sundays.seniority.daysThreshold,
                      increase: hoursTypes.sundays.seniority.increase,
                  },
                  ztiIncrease: hoursTypes.sundays.ztiIncrease,
              }
            : {},
        bankHolidays: (hoursTypes || {}).bankHolidays
            ? {
                  increase: hoursTypes.bankHolidays.increase,
                  seniority: hoursTypes.bankHolidays.seniority && {
                      daysThreshold: hoursTypes.bankHolidays.seniority.daysThreshold,
                      increase: hoursTypes.bankHolidays.seniority.increase,
                  },
                  solidarity: hoursTypes.bankHolidays.solidarity,
              }
            : {},
    };
}

export function updateCompanyHoursTypes(params: { organisationId: string; data: HoursTypes }) {
    const hoursTypes = convertHoursTypesToAPI(params.data);

    return fetch(
        withAuthHeaders(`${apiConf.BACK_URL}/organisations/${params.organisationId}/hours-types`, {
            method: `PATCH`,
            body: JSON.stringify(hoursTypes),
        }),
    );
}
