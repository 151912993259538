import fetch from "isomorphic-fetch";

import { withGroupAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

export function searchOrganisation(params: { q: string }) {
    return fetch(
        withGroupAuthHeaders(
            withURLParam(`${apiConf.BACK_URL}/organisations/children/search`, { q: params.q }),
        ),
    );
}
