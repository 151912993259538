/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import i18n from "i18n-js";
import {
    Button,
    Check,
    InputPhoneNumber,
    InputText,
    ModalsService,
    Select,
    Textarea,
} from "side-ui";
import { Logo } from "sui";

import { queryClient } from "@App";
import { queries } from "@lib/queries";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "@tanstack/react-router";

import { createAccount } from "../../lib/api/createAccount";
import { googleSignUp } from "../../lib/api/googleSignUp";
import { signup } from "../../lib/api/signup";
import { useFormInput } from "../../lib/hooks/form";
import trackEvent from "../../lib/trackers";

import OnboardingModal from "./OnboardingModal/OnboardingModal";

import "./SignUp.scss";

const companySizeArray = ["1-10", "11-50", "51-200", "201-1000", "1001-5000", "5000+"];
const companySizes = {
    "1-10": "1 to 10",
    "11-50": "11 to 50",
    "51-200": "51 to 200",
    "201-1000": "201 to 1000",
    "1001-5000": "1001 to 5000",
    "5000+": "5000+",
};

const companyNeedArray = ["less-than-7-days", "more-than-7-days", "learn-about-side"];

const SignUp = () => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const emailQuery = urlParams?.get("email");
    const { formInput: email, handleChange: setEmail } = useFormInput(emailQuery || "", (value) => {
        if (
            !value
                .toLowerCase()
                .match(
                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                )
        ) {
            return i18n.t(`signup_error_invalid_email`);
        }
        return null;
    });
    const [blurred, setBlurred] = useState(false);
    const [secondStep, setSecondStep] = useState(false);
    const [googleLogin, setGoogleLogin] = useState(false);

    // Second step fields
    const { formInput: fullname, handleChange: setFullname } = useFormInput("", (value) => {
        if (!value.trim().includes(" ")) {
            return i18n.t(`error_field_required`);
        }

        return null;
    });
    const { formInput: password, handleChange: setPassword } = useFormInput("", (value) => {
        if (value.length < 8) {
            return i18n.t(`error_password_length_8`);
        }

        if (!/[ `!@#$%^&*()_+\-=[\]{};':"|,.<>/?~]/.test(value)) {
            return i18n.t(`error_password_special_char`);
        }

        return null;
    });
    const [isPassword, setIsPassWord] = useState(true);
    const [phoneBlur, setPhoneBlur] = useState(false);
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(null);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(null);
    const { formInput: phone, handleChange: setPhone } = useFormInput("", (value) => {
        if (!value || value.length === 0) {
            i18n.t(`error_field_required`);
        }
        return null;
    });
    if (isValidPhoneNumber === false && !phone.error)
        phone.error = i18n.t(`error_phone_number_invalid`);
    const { formInput: company, handleChange: setCompany } = useFormInput("", (value) =>
        !value || value.length === 0 ? i18n.t(`error_field_required`) : null,
    );
    const { formInput: companySize, handleChange: setCompanySize } = useFormInput("", () => null);
    const { formInput: companyNeed, handleChange: setCompanyNeed } = useFormInput("", () => null);
    const { formInput: message, handleChange: setMessage } = useFormInput("", () => null);
    const [termsAndConditions, setTermsAndConditions] = useState(false);

    const [loading, setLoading] = useState(false);

    const invalidForm =
        email.error ||
        (!googleLogin && password.error) ||
        fullname.error ||
        phone.error ||
        company.error ||
        companySize.value === "" ||
        companyNeed.value === "" ||
        !termsAndConditions;

    const signUpWithGoogle = useGoogleLogin({
        onSuccess: (res) => {
            if (!res.code) {
                return;
            }

            // Send code to backend
            googleSignUp({ code: res.code }).then((userInfo) => {
                if (userInfo) {
                    localStorage.setItem("google_login", "true");
                    setEmail(userInfo.email);
                    setGoogleLogin(true);

                    setSecondStep(true);
                }
            });
        },
        flow: "auth-code",
    });

    function signUp() {
        if (email.error) {
            setBlurred(true);
            return;
        }

        signup({ email: email.value.trim() }).then((res) => {
            if (!res) {
                return;
            }

            setEmail(res.email);
            setSecondStep(true);
        });
    }

    function submitAccount() {
        const urlSearchParams = new URLSearchParams(window.location.search);

        if (
            email.error ||
            (!googleLogin && password.error) ||
            fullname.error ||
            phone.error ||
            company.error ||
            companySize.value === "" ||
            companyNeed.value === "" ||
            !termsAndConditions
        ) {
            return;
        }

        setLoading(true);

        createAccount({
            email: email.value.trim(),
            password: password.value,
            firstName: fullname.value.trim().split(/\s(.+)/)[0],
            lastName: fullname.value.trim().split(/\s(.+)/)[1],
            phoneNumber: formattedPhoneNumber,
            companyName: company.value.trim(),
            companySize: companySizes[companySize.value],
            need: companyNeed.value,
            message: message.value,
            utm_campaign: urlSearchParams.get("utm_campaign"),
            utm_medium: urlSearchParams.get("utm_medium"),
            utm_source: urlSearchParams.get("utm_source"),

            googleLogin: !!localStorage.getItem("google_login"),
        }).then((res) => {
            if (!res) {
                setLoading(false);
                return;
            }

            localStorage.setItem("Meteor.loginToken", res.token);
            localStorage.setItem("Meteor.userId", res.userId);
            localStorage.setItem("side_team_activeOrganisationId", res.organisationId);

            trackEvent({
                name: `createdAccount`,
                params: {
                    utms: {
                        utm_campaign: urlSearchParams.get("utm_campaign"),
                        utm_medium: urlSearchParams.get("utm_medium"),
                        utm_source: urlSearchParams.get("utm_source"),
                    },
                },
            });

            queryClient.invalidateQueries(queries.user.detail());

            // Trick to get the store populated before navigating, because
            // the user would be redirected on the planning, not knowing
            // what he need to do
            setTimeout(() => {
                navigate({ to: `/settings` });

                ModalsService.openModal({
                    id: "OnboardingModal",
                    content: (
                        <OnboardingModal
                            hideModal={() => ModalsService.closeModal("OnboardingModal")}
                        />
                    ),
                });
            }, 1000);
        });
    }

    useEffect(() => {
        if (email.value && email.value.length > 0) {
            signUp();
        }
    }, []);

    return (
        <div className='team-signup'>
            <header className='team-signup__header'>
                <div className='team-signin__header__left'>
                    <a href='https://www.side.co/entreprises' className='team-signup__header__logo'>
                        <Logo version='topbar' />
                    </a>
                    <div className='team-signup__header__title'>
                        {i18n.t(`sign_up_logo_company`)}
                    </div>
                </div>
                <div className='team-signup__header__spacer' />
                <div className='team-signup__header__create-account'>
                    <span>
                        {i18n.t(`sign_up_no_account_yet`)}
                        <a href='/signin' target='_self'>
                            {i18n.t(`sign_up_log_in`)}
                        </a>
                    </span>
                </div>
                <a
                    href='https://app.side.co/e0ra/website'
                    target='_blank'
                    className='team-signup__header__button'
                    rel='noopener noreferrer'
                >
                    {i18n.t(`sign_up_work_on_side`)}
                </a>
            </header>

            <section className='team-signup__content'>
                {!secondStep ? (
                    <>
                        <h1 className='team-signup__content__title'>
                            {i18n.t(`sign_up_form_title`)}
                        </h1>
                        <button
                            className='team-signup__content__google'
                            type='button'
                            onClick={signUpWithGoogle}
                            disabled={!termsAndConditions}
                        >
                            <img
                                src='https://s3.eu-central-1.amazonaws.com/weslash-static/static/company/signup-google.svg'
                                alt='Google'
                            />
                            <span>{i18n.t(`sign_up_with_google`)}</span>
                        </button>

                        <div className='team-signup__content__divider'>
                            <div className='team-signup__content__divider__line' />
                            <span className='team-signup__content__divider__text'>
                                {i18n.t(`sign_up_or`)}
                            </span>
                            <div className='team-signup__content__divider__line' />
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className='team-signup__content__second-step__title'>
                            {i18n.t(`sign_up_form_title_second_step`)}
                        </h1>
                        <p className='team-signup__content__second-step__subtitle'>
                            {i18n.t(`sign_up_form_subtitle_second_step`)}
                        </p>
                    </>
                )}

                <form
                    className='team-signup__content__form'
                    onSubmit={() => {
                        if (!secondStep) {
                            signUp();
                            return;
                        }

                        submitAccount();
                    }}
                >
                    <InputText
                        id='email'
                        name='email'
                        label={i18n.t(`sign_up_email_label`)}
                        placeholder={i18n.t("sign_up_email_placeholder")}
                        error={email.dirty && blurred ? email.error : null}
                        value={email.value}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => setBlurred(true)}
                        iconAfter={secondStep ? "LockXL" : null}
                        disabled={!!secondStep}
                        type='email'
                        autoComplete='email'
                    />

                    {secondStep && (
                        <>
                            {!googleLogin && (
                                <InputText
                                    id='password'
                                    name='password'
                                    label={i18n.t(`sign_up_password_label`)}
                                    placeholder={i18n.t("sign_up_password_placeholder")}
                                    error={password.touched ? password.error : null}
                                    value={password.value}
                                    onChange={(e) => setPassword(e.target.value)}
                                    iconAfter='Eye'
                                    isPassword={isPassword}
                                    onIconAfterClick={() => setIsPassWord(!isPassword)}
                                    autoComplete='new-password'
                                />
                            )}

                            <InputText
                                id='fullname'
                                name='fullname'
                                label={i18n.t(`sign_up_fullname_label`)}
                                error={fullname.touched ? fullname.error : null}
                                placeholder={i18n.t("sign_up_fullname_placeholder")}
                                value={fullname.value}
                                onChange={(e) => setFullname(e.target.value)}
                                autoComplete='name'
                            />

                            <InputPhoneNumber
                                id='phone'
                                name='phone'
                                label={i18n.t(`sign_up_phone_label`)}
                                placeholder='(0)6 07 08 09 10'
                                error={phone.touched && phoneBlur ? phone.error : null}
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone.value}
                                setFormattedOutput={setFormattedPhoneNumber}
                                setIsValid={setIsValidPhoneNumber}
                                onBlur={() => setPhoneBlur(true)}
                                type='tel'
                                autoComplete='tel'
                            />

                            <InputText
                                id='company'
                                name='company'
                                label={i18n.t(`sign_up_company_label`)}
                                error={company.touched ? company.error : null}
                                placeholder={i18n.t("sign_up_company_placeholder")}
                                value={company.value}
                                onChange={(e) => setCompany(e.target.value)}
                                autoComplete='organization'
                            />

                            <Select
                                id='companySize'
                                name='companySize'
                                label={i18n.t(`sign_up_company_size_label`)}
                                placeholder={i18n.t("sign_up_company_size_placeholder")}
                                onChange={(e, name, value) => setCompanySize(value)}
                                selectedItem={companySize.value}
                                options={companySizeArray.map((item, i) => ({
                                    id: i,
                                    value: item,
                                    label: item.split("-").join(` ${i18n.t(`sign_up_size_to`)} `),
                                }))}
                                chevronIcon={true}
                            />

                            <Select
                                id='companyNeed'
                                name='companyNeed'
                                label={i18n.t(`sign_up_company_need_label`)}
                                placeholder={i18n.t("sign_up_company_need_placeholder")}
                                onChange={(e, name, value) => {
                                    setCompanyNeed(value);
                                }}
                                selectedItem={
                                    companyNeed.dirty
                                        ? i18n.t(
                                              `sign_up_company_need_${companyNeed.value
                                                  .split("-")
                                                  .join("_")}`,
                                          )
                                        : ""
                                }
                                options={companyNeedArray.map((item, i) => ({
                                    id: i,
                                    value: item,
                                    label: i18n.t(
                                        `sign_up_company_need_${item.split("-").join("_")}`,
                                    ),
                                }))}
                                chevronIcon={true}
                            />

                            <Textarea
                                id='message'
                                name='message'
                                height={120}
                                value={message.value}
                                label={i18n.t("sign_up_company_message_label")}
                                onChange={(e) => {
                                    setMessage(e);
                                }}
                            />
                        </>
                    )}

                    {!secondStep ? (
                        <div
                            className='team-signup__content__terms-and-conditions'
                            onClick={() => setTermsAndConditions((value) => !value)}
                        >
                            <Check
                                checked={termsAndConditions}
                                onChange={() => setTermsAndConditions((value) => !value)}
                            />
                            <span>
                                {i18n.t(`sign_up_accept`)}
                                <a
                                    href='https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/cgu-fr.pdf'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {i18n.t(`sign_up_terms_and_conditions`)}
                                </a>
                            </span>
                        </div>
                    ) : null}

                    <Button
                        customClass='team-signup__content__form__submit'
                        type='submit'
                        action={() => {
                            if (!secondStep) {
                                signUp();
                                return;
                            }

                            submitAccount();
                        }}
                        loading={loading}
                        fullWidth={true}
                        color='blue'
                        disabled={
                            !termsAndConditions || !email.value || (invalidForm && secondStep)
                        }
                    >
                        {i18n.t(`sign_up_create_account`)}
                    </Button>
                </form>

                <a
                    href='https://app.side.co/e0ra/website'
                    target='_blank'
                    className='team-signup__content__button'
                    rel='noopener noreferrer'
                >
                    {i18n.t(`sign_up_temp_worker`)}
                </a>

                <div className='team-signup__content__divider'>
                    <div className='team-signup__content__divider__full-line' />
                </div>

                <div className='team-signup__content__create-account'>
                    <div>{i18n.t(`sign_up_no_account_yet`)}</div>
                    <a href='/signin'>{i18n.t(`sign_up_log_in`)}</a>
                </div>
            </section>
        </div>
    );
};

export default SignUp;
