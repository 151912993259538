import { queryClient } from "@App";
import { queries } from "@lib/queries";
import isAuth from "@lib/utils/isAuth";

export async function getUser() {
    if (!isAuth()) return null;

    try {
        const user = await queryClient.fetchQuery(queries.user.detail());
        return user;
    } catch (e) {
        console.error(e);
        return null;
    }
}
export const allowGroup = () => localStorage.getItem("side_team_groupId") !== null;
export const allowInsider = async () => {
    const user = await getUser();
    return user?.isInsider;
};
export const allowCompany = () => localStorage.getItem("side_team_activeOrganisationId") !== null;
export const allowAdminAndUser = async () => {
    const user = await getUser();
    return user?.role === "admin" || user?.role === "user";
};
