import { z } from "zod";

import { debug } from "@side.co/client-debug";

import { apiRequest } from "./apiHelpers/apiRequest";
import { apiConf } from ".";

const preSelectionSiderSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    preSelectionStatus: z.enum(["sent", "approved", "refused"]),
    cvLink: z.string().nullish(),
    availabilities: z
        .object({
            startDate: z.string().datetime().nullish(),
            endDate: z.string().datetime().nullish(),
        })
        .nullish(),
    comment: z.string().nullish(),
    visible: z.boolean().catch(false),
    refusalReason: z.string().nullish(),
});

export type PreSelectionSider = z.infer<typeof preSelectionSiderSchema>;

const getTaskPreSelectionSchema = z.array(preSelectionSiderSchema);

export async function getTaskPreSelection(taskId, signal?: AbortSignal) {
    debug.addBreadcrumb({
        message: `getTaskPreSelection`,
    });
    const url = `${apiConf.BACK_URL}/tasks/${taskId}/applicants`;
    return apiRequest({
        url,
        responseSchema: getTaskPreSelectionSchema,
        fetchOptions: {
            signal,
        },
    });
}
