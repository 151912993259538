import { connect } from "react-redux";

import { handleError } from "../../../lib/utils/error";
import { getCompanyPaymentInfo, updateCompanyPaymentInfo } from "../../../store/company";
import {
    getCompanyPaymentInfoData,
    getCompanyPaymentInfoStatus,
} from "../../../store/selectors/company";
import PaymentInformation from "../components/PaymentInformation";

const mapStateToProps = (state) => ({
    paymentInfo: getCompanyPaymentInfoData(state),
    isLoading: getCompanyPaymentInfoStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    getPaymentInfo: () => dispatch(getCompanyPaymentInfo()),
    updateCompanyPaymentInfo: (dataToSend, dataToStore) =>
        dispatch(updateCompanyPaymentInfo(dataToSend, dataToStore)),
    setError: (error, title) => handleError(error, title),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInformation);
