import { z, ZodError } from "zod";

import { debug } from "@side.co/client-debug";

import { authHeaders } from ".";

const DEFAULT_ERROR_MESSAGE = "Failed to fetch";

interface ApiRequestParams<T extends z.ZodTypeAny> {
    url: string;
    responseSchema: T;
    fetchOptions?: RequestInit;
    timeout?: number;
}

export async function apiRequest<T extends z.ZodTypeAny>({
    url,
    responseSchema,
    fetchOptions = {},
}: ApiRequestParams<T>) {
    try {
        // Merge headers from authHeaders and fetchOptions
        const headers = new Headers(authHeaders());
        if (fetchOptions.headers) {
            new Headers(fetchOptions.headers).forEach((value, key) => {
                headers.append(key, value);
            });
        }

        const response = await fetch(url, {
            ...fetchOptions,
            headers,
            signal: fetchOptions.signal,
        });

        if (!response.ok) {
            throw new Error(
                `${DEFAULT_ERROR_MESSAGE} at ${url}: ${response.status} - ${response.statusText}`,
            );
        }

        // if status is 204 (No Content), parse undefined
        if (response.status === 204) {
            return responseSchema.parse(undefined) as z.infer<T>;
        }

        try {
            const json = await response.json();
            // We cast z.infer to ensure that the return type is correct after transforms
            // https://zod.dev/?id=inferring-the-inferred-type
            return responseSchema.parse(json) as z.infer<T>;
        } catch (error) {
            debug.catch(error);
            if (error instanceof ZodError) {
                console.error("Zod validation error: ", error.issues);
            } else {
                console.error(`Failed to parse JSON: ${error}`);
            }
            return Promise.reject(error);
        }
    } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
            console.info("Fetch aborted: ", error.message ?? url);
        } else if (error instanceof Error && error.name === "TimeoutError") {
            console.error("Fetch timeout: ", error.message ?? url);
        } else {
            debug.catch(error);
            console.error(`${DEFAULT_ERROR_MESSAGE} at ${url}: `, error);
        }
        return Promise.reject(error);
    }
}
