import moment from "moment";
import { createSelector } from "reselect";

import type { State } from "../types";
import type { Invoice } from "../types/invoices";

export const getCurrentInvoices = (state: State) =>
    state.invoices.invoicesByStartDate[state.invoices.selectedStartDate];
export const getPageIndex = (state: State) =>
    state.invoices.startDates.indexOf(state.invoices.selectedStartDate);
export const getPagination = (state: State) => state.invoices.pagination;
export const getStartDates = (state: State) => state.invoices.startDates;
export const getSelectedStartDate = (state: State) => state.invoices.selectedStartDate;
export const getInvoicesByStartDate = (state: State) => state.invoices.invoicesByStartDate;
export const getLoadingStatus = (state: State) => state.invoices.isLoading;
export const getFirstPaymentDate = (state: State) => state.invoices.firstPaymentDate;
export const getHasInvoices = (state: State) => !!state.invoices.firstPaymentDate;

/**
 * List the months to display on the page
 * @returns array of strings (dates of months - format: `YYYY-MM`)
 */
export const getMonthsInPage = createSelector(
    getCurrentInvoices,
    getPageIndex,
    getPagination,
    getSelectedStartDate,
    getInvoicesByStartDate,
    getHasInvoices,
    (
        currentInvoices,
        pageIndex,
        pagination,
        selectedStartDate,
        invoicesByStartDate,
        hasInvoices,
    ) => {
        const monthsInPage = [] as string[];

        // there are no invoices
        // it means the company did not have any invoices so we'll show a black state
        // (in the render function of the component, we check the length of
        // monthsInPage before looping through it)
        if (hasInvoices) {
            for (let i = 0; i <= 6; i += 1) {
                const month = moment(selectedStartDate);
                month.subtract(i, `months`);
                monthsInPage.push(moment(month).format(`YYYY-MM`));
            }
        }

        return monthsInPage;
    },
);

/**
 * Get the invoices and dispatch them by month
 * @returns object of arrays of invoices indexed by month dates
 */
export const getInvoicesByMonth = createSelector(
    getCurrentInvoices,
    getMonthsInPage,
    (currentInvoices, monthsInPage: Array<string>): { [key: string]: Array<Invoice> } => {
        const invoicesByMonth = monthsInPage.reduce((obj, month) => ({ ...obj, [month]: [] }), {});

        if (currentInvoices && currentInvoices.length) {
            currentInvoices.forEach((invoice) => {
                const monthArray = invoicesByMonth[moment(invoice.weekStartDate).format(`YYYY-MM`)];
                if (monthArray) {
                    monthArray.push(invoice);
                }
            });
        }
        return invoicesByMonth;
    },
);
