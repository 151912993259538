/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import i18n from "i18n-js";
import { Button, InputText } from "side-ui";
import { Logo } from "sui";

import { queryClient } from "@App";
import { queries } from "@lib/queries";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "@tanstack/react-router";

import { activateUser } from "../../lib/api/activateAccount";
import { googleActivateUser } from "../../lib/api/googleActivateAccount";
import { useFormInput } from "../../lib/hooks/form";

import "./ActivateAccount.scss";

const ActivateAccount = () => {
    const navigate = useNavigate();
    const { formInput: password, handleChange: setPassword } = useFormInput("", (value) => {
        if (value.length < 8) {
            return i18n.t(`error_password_length_8`);
        }

        if (!/[ `!@#$%^&*()_+\-=[\]{};':"|,.<>/?~]/.test(value)) {
            return i18n.t(`error_password_special_char`);
        }

        return null;
    });
    const [isLinkBroken, setIsLinkBroken] = useState(false);

    const [isPassword, setIsPassword] = useState(true);

    const activateAccountWithGoogle = useGoogleLogin({
        onSuccess: (res) => {
            if (!res.code) {
                return;
            }

            // Send code to backend
            googleActivateUser({ userId, token, code: res.code }).then((userInfo) => {
                if (userInfo) {
                    localStorage.setItem("Meteor.loginToken", userInfo.token);
                    localStorage.setItem("Meteor.userId", userInfo.userId);
                    localStorage.setItem("google_login", "true");
                    if (userInfo.organisationId) {
                        if (userInfo.organisationType === "parent") {
                            localStorage.setItem("side_team_groupId", userInfo.organisationId);
                        } else {
                            localStorage.setItem(
                                "side_team_activeOrganisationId",
                                userInfo.organisationId,
                            );
                        }
                        navigate({ to: `/home` });
                        return;
                    }

                    queryClient.invalidateQueries(queries.user.detail());

                    setTimeout(() => {
                        navigate({ to: `/me/profile` });
                    }, 1000);
                }
            });
        },
        flow: "auth-code",
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const userId = urlParams.get("userId");
        const token = urlParams.get("token");
        if (!userId || !token) {
            setIsLinkBroken(true);
        }
    }, []);

    function activateAccount() {
        const urlParams = new URLSearchParams(window.location.search);

        const userId = urlParams.get("userId");
        const token = urlParams.get("token");

        activateUser({ userId, token, password: password.value }).then((res) => {
            if (res) {
                localStorage.setItem("Meteor.loginToken", res.token);
                localStorage.setItem("Meteor.userId", res.userId);
                if (res.organisationType === "parent") {
                    localStorage.setItem("side_team_groupId", res.organisationId);
                } else {
                    localStorage.setItem("side_team_activeOrganisationId", res.organisationId);
                }

                navigate({ to: `/me/profile` });
            }
        });
    }

    if (isLinkBroken) {
        return (
            <div className='team-activate-account'>
                <header className='team-signin__header'>
                    <a href='https://www.side.co/entreprises' className='team-signin__header__logo'>
                        <Logo version='topbar' />
                    </a>
                    <div className='team-signin__header__title'>
                        {i18n.t(`activate_account_logo_company`)}
                    </div>
                </header>

                <section className='team-activate-account__content'>
                    <h1 className='team-activate-account__content__link-broken'>
                        {i18n.t(`activate_account_link_broken`)}
                    </h1>
                </section>
            </div>
        );
    }

    return (
        <div className='team-activate-account'>
            <header className='team-signin__header'>
                <a href='https://www.side.co/entreprises' className='team-signin__header__logo'>
                    <Logo version='topbar' />
                </a>
                <div className='team-signin__header__title'>
                    {i18n.t(`activate_account_logo_company`)}
                </div>
            </header>

            <section className='team-activate-account__content'>
                <h1 className='team-activate-account__content__title'>
                    {i18n.t(`activate_account_form_title`)}
                </h1>

                <button
                    className='team-activate-account__content__google'
                    type='button'
                    onClick={activateAccountWithGoogle}
                >
                    <img
                        src='https://s3.eu-central-1.amazonaws.com/weslash-static/static/company/signup-google.svg'
                        alt='Google'
                    />
                    <span>{i18n.t(`activate_account_with_google`)}</span>
                </button>

                <div className='team-activate-account__content__divider'>
                    <div className='team-activate-account__content__divider__line' />
                    <span className='team-activate-account__content__divider__text'>
                        {i18n.t(`activate_account_or`)}
                    </span>
                    <div className='team-activate-account__content__divider__line' />
                </div>

                <form className='team-activate-account__content__form' onSubmit={activateAccount}>
                    <div className='team-activate-account__content__form__input'>
                        <div className='team-signup__content__form__input'>
                            <InputText
                                name='password'
                                label={i18n.t(`sign_up_password_label`)}
                                error={password.touched ? password.error : null}
                                placeholder={i18n.t("sign_up_password_placeholder")}
                                value={password.value}
                                onChange={(e) => setPassword(e.target.value)}
                                isPassword={isPassword}
                                iconAfter='Eye'
                                onIconAfterClick={() => setIsPassword(!isPassword)}
                            />
                        </div>
                    </div>
                    <Button
                        customClass='team-activate-account__content__form__submit'
                        type='submit'
                        action={() => {
                            activateAccount();
                        }}
                        fullWidth={true}
                        color='blue'
                    >
                        {i18n.t(`activate_account_log_in`)}
                    </Button>
                </form>
            </section>
        </div>
    );
};

export default ActivateAccount;
