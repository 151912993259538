import { StrictMode } from "react";
import { Provider } from "react-redux";
import { ToastsRoot, ToastsService } from "side-ui";
import { v4 as uuidv4 } from "uuid";

import { handleErrorAsString } from "@lib/utils/error";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AppRouter } from "@routes";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FlagProvider } from "@unleash/proxy-client-react";

import Error from "./components/Error";

import "@styles/tailwind.css";

import "sui/props";

const config = {
    url: process.env.REACT_APP_UNLEASH_URL || import.meta.env.REACT_APP_UNLEASH_URL,
    clientKey:
        process.env.REACT_APP_UNLEASH_CLIENT_KEY || import.meta.env.REACT_APP_UNLEASH_CLIENT_KEY,
    refreshInterval: 15,
    appName: "team",
};

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 20,
            retry: 1,
        },
    },
    queryCache: new QueryCache({
        onError: (error) => {
            ToastsService.addToast({
                id: uuidv4(),
                icon: "Warning",
                content: handleErrorAsString(error),
                isClosable: true,
                type: "warning",
            });
        },
    }),
    mutationCache: new MutationCache({
        onError: (error) =>
            ToastsService.addToast({
                id: uuidv4(),
                icon: "Warning",
                content: handleErrorAsString(error),
                isClosable: true,
                type: "warning",
            }),
    }),
});

const App = ({ store, errorDisplay = false }) => (
    <StrictMode>
        <Provider store={store}>
            <FlagProvider config={config}>
                <GoogleOAuthProvider clientId='54503600174-07prhmecenv6bic8im520a7uq1a9b8sb.apps.googleusercontent.com'>
                    <QueryClientProvider client={queryClient}>
                        <div className='app'>
                            {errorDisplay && <Error />}
                            <AppRouter />
                            <ToastsRoot />
                        </div>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </FlagProvider>
        </Provider>
    </StrictMode>
);

export default App;
