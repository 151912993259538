import i18n from "i18n-js";
import { connect } from "react-redux";
import { ModalsService, ToastsService } from "side-ui";
import { v4 as uuidv4 } from "uuid";

import { router } from "@routes";

import * as backend from "../../../lib/api";
import trackEvent from "../../../lib/trackers";
import DuplicateModal from "../components/DuplicateModal";

const mapStateToProps = (state, ownProps) => ({
    taskName: ownProps.task.name,
    taskJobtypeTranslatedName: ownProps.task.jobTitle,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        task: { status, type, id },
        match,
    } = ownProps;

    return {
        duplicateTask: ({ taskName, attendanceConfigType }) => {
            trackEvent({
                name: `taskOptionButton`,
                params: {
                    optionClick: `duplicateTask`,
                    page: !match || !match.params.status ? `all` : status,
                    taskStatus: status,
                },
            });
            backend
                .duplicateTask({
                    type,
                    name: taskName,
                    attendanceConfigType,
                    taskId: id,
                })
                .then((res) => res.json())
                .then((res) => {
                    if (!res.id) {
                        ToastsService.addToast({
                            id: uuidv4(),
                            icon: "Warning",
                            content: i18n.t("errors_generic"),
                            isClosable: true,
                            type: "warning",
                        });
                        throw new Error(`Task not duplicated`);
                    }
                    router.navigate({
                        to: `/taskPosting/$taskId/motive`,
                        params: {
                            taskId: res.id,
                        },
                    });
                });

            ModalsService.closeModal(`DUPLICATE_TASK`);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateModal);
