import { connect } from "react-redux";

import * as backend from "../../../lib/api";
import { handleError } from "../../../lib/utils/error";
import { getInvoices } from "../../../store/invoices";
import {
    getInvoicesByMonth,
    getLoadingStatus,
    getMonthsInPage,
    getPagination,
    getStartDates,
} from "../../../store/selectors/invoices";
import Invoices from "../components/Invoices";

const mapStateToProps = (state) => ({
    monthsInPage: getMonthsInPage(state),
    invoicesByMonth: getInvoicesByMonth(state),
    pagination: getPagination(state),
    startDates: getStartDates(state),
    isLoading: getLoadingStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    getInvoices: (endDate, startDate) => dispatch(getInvoices(endDate, startDate)),
    downloadInvoices: (endDate) => backend.downloadInvoices(endDate),
    downloadCSV: (params) => backend.downloadCSV(params),
    setError: (error) => handleError(error),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
