import { connect } from "react-redux";

import { hideError } from "../../store/display";

import Error from "./Error";

const mapStateToProps = (state) => {
    const { error, errorTitle } = state.display;

    return {
        error,
        title: errorTitle,
    };
};

const mapDispatchToProps = (dispatch) => ({
    hideError: () => dispatch(hideError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Error);
