import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const JobDescriptionSchema = z.object({
    id: z.string(),
    organisationId: z.string(),
    enabled: z.boolean(),
    hourlyRate: z.number().nullable(),
    origin: z.enum(["account", "parent"]),
    pricingOrigin: z.enum(["detached", "parent"]),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
});

export type CreateJobDescription = z.infer<typeof JobDescriptionSchema>;

const message = "Create job descriptions";
export async function createJobDescription(
    params?: CreateJobDescription,
): Promise<CreateJobDescription> {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: params || {},
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/job-descriptions`, {
                method: `POST`,
                body: JSON.stringify(params || {}),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        const data = await resp.json();
        return data;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
