import i18n from "i18n-js";
import { ModalsService } from "side-ui";
import * as yup from "yup";

import {
    clearOtherParametersValues,
    otherParametersSchema,
} from "./otherParameters/otherParametersValidationLogic";

const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
        (errors, error) => ({
            ...errors,
            [error.path]: error.errors[FIRST_ERROR],
        }),
        {},
    );
};

export const indemnityTransportSchema = () =>
    yup.object().shape({
        publicCoverRate: yup
            .number()
            .moreThan(
                49,
                i18n.t(
                    `settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty`,
                ),
            )
            .lessThan(
                101,
                i18n.t(
                    `settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred`,
                ),
            )
            .required(i18n.t(`error_field_is_required`)),
        otherParameters: yup.object().shape(otherParametersSchema()),
    });

export const submitIndemnityTransport = async (
    values,
    { setSubmitting, resetForm },
    updateIndemnity,
) => {
    const newValues = {
        id: values.id,
        publicCoverRate: parseInt(values.publicCoverRate, 10),
    };
    let otherParameters = clearOtherParametersValues(values.otherParameters);
    if (Object.keys(otherParameters).length === 0) {
        otherParameters = undefined;
    }

    updateIndemnity({ transportation: { ...newValues, otherParameters } })
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        })
        .finally(() => {
            ModalsService.closeModal(`TRANSPORT_INDEMNITY`);
        });
};

export const indemnityLunchValidationSchema = () =>
    yup.object().shape({
        type: yup.string().required(i18n.t(`settings_remuneration_indemnities_lunch_type_error`)),
        amount: yup.number().when("type", (value, schema) => {
            if (value === "ticket" || value === "indemnity") {
                return schema
                    .moreThan(0, i18n.t(`settings_remuneration_indemnities_lunch_min_error`))
                    .lessThan(30, i18n.t(`settings_remuneration_indemnities_lunch_max_error`))
                    .required(i18n.t(`error_field_is_required`));
            }
            return schema;
        }),
        coverRate: yup.number().when("type", {
            is: "ticket",
            then: yup
                .number()
                .moreThan(0, i18n.t(`settings_remuneration_indemnities_lunch_tr_rate_format_error`))
                .lessThan(
                    100,
                    i18n.t(`settings_remuneration_indemnities_lunch_tr_rate_format_error`),
                )
                .required(i18n.t(`error_field_is_required`)),
        }),
        otherParameters: yup.object().shape(otherParametersSchema()),
    });

export const submitIndemnityLunch = async (
    values,
    { setSubmitting, resetForm },
    updateIndemnity,
) => {
    const newValues = {
        id: values.id,
        type: values.type,
        amount: parseFloat(values.amount),
    };
    if (values.type === "ticket") {
        newValues.coverRate = parseInt(values.coverRate, 10);
    }
    let otherParameters = clearOtherParametersValues(values.otherParameters);
    if (Object.keys(otherParameters).length === 0) {
        otherParameters = undefined;
    }

    updateIndemnity({ lunch: { ...newValues, otherParameters } })
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        })
        .finally(() => {
            ModalsService.closeModal(`LUNCH_INDEMNITY`);
        });
};

export const indemnityOtherValidationLogic = (values, indemnities, isEdit) => {
    const schema = yup.object().shape({
        category: yup.string().required(i18n.t(`error_field_is_required`)),
        name: yup.string().required(i18n.t(`error_field_is_required`)),
        amount: yup
            .number()
            .required(i18n.t(`error_field_is_required`))
            .moreThan(0, i18n.t(`error_field_is_required`))
            .typeError(i18n.t(`error_field_is_required`)),
        frequency: yup.string().required(i18n.t(`error_field_is_required`)),
        otherParameters: yup.object().shape(otherParametersSchema()),
    });

    let nameError;
    if (
        !isEdit &&
        values.category !== "" &&
        values.name !== "" &&
        indemnities.other.some(
            (ind) => ind.category === values.category && ind.name === values.name,
        )
    ) {
        nameError = i18n.t(`settings_remuneration_indemnities_other_type_error_already_exists`);
    }

    let errors = {};
    try {
        schema.validateSync(values, { abortEarly: false });
    } catch (error) {
        errors = { ...errors, ...getErrorsFromValidationError(error) };
    }

    return {
        ...(nameError ? { name: nameError } : {}),
        ...errors,
    };
};

export const submitIndemnityOther = async (
    values,
    { setSubmitting, resetForm },
    initialValues,
    updateIndemnity,
) => {
    const newValues = {
        id: values.id,
        category: values.category,
        name: values.name,
        amount: parseFloat(values.amount),
        frequency: {
            type: values.frequency,
        },
    };

    let otherParameters = clearOtherParametersValues(values.otherParameters);
    if (Object.keys(otherParameters).length === 0) {
        otherParameters = undefined;
    }

    updateIndemnity({ other: { ...newValues, otherParameters } })
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        })
        .finally(() => {
            ModalsService.closeModal(`OTHER_INDEMNITY`);
        });
};

export const submitNightHours = async (
    values,
    { setSubmitting, resetForm },
    initialValues,
    updateHoursTypes,
) => {
    const newValues = {
        startHour: parseFloat(values.startHour),
        endHour: parseFloat(values.endHour),
        increase: parseInt(values.increase, 10),
        seniority:
            values.seniority && values.seniority.checked
                ? {
                      daysThreshold: parseInt(values.seniority.daysThreshold, 10),
                      increase: parseInt(values.seniority.increase, 10),
                  }
                : null,
    };

    updateHoursTypes({ night: newValues })
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        })
        .finally(() => {
            ModalsService.closeModal(`night_hours`);
        });
};

const hoursSeniorityIncreaseValidationSchema = () => ({
    checked: yup.boolean(),
    daysThreshold: yup
        .number()
        .when("checked", {
            is: true,
            then: yup
                .number()
                .moreThan(
                    0,
                    i18n.t(
                        `settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0`,
                    ),
                )
                .typeError(i18n.t(`error_field_is_required`))
                .required(i18n.t(`error_field_is_required`)),
        })
        .typeError(""),
    increase: yup
        .number()
        .when("checked", {
            is: true,
            then: yup
                .number()
                .moreThan(
                    0,
                    i18n.t(
                        `settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0`,
                    ),
                )
                .max(200, i18n.t(`settings_remuneration_indemnities_night_hour_increase_error`))
                .typeError(i18n.t(`error_field_is_required`))
                .required(i18n.t(`error_field_is_required`)),
        })
        .typeError(""),
});

export const nightHoursValidationSchema = () =>
    yup.object().shape({
        startHour: yup
            .number()
            .test(
                "startHour",
                i18n.t(`settings_remuneration_indemnities_night_hour_start_error`),
                (value) => value <= 6 || value >= 18,
            )
            .required(i18n.t(`error_field_is_required`)),
        endHour: yup
            .number()
            .test(
                "endHour",
                i18n.t(`settings_remuneration_indemnities_night_hour_end_error`),
                (value) => value <= 9 || value >= 24,
            )
            .when("startHour", (startHour, schema) => {
                if (startHour <= 6) {
                    return schema.moreThan(
                        startHour,
                        i18n.t(
                            `settings_remuneration_indemnities_night_hour_end_before_start_error`,
                        ),
                    );
                }

                return schema;
            })
            .required(i18n.t(`error_field_is_required`)),
        increase: yup
            .number()
            .max(200, i18n.t(`settings_remuneration_indemnities_night_hour_increase_error`))
            .required(i18n.t(`error_field_is_required`)),
        seniority: yup.object().shape(hoursSeniorityIncreaseValidationSchema()),
    });

export const extraHoursValidationSchema = () =>
    yup.object().shape({
        thresholds: yup
            .array()
            .of(
                yup.object().shape({
                    threshold: yup
                        .number()
                        .typeError(i18n.t(`error_field_is_required`))
                        .min(
                            35,
                            i18n.t(`settings_remuneration_indemnities_extra_hour_threshold_format`),
                        )
                        .max(
                            48,
                            i18n.t(`settings_remuneration_indemnities_extra_hour_threshold_format`),
                        )
                        .required(i18n.t(`error_field_is_required`)),
                    increase: yup
                        .number()
                        .typeError(i18n.t(`error_field_is_required`))
                        .min(
                            0,
                            i18n.t(
                                `settings_remuneration_indemnities_extra_hour_increase_error_min`,
                            ),
                        )
                        .max(
                            200,
                            i18n.t(
                                `settings_remuneration_indemnities_extra_hour_increase_error_max`,
                            ),
                        )
                        .required(i18n.t(`error_field_is_required`)),
                }),
            )
            .required(i18n.t(`error_field_is_required`)) // these constraints are shown if and only if inner constraints are satisfied
            .min(1, i18n.t(`settings_remuneration_indemnities_extra_hour_min`)),
    });

export const submitExtraHours = async (
    values,
    { setSubmitting, resetForm },
    initialValues,
    updateHoursTypes,
) => {
    const newValues = {
        thresholds: values.thresholds.map((threshold) => ({
            threshold: parseInt(threshold.threshold, 10),
            increase: parseInt(threshold.increase, 10),
        })),
    };

    updateHoursTypes({ extra: newValues })
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        })
        .finally(() => {
            ModalsService.closeModal(`EXTRA_HOURS_FORM`);
        });
};

export const submitSundayHours = async (
    values,
    { setSubmitting, resetForm },
    initialValues,
    updateHoursTypes,
) => {
    const newValues = {
        increase: parseInt(values.increase, 10),
        seniority:
            values.seniority && values.seniority.checked
                ? {
                      daysThreshold: parseInt(values.seniority.daysThreshold, 10),
                      increase: parseInt(values.seniority.increase, 10),
                  }
                : null,
        ztiIncrease:
            values.zti && values.zti.checked ? parseInt(values.zti.increase, 10) : undefined,
    };

    updateHoursTypes({ sundays: newValues })
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        })
        .finally(() => {
            ModalsService.closeModal(`sunday_hours`);
        });
};

export const sundayHoursValidationSchema = () =>
    yup.object().shape({
        increase: yup
            .number()
            .min(0, i18n.t(`settings_remuneration_indemnities_sunday_hours_rate_error_min`))
            .max(200, i18n.t(`settings_remuneration_indemnities_sunday_hours_rate_error_max`))
            .required(i18n.t(`error_field_is_required`)),
        zti: yup.object().shape({
            checked: yup.boolean(),
            increase: yup
                .number()
                .when("checked", {
                    is: true,
                    then: yup
                        .number()
                        .moreThan(
                            0,
                            i18n.t(`settings_remuneration_indemnities_sunday_hours_rate_error_min`),
                        )
                        .max(
                            200,
                            i18n.t(`settings_remuneration_indemnities_sunday_hours_rate_error_max`),
                        )
                        .typeError(i18n.t(`error_field_is_required`))
                        .required(i18n.t(`error_field_is_required`)),
                })
                .typeError(""),
        }),
        seniority: yup.object().shape(hoursSeniorityIncreaseValidationSchema()),
    });

export const holidaysHoursValidationSchema = () =>
    yup.object().shape({
        increase: yup
            .number()
            .min(0, i18n.t(`settings_remuneration_indemnities_bank_holidays_hours_rate_error_min`))
            .max(
                200,
                i18n.t(`settings_remuneration_indemnities_bank_holidays_hours_rate_error_max`),
            )
            .required(i18n.t(`error_field_is_required`)),
        seniority: yup.object().shape(hoursSeniorityIncreaseValidationSchema()),
        solidarity: yup.string(),
    });

export const submitHolidaysHours = async (
    values,
    { setSubmitting, resetForm },
    initialValues,
    updateHoursTypes,
) => {
    const newValues = {
        increase: parseInt(values.increase, 10),
        seniority:
            values.seniority && values.seniority.checked
                ? {
                      daysThreshold: parseInt(values.seniority.daysThreshold, 10),
                      increase: parseInt(values.seniority.increase, 10),
                  }
                : null,
        solidarity: values.solidarity?.length > 0 ? values.solidarity : null,
    };

    updateHoursTypes({ bankHolidays: newValues })
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        })
        .finally(() => {
            ModalsService.closeModal(`HOLIDAYS_HOURS_FORM`);
        });
};
