import i18n from "i18n-js";
import moment from "moment";
import { IconArrowRight, IconSmileyHappy, ModalsService, Tag } from "side-ui";

import CommentsModal from "./CommentsModal";

import "./ProfileItem.scss";

const ProfileItem = ({
    id,
    title,
    subtitle = "",
    startDate,
    endDate,
    jobTypeFeedbacks = [],
    feedbacks = [],
    duration = "",
    tasksCount,
    subitems = [],
}) => {
    const feedbacksWithCommentsArray = feedbacks?.filter(
        (feedback) => feedback?.comment?.length > 0,
    );

    const jobTypeFeedbacksWithCommentsArray = jobTypeFeedbacks?.filter(
        (jobTypeFeedback) => jobTypeFeedback?.length > 0,
    );

    return (
        <div className='profile__item' key={id}>
            {title && (
                <div className='profile__item__row profile__item__row--top'>
                    <p className='profile__item__title'>{title}</p>
                    {(feedbacks?.length || jobTypeFeedbacks?.length || 0) > 0 && (
                        <div
                            className={`profile__item__feedbacks ${
                                (feedbacksWithCommentsArray?.length ||
                                    jobTypeFeedbacksWithCommentsArray?.length ||
                                    0) > 0
                                    ? `profile__item__feedbacks--with-comments`
                                    : ``
                            }`}
                            onClick={() =>
                                (feedbacksWithCommentsArray?.length ||
                                    jobTypeFeedbacksWithCommentsArray?.length ||
                                    0) > 0 &&
                                ModalsService.openModal({
                                    id: `COMMENTS_MODAL`,
                                    content: (
                                        <CommentsModal
                                            feedbacks={feedbacksWithCommentsArray}
                                            jobTypeFeedbacks={jobTypeFeedbacksWithCommentsArray}
                                            duration={duration && duration}
                                            tasksCount={tasksCount && tasksCount}
                                            title={title}
                                        />
                                    ),
                                })
                            }
                        >
                            <IconSmileyHappy customClass='profile__item__feedbacks__icon' />
                            <p className='profile__item__feedbacks__count'>
                                {feedbacks?.length || jobTypeFeedbacks?.length}
                            </p>
                        </div>
                    )}
                </div>
            )}
            {(subtitle || (startDate && endDate) || duration || tasksCount) && (
                <div className='profile__item__row'>
                    <div className='profile__item__row__left'>
                        {subtitle && <p className='profile__item__subtitle'>{subtitle}</p>}
                        {tasksCount && (
                            <p className='profile__item__subtitle'>
                                {i18n.t("sider_profile_panel_tasks", {
                                    tasksCount,
                                    numberOfTasks: tasksCount,
                                    plural: tasksCount > 1 ? `s` : ``,
                                })}
                            </p>
                        )}
                        {startDate && endDate && (
                            <div className='profile__item__dates'>
                                <p className='profile__item__subtitle profile__item__dates--start'>
                                    {moment(startDate).format(`DD/MM/YYYY`)}
                                </p>
                                <IconArrowRight customClass='profile__item__dates__icon' />
                                <p className='profile__item__subtitle profile__item__dates--end'>
                                    {moment(endDate).format(`DD/MM/YYYY`)}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className='profile__item__row__right'>
                        {duration && <p className='profile__item__duration'>{duration}</p>}
                    </div>
                </div>
            )}
            {subitems && (
                <div className='profile__item__row profile__item__row--subitems'>
                    {subitems.map(
                        (subitem, i) =>
                            subitem.name && (
                                <Tag key={i} label={subitem.name} color='grey' big={true} />
                            ),
                    )}
                </div>
            )}
        </div>
    );
};

export default ProfileItem;
