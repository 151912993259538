import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type UpdateUserParams = { locale: string };
export function updateUser(params: UpdateUserParams) {
    debug.addBreadcrumb({
        message: `Updates user`,
        category: `action`,
        data: {
            locale: params.locale,
        },
    });

    return fetch(
        withAuthHeaders(`${apiConf.BACK_URL}/v1/users/me`, {
            method: `PUT`,
            body: JSON.stringify({
                locale: params.locale,
            }),
        }),
    );
}
