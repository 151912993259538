import I18n from "i18n-js";

import { rootRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import SignIn from "./SignIn";

export const signInRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `signin`,
    component: SignIn,
    beforeLoad: () => {
        localStorage.clear();
        return { getTitle: () => I18n.t(`sign_in_title`) };
    },
});
