export function addressAdapter(siderAddress) {
    if (!siderAddress) {
        return "";
    }

    if (siderAddress.address) {
        return siderAddress.address;
    }

    // below part needs to be negotiated with backend
    const { streetName, streetNumber = "", city, postalCode } = siderAddress;

    if (streetName && city && postalCode) {
        return `${streetNumber && `${streetNumber} `}${streetName}, ${postalCode} ${city}`;
    }

    return "";
}

export function addressComponentsAdapter(addressComponents) {
    const address = {};

    if (!addressComponents || !addressComponents.length) {
        return address;
    }
    // disable false positives indent and return of arrow function
    // since foreach return nothing
    /* eslint-disable */
    addressComponents.forEach((addressPart) => {
        switch (addressPart.types[0]) {
            case "street_number": {
                address.streetNumber = addressPart.long_name;
                break;
            }

            case "premise":
            case "route": {
                address.streetName = addressPart.long_name;
                break;
            }

            case "postal_code": {
                address.postalCode = addressPart.long_name;
                break;
            }

            case "locality": {
                address.city = addressPart.long_name;
                break;
            }

            case "administrative_area_level_1": {
                address.region = addressPart.long_name;
                break;
            }

            case "administrative_area_level_2": {
                address.department = addressPart.long_name;
                break;
            }

            case "country": {
                address.country = addressPart.long_name;
                break;
            }

            default:
                return false;
        }
    });

    return address;
    /* eslint-enable */
}
// eslint-disable-next-line consistent-return
export function keyPressHandler({ key }, keyName, callback) {
    // if you have passed an array of keyNames
    if (Array.isArray(keyName)) {
        return keyName.includes(key) ? callback() : null;
    }

    if (key === keyName) {
        return callback();
    }
}

// checks for falsy values
function isFalsy(val) {
    return Boolean(val) === false;
}

// traverses every value and checks if all are falsy
// useful when object is defined but no values are actually set
export function allEmptyValues(object) {
    if (!object) {
        return true;
    }

    return Object.values(object).every((value) => {
        // if value is an object
        // traverse it recursively
        if (typeof value === "object" && value instanceof Object) {
            return allEmptyValues(value);
        }

        if (Array.isArray(value)) {
            return value.length === 0;
        }

        return isFalsy(value);
    });
}
