import { useEffect, useState } from "react";
import { isBefore, isValid } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { z } from "zod";

import { PostAttendancesParams, SiderIdSchema } from "@lib/api/postAttendances";

const requiredColumns = ["siderId", "startDate", "endDate", "breakDuration"];
export type FileImportErrors = {
    column?: (typeof requiredColumns)[number][];
    empty?: number[];
    format?: number[];
    siderId?: number[];
    endBeforeStart?: number[];
    fileAttDuplicate?: number[];
    dbAttDuplicate?: number[];
    shiftsNotFound?: number[];
};

const BreakDurationSchema = z.string().regex(/^([01]?[0-9]|2[0-3]):([0-59]|[0-5][0-9])$/);

export function useCsvToJson() {
    const [file, setFile] = useState<File[]>();
    const [json, setJson] = useState<PostAttendancesParams["attendances"]>([]);
    const [errors, setErrors] = useState<FileImportErrors | null>(null);

    function reset() {
        setFile([]);
        setErrors(null);
    }

    function processCSV(str: string): PostAttendancesParams["attendances"] {
        const separator = (() => {
            const commaCount = (str.match(/,/g) || []).length;
            const semiColonCount = (str.match(/;/g) || []).length;
            return commaCount > semiColonCount ? "," : ";";
        })();
        const errs: Required<FileImportErrors> = {
            column: [],
            empty: [],
            format: [],
            siderId: [],
            endBeforeStart: [],
            fileAttDuplicate: [],
            dbAttDuplicate: [],
            shiftsNotFound: [],
        };
        const headers = str
            .slice(0, str.indexOf("\n"))
            .split(separator)
            .map((h) => h.trim());

        for (const col of requiredColumns) {
            if (!headers.includes(col)) {
                errs.column.push(col);
            }
        }

        const rows = str.slice(str.indexOf("\n") + 1).split("\n");

        const shifts = rows.map((row, rowIndex) => {
            const startDateIndex = headers.indexOf("startDate"); // to check if end date is before start date
            const values: (string | number)[] = row.split(separator);

            const shift = headers.reduce((obj, header: string, headerIndex) => {
                if (!requiredColumns.includes(header)) return obj;
                const value = (values[headerIndex] as string)?.trim().replace("\r", "");
                // rowIndex + 2 stands for the row number in the file seen by the user in Sheets or Excel
                // Line 1 is the header, so data starts at line 2
                if (!value) {
                    if (!errs.empty.includes(rowIndex + 2)) {
                        errs.empty.push(rowIndex + 2);
                    }
                } else {
                    switch (header) {
                        case "siderId": {
                            const parsed = SiderIdSchema.safeParse(value);
                            if (!parsed.success) errs.siderId.push(rowIndex + 2);
                            break;
                        }
                        case "startDate":
                        case "endDate": {
                            const isDateValid = isValid(new Date(value));

                            if (isDateValid) {
                                if (header === "endDate") {
                                    const endDate = new Date(value);
                                    const startDate = new Date(values[startDateIndex]);
                                    if (isValid(startDate) && isBefore(endDate, startDate)) {
                                        errs.endBeforeStart.push(rowIndex + 2);
                                    }
                                }
                                values[headerIndex] = zonedTimeToUtc(
                                    new Date(value),
                                    "Europe/Paris",
                                ).toISOString();
                            } else {
                                if (!errs.format.includes(rowIndex + 2)) {
                                    errs.format.push(rowIndex + 2);
                                }
                            }
                            break;
                        }
                        case "breakDuration": {
                            const parsed = BreakDurationSchema.safeParse(value);
                            if (!parsed.success) {
                                if (!errs.format.includes(rowIndex + 2)) {
                                    errs.format.push(rowIndex + 2);
                                }
                            } else {
                                const time = value.split(":");
                                values[headerIndex] = Number(time[0]) * 60 + Number(time[1]); // minutes
                            }
                            break;
                        }
                    }
                }
                obj[header] =
                    typeof values[headerIndex] === "string"
                        ? (values[headerIndex] as string).trim().replace("\r", "")
                        : values[headerIndex];
                return obj;
            }, {});
            return shift;
        });
        if (Object.values(errs).some((v) => v.length)) {
            setErrors(errs);
        } else setErrors(null);

        return shifts as PostAttendancesParams["attendances"];
    }

    useEffect(() => {
        if (file?.length && file[0]) {
            const csv = file[0];
            const reader = new FileReader();

            reader.onload = function (e) {
                const text = e.target?.result;
                if (typeof text !== "string") return;
                setJson(processCSV(text));
            };

            reader.readAsText(csv);
        }
        if (file?.length && !file[0]) {
            setFile([]);
        }
        if (!file?.length) setJson([]);
    }, [file]);

    return {
        file,
        setFile,
        errors,
        reset,
        json,
        setJson,
        setErrors,
    };
}
