import { connect } from "react-redux";

import { deleteIndemnity, updateIndemnity } from "../../../../store/company";

import IndemnityTransport from "./IndemnityTransport";

const mapDispatchToProps = (dispatch) => ({
    updateIndemnity: (data) => dispatch(updateIndemnity(data)),
    deleteIndemnity: (id) => dispatch(deleteIndemnity("transportation", id)),
});

export default connect(null, mapDispatchToProps)(IndemnityTransport);
