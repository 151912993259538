import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type DuplicateTaskParams = {
    taskId: string;
    type: "delegation" | "gestion";
    name: string;
    attendanceConfigType: "basic" | "timesheet";
};
export function duplicateTask(params: DuplicateTaskParams) {
    debug.addBreadcrumb({
        message: `Duplicate task`,
        category: `action`,
        data: params,
    });

    return fetch(
        withAuthHeaders(`${apiConf.BACK_URL}/tasks/${params.taskId}/clone`, {
            method: `POST`,
            body: JSON.stringify({
                type: params.type,
                name: params.name,
                attendanceConfigType: params.attendanceConfigType,
            }),
        }),
    );
}
