import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { EquipmentSchema } from "./getJobDescriptions";
import { LanguageSchema, LicenceSchema, RiskSchema, ToolSchema } from "./getTask";
import { apiConf } from "./index";

const JobDescriptionOptionsSchema = z.object({
    equipmentList: z.array(EquipmentSchema),
    languages: z.array(LanguageSchema),
    licences: z.array(LicenceSchema),
    risks: z.array(RiskSchema),
    tools: z.array(ToolSchema),
});

const message = "Get options for job description form";

export async function getJobDescriptionOptions() {
    debug.addBreadcrumb({
        message,
        category: `action`,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/job-descriptions/options`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return JobDescriptionOptionsSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
