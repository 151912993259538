import i18n from "i18n-js";
import moment from "moment";

import addMomentCustom from "../moment-custom";

import en from "./en-us";
import fr from "./fr-fr";

export default (locale) => {
    i18n.translations = { en, fr };
    i18n.fallbacks = true;
    i18n.defaultLocale = `fr`;
    const currentLocale = locale || localStorage.getItem(`side_team_locale`) || `fr`;
    i18n.locale = currentLocale;
    // i18n.missingTranslation = function () { return undefined; };
    addMomentCustom();
    moment.locale(currentLocale);
    return i18n;
};
