import { connect } from "react-redux";

import { handleError } from "../../../lib/utils/error";
import { confirmIndemnities, getCompanyRemunerationInfo } from "../../../store/company";
import {
    areIndemnitiesConfirmed,
    getCompanyRemunerationInfoData,
    getCompanyRemunerationInfoStatus,
} from "../../../store/selectors/company";
import RemunerationInformation from "../components/RemunerationInformation";

const mapStateToProps = (state) => ({
    remunerationInfo: getCompanyRemunerationInfoData(state),
    isLoading: getCompanyRemunerationInfoStatus(state),
    areIndemnitiesConfirmed: areIndemnitiesConfirmed(state),
});

const mapDispatchToProps = (dispatch) => ({
    getCompanyRemunerationInfo: () => dispatch(getCompanyRemunerationInfo()),
    confirmIndemnities: () => dispatch(confirmIndemnities()),
    setError: (error, title) => handleError(error, title),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemunerationInformation);
