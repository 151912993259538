import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type PostFeedbackParams = {
    organisationId: string;
    siderId: string;
    data: {
        taskId: string;
        typeId: string;
        managerId: string;
        workAgain: 1 | 0 | -1;
        pro: 1 | 0 | -1;
        perf: 1 | 0 | -1;
        comment: string;
    };
};
export type PostFeedbackResponse = string;
export async function postFeedback(params: PostFeedbackParams): Promise<PostFeedbackResponse> {
    debug.addBreadcrumb({
        message: `Post a feedback`,
        category: `action`,
        data: {
            ...params.data,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/v1/feedback/organisation/${params.organisationId}/sider/${params.siderId}`,
                {
                    method: `POST`,
                    body: JSON.stringify({
                        ...params.data,
                    }),
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("PostFeedback"));
        }
        const json = await resp.json();
        return json;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("PostFeedback"));
    }
}
