import { connect } from "react-redux";

import { emptyContracts, getContractsBatches, updateCurrentTab } from "../../../store/contracts";
import { updateFilter } from "../../../store/display";
import { getManagerIdFilter } from "../../../store/selectors/display";
import { getTasksCurrentPagination, getTasksStatuses } from "../../../store/selectors/tasks";
import { getStatuses } from "../../../store/tasks";
import TasksList from "../components/TasksList";

const mapStateToProps = (state, ownProps) => ({
    statuses: getTasksStatuses(state),

    managerIdFilter: getManagerIdFilter(state),
    pagination: getTasksCurrentPagination(state, ownProps),
});

const mapDispatchToProps = (dispatch) => ({
    getUnsignedContractsBatches: () => {
        dispatch(emptyContracts());
        dispatch(updateCurrentTab("toSign"));
        dispatch(getContractsBatches(0, true));
    },
    addFilter: (key, value) => dispatch(updateFilter(key, value)),
    getStatuses: () => dispatch(getStatuses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksList);
