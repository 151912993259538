import moment from "moment";

// returns the date formatted only for timesheets logic purposes
export const getTimesheetsDateFormat = (date) => moment(date).format(`YYYY-MM-DD`);

// returns a string representation of a number of hours
export const numberToHours = (n) => {
    const hours = parseInt(n, 10); // remove decimal
    const minutes = ((n % 1) * 60).toFixed(0);

    return minutes !== "0"
        ? `${hours > 10 ? hours : `${hours}`}h${minutes > 10 ? minutes : `0${minutes}`}`
        : `${hours}h`;
};

/**
 * @param {Date} week a week
 * @returns {Array} a range of 7 days from monday to sunday
 */
export function getWeekdays(week) {
    const startOfWeek = moment(week).startOf("isoWeek");
    const endOfWeek = moment(week).endOf("isoWeek");

    const weekDays = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
        weekDays.push(day.toDate());
        day = day.clone().add(1, "d");
    }

    return weekDays;
}

/**
 * @param {Date} date1 first date to compare
 * @param {Date} date2 second date
 * @returns {Boolean}
 */
export function isSameDay(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}
