import I18n from "i18n-js";

import { globalLayoutRoute } from "@routes";
import { allowAdminAndUser, allowCompany } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import Invoices from "./index";

export const invoicesRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `invoices`,
    component: Invoices,
    beforeLoad: async () => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() && allowedAdminAndUser)) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => I18n.t(`doc_title_invoices`) };
    },
});
