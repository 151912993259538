import i18n from "i18n-js";
import { ExpandableCheckboxPanel, InputNumber } from "side-ui";

import { onFieldChange } from "./onFieldChange";

export const SeniorityCondition = (props) => {
    const { field, form } = props;
    const { errors, touched, handleBlur, setFieldValue } = form;

    return (
        <div className='other-parameters__field'>
            <ExpandableCheckboxPanel
                checked={field.value.checked}
                label={i18n.t(`settings_remuneration_indemnities_seniority_switch`)}
                onChange={() => {
                    onFieldChange(
                        "seniority",
                        "checked",
                        !field.value.checked,
                        form.values.otherParameters,
                        setFieldValue,
                    );
                }}
            >
                <InputNumber
                    id='otherParameters.seniority.daysThreshold'
                    label={i18n.t(`settings_remuneration_indemnities_seniority_threshold`)}
                    placeholder={60}
                    onChange={(e) =>
                        onFieldChange(
                            "seniority",
                            "daysThreshold",
                            e.target.value,
                            form.values.otherParameters,
                            setFieldValue,
                        )
                    }
                    onBlur={handleBlur}
                    smallInput={true}
                    value={field.value.daysThreshold}
                    error={
                        touched.otherParameters?.seniority?.daysThreshold &&
                        errors.otherParameters?.seniority?.daysThreshold
                    }
                    hint={i18n.t(
                        `settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit`,
                    )}
                />
            </ExpandableCheckboxPanel>
        </div>
    );
};
