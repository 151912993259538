import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type GetOrganisationResponse = {
    children: any[];
    members: Member[];
    organisation: Organisation;
    parent: string;
};

type Organisation = {
    id: string;
    name: string;
    apeCode: string;
    siret: string;
    invoiceInfos: InvoiceInfos;
    hoursTypesConfirmation: HoursTypesConfirmation;
    indemnitiesConfirmation: HoursTypesConfirmation;
    users: User[];
    workLegalStatus: string;
    logoUrl: string;
    features: string[];
    organisationType: string;
    parentOrganisationIds: string[];
    industry: string;
    attendanceConfigType: "default" | "timesheet";
};

type User = {
    id: string;
    status: string;
    index: number;
    roles: string[];
};

type HoursTypesConfirmation = {
    date: string;
    userId: string;
};

type InvoiceInfos = {
    businessName: string;
    address: Address;
};

type Address = {
    country: string;
    street: string;
    postalCode: string;
    city: string;
};

type Member = {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

export async function getCompanyInfo(): Promise<GetOrganisationResponse> {
    try {
        const resp = await fetch(withAuthHeaders(`${apiConf.BACK_URL}/v1/organisations`));
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("GetCompanyInfo"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("GetCompanyInfo"));
    }
}
