import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { addMinutes, format } from "date-fns";
import { enGB, fr } from "date-fns/locale";
import I18n from "i18n-js";
import { atom, useAtom } from "jotai";
import { ModalsService, ToastsService } from "side-ui";
import { ArrowForwardIos, Button, Checkbox, Delete, Duplicate, Edit } from "sui";

import { patchShifts, Task } from "@lib/api";
import { queries } from "@lib/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import ShiftDuplicationModal from "../ShiftDuplicationModal/ShiftDuplicationModal";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./Table";

import styles from "./ShiftsTable.module.css";

export const shiftsToDeleteAtom = atom<Task["shifts"][number]["id"][]>([]);

type ShiftTableProps = {
    shifts: Task["shifts"];
    openModal: (mode: "edition" | "deletion", shift: Task["shifts"][number]) => void;
};

export function ShiftsTable({ shifts, openModal }: ShiftTableProps) {
    const locale = I18n.locale;
    const [shiftsToDelete, setShiftsToDelete] = useAtom(shiftsToDeleteAtom);
    const [tableHeight, setTableHeight] = useState(0);
    const { data: currentYearHolidays = [] } = useQuery(
        queries.holiday.list(new Date().getFullYear()),
    );
    const { data: nextYearHolidays = [] } = useQuery(
        queries.holiday.list(new Date().getFullYear() + 1),
    );
    const holidays = [...(currentYearHolidays ?? []), ...(nextYearHolidays ?? [])];
    const route = getRouteApi("/globalLayout/taskPosting/$taskId/shifts");
    const { taskId } = route.useParams();
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleResize = () => {
            if (tableContainerRef.current) {
                // 148 is the height of the header + footer
                setTableHeight(tableContainerRef.current.offsetTop + 148);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // filter out deleted shifts
    useEffect(() => {
        setShiftsToDelete((prev) => prev.filter((id) => shifts.some((shift) => shift.id === id)));
    }, [shifts.length]);

    function selectToggleAll() {
        if (shiftsToDelete.length === shifts.length) {
            setShiftsToDelete([]);
        } else {
            setShiftsToDelete(shifts.map((shift) => shift.id));
        }
    }

    const columns = useMemo<ColumnDef<Task["shifts"][number]>[]>(
        () => [
            {
                accessorKey: "id",
                header: () => {
                    return (
                        <Checkbox
                            checked={shiftsToDelete.length === shifts.length}
                            onChange={selectToggleAll}
                        />
                    );
                },
                size: 56,
                cell: ({ row }) => {
                    const shiftId = row.original.id;
                    const isChecked = shiftsToDelete.includes(shiftId);
                    return (
                        <Checkbox
                            checked={isChecked}
                            onChange={() => {
                                setShiftsToDelete((prev) =>
                                    isChecked
                                        ? prev.filter((id) => id !== shiftId)
                                        : [...prev, shiftId],
                                );
                            }}
                        />
                    );
                },
            },
            {
                accessorKey: "startDate",
                header: I18n.t("task_shifts_table_shifts"),
                size: 193,
                cell: (info) => {
                    const { startDate } = info.row.original;
                    return (
                        <div className={styles.inline}>
                            <span className={styles.weekday}>
                                {format(startDate, "EEEE", {
                                    locale: locale === "fr" ? fr : enGB,
                                }).slice(0, 3)}
                            </span>
                            {format(startDate, "P", {
                                locale: locale === "fr" ? fr : enGB,
                            })}
                        </div>
                    );
                },
            },
            {
                id: "hours",
                header: I18n.t("task_shifts_table_hours"),
                size: 170,
                cell: ({ row }) => {
                    return (
                        <div className={styles.hours}>
                            {format(row.original.startDate, "HH:mm", {
                                locale: locale === "fr" ? fr : enGB,
                            })}
                            <ArrowForwardIos />
                            {format(row.original.endDate, "HH:mm", {
                                locale: locale === "fr" ? fr : enGB,
                            })}
                        </div>
                    );
                },
            },
            {
                id: "breakDuration",
                header: I18n.t("task_shifts_table_break"),
                size: 128,
                cell: ({ row }) => {
                    if (!row.original.breakDuration)
                        return I18n.t("task_shifts_modal_break_placeholder");
                    return format(
                        addMinutes(new Date(0, 0, 0), row.original.breakDuration),
                        row.original.breakDuration > 59 ? "H'h'm 'min'" : "m 'min'",
                    );
                },
            },
            {
                accessorKey: "slots",
                header: "Sider",
                size: 140,
                cell: ({ row }) => {
                    return `${row.original.slots} Sider${row.original.slots > 1 ? "s" : ""}`;
                },
            },
            {
                accessorKey: "actions",
                header: "Actions",
                cell: ({ row }) => {
                    const shiftId = row.original.id;
                    const isChecked = shiftsToDelete.includes(shiftId);
                    if (isChecked) return null;

                    const { mutate: duplicateShifts } = useMutation({
                        mutationFn: patchShifts,
                        onSuccess: () => {
                            queryClient.invalidateQueries(queries.task.detail(taskId));
                            ToastsService.addToast({
                                id: "location_favorite_list_addition",
                                icon: "Checkmark",
                                content: I18n.t(`shifts_duplicate_modal_success`),
                                isClosable: true,
                                type: "confirmation",
                            });
                        },
                    });

                    async function handleDuplicateShifts(
                        shifts: Parameters<typeof patchShifts>[0]["shifts"],
                    ) {
                        duplicateShifts({
                            taskId: taskId,
                            shifts,
                        });
                    }

                    function openDuplicationModal(shift) {
                        ModalsService.openModal({
                            id: `SHIFTS_DUPLICATION`,
                            content: (
                                <ShiftDuplicationModal
                                    shift={{
                                        ...shift,
                                        startDate: new Date(shift.startDate),
                                        endDate: new Date(shift.endDate),
                                    }}
                                    holidays={holidays}
                                    handleShiftDuplication={handleDuplicateShifts}
                                />
                            ),
                        });
                    }

                    return (
                        <div className={styles.actions}>
                            <Button
                                className={styles.edit}
                                intention='secondary'
                                title='Dupliquer'
                                onClick={() => openDuplicationModal(row.original)}
                                icon={<Duplicate />}
                            />
                            <Button
                                className={styles.edit}
                                intention='secondary'
                                title='Editer'
                                onClick={() => openModal("edition", row.original)}
                                icon={<Edit />}
                            />
                            <Button
                                className={styles.delete}
                                intention='danger'
                                shape='invisible'
                                title='Supprimer'
                                onClick={() => openModal("deletion", row.original)}
                                icon={<Delete />}
                            />
                        </div>
                    );
                },
            },
        ],
        [shiftsToDelete],
    );

    const table = useReactTable({
        data: shifts,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const { rows } = table.getRowModel();

    const tableContainerRef = useRef<HTMLTableElement>(null);

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 88,
        getScrollElement: () => tableContainerRef.current,
        overscan: 5,
    });

    return (
        <div style={{ height: `calc(100vh - ${tableHeight}px)`, overflowY: "auto" }}>
            <Table ref={tableContainerRef}>
                <TableHeader className={styles.tableHeader}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        className={styles.tableHead}
                                        style={{
                                            width: header.getSize(),
                                        }}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody
                    className={styles.tableBody}
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const row = rows[virtualRow.index] as Row<Task["shifts"][number]>;
                        return (
                            <TableRow
                                data-index={virtualRow.index} //needed for dynamic row height measurement
                                ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                                key={row.id}
                                className={clsx(
                                    styles.tableRow,
                                    shiftsToDelete.includes(row.original.id) && styles.selected,
                                )}
                                style={{
                                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                                }}
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <TableCell
                                            key={cell.id}
                                            className={styles.tableCell}
                                            style={{
                                                width: cell.column.getSize(),
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}
