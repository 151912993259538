import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type GetLegalValuesResponse = {
    minimumSalary: number;
};

export function getLegalValues() {
    debug.addBreadcrumb({
        message: `Get legal values`,
        category: `action`,
    });

    return fetch(
        withAuthHeaders(`${apiConf.BACK_URL}/legal-values`, {
            method: `GET`,
        }),
    );
}
