import { forwardRef } from "react";
import { addDays } from "date-fns";
import I18n from "i18n-js";
import { ToastsService } from "side-ui";
import {
    Button,
    DatePicker,
    fonts,
    Label,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    TextArea,
    TextField,
} from "sui";

import { postPermanentContracts } from "@lib/api/postPermanentContracts";
import { useFormInput } from "@lib/hooks";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQuery } from "@tanstack/react-query";

import { LocationInput } from "./LocationInput";

import styles from "./HorizonModal.module.css";

type Props = { reset: () => void };
export const HorizonModal = forwardRef<HTMLDialogElement, Props>(function HorizonModal(
    { reset },
    ref,
) {
    const { data: company } = useQuery(queries.company.detail());
    const {
        formInput: { value: jobName },
        handleChange: setJobName,
    } = useFormInput("");
    const {
        formInput: { value: location },
        handleChange: setLocation,
    } = useFormInput({
        address: "",
        postalCode: "",
        city: "",
        country: "",
        lat: 0,
        lon: 0,
    });
    const {
        formInput: { value: availablePositions },
        handleChange: setAvailablePositions,
    } = useFormInput(1);
    const { formInput: salary, handleChange: setSalary } = useFormInput<{
        min?: number;
        max?: number;
    }>({
        min: undefined,
        max: undefined,
    });
    const {
        formInput: { value: commentary },
        handleChange: setCommentary,
    } = useFormInput("");
    const {
        formInput: { value: startDate },
        handleChange: setStartDate,
    } = useFormInput(addDays(new Date(), 1));

    const { mutate: createPermanentContractsRequest, isPending } = useMutation({
        mutationFn: postPermanentContracts,
        onSuccess: () => {
            ToastsService.addToast({
                id: "horizon_modal_success",
                icon: "Checkmark",
                content: I18n.t("horizon_modal_confirmation"),
                isClosable: true,
                type: "confirmation",
            });
            onClose();
            trackEvent({
                name: `bbhorizon - user sends cdi request`,
                params: {
                    logAs: !!localStorage.getItem("side_team_logas_email"),
                    companyId: company?.organisation.id,
                    companyName: company?.organisation.name,
                    network: !!company?.parent,
                    industry: company?.organisation.industry || '',
                    networkName: company?.parent,
                },
            });
        },
    });

    function onClose() {
        if (ref && "current" in ref) {
            ref.current?.close();
        }
        reset();
    }

    return (
        <Modal ref={ref} onClose={onClose}>
            <ModalHeader title={I18n.t("horizon_modal_title")} />
            <ModalContent className={styles.content}>
                <TextField
                    size='small'
                    label={I18n.t("horizon_modal_jobtitle_label")}
                    placeholder='Responsable de magasin F/H'
                    value={jobName}
                    onChange={(e) => setJobName(e.target.value)}
                />
                <LocationInput setLocation={setLocation} />
                <div className={styles.salary}>
                    <Label htmlFor='salary' className={styles.label}>
                        {I18n.t("horizon_modal_salary_label")}{" "}
                        <span className={fonts.sans18Regular}>({I18n.t("optional")})</span>
                    </Label>
                    <div className={styles.fields}>
                        <TextField
                            id='salary'
                            className={styles.field}
                            size='small'
                            aria-label='Salaire annuel brut minimum'
                            placeholder='Minimum'
                            value={salary.value.min}
                            type='number'
                            onChange={(e) =>
                                setSalary({ ...salary.value, min: Number(e.target.value) })
                            }
                        />
                        <span>-</span>
                        <TextField
                            className={styles.field}
                            size='small'
                            aria-label='Salaire annuel brut maximum'
                            placeholder='Maximum'
                            value={salary.value.max}
                            type='number'
                            onChange={(e) =>
                                setSalary({ ...salary.value, max: Number(e.target.value) })
                            }
                        />
                    </div>
                </div>
                <div className={styles.date}>
                    <DatePicker
                        size='small'
                        label={I18n.t("horizon_modal_startdate_label")}
                        selected={startDate}
                        onSelect={(date) => setStartDate(date || new Date())}
                        fromDate={new Date()}
                        error={!startDate}
                    />
                </div>
                <TextField
                    className={styles.availablePositions}
                    size='small'
                    label={I18n.t("horizon_modal_positions_label")}
                    type='number'
                    value={availablePositions}
                    onChange={(event) => setAvailablePositions(Number(event.target.value))}
                    min={1}
                />
                <div>
                    <Label className={styles.label} htmlFor='commentary'>
                        {I18n.t("horizon_modal_commentary_label")}{" "}
                        <span className={fonts.sans18Regular}>({I18n.t("optional")})</span>
                    </Label>
                    <TextArea
                        id='commentary'
                        aria-label={I18n.t("horizon_modal_commentary_label")}
                        placeholder={I18n.t("horizon_modal_commentary_placeholder")}
                        value={commentary}
                        onChange={(e) => setCommentary(e.target.value)}
                    />
                </div>
            </ModalContent>
            <ModalFooter
                mainButton={
                    <Button
                        type='button'
                        onClick={() =>
                            createPermanentContractsRequest({
                                jobName,
                                location,
                                commentary,
                                startDate: startDate.toISOString(),
                                availablePositions,
                                ...(salary.value.max && salary.value.min
                                    ? {
                                          salary: salary.value as {
                                              min: number;
                                              max: number;
                                          },
                                      }
                                    : {}),
                            })
                        }
                        disabled={!jobName || !startDate || !location.address}
                        loading={isPending}
                    >
                        {I18n.t("submit")}
                    </Button>
                }
                cancelButtonLabel={I18n.t("cancel")}
            />
        </Modal>
    );
});
