import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const SearchJobTitlesSchema = z.array(
    z.object({
        id: z.string(),
        industry: z.string(),
        name: z.string(),
        romeCodeId: z.string(),
        romeCodeName: z.string(),
    }),
);

const message = "Search job-titles with user input for job description form";

export async function searchJobTitles(query?: string) {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: query,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/search/job-titles?q=${query}`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return SearchJobTitlesSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
