import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export async function validateAttendances(
    attendanceIds: string[],
): Promise<{ rejectedAttendanceIds: string[] }> {
    debug.addBreadcrumb({
        message: `Validate attendance`,
        category: `action`,
        data: {
            attendanceIds,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/v1/attendances/validate`, {
                method: `POST`,
                body: JSON.stringify({
                    attendanceIds,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("ValidateAttendances"));
        }
        const json = await resp.json();
        return json;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("ValidateAttendances"));
    }
}
