import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf, TaskSchema } from "./index";

type CreateTaskParams = {
    jobDescriptionId: string;
    taskDetails: {
        type: "delegation" | "gestion";
        name: string;
        attendanceConfigType: "default" | "timesheet";
    };
};

const message = "Create new task from job description";
export async function createTask(params: CreateTaskParams) {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: params?.jobDescriptionId || {},
    });

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/job-descriptions/${params.jobDescriptionId}/tasks`,
                {
                    method: `POST`,
                    body: JSON.stringify(params?.taskDetails || {}),
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return TaskSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
