import I18n from "i18n-js";
import { Loader } from "sui";

import { globalLayoutRoute } from "@routes";
import { allowAdminAndUser, allowCompany } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import { DetailsStep } from "./DetailsStep/DetailsStep";
import { MotiveStep } from "./MotiveStep/MotiveStep";
import { ShiftsStep } from "./ShiftsStep/ShiftsStep";
import { Summary } from "./Summary/Summary";
import { WorkersStep } from "./WorkersStep/WorkersStep";
import TaskForm from "./index";

import styles from "./TaskForm.module.css";

export const taskFormRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `taskPosting/$taskId`,
    component: TaskForm,
    beforeLoad: async () => {
        const allowedAdminAndUser = await allowAdminAndUser();
        if (!(allowCompany() && allowedAdminAndUser)) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => I18n.t(`doc_title_posting`) };
    },
});

export const motiveStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `motive`,
    component: MotiveStep,
    pendingComponent: () => (
        <div className={styles.loadingLayout}>
            <Loader className={styles.loader} />
        </div>
    ),
});

export const shiftsStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `shifts`,
    component: ShiftsStep,
});

export const detailsStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `details`,
    component: DetailsStep,
});

export const workersStepRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `workers`,
    component: WorkersStep,
});

export const summaryRoute = createRoute({
    getParentRoute: () => taskFormRoute,
    path: `summary`,
    component: Summary,
});
