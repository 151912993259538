import i18n from "i18n-js";
import { Button, Chip, ModalsService } from "side-ui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import { CustomDeviceModal } from "./CustomDeviceModal";

import styles from "./DevicesForm.module.css";

export function DevicesForm({ selectedDevices, setSelectedDevices }) {
    const { data: devices } = useQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) => data?.equipmentList?.filter((device) => device.group.includes("Other")),
    });

    function handleChange(checkbox) {
        if (selectedDevices?.find(({ id }) => id === checkbox.id)) {
            const selectedFilteredDevices = selectedDevices.filter(({ id }) => id !== checkbox?.id);
            setSelectedDevices(selectedFilteredDevices);
        } else {
            const newSelectedDevices = selectedDevices?.concat(checkbox);
            setSelectedDevices(newSelectedDevices);
        }
    }

    return (
        <div className={styles.devices}>
            {selectedDevices?.length ? (
                <div className={styles.selected}>
                    {selectedDevices.map(({ id, name, custom }) => (
                        <span key={id} style={{ marginRight: "8px" }}>
                            <Button
                                color='light'
                                action={() => handleChange({ id, name })}
                                iconAfter='CrossXL'
                            >
                                {custom ? name : i18n.t(name)}
                            </Button>
                        </span>
                    ))}
                </div>
            ) : null}

            {devices?.reduce(
                (
                    checkboxes,
                    {
                        // map + filter in one passage
                        id,
                        name,
                    },
                ) => {
                    if (selectedDevices?.find((selected) => selected.id === id)) {
                        return checkboxes;
                    }

                    checkboxes.push(
                        <Chip
                            key={id}
                            label={i18n.t(name)}
                            id={id}
                            value={id}
                            onChange={(event) => {
                                event.preventDefault();
                                handleChange({ id, name, providedBy: "sider" });
                            }}
                            checked={selectedDevices?.find((s) => s.id === id)}
                            name='devices'
                        />,
                    );
                    return checkboxes;
                },
                [] as JSX.Element[],
            )}
            <Button
                color='blue'
                iconAfter='Plus'
                customClass={styles.button}
                action={() =>
                    ModalsService.openModal({
                        id: `ADD_CUSTOM_DEVICE`,
                        content: (
                            <CustomDeviceModal
                                submitDevice={(customDevice) => handleChange(customDevice)}
                            />
                        ),
                    })
                }
            >
                {i18n.t(`job-descriptions_creation_conditions_equipments_other_add_custom_button`)}
            </Button>
        </div>
    );
}
