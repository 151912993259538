import { useEffect } from "react";
import i18n from "i18n-js";
import { InputText, ToastsService } from "side-ui";

import { queryClient } from "@App";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import { patchProfile } from "../../lib/api/patchProfile";
import { useFormInput } from "../../lib/hooks/form";
import SaveBar from "../Settings/components/partials/SaveBar";

import "./UserProfile.scss";

const UserProfile = () => {
    const { data: user } = useQuery(queries.user.detail());
    const { formInput: firstName, handleChange: setFirstName } = useFormInput("", (value) =>
        value === "" ? i18n.t(`error_field_is_required`) : null,
    );
    const { formInput: lastName, handleChange: setLastName } = useFormInput("", (value) =>
        value === "" ? i18n.t(`error_field_is_required`) : null,
    );
    const { formInput: email, handleChange: setEmail } = useFormInput("", (value) => {
        if (
            !value?.toLowerCase().match(
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            )
        ) {
            return i18n.t(`signup_error_invalid_email`);
        }
        return null;
    });
    const { formInput: newPassword, handleChange: setNewPassword } = useFormInput("", (value) => {
        if (value.length < 8) {
            return i18n.t(`error_password_length_8`);
        }

        // eslint-disable-next-line no-useless-escape
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\|,.<>/?~]/.test(value)) {
            return i18n.t(`error_password_special_char`);
        }

        return null;
    });
    const { formInput: newPasswordConfirmation, handleChange: setNewPasswordConfirmation } =
        useFormInput("", (value) => {
            if (value.length < 8) {
                return i18n.t(`error_password_length_8`);
            }

            // eslint-disable-next-line no-useless-escape
            if (!/[ `!@#$%^&*()_+\-=[\]{};':"\|,.<>/?~]/.test(value)) {
                return i18n.t(`error_password_special_char`);
            }

            if (value !== newPassword.value) {
                return i18n.t(`error_confirmation_not_equal_password`);
            }

            return null;
        });
    const { formInput: phone, handleChange: setPhone } = useFormInput("", (value) =>
        !value || value.length === 0 ? i18n.t(`error_field_required`) : null,
    );

    useEffect(() => {
        setFirstName(user?.firstName, false);
        setLastName(user?.lastName, false);
        setEmail(user?.email, false);
        setPhone(user?.phoneNumber, false);
    }, [JSON.stringify(user)]);

    function save() {
        patchProfile({
            firstName: firstName.value,
            lastName: lastName.value,
            phone: phone.value,
            email: email.value,
            ...(newPassword.dirty && newPassword.value !== ""
                ? { password: newPassword.value }
                : {}),
        }).then((res) => {
            if (res) {
                queryClient.invalidateQueries(queries.user.detail());
                setNewPassword("");
                setNewPasswordConfirmation("");
                ToastsService.addToast({
                    id: Date.now(),
                    icon: "Checkmark",
                    content: i18n.t(`personal_informations_profile_saved`),
                    isClosable: true,
                    type: "confirmation",
                });
            }
        });
    }

    return (
        <div className='user-profile__container'>
            <div className='user-profile'>
                <h2 className='user-profile__title'>{i18n.t(`personal_informations`)}</h2>
                <div className='user-profile__line'>
                    <InputText
                        label={i18n.t("personal_informations_firstname")}
                        placeholder={i18n.t("personal_informations_firstname_placeholder")}
                        value={firstName.value}
                        name='firstName'
                        id='firstName'
                        error={firstName.error}
                        smallInput={true}
                        required={true}
                        onChange={(event) => {
                            setFirstName(event.target.value);
                        }}
                    />
                    <InputText
                        label={i18n.t("personal_informations_lastname")}
                        placeholder={i18n.t("personal_informations_lastname_placeholder")}
                        value={lastName.value}
                        name='lastName'
                        id='lastName'
                        error={lastName.error}
                        smallInput={true}
                        required={true}
                        onChange={(event) => {
                            setLastName(event.target.value);
                        }}
                    />
                </div>
                <div className='user-profile__line'>
                    <InputText
                        label={i18n.t("personal_informations_email")}
                        placeholder={i18n.t("personal_informations_email_placeholder")}
                        value={email.value}
                        name='email'
                        id='email'
                        error={email.error}
                        smallInput={true}
                        required={true}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <InputText
                        label={i18n.t("personal_informations_phone")}
                        placeholder={i18n.t("personal_informations_phone_placeholder")}
                        value={phone.value}
                        name='phone'
                        id='phone'
                        error={phone.error}
                        smallInput={true}
                        required={true}
                        onChange={(event) => {
                            setPhone(event.target.value);
                        }}
                    />
                </div>
                <div className='user-profile__line'>
                    <InputText
                        label={i18n.t("personal_informations_new_password")}
                        placeholder={i18n.t("personal_informations_new_password_placeholder")}
                        value={newPassword.value}
                        name='newPassword'
                        id='newPassword'
                        type='password'
                        error={
                            newPassword.dirty && newPassword.value !== "" ? newPassword.error : ""
                        }
                        smallInput={true}
                        required={true}
                        onChange={(event) => {
                            setNewPassword(event.target.value);
                        }}
                    />
                    <InputText
                        label={i18n.t("personal_informations_new_password_confirmation")}
                        placeholder={i18n.t(
                            "personal_informations_new_password_confirmation_placeholder",
                        )}
                        value={newPasswordConfirmation.value}
                        name='newPasswordConfirmation'
                        id='newPasswordConfirmation'
                        type='password'
                        error={
                            newPasswordConfirmation.dirty && newPasswordConfirmation.value !== ""
                                ? newPasswordConfirmation.error
                                : ""
                        }
                        smallInput={true}
                        required={true}
                        onChange={(event) => {
                            setNewPasswordConfirmation(event.target.value);
                        }}
                    />
                </div>

                {(firstName.dirty ||
                    lastName.dirty ||
                    email.dirty ||
                    phone.dirty ||
                    newPassword.dirty ||
                    newPasswordConfirmation.dirty) && (
                    <SaveBar
                        saveAction={() => save()}
                        canSave={
                            !(
                                firstName.error ||
                                lastName.error ||
                                email.error ||
                                phone.error ||
                                (newPassword.dirty &&
                                    newPassword.value !== "" &&
                                    newPassword.error) ||
                                (newPasswordConfirmation.dirty &&
                                    newPasswordConfirmation.value !== "" &&
                                    newPasswordConfirmation.error)
                            )
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default UserProfile;
