import { Component } from "react";
import { Formik } from "formik";
import i18n from "i18n-js";
import { InputNumber, ModalSimple, ModalsService, SimpleSelect } from "side-ui";

import { normalizeNumber } from "../../../../lib/utils/inputsNormalizers";

import HoursSeniorityIncrease from "./HoursSeniorityIncrease";
import { nightHoursValidationSchema, submitNightHours } from "./remunerationFormsValidation";

import "./IndemnityForm.scss";

const onFieldChange = (fieldName, value, prevValue, setFieldValue) => {
    let newValue = value;
    switch (fieldName) {
        case "increase":
            newValue = normalizeNumber(value, prevValue);
            break;
        default:
            break;
    }
    setFieldValue(fieldName, newValue);
};

class NightHoursForm extends Component {
    getHoursOptions() {
        return Array.from(Array(4 * 24).keys()).map((idx) => {
            const hour = Math.trunc(idx / 4);
            const literalHour = hour > 10 ? `${hour}` : `0${hour}`;
            const decimalMinutes = ((idx % 4) * 100) / 4;
            const minutes = (decimalMinutes * 60) / 100;
            const literalMinutes = minutes > 10 ? `${minutes}` : `0${minutes}`;

            return {
                id: idx,
                value: hour + decimalMinutes / 100,
                label: `${literalHour}:${literalMinutes}`,
            };
        });
    }

    getStartHourOptions() {
        const hours = this.getHoursOptions();

        const firstPart = hours.filter((hour) => hour.value >= 18);
        const secondPart = hours.filter((hour) => hour.value <= 6);

        return [...firstPart, ...secondPart];
    }

    getEndHourOptions() {
        return this.getHoursOptions().filter((hour) => hour.value <= 9);
    }

    render() {
        const startHourOptions = this.getStartHourOptions();
        const endHourOptions = this.getEndHourOptions();
        const { nightHours, updateHourType } = this.props;

        const initialVal = {
            startHour: nightHours.startHour,
            endHour: nightHours.endHour,
            increase: `${nightHours.increase}`,
            dependsOnSeniority: !!nightHours.seniority,
            seniority: nightHours.seniority
                ? {
                      checked: true,
                      daysThreshold: `${nightHours.seniority.daysThreshold}`,
                      increase: `${nightHours.seniority.increase}`,
                  }
                : {
                      checked: false,
                      daysThreshold: "",
                      increase: "",
                  },
        };

        return (
            <Formik
                initialValues={initialVal}
                onSubmit={(values, actions) =>
                    submitNightHours(values, actions, initialVal, updateHourType)
                }
                validationSchema={nightHoursValidationSchema()}
                validateOnChange={false}
                validateOnBlur={true}
                render={({ values, errors, touched, handleBlur, submitForm, setFieldValue }) => (
                    <ModalSimple
                        action={submitForm}
                        validateLabel={i18n.t(`settings_remuneration_indemnities_modal_save`)}
                        cancelLabel={i18n.t(`settings_remuneration_indemnities_modal_cancel`)}
                        title={i18n.t(`settings_remuneration_hours_night_title`)}
                        subtitle={i18n.t(`settings_remuneration_indemnities_night_hour_subtitle`)}
                        hideModal={() => ModalsService.closeModal(`night_hours`)}
                    >
                        <div className='indemnity'>
                            <div className='indemnity__section'>
                                <div className='indemnity__field row'>
                                    <div className='indemnity__field__splitted'>
                                        <div className='indemnity__field__label'>
                                            {i18n.t(
                                                `settings_remuneration_indemnities_night_hour_start`,
                                            )}
                                        </div>
                                        <SimpleSelect
                                            id='startHour'
                                            onChange={(e) =>
                                                onFieldChange(
                                                    "startHour",
                                                    e.target.value,
                                                    values.startHour,
                                                    setFieldValue,
                                                )
                                            }
                                            options={startHourOptions}
                                            error={touched.startHour && errors.startHour}
                                            value={values.startHour}
                                            smallInput={true}
                                        />
                                    </div>
                                    <div className='indemnity__field__splitted'>
                                        <div className='indemnity__field__label'>
                                            {i18n.t(
                                                `settings_remuneration_indemnities_night_hour_end`,
                                            )}
                                        </div>
                                        <SimpleSelect
                                            id='endHour'
                                            onChange={(e) =>
                                                onFieldChange(
                                                    "endHour",
                                                    e.target.value,
                                                    values.endHour,
                                                    setFieldValue,
                                                )
                                            }
                                            options={endHourOptions}
                                            error={touched.endHour && errors.endHour}
                                            value={values.endHour}
                                            smallInput={true}
                                        />
                                    </div>
                                </div>
                                <InputNumber
                                    id='increase'
                                    onChange={(e) =>
                                        onFieldChange(
                                            "increase",
                                            e.target.value,
                                            values.increase,
                                            setFieldValue,
                                        )
                                    }
                                    label={i18n.t(
                                        `settings_remuneration_indemnities_night_hour_rate`,
                                    )}
                                    placeholder={50}
                                    unit='%'
                                    onBlur={handleBlur}
                                    smallInput={true}
                                    value={values.increase}
                                    error={touched.increase && errors.increase}
                                    customClass='indemnity__half-input'
                                />
                            </div>
                            <HoursSeniorityIncrease />
                        </div>
                    </ModalSimple>
                )}
            />
        );
    }
}

export default NightHoursForm;
