import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GetQuotationParams = { taskId: string };
export type GetQuotationResponse = {
    salary: string;
    price: string;
    assignments: Assignment[];
};

type Assignment = {
    name: string;
    quantity: string;
    hourlyRate: string;
    price: string;
    rate: string;
    tags: string[];
};
export async function getQuotation(params: GetQuotationParams) {
    debug.addBreadcrumb({
        message: `deleteShift`,
        category: `action`,
        data: {
            taskId: params?.taskId,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${params?.taskId}/quotation`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("GetQuotation"));
        }

        return resp.json();
    } catch (e) {
        debug.catch(e);
        handleError(e, 500);
        return Promise.reject(new Error("GetQuotation"));
    }
}
