import i18n from "i18n-js";
import { Button, ModalSimple, ModalsService } from "side-ui";
import { IconCopy } from "side-ui";

import { useNavigate } from "@tanstack/react-router";

import "./RedirectModal.scss";

const RedirectModal = () => {
    const navigate = useNavigate();

    return (
        <div className='duplicate-old-task-redirect-modal'>
            <ModalSimple
                withCloseButton={false}
                hideModal={() => ModalsService.closeModal("REDIRECT_MODAL")}
            >
                {/* @todo refacto content container to side-ui */}
                <div className='duplicate-old-task-redirect-modal__icon'>
                    <IconCopy />
                </div>

                <h2 className='duplicate-old-task-redirect-modal__title'>
                    {i18n.t("modal_redirect_title")}
                </h2>

                <p className='duplicate-old-task-redirect-modal__description'>
                    {i18n.t("modal_redirect_description")}
                </p>

                <div className='duplicate-old-task-redirect-modal__item'>
                    <span className='duplicate-old-task-redirect-modal__item__count'>1</span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: i18n.t("modal_redirect_step_1"),
                        }}
                    />
                </div>
                <div className='duplicate-old-task-redirect-modal__item'>
                    <span className='duplicate-old-task-redirect-modal__item__count'>2</span>
                    {i18n.t("modal_redirect_step_2")}
                </div>

                <div className='duplicate-old-task-redirect-modal__button'>
                    <Button
                        color='blue'
                        action={() => {
                            ModalsService.closeModal(`REDIRECT_MODAL`);
                            navigate({ to: `/job-descriptions` }).then(() => {
                                window.location.reload();
                            });
                        }}
                    >
                        {i18n.t("modal_redirect_button")}
                    </Button>
                </div>
            </ModalSimple>
        </div>
    );
};

export default RedirectModal;
