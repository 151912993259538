import i18n from "i18n-js";
import fetch from "isomorphic-fetch";
import { ToastsService } from "side-ui";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { recurrenceOptions } from "@routes/TaskPosting/ShiftsStep/useShiftAPI";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf, shiftShchema } from "./index";

const updateShiftSchema = z.object({
    shift: shiftShchema.extend({ id: z.string() }),
    scope: recurrenceOptions,
});

export type UpdateShiftParams = z.input<typeof updateShiftSchema>;
export async function updateShift(params: UpdateShiftParams) {
    debug.addBreadcrumb({
        message: `updateShift`,
        category: `action`,
        data: {
            id: params?.shift?.id,
            startDate: params?.shift?.startDate,
            endDate: params?.shift?.endDate,
            breakDuration: params?.shift?.breakDuration,
            slots: params?.shift?.slots,
        },
    });

    const urlParams = new URLSearchParams();
    if (params.scope) {
        urlParams.append(`scope`, params.scope);
    }

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/shifts/${params?.shift.id}?${urlParams.toString()}`,
                {
                    method: `PATCH`,
                    body: JSON.stringify({
                        ...params.shift,
                    }),
                },
            ),
        );

        if (resp.status === 400) {
            ToastsService.addToast({
                id: "patch-shifts",
                icon: "Cross",
                content: i18n.t("error_invalid_shift"),
                isClosable: true,
                type: "error",
            });
            return {};
        }

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("updateShift"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("updateShift"));
    }
}
