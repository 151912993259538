import { memo } from "react";
import { format } from "date-fns";
import i18n from "i18n-js";
import { Tag, Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { getLocale } from "@lib/utils/getLocale";

import { filterColors, setStatusLabel } from "../../../../utils";

type StatusCellProps = {
    status: string;
    validation?: any;
    locale?: string;
};

const StatusCell = memo(function StatusCell({ status, validation = null }: StatusCellProps) {
    const locale = getLocale();

    function validationDisclaimer(validatedBy?: string) {
        // eslint-disable-line consistent-return
        if (validation.actions?.length) {
            // getting last items updatedAt value
            const { updatedAt } = validation.actions[validation.actions.length - 1];

            if (updatedAt) {
                const validationDate = `${format(new Date(updatedAt), "d MMMM yyyy", {
                    locale,
                })} ${locale.code === "fr" ? "à " : ""}
        ${new Date(updatedAt).toLocaleTimeString(locale.code)}`;

                if (validatedBy) {
                    return i18n.t("ts_table_validated_tag_content_validated_by", {
                        validationDate,
                        validatedBy,
                    });
                }

                return i18n.t("ts_table_validated_tag_content", { validationDate });
            }
        }
    }

    function renderValidationDisclaimer() {
        if (!validation) return null;

        if (!validation.actions) {
            return validationDisclaimer();
        }

        const { updatedBy } = validation.actions[validation.actions.length - 1];
        if (!updatedBy) {
            return validationDisclaimer();
        }

        return validationDisclaimer(`${updatedBy.firstName} ${updatedBy.lastName}`);
    }

    return (
        <span
            className={`
        timesheets-table__cell
        timesheets-table__cell--status
        timesheets-table__cell__hoverable__wrapper
      `}
        >
            <Tooltip enabled={validation} placement='bottom'>
                <TooltipTrigger>
                    <Tag
                        className='timesheets-table__cell--status__tag'
                        color={filterColors[status]}
                    >
                        {setStatusLabel(status)}
                    </Tag>
                </TooltipTrigger>
                <TooltipContent
                    type='info'
                    intention='success'
                    label={i18n.t("ts_table_validated_tag_title")}
                >
                    {renderValidationDisclaimer()}
                </TooltipContent>
            </Tooltip>
        </span>
    );
});

export default StatusCell;
