import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type SendDisputeParams = {
    shiftId: string;
    siderId: string;
    attendanceId: string;
    startDate: string;
    endDate: string;
    taskId: string;
    managerId: string;
    breakDates: string[];
    trackerName: string;
    source: string;
    companyId: string;
    multiple?: boolean;
    companyName: string;
};
export async function sendDispute(params: SendDisputeParams): Promise<void> {
    debug.addBreadcrumb({
        message: `Dispute a shift`,
        category: `action`,
        data: {
            ...params,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/v1/attendances/${params.attendanceId}/dispute`, {
                method: `POST`,
                body: JSON.stringify({
                    ...params,
                    bySider: false,
                    byCompany: true,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("SendDispute"));
        }

        return;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("SendDispute"));
    }
}
