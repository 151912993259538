import moment from "moment";

const normalizeSider = (data) => {
    const sider = {
        firstName: data.firstName,
        lastName: data.lastName,
        pictureUrl: data.pictureUrl,
        phoneNumber: data.phoneNumber,
        emailAddress: data.email,
        ongoingTasks: data.ongoing?.map((ongoingTask) => ({
            id: ongoingTask.id,
            name: ongoingTask.name,
            startDate: moment(ongoingTask.startDate),
            endDate: moment(ongoingTask.endDate),
            workedHours: ongoingTask.workedHours || 0,
            positiveFeedbacks: ongoingTask.positiveFeedbacks?.map((positiveFeedback) => ({
                id: positiveFeedback.id,
                createdAt: positiveFeedback.createdAt,
                authorName: positiveFeedback.name,
                comment: positiveFeedback.comment,
            })),
        })),
        companyTasks: data.companyTasks?.map((companyTask) => ({
            id: companyTask.id,
            name: companyTask.name,
            startDate: moment(companyTask.startDate),
            endDate: moment(companyTask.endDate),
            workedHours: companyTask.workedHours,
            positiveFeedbacks: companyTask.positiveFeedbacks?.map((positiveFeedback) => ({
                id: positiveFeedback.id,
                createdAt: positiveFeedback.createdAt,
                authorName: positiveFeedback.name,
                comment: positiveFeedback.comment,
            })),
        })),
        jobTypes: data.jobTypes?.map((jobType) => ({
            id: jobType.id,
            name: jobType.name,
            workedHours: jobType.workedHours,
            tasksCount: jobType.tasksCount,
            positiveFeedbacks: jobType.positiveFeedbacks,
        })),
        experiences: data.experiences?.map((experience) => ({
            categories: experience.categories,
            companyName: experience.companyName,
            name: experience.name,
            duration: experience.duration,
            subtasks: experience.subTasks?.map((subtask) => ({
                name: subtask.name,
            })),
        })),
        educations: data.educations?.map((education) => ({
            id: education.id,
            schoolName: education.schoolName,
            degree: education.degree,
            fieldOfStudy: education.fieldOfStudy,
            startYear: education.startYear,
            endYear: education.endYear,
        })),
    };
    return sider;
};

export default normalizeSider;
