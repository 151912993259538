import { memo } from "react";
import { format } from "date-fns";

import { getLocale } from "@lib/utils/getLocale";

type DateCellProps = {
    startDate: string;
};

// syntax to avoid anonymous name in React dev tools
const DateCell = memo(function DateCell({
    // eslint-disable-line
    startDate,
}: DateCellProps) {
    const locale = getLocale();
    return (
        <div className='timesheets-table__cell timesheets-table__cell--date'>
            <span className='timesheets-table__cell--date--short'>
                {format(new Date(startDate), "EEEE", {
                    locale,
                }).slice(0, 3)}
            </span>
            <span>
                {format(new Date(startDate), "P", {
                    locale,
                })}
            </span>
        </div>
    );
});

export default DateCell;
