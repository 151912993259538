import { queries } from "@lib/queries";
import { ORGANISATION_USERS_ROLES } from "@store/types/company";
import { useQuery } from "@tanstack/react-query";

export function useCompanyManagers() {
    const { data: companyInfoData } = useQuery(queries.company.detail());
    const companyMembers = companyInfoData?.members || [];
    const companyUsers = companyInfoData?.organisation.users || [];

    const companyManagers = companyUsers.reduce(
        (managersArray, { id, roles }) => {
            if (
                roles.includes(ORGANISATION_USERS_ROLES.MISSION_MANAGER) ||
                roles.includes(ORGANISATION_USERS_ROLES.ADMIN)
            ) {
                const manager = companyMembers.find(({ _id }) => _id === id);
                if (manager) {
                    managersArray.push({
                        id: manager._id,
                        label: `${manager.firstName} ${manager.lastName}`,
                        value: manager._id,
                    });
                }
            }

            return managersArray;
        },
        [] as {
            id: string;
            label: string;
            value: string;
        }[],
    );

    return companyManagers;
}
