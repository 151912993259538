import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

type GetCompanySidersParams = { query: string; limit?: number; offset?: number; total?: number };

export type GetCompanySidersResponse = {
    offset: number;
    limit: number;
    total: number;
    siders: Sider[];
};

type Sider = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    pictureURL: string;
};

export async function getCompanySiders({
    query,
    limit = 40,
    offset = 0,
    total = 40,
}: GetCompanySidersParams): Promise<GetCompanySidersResponse> {
    try {
        const resp = await fetch(
            withAuthHeaders(
                withURLParam(`${apiConf.BACK_URL}/siders/search`, {
                    search: query,
                    limit,
                    offset,
                    total,
                }),
            ),
        );
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("GetCompanySiders"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("GetCompanySiders"));
    }
}
