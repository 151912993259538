import i18n from "i18n-js";
import { Button, InputText } from "side-ui";
import { Logo } from "sui";

import { queryClient } from "@App";
import { queries } from "@lib/queries";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "@tanstack/react-router";

import { googleLogin } from "../../lib/api/googleLogin";
import { login } from "../../lib/api/login";
import env from "../../lib/env";
import { useFormInput } from "../../lib/hooks/form";

import "./SignIn.scss";

const SignIn = () => {
    const navigate = useNavigate();
    const { formInput: email, handleChange: setEmail } = useFormInput("", () => null);
    const { formInput: password, handleChange: setPassword } = useFormInput("", () => null);

    const loginWithGoogle = useGoogleLogin({
        onSuccess: (res) => {
            if (!res.code) {
                return;
            }

            // Send code to backend
            googleLogin({ code: res.code }).then((userInfo) => {
                if (userInfo) {
                    localStorage.setItem("Meteor.loginToken", userInfo.token);
                    localStorage.setItem("Meteor.userId", userInfo.userId);
                    localStorage.setItem("google_login", "true");
                    if (userInfo.organisationId) {
                        if (userInfo.organisationType === "parent") {
                            localStorage.setItem("side_team_groupId", userInfo.organisationId);
                        } else {
                            localStorage.setItem(
                                "side_team_activeOrganisationId",
                                userInfo.organisationId,
                            );
                        }
                    }
                    queryClient.invalidateQueries(queries.user.detail());
                    navigate({ to: `/` });
                    return;
                }
            });
        },
        flow: "auth-code",
    });

    function signIn() {
        login({ email: email.value, password: password.value }).then((res) => {
            if (res) {
                localStorage.setItem("Meteor.loginToken", res.token);
                localStorage.setItem("Meteor.userId", res.userId);
                if (res.organisationType === "parent") {
                    localStorage.setItem("side_team_groupId", res.organisationId);
                } else {
                    localStorage.setItem("side_team_activeOrganisationId", res.organisationId);
                }
                queryClient.invalidateQueries(queries.user.detail());
                navigate({ to: `/` });
            }
        });
    }

    return (
        <div className='team-signin'>
            <header className='team-signin__header'>
                <div className='team-signin__header__left'>
                    <a href='https://www.side.co/entreprises' className='team-signin__header__logo'>
                        <Logo version='topbar' />
                    </a>
                    <div className='team-signin__header__title'>
                        {i18n.t(`sign_in_logo_company`)}
                    </div>
                </div>
                <div className='team-signin__header__spacer' />
                <div className='team-signin__header__create-account'>
                    <span>
                        {i18n.t(`sign_in_no_account_yet`)}
                        <a href='/signup' target='_self'>
                            {i18n.t(`sign_in_create_account`)}
                        </a>
                    </span>
                </div>
                <a
                    href='https://app.side.co/e0ra/website'
                    target='_blank'
                    className='team-signin__header__button'
                    rel='noreferrer'
                >
                    {i18n.t(`sign_in_work_on_side`)}
                </a>
            </header>

            <section className='team-signin__content'>
                <h1 className='team-signin__content__title'>{i18n.t(`sign_in_form_title`)}</h1>

                <button
                    className='team-signin__content__google'
                    type='button'
                    onClick={loginWithGoogle}
                >
                    <img
                        src='https://s3.eu-central-1.amazonaws.com/weslash-static/static/company/signup-google.svg'
                        alt='Google'
                    />
                    <span>{i18n.t(`sign_in_with_google`)}</span>
                </button>

                <div className='team-signin__content__divider'>
                    <div className='team-signin__content__divider__line' />
                    <span className='team-signin__content__divider__text'>
                        {i18n.t(`sign_in_or`)}
                    </span>
                    <div className='team-signin__content__divider__line' />
                </div>

                <form className='team-signin__content__form' onSubmit={signIn}>
                    <div className='team-signin__content__form__input'>
                        <InputText
                            placeholder={i18n.t("sign_in_email_placeholder")}
                            value={email.value}
                            name='email'
                            id='email'
                            smallInput={true}
                            required={true}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                    </div>
                    <div className='team-signin__content__form__input'>
                        <InputText
                            placeholder={i18n.t("sign_in_password_placeholder")}
                            value={password.value}
                            name='password'
                            id='password'
                            type='password'
                            smallInput={true}
                            required={true}
                            onChange={(event) => {
                                setPassword(event.target.value);
                            }}
                        />
                    </div>
                    <Button
                        customClass='team-signin__content__form__submit'
                        type='submit'
                        action={() => {
                            signIn();
                        }}
                        fullWidth={true}
                        color='blue'
                    >
                        {i18n.t(`sign_in_log_in`)}
                    </Button>
                </form>

                <div className='team-signin__content__forgot-password__container'>
                    <a
                        href={env.RESET_PASSWORD_URL}
                        target='_blank'
                        className='team-signin__content__forgot-password'
                        rel='noreferrer'
                    >
                        {i18n.t(`sign_in_forgot_password`)}
                    </a>
                </div>

                <div className='team-signin__content__divider'>
                    <div className='team-signin__content__divider__full-line' />
                </div>

                <div className='team-signin__content__create-account'>
                    <span>{i18n.t(`sign_in_no_account_yet`)}</span>
                    <a href='/signup'>{i18n.t(`sign_in_create_account`)}</a>
                </div>
            </section>
        </div>
    );
};

export default SignIn;
