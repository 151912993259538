import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type SendContestationParams = {
    organisationId: string;
    documentId: string;
    data: {
        description: string;
        managerId: string;
    };
};
export function sendContestation(params: SendContestationParams) {
    debug.addBreadcrumb({
        message: `Send a contestation`,
        category: `action`,
        data: {
            ...params.data,
        },
    });

    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/document/${params.documentId}/contest`,
            {
                method: `PUT`,
                body: JSON.stringify({
                    ...params.data,
                }),
            },
        ),
    );
}
