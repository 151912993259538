import { Component } from "react";
import { Loader } from "side-ui";

import { router } from "@routes";

import Pagination from "../../../components/Pagination";

import ContractsBatchesList from "./ContractsBatchesList";

import "./ContractsBatches.scss";

class ContractsBatches extends Component {
    componentDidMount() {
        const { getContractsBatches, updateCurrentTab } = this.props;

        updateCurrentTab(`toSign`);

        const pageNumber = new URLSearchParams(window.location.search).get(`page`) || 1;
        getContractsBatches(pageNumber);
    }

    componentDidUpdate(prevProps) {
        const { getContractsBatches } = this.props;
        const { location } = router.history;

        // compare page number via the url
        // if it changed, we get the new shifts array
        if (location && prevProps.location && location.search !== prevProps.location.search) {
            const pageNumber = new URLSearchParams(location.search).get(`page`);
            getContractsBatches(parseInt(pageNumber));
        }
    }

    render() {
        const { contractsBatches, isLoading, pagination } = this.props;
        const { location } = router.history;

        if (isLoading) {
            return (
                <div className='loader--centered'>
                    <Loader />
                </div>
            );
        }
        return (
            <div className='contracts-batches'>
                <ContractsBatchesList contractsBatches={contractsBatches} />
                {pagination.pageCount > 1 && (
                    <Pagination
                        location={location}
                        history={history}
                        pageCount={pagination.pageCount}
                        path='/contracts'
                        page='contracts'
                    />
                )}
            </div>
        );
    }
}

export default ContractsBatches;
