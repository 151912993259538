import i18n from "i18n-js";
import * as yup from "yup";

// I didnt find how to have a conditional validation schema that depends on the form values
// so instead of being called as a validationSchema (https://jaredpalmer.com/formik/docs/api/formik#validationschema-schema-schema)
// the following function is called in the validate method (https://jaredpalmer.com/formik/docs/api/formik#validate-values-values-formikerrors-values-promise-any)
// using the method and function from this article https://itnext.io/simple-react-form-validation-with-formik-yup-and-or-spected-206ebe9e7dcc
export const paymentInfoValidationSchema = (values) => {
    const schemaDef = {
        billingAddress: yup.string().required(i18n.t(`error_field_is_required`)),
        billingPostalCode: yup
            .string()
            .matches(/^[-a-zA-z0-9]+$/, i18n.t(`settings_payment_error_postal_code_format`))
            .min(4, i18n.t(`settings_payment_error_postal_code_format`))
            .max(10, i18n.t(`settings_payment_error_postal_code_format`))
            .required(i18n.t(`error_field_is_required`)),
        billingCity: yup.string().required(i18n.t(`error_field_is_required`)),
        billingCountry: yup
            .string()
            .matches(/^[-a-zA-z]+$/, i18n.t(`settings_payment_error_country_format`))
            .required(i18n.t(`error_field_is_required`)),
        accountingFirstname: yup.string().required(i18n.t(`error_field_is_required`)),
        accountingLastname: yup.string().required(i18n.t(`error_field_is_required`)),
        accountingEmail: yup
            .string()
            .matches(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                i18n.t(`settings_payment_error_email_format`),
            )
            .required(i18n.t(`error_field_is_required`)),
        accountingPhonenumber: yup
            .string()
            .matches(/[0-9 ]{6,15}/, i18n.t(`settings_payment_error_phone_number_format`))
            .required(i18n.t(`error_field_is_required`)),
    };
    if (values.billingCountry !== "FR") {
        schemaDef.tvaNumber = yup
            .string()
            .matches(
                /[a-zA-Z]{2} [0-9a-zA-Z]{8,12}/,
                i18n.t(`settings_payment_error_tva_number_format`),
            )
            .required(i18n.t(`error_field_is_required`));
    }
    return yup.object().shape(schemaDef);
};

const didPaymentMethodChange = (values, initialValues) =>
    values.method !== initialValues.method ||
    values.methodCardCvv !== initialValues.methodCardCvv ||
    values.methodCardExpDate !== initialValues.methodCardExpDate ||
    values.methodCardNumber !== initialValues.methodCardNumber ||
    values.methodSepaCountry !== initialValues.methodSepaCountry ||
    values.methodSepaCurrency !== initialValues.methodSepaCurrency ||
    values.methodSepaIban !== initialValues.methodSepaIban ||
    values.methodSepaTos !== initialValues.methodSepaTos;

const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
        (errors, error) => ({
            ...errors,
            [error.path]: error.errors[FIRST_ERROR],
        }),
        {},
    );
};

export const paymentInfoValidationLogic = (values, initialValues) => {
    let errors = {};
    const paymentMethodChanged = didPaymentMethodChange(values, initialValues);
    try {
        paymentInfoValidationSchema(values, paymentMethodChanged).validateSync(values, {
            abortEarly: false,
        });
        return errors;
    } catch (error) {
        errors = { ...errors, ...getErrorsFromValidationError(error) };
        return errors;
    }
};

export const submitPaymentInfo = async (
    clientSecret,
    values,
    { setSubmitting, setErrors, resetForm },
    initialValues,
    updateCompanyPaymentInfo,
) => {
    const dataToSend = {
        wantOrderForm: values.orderForm,
        billingAddress: {
            street: values.billingAddress,
            postalCode: values.billingPostalCode,
            city: values.billingCity,
            country: values.billingCountry,
        },
        tvaNumber: values.tvaNumber,
        accountingInfo: {
            firstName: values.accountingFirstname,
            lastName: values.accountingLastname,
            email: values.accountingEmail,
            phoneNumber: values.accountingPhonenumber,
        },
        ...(values.paymentMethodId !== "" && values.method === "card"
            ? { paymentMethodId: values.paymentMethodId }
            : {}),
        ...(values.sourceId !== "" && values.method === "sepa_debit"
            ? { sourceId: values.sourceId }
            : {}),
    };
    const dataToStore = {
        wantOrderForm: values.orderForm,
        billingAddress: {
            street: values.billingAddress,
            postalCode: values.billingPostalCode,
            city: values.billingCity,
            country: values.billingCountry,
        },
        tvaNumber: values.tvaNumber,
        accountingInfo: {
            firstName: values.accountingFirstname,
            lastName: values.accountingLastname,
            email: values.accountingEmail,
            phoneNumber: values.accountingPhonenumber,
        },
        paymentMethod: {
            type: values.method,
            last4: values.methodCardNumber && values.methodCardNumber.slice(-4),
            IBAN: values.methodSepaIban,
            expirationDate: values.methodCardExpDate,
        },
        clientSecret,
        ...(values.paymentMethodId !== "" ? { paymentMethodId: values.paymentMethodId } : {}),
        ...(values.sourceId !== "" ? { sourceId: values.sourceId } : {}),
    };
    updateCompanyPaymentInfo(dataToSend, dataToStore)
        .then((res) => {
            setSubmitting(false);
            if (res.status > 400) {
                setErrors({ api: i18n.t(`settings_payment_api_error`) });
            } else {
                resetForm(values);
            }
        })
        .catch((err) => {
            setErrors({ api: err });
            setSubmitting(false);
        });
};
