// STATE

export type CompanyMember = {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
};

export type CompanyUser = {
    id: string;
    index: number;
    roles: string[];
    status: string;
};

export type CompanyManager = {
    _id: string;
    firstName: string;
    lastName: string;
};

export type ConventionConfirmation = {
    date: Date;
    userId: string;
};

export type ExtraHoursThreshold = {
    threshold: number;
    increase: number;
};

export type ExtraHours = {
    thresholds: ExtraHoursThreshold[];
};

export type SeniorityIncreaseThreshold = {
    daysThreshold: number;
    increase: number;
};

export type NightHours = {
    startHour: number;
    endHour: number;
    increase: number;
    seniority?: SeniorityIncreaseThreshold;
};

export type SundayHours = {
    increase: number;
    seniority?: SeniorityIncreaseThreshold;
    ztiIncrease?: number;
};

export type BankHolidayHours = {
    increase: number;
    seniority?: SeniorityIncreaseThreshold;
    solidarity?: string;
};

export type HoursTypes = {
    extra: ExtraHours;
    night: NightHours;
    sundays: SundayHours;
    bankHolidays: BankHolidayHours;
    valid?: boolean;
};
export type OtherIndemnityCategories =
    | "labor-agreement"
    | "performances"
    | "work-condition"
    | "events"
    | "other";

export type IndemnitySeniorityCondition = {
    daysThreshold: number;
};

export type IndemnityHoursPerDayCondition = {
    threshold: number;
};

export type IndemnityZTICondition = {
    isApplicable: number;
};

export type IndemnityTaskTypeCondition = {
    jobTypes: string[];
};

export type IndemnityAdvancedParameters = {
    seniority?: IndemnitySeniorityCondition;
    hoursPerDay?: IndemnityHoursPerDayCondition;
    zti?: IndemnityZTICondition;
    taskType?: IndemnityTaskTypeCondition;
};

export type OtherIndemnityNames =
    | "holiday-bonus"
    | "thirteenth-month-bonus"
    | "productivity-bonus"
    | "target-based-bonus"
    | "diligence-bonus"
    | "cold-bonus"
    | "risk-bonus"
    | "hardship-bonus"
    | "unhealthy-bonus"
    | "year-end-bonus"
    | "christmas-bonus"
    | "other-bonus";

export type OtherIndemnityFrequency = "once" | "daily" | "weekly" | "monthly";

export type OtherIndemnity = {
    id: string;
    category: OtherIndemnityCategories;
    name: OtherIndemnityNames;
    amount: number;
    frequency: {
        type: OtherIndemnityFrequency;
    };
    otherParameters?: IndemnityAdvancedParameters;
};

export type LunchIndemnity = {
    id: string;
    type: "ticket" | "indemnity";
    amount: number;
    coverRate?: number;
    otherParameters?: IndemnityAdvancedParameters;
};

export type TransportationIndemnity = {
    id: string;
    publicCoverRate: number;
    otherParameters?: IndemnityAdvancedParameters;
};

export type Indemnities = {
    lunch: LunchIndemnity[];
    transportation: TransportationIndemnity[];
    other: OtherIndemnity[];
    valid?: boolean;
};

export type RemunerationInfo = {
    hoursTypesConfirmation?: ConventionConfirmation;
    indemnitiesConfirmation?: ConventionConfirmation;
    hoursTypes?: HoursTypes;
    indemnities?: Indemnities;
};

export const OTHER_INDEMNITY_CATEGORIES: OtherIndemnityCategories[] = [
    "labor-agreement",
    "performances",
    "work-condition",
    "events",
    "other",
];

export const OTHER_INDEMNITY_NAMES: { [key in OtherIndemnityCategories]: OtherIndemnityNames[] } = {
    "labor-agreement": ["holiday-bonus", "thirteenth-month-bonus", "other-bonus"],
    "performances": ["productivity-bonus", "target-based-bonus", "diligence-bonus", "other-bonus"],
    "work-condition": [
        "cold-bonus",
        "risk-bonus",
        "hardship-bonus",
        "unhealthy-bonus",
        "other-bonus",
    ],
    "events": ["year-end-bonus", "christmas-bonus", "other-bonus"],
    "other": ["other-bonus"],
};

export const OTHER_INDEMNITY_FREQUENCY = ["once", "daily", "weekly", "monthly"];

export type LegalInfo = {
    name?: string;
    businessName?: string;
    siret?: string;
    apeCode?: string;
};

export type LegalInfoState = LegalInfo & {
    isLoading?: boolean;
};

export type PaymentInfo = {
    wantOrderForm?: boolean;
    paymentMethod?: {
        type: "card" | "sepa";
        last4?: string;
        expirationDate?: string;
        cvv?: string;
        currency?: string;
        country?: string;
        tos?: string;
    };
    accountingInfo?: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
    };
    tvaNumber?: string;
    billingAddress?: {
        street: string;
        postalCode: string;
        city: string;
        country: string;
    };
    clientSecret?: string;
};

export type RemunerationInfoState = RemunerationInfo & {
    isLoading?: boolean;
};

export type PaymentInfoState = PaymentInfo & {
    isLoading?: boolean;
};

export type State = {
    group: {
        members: Array<CompanyMember>;
        workLegalStatus?: string;
        name: string;
        logoUrl: string;
    };
    features?: Array<string>;
    members: Array<CompanyMember>;
    workLegalStatus: string;
    name: string;
    logoUrl: string;
    legalInfo?: LegalInfoState;
    paymentInfo?: PaymentInfoState;
    remunerationInfo?: RemunerationInfoState;
    users?: Array<CompanyUser>;
};

// ACTIONS

export type SetCompanyFeatures = { type: "SET_COMPANY_FEATURES"; features: Array<string> };
export type SetCompanyMembersAction = {
    type: "SET_COMPANY_MEMBERS";
    members: Array<CompanyMember>;
};
export type SetGroupMembersAction = { type: "SET_GROUP_MEMBERS"; members: Array<CompanyMember> };
export type SetWorkLegalStatus = { type: "SET_WORKLEGALSTATUS"; workLegalStatus: string };
export type SetGroupWorkLegalStatus = {
    type: "SET_GROUP_WORKLEGALSTATUS";
    workLegalStatus: string;
};
export type SetGroupName = { type: "SET_GROUP_NAME"; name: string };
export type SetGroupLogo = { type: "SET_GROUP_LOGO"; logoUrl: string };
export type SetCompanyName = { type: "SET_COMPANY_NAME"; name: string };
export type SetCompanyLogo = { type: "SET_COMPANY_LOGO"; logoUrl: string };
export type SetCompanyUsers = { type: "SET_COMPANY_ORGANISATION_USERS"; payload: CompanyUser[] };
export type SetCompanyLegalInfo = { type: "SET_COMPANY_LEGAL_INFO"; data: LegalInfo };
export type SetLegalInfoLoadingStatus = {
    type: "SET_LEGAL_INFO_LOADING_STATUS";
    isLoading: boolean;
};

export type SetCompanyPaymentInfo = { type: "SET_COMPANY_PAYMENT_INFO"; data: PaymentInfo };
export type SetPaymentInfoLoadingStatus = {
    type: "SET_PAYMENT_INFO_LOADING_STATUS";
    isLoading: boolean;
};
export type SetCompanyRemunerationInfo = {
    type: "SET_COMPANY_REMUNERATION_INFO";
    data: RemunerationInfo;
};
export type SetRemunerationInfoLoadingStatus = {
    type: "SET_REMUNERATION_INFO_LOADING_STATUS";
    isLoading: boolean;
};
export type MarkIndemnitiesConfirmed = { type: "MARK_INDEMNITIES_CONFIRMED" };
export type MarkHoursTypesConfirmed = { type: "MARK_HOURS_TYPES_CONFIRMED" };

export type Action =
    | SetCompanyFeatures
    | SetCompanyMembersAction
    | SetGroupMembersAction
    | SetWorkLegalStatus
    | SetGroupWorkLegalStatus
    | SetGroupName
    | SetGroupLogo
    | SetCompanyName
    | SetCompanyLogo
    | SetCompanyUsers
    | SetCompanyLegalInfo
    | SetLegalInfoLoadingStatus
    | SetCompanyPaymentInfo
    | SetPaymentInfoLoadingStatus
    | SetCompanyRemunerationInfo
    | SetRemunerationInfoLoadingStatus
    | MarkIndemnitiesConfirmed
    | MarkHoursTypesConfirmed;

export const ORGANISATION_USERS_ROLES = {
    ADMIN: "admin",
    MISSION_MANAGER: "missionManager",
};
