import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import I18n from "i18n-js";
import { atom, useAtomValue, useSetAtom } from "jotai";
import {
    Add,
    ArrowBack,
    ArrowForward,
    Button,
    Delete,
    fonts,
    Information,
    Loader,
    useConfirmDialog,
} from "sui";

import { queryClient } from "@App";
import { useLocation } from "@lib/hooks/useLocation";
import useOnClickOutside from "@lib/hooks/useOnClickOutside";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { useMatchRoute, useNavigate } from "@tanstack/react-router";

import Quotation from "./Quotation/Quotation";
import { modalShiftsStateAtom } from "./ShiftsStep/ShiftsStep";
import { shiftsToDeleteAtom } from "./ShiftsStep/ShiftsTable/ShiftsTable";
import { useShiftAPI } from "./ShiftsStep/useShiftAPI";
import { taskFormRoute } from "./route";

import styles from "./TaskFormFooter.module.css";
export const orderDataToSendAtom = atom({});
export const disabledSubmitAtom = atom(false);

export function TaskFormFooter() {
    const setModalShistsStateAtom = useSetAtom(modalShiftsStateAtom);
    const shiftsToDeleteAtomValue = useAtomValue(shiftsToDeleteAtom);
    const matchRoute = useMatchRoute();
    const { taskId } = taskFormRoute.useParams();
    const { data: task, isLoading: isTaskLoading } = useQuery(queries.task.detail(taskId));
    const { data: quotation, refetch: refetchQuotation } = useQuery({
        ...queries.quotation.detail(taskId),
        enabled: !!task?.shifts?.length,
    });
    const quotationTotal = quotation?.price;

    const orderDataToSend = useAtomValue(orderDataToSendAtom);
    const disabledSubmit = useAtomValue(disabledSubmitAtom);

    const location = useLocation();
    const step = location.pathname.split("/")[3];
    const navigate = useNavigate();

    const [priceEstimateTooltipVisible, setPriceEstimateTooltipVisible] = useState(false);
    const priceEstimateRef = useRef(null);
    useOnClickOutside(priceEstimateRef, () => setPriceEstimateTooltipVisible(false));

    const confirm = useConfirmDialog();

    const { removeShifts } = useShiftAPI();

    useEffect(() => {
        if (task?.shifts?.length) {
            refetchQuotation();
        } else {
            queryClient.removeQueries(queries.quotation.detail(taskId));
        }
    }, [task?.shifts]);

    const { patchTask: updateTask, isPending } = usePatchTask({
        onSuccess: goToNextStep,
    });

    function handleValidation() {
        updateTask({
            id: taskId,
            ...orderDataToSend,
        });
    }

    function goToNextStep() {
        switch (step) {
            case "motive":
                navigate({
                    to: `/taskPosting/$taskId/shifts`,
                    params: { taskId },
                });
                break;
            case "shifts":
                navigate({
                    to: `/taskPosting/$taskId/details`,
                    params: { taskId },
                });
                break;
            case "details":
                navigate({
                    to: `/taskPosting/$taskId/workers`,
                    params: { taskId },
                });
                break;
            case "workers":
                navigate({
                    to: `/taskPosting/$taskId/summary`,
                    params: { taskId },
                });
                break;
            default:
                navigate({
                    to: `/taskPosting/$taskId/motive`,
                    params: { taskId },
                });
                break;
        }
    }

    function goToPreviousStep() {
        switch (step) {
            case "summary":
                navigate({
                    to: `/taskPosting/$taskId/workers`,
                    params: { taskId },
                });
                break;
            case "workers":
                navigate({
                    to: `/taskPosting/$taskId/details`,
                    params: { taskId },
                });
                break;
            case "details":
                navigate({
                    to: `/taskPosting/$taskId/shifts`,
                    params: { taskId },
                });
                break;
            case "shifts":
                navigate({
                    to: `/taskPosting/$taskId/motive`,
                    params: { taskId },
                });
                break;
            default:
                navigate({ to: `/job-descriptions` });
                break;
        }
    }
    const isShiftPage = !!matchRoute({ to: "/taskPosting/$taskId/shifts" });

    if (isShiftPage && task?.shifts?.length === 0) return null;

    return (
        <footer className={styles.footer}>
            {shiftsToDeleteAtomValue.length > 0 ? (
                <div className={styles.flex}>
                    <Button
                        shape='outlined'
                        intention='danger'
                        onClick={() =>
                            confirm({
                                title: I18n.t("task_shifts_dialog_title"),
                                message: I18n.t("task_shifts_dialog_message"),
                            }).then(() => removeShifts({ taskId, ids: shiftsToDeleteAtomValue }))
                        }
                        icon={<Delete />}
                        iconDisposition='left'
                    >
                        {I18n.t(`task_shifts_footer_delete_button`)}
                    </Button>
                </div>
            ) : (
                <>
                    <div className={styles.flex}>
                        <Button
                            shape='outlined'
                            intention='secondary'
                            onClick={goToPreviousStep}
                            icon={<ArrowBack />}
                            iconDisposition='left'
                        >
                            {I18n.t(`back`)}
                        </Button>
                        <div className={styles.shiftsEstimationAction}>
                            {(task?.shifts?.length ?? 0) > 0 && quotation !== null && (
                                <div ref={priceEstimateRef} className={styles.priceEstimate}>
                                    {priceEstimateTooltipVisible && (
                                        <div className={styles.tooltip}>
                                            <Quotation quotation={quotation} withTaxes={false} />
                                        </div>
                                    )}
                                    <div
                                        className={clsx(
                                            styles.priceEstimateLabel,
                                            fonts.sans20Medium,
                                        )}
                                    >
                                        Estimation Totale HT
                                    </div>
                                    <div
                                        className={clsx(
                                            styles.priceEstimateAmount,
                                            fonts.sans24Medium,
                                        )}
                                    >
                                        {quotationTotal ? (
                                            parseFloat(quotationTotal).toFixed(2) + "€"
                                        ) : (
                                            <Loader />
                                        )}
                                    </div>
                                    <Button
                                        onClick={() =>
                                            setPriceEstimateTooltipVisible(
                                                !priceEstimateTooltipVisible,
                                            )
                                        }
                                        icon={<Information />}
                                        shape='outlined'
                                        intention='secondary'
                                    />
                                </div>
                            )}
                            {isShiftPage && (
                                <Button
                                    onClick={() =>
                                        setModalShistsStateAtom({
                                            open: true,
                                            mode: "creation",
                                            shift: null,
                                        })
                                    }
                                    icon={<Add />}
                                    iconDisposition='left'
                                    shape='outlined'
                                    intention='secondary'
                                >
                                    {I18n.t("task_shifts_add")}
                                </Button>
                            )}
                        </div>
                    </div>
                    <Button
                        disabled={disabledSubmit}
                        loading={isTaskLoading || isPending}
                        onClick={() => handleValidation()}
                        icon={<ArrowForward />}
                    >
                        {I18n.t(`continue`)}
                    </Button>
                </>
            )}
        </footer>
    );
}
