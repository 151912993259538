import { differenceInMinutes } from "date-fns";
import i18n from "i18n-js";

export const ATT_WAITING = "att.waiting";
export const ATT_DISPUTED = "att.disputed";
export const ATT_CONFLICT = "att.conflict";
export const ATT_VALIDATED = "att.validated";

export const filterColors = {
    [ATT_WAITING]: "orange",
    [ATT_DISPUTED]: "grey",
    [ATT_CONFLICT]: "grey",
    [ATT_VALIDATED]: "green",
} as const;

export const setStatusLabel = (status) => {
    const filtersLabels = {
        [ATT_WAITING]: i18n.t("ts_tag_waiting"),
        [ATT_DISPUTED]: i18n.t("ts_tag_disputed"),
        [ATT_CONFLICT]: i18n.t("ts_tag_disputed"),
        [ATT_VALIDATED]: i18n.t("ts_tag_validated"),
    };

    return filtersLabels[status] || "";
};

/**
 * @return {string}
 */
export function sumAttendancesNetDuration(attendances) {
    if (!attendances || !attendances.length) {
        return "";
    }

    const sumInMinutes = attendances.reduce((minutesTotal, attendance) => {
        const breakSum = attendance.history[attendance.history.length - 1].breaks
            ? attendance.history[attendance.history.length - 1].breaks.reduce(
                  (breakTotal, { startDate, endDate }) =>
                      breakTotal +
                      Math.abs(differenceInMinutes(new Date(startDate), new Date(endDate))),
                  0,
              )
            : 0;

        return (
            minutesTotal +
            differenceInMinutes(
                new Date(attendance.history[attendance.history.length - 1].endDate),
                new Date(attendance.history[attendance.history.length - 1].startDate),
            ) -
            breakSum
        );
    }, 0);

    if (!sumInMinutes) {
        return "";
    }

    const hours = Math.floor(sumInMinutes / 60);
    const minutes = sumInMinutes % 60;

    return `${hours < 10 ? "" : hours}h${minutes < 10 ? "" : minutes}`;
}

/**
 * @return {number} sum of breaks in minutes
 */
export function sumShiftBreaks(breaks) {
    if (!breaks || !breaks.length) {
        return 0;
    }

    return breaks.reduce(
        (total, { startDate, endDate }) =>
            total + Math.abs(differenceInMinutes(new Date(startDate), new Date(endDate))),
        0,
    );
}

/**
 * @param {string} duration a string like 08h30
 * @returns a duration string, 0 padded if minutes are less than 10 (length 1)
 */
export function padZeroBeforeMinutes(duration) {
    // split and remove whitespaces
    const splittedDuration = duration.split("h").map((d) => d.trim());

    // if is minutes below 10 (length 1)
    if (splittedDuration[1] && splittedDuration[1].length === 1) {
        return `${splittedDuration[0]}h0${splittedDuration[1]}`;
    }

    return duration;
}
