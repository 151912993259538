import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type GetNumberOfAttendancesResponse = {
    count: number;
};
export async function getNumberOfAttendances(): Promise<GetNumberOfAttendancesResponse> {
    debug.addBreadcrumb({
        message: `Get Number Of attendances`,
        category: `action`,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/v1/attendances/to_validate/count`),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("getNumberofAttendances"));
        }
        const json = await resp.json();
        return json;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("getNumberofAttendances"));
    }
}
