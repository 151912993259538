import { connect } from "react-redux";

import { updateTaskManager } from "../../../store/tasks";
import ManagerUpdateModal from "../components/ManagerUpdateModal";

const mapStateToProps = (state, ownProps) => ({
    taskId: ownProps.task.id,
    taskManagerId: ownProps.task.manager.id,
});

const mapDispatchToProps = (dispatch) => ({
    updateTaskManager: (taskId, managerId) => dispatch(updateTaskManager(taskId, managerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerUpdateModal);
