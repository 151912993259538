import { filter } from "lodash";

//------------------------------------
// Constants
//------------------------------------

export const SET_TASKS_IDS = `SET_TASKS_IDS` as const;
export const PENDING_REQUEST = `PENDING_REQUEST` as const;
export const SET_ITEMS_PER_PAGE = `SET_ITEMS_PER_PAGE` as const;
export const SET_PAGE_COUNT = `SET_PAGE_COUNT` as const;
export const DELETE_TASK = `DELETE_TASK` as const;

//------------------------------------
// Actions Handlers
//------------------------------------

const ACTION_HANDLERS = {
    [SET_ITEMS_PER_PAGE]: (state, action) => ({ ...state, itemsPerPage: action.itemsNumber }),
    [SET_PAGE_COUNT]: (state, action) => ({ ...state, pageCount: action.pageCount }),
    [SET_TASKS_IDS]: (state, action) => ({
        ...state,
        isFetching: false,
        ids: action.tasks && Object.keys(action.tasks),
        pageNumber: action.pageNumber,
        count: action.count,
    }),
    [DELETE_TASK]: (state, action) => ({
        ...state,
        ids: filter(state.ids, (id) => action.taskId !== id),
    }),
};

const initialState = {
    isFetching: false,
    pageCount: 1,
    pageNumber: 1,
    ids: [] as string[],
    itemsPerPage: 5,
};

const paginationReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};

export default paginationReducer;
