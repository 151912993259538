import i18n from "i18n-js";
import { ExpandableCheckboxPanel, InputNumber } from "side-ui";

import { onFieldChange } from "./onFieldChange";

const HoursPerDayCondition = (props) => {
    const { field, form } = props;
    const { errors, touched, handleBlur, setFieldValue } = form;

    return (
        <div className='other-parameters__field'>
            <ExpandableCheckboxPanel
                checked={field.value.checked}
                label={i18n.t(
                    `settings_remuneration_indemnities_other_other_parameters_hours_per_day`,
                )}
                onChange={() => {
                    onFieldChange(
                        "hoursPerDay",
                        "checked",
                        !field.value.checked,
                        form.values.otherParameters,
                        setFieldValue,
                    );
                }}
            >
                <InputNumber
                    id='otherParameters.hoursPerDay.threshold'
                    label={i18n.t(
                        `settings_remuneration_indemnities_other_other_parameters_hours_per_day_label`,
                    )}
                    placeholder={60}
                    onChange={(e) =>
                        onFieldChange(
                            "hoursPerDay",
                            "threshold",
                            e.target.value,
                            form.values.otherParameters,
                            setFieldValue,
                        )
                    }
                    onBlur={handleBlur}
                    smallInput={true}
                    value={field.value.threshold}
                    error={
                        touched.otherParameters &&
                        touched.otherParameters.hoursPerDay &&
                        touched.otherParameters.hoursPerDay.threshold &&
                        errors.otherParameters &&
                        errors.otherParameters.hoursPerDay &&
                        errors.otherParameters.hoursPerDay.threshold
                    }
                    hint={i18n.t(
                        `settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit`,
                    )}
                />
            </ExpandableCheckboxPanel>
        </div>
    );
};

export { HoursPerDayCondition };
