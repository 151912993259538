import * as React from "react";
import i18n from "i18n-js";
import { ModalSimple, ModalsService } from "side-ui";

export const showUnsavedChangesModal = (dirty, confirm, handleSubmit) => {
    if (!dirty) {
        confirm();
        return;
    }

    ModalsService.openModal({
        id: "UNSAVED_CHANGES_MODAL",
        content: (
            <UnsavedChangesModal
                hideModal={() => {
                    ModalsService.closeModal("UNSAVED_CHANGES_MODAL");
                    confirm();
                }}
                saveChanges={() => {
                    ModalsService.closeModal("UNSAVED_CHANGES_MODAL");
                    handleSubmit();
                }}
            />
        ),
    });
};

const UnsavedChangesModal = (props) => {
    const { saveChanges, hideModal } = props;
    return (
        <ModalSimple
            action={saveChanges}
            validateLabel={i18n.t(`settings_warning_save_dialog_save`)}
            hideModal={hideModal}
            cancelLabel={i18n.t(`settings_warning_save_dialog_discard`)}
            title={i18n.t(`settings_warning_save_dialog_title`)}
        >
            {i18n.t(`settings_warning_save_dialog_content`)}
        </ModalSimple>
    );
};

export default UnsavedChangesModal;
