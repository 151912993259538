import i18n from "i18n-js";
import { ModalsService, ToastsService } from "side-ui";

import { queryClient } from "@App";
import { queries } from "@lib/queries";
import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import * as backend from "../lib/api";
import checkResForError from "../lib/utils/checkResForError";
import { handleError } from "../lib/utils/error";
import isAuth from "../lib/utils/isAuth";

import normalizeTasks from "./normalizers/tasks";
import type { Action, State } from "./types/tasks";
import paginationReducer from "./paginate";
import type { Dispatch, GetState } from "./types";
//------------------------------------
// Constants
//------------------------------------

export const SELECT_STATUS = `SELECT_STATUS` as const;
export const SET_STATUSES = `SET_STATUSES` as const;
export const LOAD_STATUSES = `LOAD_STATUSES` as const;
export const LOAD_LATEST_TASK = `LOAD_LATEST_TASK` as const;
export const REMOVE_LATEST_TASK_ID = `REMOVE_LATEST_TASK_ID` as const;
export const SET_TASKS_IDS = `SET_TASKS_IDS` as const;
export const LOAD_TASKS = `LOAD_TASKS` as const;
export const PENDING_REQUEST = `PENDING_REQUEST` as const;
export const SET_ITEMS_PER_PAGE = `SET_ITEMS_PER_PAGE` as const;
export const SET_PAGE_COUNT = `SET_PAGE_COUNT` as const;
export const DELETE_TASK = `DELETE_TASK` as const;
export const RENAME_TASK = `RENAME_TASK` as const;
export const UPDATE_TASK_MANAGER = `UPDATE_TASK_MANAGER` as const;

//------------------------------------
// Actions
//------------------------------------
export const selectStatus = (status?: string) => ({
    type: SELECT_STATUS,
    status,
});

export const setStatuses = (statuses: Array<string>) => ({
    type: SET_STATUSES,
    statuses,
});

export const loadStatuses = (statuses: Array<string>) => ({
    type: LOAD_STATUSES,
    statuses,
});

export const removeLatestTaskId = () => ({
    type: REMOVE_LATEST_TASK_ID,
});

export const setTasksIds = (status, tasks, count, pageNumber) => ({
    type: SET_TASKS_IDS,
    tasks,
    status,
    pageNumber,
    count,
});

export const loadTasks = (tasks: { [key: string]: backend.TasksItem }) => ({
    type: LOAD_TASKS,
    tasks,
});

export const pendingRequest = (status: string, bool: boolean) => ({
    type: PENDING_REQUEST,
    status,
    payload: bool,
});

export const setItemsPerPage = (status: string, itemsNumber: number) => ({
    type: SET_ITEMS_PER_PAGE,
    status,
    itemsNumber,
});

export const setPageCount = (status: string, pageCount: number) => ({
    type: SET_PAGE_COUNT,
    status,
    pageCount,
});

export const deleteTaskById = (status: string, taskId: string) => ({
    type: DELETE_TASK,
    status,
    taskId,
});

export const renameTaskById = (taskId: string, newName: string) => ({
    type: RENAME_TASK,
    taskId,
    newName,
});

export const setTaskManager = (taskId: string, manager: any) => ({
    type: UPDATE_TASK_MANAGER,
    taskId,
    manager,
});

//------------------------------------
// Specialized Action Creator
//------------------------------------

export const getStatuses = () =>
    async function (dispatch: Dispatch) {
        const userInfo = queryClient.getQueryData<backend.GetUserInfoResponse>(
            queries.user.detail().queryKey,
        );
        const role = userInfo?.role || ""; // Provide a default value for role
        const statusConditions = {
            ongoing: true,
            upcoming: true,
            draft: role && role !== "" ? ["admin", "user"].includes(role) : true,
            completed: true,
        };
        const statuses = Object.keys(statusConditions).filter((status) => statusConditions[status]);
        dispatch(setStatuses(statuses));
        dispatch(loadStatuses(statuses));
    };

// get the tasks by status (draft, upcoming, ongoing, completed)
// then, it dispatches the data to the store
export const getTasks = (status: string, pageNumber: number, noLimit: boolean) =>
    async function (dispatch: Dispatch, getState: GetState) {
        if (!isAuth()) {
            debug.warn(`User doesn't have auth info in localStorage`);
            if (window.location.pathname !== "/signin") {
                router.navigate({ to: `/signin` });
            }
            return;
        }

        const {
            display: { filters },
            tasks: { selectedStatus },
        } = getState();
        const limit = 10;
        const offset = limit * (pageNumber - 1);

        if ((filters && filters.managerId) || selectedStatus) {
            dispatch(removeLatestTaskId());
        }

        // Start fetching tasks
        dispatch(pendingRequest(status, true));
        try {
            const res = await backend.getTasks({
                status,
                offset,
                limit,
                managerId: filters.managerId,
                noLimit,
            });
            if (res) {
                if (checkResForError(res)) {
                    if (res.error) handleError(res.error);
                    dispatch(pendingRequest(status, false));
                    return;
                }
                const json = (await res.json()) as backend.getTasksResponse;
                const normalizedTasks = normalizeTasks(json.tasks);
                dispatch(setTasksIds(status, normalizedTasks, json.total, pageNumber));
                dispatch(loadTasks(normalizedTasks));
                if (status) {
                    dispatch(setItemsPerPage(status, limit));
                    dispatch(setPageCount(status, Math.ceil((json.total ?? 0) / limit)));
                }
                dispatch(pendingRequest(status, false));
            }
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
            dispatch(pendingRequest(status, false));
        }
    };

export const backendTasksStatusMap = {
    ongoing: "ongoing",
    upcoming: "upcoming",
    draft: "draft",
    completed: "done",
};

export const getHomeTasks = () =>
    async function (dispatch: Dispatch) {
        dispatch(selectStatus(undefined));
        ["upcoming", "ongoing"].forEach((status) => {
            dispatch(getTasks(backendTasksStatusMap[status], 1, true));
        });
    };

export const deleteTask = (status: string, taskId: string) =>
    async function (dispatch: Dispatch) {
        try {
            const res = await backend.deleteTask({
                taskId,
            });
            if (res) {
                if (checkResForError(res)) {
                    if (res.error) handleError(res.error);
                    return;
                }
            }
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }

        dispatch(deleteTaskById(status, taskId));

        ToastsService.addToast({
            id: `draft_deleted`,
            icon: `Checkmark`,
            content: i18n.t("draft_deleted_success"),
            type: `confirmation`,
        });

        ModalsService.closeModal(`DELETE_TASK`);
    };

export const renameTask = (taskId: string, newName: string) =>
    async function (dispatch: Dispatch) {
        try {
            await backend.updateTask({
                id: taskId,
                name: newName,
            });
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }

        dispatch(renameTaskById(taskId, newName));
        ModalsService.closeModal(`RENAME_TASK`);
    };

export const updateTaskManager = (taskId: string, managerId: string) =>
    async function (dispatch: Dispatch) {
        try {
            const task = await backend.updateTask({
                id: taskId,
                managerId,
            });

            dispatch(setTaskManager(taskId, task.manager) as any);
            ModalsService.closeModal(`UPDATE_MANAGER`);

            ToastsService.addToast({
                id: `manager_update_success`,
                icon: `Checkmark`,
                content: i18n.t("manager_update_success"),
                type: `confirmation`,
            });
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
        }
    };

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    statuses: [],
    selectedStatus: undefined,
    tasks: {},
    latestTaskId: undefined,
    pagination: {},
};

const tasksReducer = (state: State = initialState, action: Action): State => {
    /* eslint-disable indent */
    switch (action.type) {
        case SELECT_STATUS:
            return { ...state, selectedStatus: action.status };
        case SET_STATUSES:
            return { ...state, statuses: action.statuses };
        case LOAD_STATUSES:
            return {
                ...state,
                pagination: {
                    ...action.statuses.reduce((obj, status) => ({ ...obj, [status]: {} }), {}),
                    ...state.pagination,
                },
            };
        case REMOVE_LATEST_TASK_ID:
            return {
                ...state,
                latestTaskId: undefined,
            };
        case SET_TASKS_IDS:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [action.status]: paginationReducer(state.pagination[action.status], action),
                },
            };
        case LOAD_TASKS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    ...action.tasks,
                },
            };
        case PENDING_REQUEST:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [action.status]: {
                        ...state.pagination[action.status],
                        isFetching: action.payload,
                    },
                },
            };
        case SET_ITEMS_PER_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [action.status]: paginationReducer(state.pagination[action.status], action),
                },
            };
        case SET_PAGE_COUNT:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [action.status]: paginationReducer(state.pagination[action.status], action),
                },
            };
        case DELETE_TASK:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [action.status]: paginationReducer(state.pagination[action.status], action),
                },
                tasks: Object.values(state.tasks).reduce((acc, task) => {
                    if (task.id === action.taskId) return acc;
                    return {
                        ...acc,
                        [task.id]: task,
                    };
                }, {}),
            };

        case RENAME_TASK:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: {
                        ...state.tasks[action.taskId],
                        name: action.newName,
                    },
                },
            };
        case UPDATE_TASK_MANAGER:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: {
                        ...state.tasks[action.taskId],
                        manager: action.manager,
                    },
                },
            };
        default:
            return state;
    }
};

export default tasksReducer;
