import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export async function download({ url, fileName }: { url: string; fileName: string }) {
    debug.addBreadcrumb({
        message: `download`,
        category: `action`,
        data: { url, userId: localStorage.getItem("side-user-id") },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/download/${url}`, {
                method: `GET`,
                credentials: "omit",
            }),
        );

        if (!resp.ok) {
            return Promise.reject(new Error(`Failed to download file`));
        }

        const blob = await resp.blob();
        const dlUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = dlUrl;
        a.download = fileName;
        a.click();
    } catch (e) {
        return Promise.reject(e);
    }
}
