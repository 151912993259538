import i18n from "i18n-js";
import { ExpandableCheckboxPanel, Loader, MultiSelect } from "side-ui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import { onFieldChange } from "./onFieldChange";

export function TaskTypeCondition({ field, form }) {
    const { errors, touched, setFieldValue } = form;

    const { data: jobTypes, isLoading: isLoading } = useQuery(queries.jobTypes.list());

    const locale = localStorage.getItem(`side_team_locale`) || `fr`;

    return (
        <div className='other-parameters__field'>
            <ExpandableCheckboxPanel
                checked={field.value.checked}
                label={i18n.t(`settings_remuneration_indemnities_other_other_parameters_jobtype`)}
                onChange={() => {
                    onFieldChange(
                        "taskType",
                        "checked",
                        !field.value.checked,
                        form.values.otherParameters,
                        setFieldValue,
                    );
                }}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <MultiSelect
                        id='otherParameters.taskType.jobTypes'
                        items={jobTypes.map((jobType) => ({
                            label: jobType[locale].translatedName,
                            value: jobType.id,
                        }))}
                        eventTypes={["mousedown", "touchstart"]}
                        value={field.value.jobTypes}
                        excludeScrollbar={false}
                        label={i18n.t(
                            `settings_remuneration_indemnities_other_other_parameters_jobtype_label`,
                        )}
                        onChange={(e) =>
                            onFieldChange(
                                `taskType`,
                                `jobTypes`,
                                e.target,
                                form.values.otherParameters,
                                setFieldValue,
                            )
                        }
                        error={
                            touched.otherParameters?.taskType?.jobTypes &&
                            errors.otherParameters?.taskType?.jobTypes
                        }
                    />
                )}
            </ExpandableCheckboxPanel>
        </div>
    );
}
