import { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import i18n from "i18n-js";
import { Elements } from "react-stripe-elements";
import { StripeProvider } from "react-stripe-elements";
import {
    BlockFeedback,
    Button,
    HeadingWithIcon,
    InputText,
    Loader,
    NavigationService,
    RadioButtons,
    ToggleSwitch,
} from "side-ui";
import { ComboBox } from "sui";

import env from "@lib/env";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import FormCard from "../../../components/FormCard";
import { normalizeTvaNumber } from "../../../lib/utils/inputsNormalizers";
import { paymentInfoValidationLogic, submitPaymentInfo } from "../logic/PaymentInformation";

import SaveBar from "./partials/SaveBar";
import { showUnsavedChangesModal } from "./partials/UnsavedChangesModal";
import BankCardForm from "./BankCardForm";
import { getBillingCountryOptions } from "./billing-countries";
import IbanForm from "./IbanForm";

import "./PaymentInformation.scss";

const onFieldChange = (fieldName, value, prevValue, setFieldValue) => {
    let newValue = value;
    switch (fieldName) {
        case "tvaNumber":
            newValue = normalizeTvaNumber(value, prevValue);
            break;
        default:
            break;
    }
    setFieldValue(fieldName, newValue);
};

function PaymentInformation({
    isLoading,
    getPaymentInfo,
    paymentInfo: {
        wantOrderForm,
        paymentMethod,
        accountingInfo,
        tvaNumber,
        billingAddress,
        clientSecret,
    },
    updateCompanyPaymentInfo,
}) {
    const { data: company } = useQuery(queries.company.detail());
    const billingName = company?.organisation.name;
    const billingCountryOptions = useMemo(() => getBillingCountryOptions(), []);
    const [stripeLoaded, setStripeLoaded] = useState(false);

    const [billingCountryInputValue, setBillingCountryInputValue] = useState("");

    const { data: user } = useQuery(queries.user.detail());
    const isUserInsider = user?.isInsider;

    useEffect(() => {
        getPaymentInfo();

        if (window.Stripe) {
            setStripeLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (billingAddress && billingAddress.country) {
            setBillingCountryInputValue(
                billingCountryOptions.find(({ value }) => value === billingAddress?.country)?.label,
            );
        }
    }, [billingAddress]);

    if (isLoading) {
        return <Loader />;
    }

    const initialVal = {
        method: (paymentMethod && paymentMethod.type) || "",
        orderForm: wantOrderForm || false,
        methodSepaIban:
            (paymentMethod &&
                paymentMethod.type === "sepa_debit" &&
                paymentMethod.last4 &&
                `XXXXXXXXXXXXXXXXXXXXXX${paymentMethod.last4}`) ||
            "",
        methodSepaCountry: (paymentMethod && paymentMethod.country) || "FR",
        methodSepaCurrency: (paymentMethod && paymentMethod.currency) || "EUR",
        methodSepaTos: (paymentMethod && paymentMethod.type === "sepa_debit") || false,
        methodCardNumber:
            (paymentMethod &&
                paymentMethod.type === "card" &&
                paymentMethod.last4 &&
                `XXXX XXXX XXXX ${paymentMethod.last4}`) ||
            "",
        methodCardExpDate: (paymentMethod && paymentMethod.expirationDate) || "",
        methodCardCvv: paymentMethod && paymentMethod.type === "card" ? "XXX" : "",
        billingAddress: (billingAddress && billingAddress.street) || "",
        billingPostalCode: (billingAddress && billingAddress.postalCode) || "",
        billingCity: (billingAddress && billingAddress.city) || "",
        billingCountry: (billingAddress && billingAddress.country) || "",
        tvaNumber: tvaNumber || "",
        accountingFirstname: (accountingInfo && accountingInfo.firstName) || "",
        accountingLastname: (accountingInfo && accountingInfo.lastName) || "",
        accountingEmail: (accountingInfo && accountingInfo.email) || "",
        accountingPhonenumber: (accountingInfo && accountingInfo.phoneNumber) || "",
        billingName: billingName || "",
        paymentMethodId: "",
        showStripeElement: false,
    };

    function SavedPaymentDetails(title, body, buttonLabel, buttonAction) {
        return (
            <>
                <p className='input-label'>{title}</p>
                <div className='row'>
                    <div className='col-xs-14 col-sm-9'>{body}</div>
                    <div className='col-xs-14 col-sm-5'>
                        <Button color='blue' action={buttonAction}>
                            {buttonLabel}
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Formik
            initialValues={initialVal}
            onSubmit={(values, actions) =>
                submitPaymentInfo(
                    clientSecret,
                    values,
                    actions,
                    initialVal,
                    updateCompanyPaymentInfo,
                )
            }
            validate={(values) => paymentInfoValidationLogic(values, initialVal)}
            validateOnChange={false}
            validateOnBlur={true}
            render={({
                values,
                errors,
                dirty,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
                initialValues,
                setFieldValue,
            }) => {
                NavigationService.watch(
                    "payment-informations",
                    "/settings/payment",
                    i18n.t(`settings_warning_save_dialog_content`),
                    (confirm) => showUnsavedChangesModal(dirty, confirm, handleSubmit),
                    () => dirty,
                );
                const paymentMethods =
                    values.method === `collectionStatement`
                        ? [
                              {
                                  name: "method",
                                  value: "collectionStatement",
                                  label: i18n.t(`settings_payment_method_cs_label`),
                                  description: i18n.t(`settings_payment_method_cs_desc`),
                              },
                          ]
                        : [
                              {
                                  name: "method",
                                  value: "card",
                                  label: i18n.t(`settings_payment_method_card_label`),
                                  description: i18n.t(`settings_payment_method_card_desc`),
                              },
                              {
                                  name: "method",
                                  value: "sepa_debit",
                                  label: i18n.t(`settings_payment_method_sepa_label`),
                                  description: i18n.t(`settings_payment_method_sepa_desc`),
                              },
                          ];

                const StripeElements = () => {
                    if (!stripeLoaded) {
                        return <p>Please load Stripe to access the payment settings</p>;
                    }

                    return (
                        <StripeProvider apiKey={env.STRIPE_PUBLIC_KEY}>
                            <Elements
                                fonts={[
                                    {
                                        cssSrc: "https://side-static.s3.eu-central-1.amazonaws.com/fonts/fonts.scss",
                                    },
                                ]}
                                locale={localStorage.getItem(`side_team_locale`) || "fr"}
                            >
                                {values.method === "sepa_debit" ? (
                                    <IbanForm
                                        setSourceId={(value) => setFieldValue(`sourceId`, value)}
                                        values={values}
                                    />
                                ) : (
                                    <BankCardForm
                                        clientSecret={clientSecret}
                                        setPaymentMethodId={(value) =>
                                            setFieldValue(`paymentMethodId`, value)
                                        }
                                    />
                                )}
                            </Elements>
                        </StripeProvider>
                    );
                };

                return (
                    <div className='settings-payment-information'>
                        <h2 className='settings__heading'>{i18n.t(`settings_payment_title`)}</h2>
                        <form onSubmit={handleSubmit}>
                            <div
                                id='payment-method'
                                data-hash='payment-method'
                                className='settings__section'
                            >
                                <HeadingWithIcon
                                    icon='Card'
                                    label={i18n.t(`settings_payment_method_title`)}
                                />
                                <p className='settings__teasing'>
                                    {i18n.t(`settings_payment_method_subtitle`)}
                                </p>
                                <fieldset>
                                    {errors.method && (
                                        <BlockFeedback
                                            content={errors.method}
                                            type='error'
                                            withIcon={false}
                                        />
                                    )}
                                    {errors.api && (
                                        <BlockFeedback
                                            content={errors.api}
                                            type='error'
                                            withIcon={false}
                                        />
                                    )}
                                    <div>
                                        <RadioButtons
                                            label={i18n.t(`settings_payment_method_label`)}
                                            options={paymentMethods}
                                            value={{
                                                card: false,
                                                sepa_debit: false,
                                                collectionStatement: false,
                                                [values.method]: true,
                                            }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {values.method === "sepa_debit" && (
                                            <FormCard>
                                                {values.methodSepaIban && !values.showStripeElement
                                                    ? SavedPaymentDetails(
                                                          i18n.t(
                                                              `settings_payment_method_sepa_saved`,
                                                          ),
                                                          values.methodSepaIban,
                                                          i18n.t(
                                                              `settings_payment_method_sepa_modify`,
                                                          ),
                                                          () =>
                                                              setFieldValue(
                                                                  `showStripeElement`,
                                                                  true,
                                                              ),
                                                      )
                                                    : StripeElements()}
                                            </FormCard>
                                        )}
                                        {values.method === "card" && (
                                            <FormCard>
                                                {values.methodCardNumber &&
                                                !values.showStripeElement
                                                    ? SavedPaymentDetails(
                                                          i18n.t(
                                                              `settings_payment_method_card_saved`,
                                                          ),
                                                          values.methodCardNumber,
                                                          i18n.t(
                                                              `settings_payment_method_card_modify`,
                                                          ),
                                                          () =>
                                                              setFieldValue(
                                                                  `showStripeElement`,
                                                                  true,
                                                              ),
                                                      )
                                                    : StripeElements()}
                                            </FormCard>
                                        )}
                                        {values.method !== `collectionStatement` && (
                                            <p className='note'>
                                                {i18n.t(`settings_payment_method_stripe`)}
                                            </p>
                                        )}
                                        {isUserInsider && (
                                            <ToggleSwitch
                                                id='orderForm'
                                                label={i18n.t(`settings_payment_method_order_form`)}
                                                onChange={handleChange}
                                                checked={values.orderForm}
                                            />
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                            <div
                                id='billing-info'
                                data-hash='billing-info'
                                className='settings__section'
                            >
                                <HeadingWithIcon
                                    icon='Company'
                                    label={i18n.t(`settings_payment_billing_title`)}
                                />
                                <p className='settings__teasing'>
                                    {i18n.t(`settings_payment_billing_subtitle`)}
                                </p>
                                <fieldset>
                                    <div className='row'>
                                        <div className='col-sm-9 col-xs-14'>
                                            <InputText
                                                id='billingAddress'
                                                label={i18n.t(`settings_payment_billing_address`)}
                                                placeholder={i18n.t(
                                                    `settings_payment_billing_address_placeholder`,
                                                )}
                                                smallInput={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.billingAddress}
                                                error={
                                                    touched.billingAddress && errors.billingAddress
                                                }
                                            />
                                        </div>
                                        <div className='col-sm-5 col-xs-14'>
                                            <InputText
                                                id='billingPostalCode'
                                                label={i18n.t(
                                                    `settings_payment_billing_postal_code`,
                                                )}
                                                placeholder={i18n.t(
                                                    `settings_payment_billing_postal_code_placeholder`,
                                                )}
                                                smallInput={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.billingPostalCode}
                                                error={
                                                    touched.billingPostalCode &&
                                                    errors.billingPostalCode
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-7 col-xs-14'>
                                            <InputText
                                                id='billingCity'
                                                label={i18n.t(`settings_payment_billing_city`)}
                                                placeholder={i18n.t(
                                                    `settings_payment_billing_city_placeholder`,
                                                )}
                                                smallInput={true}
                                                onChange={(newSelection) =>
                                                    handleChange(newSelection)
                                                }
                                                onBlur={handleBlur}
                                                value={values.billingCity}
                                                error={touched.billingCity && errors.billingCity}
                                            />
                                        </div>
                                        <div className='col-sm-7 col-xs-14'>
                                            <ComboBox
                                                className='sui-combobox'
                                                label={i18n.t(`settings_payment_billing_country`)}
                                                placeholder={i18n.t(
                                                    `settings_payment_billing_country_placeholder`,
                                                )}
                                                options={billingCountryOptions}
                                                onChange={(country) =>
                                                    onFieldChange(
                                                        "billingCountry",
                                                        country.value,
                                                        values.billingCountry,
                                                        setFieldValue,
                                                    )
                                                }
                                                selection={
                                                    billingCountryOptions.find(
                                                        (c) => c.value === values.billingCountry,
                                                    ) || null
                                                }
                                                inputValue={billingCountryInputValue}
                                                onInputChange={setBillingCountryInputValue}
                                                openOnInitialClick

                                                // TODO: Implement error state when ComboBox will handle it
                                                // error={
                                                //     touched.billingCountry &&
                                                //     errors.billingCountry
                                                // }
                                            />
                                        </div>
                                    </div>
                                    {values.billingCountry && values.billingCountry !== "FR" && (
                                        <FormCard>
                                            <p className='tva-number-intro'>
                                                {i18n.t(`settings_payment_billing_tva_intro`)}
                                            </p>
                                            <InputText
                                                id='tvaNumber'
                                                label={i18n.t(`settings_payment_billing_tva_label`)}
                                                placeholder={i18n.t(
                                                    `settings_payment_billing_tva_placeholder`,
                                                )}
                                                tooltipContent={i18n.t(
                                                    `settings_payment_billing_tva_tip`,
                                                )}
                                                smallInput={true}
                                                onChange={(e) =>
                                                    onFieldChange(
                                                        "tvaNumber",
                                                        e.target.value,
                                                        values.tvaNumber,
                                                        setFieldValue,
                                                    )
                                                }
                                                onBlur={handleBlur}
                                                value={values.tvaNumber}
                                                error={touched.tvaNumber && errors.tvaNumber}
                                            />
                                        </FormCard>
                                    )}
                                </fieldset>
                            </div>
                            <div
                                id='accounting-info'
                                data-hash='accounting-info'
                                className='settings__section'
                            >
                                <HeadingWithIcon
                                    icon='User'
                                    label={i18n.t(`settings_payment_accounting_title`)}
                                />
                                <p className='settings__teasing'>
                                    {i18n.t(`settings_payment_accounting_subtitle`)}
                                </p>
                                <fieldset>
                                    <div className='row'>
                                        <div className='col-sm-7 col-xs-14'>
                                            <InputText
                                                id='accountingFirstname'
                                                label={i18n.t(
                                                    `settings_payment_accounting_firstname`,
                                                )}
                                                placeholder={i18n.t(
                                                    `settings_payment_accounting_firstname_placeholder`,
                                                )}
                                                smallInput={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.accountingFirstname}
                                                error={
                                                    touched.accountingFirstname &&
                                                    errors.accountingFirstname
                                                }
                                            />
                                        </div>
                                        <div className='col-sm-7 col-xs-14'>
                                            <InputText
                                                id='accountingLastname'
                                                label={i18n.t(`settings_payment_accounting_name`)}
                                                placeholder={i18n.t(
                                                    `settings_payment_accounting_name_placeholder`,
                                                )}
                                                smallInput={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.accountingLastname}
                                                error={
                                                    touched.accountingLastname &&
                                                    errors.accountingLastname
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-7 col-xs-14'>
                                            <InputText
                                                id='accountingEmail'
                                                label={i18n.t(`settings_payment_accounting_email`)}
                                                placeholder={i18n.t(
                                                    `settings_payment_accounting_email_placeholder`,
                                                )}
                                                smallInput={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.accountingEmail}
                                                error={
                                                    touched.accountingEmail &&
                                                    errors.accountingEmail
                                                }
                                            />
                                        </div>
                                        <div className='col-sm-7 col-xs-14'>
                                            <InputText
                                                id='accountingPhonenumber'
                                                label={i18n.t(
                                                    `settings_payment_accounting_phone_number`,
                                                )}
                                                placeholder={i18n.t(
                                                    `settings_payment_accounting_phone_number_placeholder`,
                                                )}
                                                smallInput={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.accountingPhonenumber}
                                                error={
                                                    touched.accountingPhonenumber &&
                                                    errors.accountingPhonenumber
                                                }
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                        {dirty && (
                            <SaveBar
                                resetAction={() => resetForm(initialValues)}
                                saveAction={handleSubmit}
                                canSave={!isSubmitting}
                            />
                        )}
                    </div>
                );
            }}
        />
    );
}

export default PaymentInformation;
