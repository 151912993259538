import { useEffect, useRef, useState } from "react";
import i18n from "i18n-js";
import { useSetAtom } from "jotai";
import { Loader, SectionHeader } from "side-ui";
import {
    Avatar,
    Button,
    Checkbox,
    ChoiceBox,
    Search,
    SmileyHappy,
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
    Tag,
    TextField,
    useConfirmDialog,
    UserAdd,
} from "sui";

import { RequestedSider, Sider } from "@lib/api";
import { useFormInput } from "@lib/hooks/form";
import { queries } from "@lib/queries";
import escapeSpecialCharacters from "@lib/utils/escapeSpecialCharacters";
import { taskFormRoute } from "@routes/TaskPosting/route";
import { useQuery } from "@tanstack/react-query";

import { useTimer } from "../../JobDescriptions/hooks/useTimer";
import { disabledSubmitAtom, orderDataToSendAtom } from "../TaskFormFooter";

import { AddProfileModal } from "./AddProfileModal";
import { FiltersModal } from "./FiltersModal";
import { RequestedSiders } from "./RequestedSiders";
import WorkersStepEmptyState from "./WorkersStepEmptyState";

import styles from "./WorkersStep.module.css";

const TABS = {
    ORGANISATION_SIDERS: "organisation-siders",
    SHARED_SIDERS: "shared-siders",
    SELECTED_SIDERS: "selected-siders",
};

export function WorkersStep() {
    const [filters, setFilters] = useState({});

    const { taskId } = taskFormRoute.useParams();
    const { data: task, isLoading: isTaskLoading } = useQuery(queries.task.detail(taskId));
    const {
        data: siders = [],
        isLoading: isLoadingSiders,
        refetch: refetchSiders,
    } = useQuery(queries.sider.list({ ...filters, type: task?.type, taskId: taskId }));

    const setOrderDataToSend = useSetAtom(orderDataToSendAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const [requestedSidersOnly, setRequestedSidersOnly] = useState(
        task?.requestedSidersOnly || false,
    );
    const [activeTab, setActiveTab] = useState(TABS.ORGANISATION_SIDERS);
    const [selectedWorkers, setSelectedWorkers] = useState<Partial<RequestedSider>[]>(
        task?.requestedSiders || [],
    );
    const { formInput: searchInput, handleChange: setSearchInput } = useFormInput("", () => null);
    const isGestionScreen = task?.type === "gestion";
    const lastestGestionSiderAddedPhoneNumber = useRef<Array<string>>([]);

    const confirm = useConfirmDialog();

    const filtersModalRef = useRef<HTMLDialogElement>(null);
    const addProfileModalRef = useRef<HTMLDialogElement>(null);

    function sortGestionSiders(gestionSiders: Sider[]) {
        if (!gestionSiders?.length || !lastestGestionSiderAddedPhoneNumber.current?.length) {
            return gestionSiders;
        }

        const sorted = gestionSiders?.reduce((acc, gs) => {
            const isJustAdded = lastestGestionSiderAddedPhoneNumber.current?.includes(
                gs.phoneNumber,
            );
            return isJustAdded ? [gs, ...acc] : [...acc, gs];
        }, []);

        return sorted;
    }

    const workers = isGestionScreen ? sortGestionSiders(siders) : siders;

    useEffect(() => {
        // No reason to block submit, even if there are no selected siders
        setDisabledSubmit(false);
    }, []);

    const organisationWorkers = workers?.filter((worker) => {
        return worker.source === "organisation";
    });

    const sharedWorkers = workers?.filter((worker) => {
        return worker.source === "network";
    });

    const searchedWorkers = workers?.filter((worker) => {
        const regex = new RegExp(escapeSpecialCharacters(searchInput.value) ?? "", "i");
        if (
            regex.test(worker?.firstName) ||
            regex.test(worker?.lastName) ||
            regex.test(worker?.phoneNumber)
        ) {
            return worker;
        }
        return false;
    });

    const searchedOrganisationWorkers = searchedWorkers?.filter((worker) => {
        // #No source field for gestion workers
        return worker.source === "organisation" || task?.type === "gestion";
    });

    const searchedSharedWorkers = searchedWorkers?.filter((worker) => {
        return worker.source === "network";
    });

    const searchedSelectedWorkers = searchedWorkers?.filter((worker) => {
        return selectedWorkers.some((w) => w.id === worker.id);
    });

    const selectedSharedWorkers = selectedWorkers?.filter(({ id }) => {
        return sharedWorkers.some((w) => w.id === id);
    });

    function handleChange(worker) {
        if (selectedWorkers.some((w) => w.id === worker.id)) {
            return setSelectedWorkers((prevState) => prevState.filter((w) => w.id !== worker.id));
        }
        return setSelectedWorkers((prevState) => prevState.concat(worker));
    }

    function handleToggleSwitchChange() {
        if (!requestedSidersOnly) {
            confirm({
                title: i18n.t("order_workers_wa-only_modal_title"),
                message: i18n.t("order_workers_wa-only_modal_warning"),
            }).then(() => {
                setRequestedSidersOnly(true);
            });
        } else {
            setRequestedSidersOnly(false);
        }
    }

    const numberOfSelectedWorkers = selectedWorkers?.length;
    const allSharedSidersSelected =
        selectedSharedWorkers.every((w) => sharedWorkers?.some((w2) => w2.id === w.id)) &&
        sharedWorkers?.length > 0;
    const numberOfSlots =
        task?.shifts?.reduce(
            (accumulator, shift) => (accumulator < shift.slots ? shift.slots : accumulator),
            0,
        ) ?? 0;

    const selectAllOrganisationSiders = () => {
        if (task?.type === "gestion") {
            return setSelectedWorkers(selectedWorkers.length !== workers.length ? workers : []);
        }
        if (selectedWorkers.length === organisationWorkers.length) {
            return setSelectedWorkers([]);
        }

        return setSelectedWorkers(organisationWorkers);
    };

    const selectAllSharedSiders = () => {
        if (selectedSharedWorkers?.length === sharedWorkers?.length) {
            return setSelectedWorkers([]);
        }

        return setSelectedWorkers(sharedWorkers);
    };

    useTimer(
        {
            timing: 100,
            callback: () =>
                setOrderDataToSend({
                    requestedSiderIds: selectedWorkers.map((worker) => worker.id),
                    requestedSidersOnly,
                }),
        },
        [selectedWorkers, requestedSidersOnly],
    );

    useEffect(() => {
        if (numberOfSelectedWorkers < numberOfSlots && requestedSidersOnly) {
            setRequestedSidersOnly(false);
        }
        if (task?.type === "gestion" && numberOfSelectedWorkers < numberOfSlots) {
            setDisabledSubmit(true);
        } else {
            setDisabledSubmit(false);
        }
    }, [selectedWorkers]);

    const tabsContent = [
        {
            value: TABS.ORGANISATION_SIDERS,
            workers: searchedOrganisationWorkers,
        },
        {
            value: TABS.SHARED_SIDERS,
            workers: searchedSharedWorkers,
        },
        {
            value: TABS.SELECTED_SIDERS,
            workers: searchedSelectedWorkers,
        },
    ];

    return (
        <div className={styles.viewContainer}>
            <SectionHeader
                title={i18n.t("order_workers_title")}
                subtitle={i18n.t("order_workers_subtitle")}
                icon='Attachment'
            />
            {isLoadingSiders || isTaskLoading ? (
                <Loader />
            ) : (
                <>
                    <section className={styles.contentContainer}>
                        {!(
                            !organisationWorkers?.length &&
                            !Object.keys(filters)?.length &&
                            activeTab === TABS.ORGANISATION_SIDERS &&
                            task?.type === "delegation"
                        ) && (
                            <div className={styles.searchContainer}>
                                <TextField
                                    className={styles.searchInput}
                                    icon={<Search />}
                                    aria-label={`search-input`}
                                    size='small'
                                    value={searchInput.value}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    placeholder={i18n.t(`order_workers_search_placeholder`)}
                                />
                                {task?.type === "delegation" ? (
                                    <Button
                                        shape={
                                            Object.keys(filters)?.length > 0
                                                ? "contained"
                                                : "outlined"
                                        }
                                        intention={
                                            Object.keys(filters)?.length > 0
                                                ? "primary"
                                                : "secondary"
                                        }
                                        onClick={() => filtersModalRef.current?.showModal()}
                                    >
                                        {i18n.t("order_workers_filters_button_label", {
                                            numberOfFilters: Object.keys(filters)?.length,
                                        })}
                                    </Button>
                                ) : (
                                    <Button
                                        shape='outlined'
                                        intention='secondary'
                                        icon={<UserAdd />}
                                        onClick={() => addProfileModalRef.current?.showModal()}
                                    >
                                        {i18n.t("order_workers_gestion_profile-add_button_label")}
                                    </Button>
                                )}
                            </div>
                        )}
                        <Tabs value={activeTab} setValue={setActiveTab}>
                            <div className={styles.tabs}>
                                <Checkbox
                                    checked={
                                        (activeTab === TABS.ORGANISATION_SIDERS
                                            ? organisationWorkers.every((w) =>
                                                  selectedWorkers.some((w2) => w2.id === w.id),
                                              ) && organisationWorkers.length > 0
                                            : allSharedSidersSelected) ||
                                        selectedWorkers.length === workers.length
                                    }
                                    onChange={
                                        activeTab === TABS.ORGANISATION_SIDERS
                                            ? selectAllOrganisationSiders
                                            : activeTab === TABS.SHARED_SIDERS
                                            ? selectAllSharedSiders
                                            : () => setSelectedWorkers([])
                                    }
                                />
                                <div className={styles.verticalSpacer} />
                                <TabsList>
                                    <TabsTrigger value={TABS.ORGANISATION_SIDERS}>
                                        {i18n.t(`order_workers_list_orga_label`, {
                                            numberOfOrganisationWorkers:
                                                task?.type === "gestion"
                                                    ? searchedOrganisationWorkers?.length
                                                    : organisationWorkers?.length,
                                        })}
                                    </TabsTrigger>
                                    {sharedWorkers?.length ? (
                                        <TabsTrigger value={TABS.SHARED_SIDERS}>
                                            {i18n.t(`order_workers_list_network_label`, {
                                                numberOfSharedWorkers: sharedWorkers?.length,
                                            })}
                                        </TabsTrigger>
                                    ) : null}
                                    <TabsTrigger value={TABS.SELECTED_SIDERS}>
                                        {i18n.t(`order_workers_list_selected_label`, {
                                            numberOfSelectedWorkers,
                                        })}
                                    </TabsTrigger>
                                </TabsList>
                            </div>

                            <div className={styles.sidersListContainer}>
                                {tabsContent.map(({ value, workers }) => (
                                    <TabsContent
                                        key={value}
                                        value={value}
                                        className={styles.sidersList}
                                    >
                                        {workers.length ? (
                                            workers.map((worker) => {
                                                return (
                                                    <ChoiceBox
                                                        key={worker.id}
                                                        label={<WorkerLabel worker={worker} />}
                                                        hierarchy='secondary'
                                                        selectionControl={
                                                            <Checkbox
                                                                checked={selectedWorkers.some(
                                                                    (w) => w.id === worker.id,
                                                                )}
                                                                onChange={() =>
                                                                    handleChange(worker)
                                                                }
                                                            />
                                                        }
                                                    />
                                                );
                                            })
                                        ) : (
                                            <WorkersStepEmptyState
                                                orderType={task?.type}
                                                workers={workers}
                                                searchValue={searchInput.value}
                                                numberOfFilters={Object.keys(filters)?.length}
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                                openFiltersModal={() => {
                                                    filtersModalRef.current?.showModal();
                                                }}
                                                openAddProfileModal={() =>
                                                    addProfileModalRef.current?.showModal()
                                                }
                                            />
                                        )}
                                    </TabsContent>
                                ))}
                            </div>
                        </Tabs>
                    </section>
                    {selectedWorkers?.length && task?.type === "delegation" ? (
                        <RequestedSiders
                            requestedSidersOnly={requestedSidersOnly}
                            selectedSidersLength={numberOfSelectedWorkers}
                            totalRequestedSiders={numberOfSlots}
                            handleToggleSwitchChange={handleToggleSwitchChange}
                        />
                    ) : null}
                </>
            )}
            <FiltersModal
                modalRef={filtersModalRef}
                setActiveFilters={(newFilters) => setFilters(newFilters)}
                activeFilters={filters}
            />
            <AddProfileModal
                modalRef={addProfileModalRef}
                submitNewProfile={(newSiderPhoneNumber) => {
                    if (isGestionScreen) {
                        lastestGestionSiderAddedPhoneNumber.current.unshift(newSiderPhoneNumber);
                    }
                    refetchSiders()
                        .then(({ data }) => {
                            if (!data) return;
                            setSelectedWorkers((prevState) => {
                                const newSider = data.find(
                                    (w) => w.phoneNumber === newSiderPhoneNumber,
                                );
                                return newSider ? [...prevState, newSider] : prevState;
                            });
                        })
                        .then(() => {
                            addProfileModalRef.current?.close();
                        });
                }}
            />
        </div>
    );
}

function WorkerLabel({ worker }: { worker: Sider }) {
    return (
        <div className={styles.siderLabel}>
            <div className={styles.avatarContainer}>
                <Avatar url={worker.pictureUrl ?? ""} />
                {worker.isRecommended ? <SmileyHappy className={styles.smiley} /> : null}
            </div>
            {`${worker.firstName} ${worker.lastName}`}
            {worker.source === "network" ? (
                <Tag color='grey'>{worker.lastOrganisationName}</Tag>
            ) : null}
        </div>
    );
}
