import { forwardRef, HTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from "react";
import clsx from "clsx";
import { fonts } from "sui";

import styles from "./Table.module.css";

const Table = forwardRef<HTMLTableElement, HTMLAttributes<HTMLTableElement>>(function Table(
    { className, ...props },
    ref,
) {
    return (
        <div className={styles.tableContainer}>
            <table ref={ref} className={clsx(styles.table, className)} {...props} />
        </div>
    );
});

const TableHeader = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
    function TableHeader({ className, ...props }, ref) {
        return (
            <thead
                ref={ref}
                className={clsx(styles.tableHeader, fonts.sans16Regular, className)}
                {...props}
            />
        );
    },
);

const TableBody = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
    function ({ className, ...props }, ref) {
        return <tbody ref={ref} className={className} {...props} />;
    },
);

const TableFooter = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
    function TableFooter({ className, ...props }, ref) {
        return <tfoot ref={ref} className={className} {...props} />;
    },
);

const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
    function TableRow({ className, ...props }, ref) {
        return <tr ref={ref} className={clsx(className, styles.tableRow)} {...props} />;
    },
);

const TableHead = forwardRef<HTMLTableCellElement, ThHTMLAttributes<HTMLTableCellElement>>(
    function TableHead({ className, ...props }, ref) {
        return <th ref={ref} className={clsx(styles.tableHead, className)} {...props} />;
    },
);

const TableCell = forwardRef<HTMLTableCellElement, TdHTMLAttributes<HTMLTableCellElement>>(
    function TableCell({ className, ...props }, ref) {
        return (
            <td
                ref={ref}
                className={clsx(styles.tableCell, fonts.sans18Regular, className)}
                {...props}
            />
        );
    },
);

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell };
