import { useEffect, useRef, useState } from "react";
import { getWeek, isSameDay, isWithinInterval, startOfDay } from "date-fns";
import i18n from "i18n-js";
import {
    ModalSimple,
    ModalsService,
    RecapConfirmation,
    SectionHeader,
    SummaryTableRow,
} from "side-ui";
import { Button, Done, TextArea, Tip } from "sui";

import ShiftsTable from "@components/ShiftsTable";
import UsersList from "@components/UsersList";
import * as backend from "@lib/api";
import { useFormInput } from "@lib/hooks/form";
import { queries } from "@lib/queries";
import { handleError } from "@lib/utils/error";
import { taskFormRoute } from "@routes/TaskPosting/route";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import Quotation from "../Quotation/Quotation";
import { getConsecutiveDates, sortByDateASC } from "../utils";

import "./Summary.scss";

export function Summary() {
    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useQuery(queries.task.detail(taskId));
    const { data: motives } = useQuery(queries.company.companyMotives());
    const motive = motives?.find((m) => m.id === task?.motiveId);
    const { data: currentYearHolidays } = useQuery(queries.holiday.list(new Date().getFullYear()));
    const { data: nextYearHolidays } = useQuery(queries.holiday.list(new Date().getFullYear() + 1));
    const holidays = [...(currentYearHolidays ?? []), ...(nextYearHolidays ?? [])];
    const { data: quotation, refetch: refetchQuotation } = useQuery(
        queries.quotation.detail(taskId),
    );
    const { formInput: sideNoteInput, handleChange: setSideNoteInput } = useFormInput(
        "",
        () => null,
    );

    const navigate = useNavigate();

    const sortedShifts = task?.shifts?.sort((a, b) => {
        const date1 = new Date(a.startDate);
        const date2 = new Date(b.startDate);

        return date1.getTime() - date2.getTime();
    });

    // Check for consecutive shifts
    const sortedDates = sortByDateASC(
        sortedShifts?.map((shift) => startOfDay(new Date(shift.startDate))),
    );
    const consecutiveCheck = getConsecutiveDates(sortedDates)[0]?.length > 6;
    const consecutiveWarning = consecutiveCheck ? (
        <Tip intention='warning' highlight={true}>
            <p>{i18n.t("shifts_warning_7_consecutive")}</p>
            <a
                href={i18n.t("order_summary_shifts_warning_FAQ_link")}
                target='_blank'
                rel='noreferrer'
            >
                {i18n.t("order_summary_shifts_warning_link_text")}
            </a>
        </Tip>
    ) : null;

    // Check for holidays
    const interval = { start: sortedDates[0], end: sortedDates[sortedDates?.length - 1] };
    // Holidays in period
    const holidaysInPeriod = holidays.filter(
        (holiday) => isWithinInterval(new Date(holiday.startDate), interval) && !holiday.solidarity,
    );
    // Check if there is no shift for holidaysInPeriod
    const noShiftForHolidaysInPeriodCheck = sortedShifts?.some((shift) =>
        holidaysInPeriod.find((holiday) =>
            isSameDay(new Date(shift.startDate), new Date(holiday.startDate)),
        ),
    );
    const noShiftForHolidaysInPeriodWarning =
        holidaysInPeriod.length > 0 && noShiftForHolidaysInPeriodCheck ? (
            <Tip intention='warning' highlight={true}>
                <p>{i18n.t("order_summary_shifts_warning_holiday_interval")}</p>
                <a href={i18n.t("shift_warning_holiday_FAQ_link")} target='_blank' rel='noreferrer'>
                    {i18n.t("order_summary_shifts_warning_link_text")}
                </a>
            </Tip>
        ) : null;

    // Check if a shift is set to an holiday
    const shiftIsHolidayCheck = sortedShifts?.some((shift) => {
        const isWithinHoliday = holidays.find(
            (holiday) =>
                isWithinInterval(new Date(shift.startDate), {
                    start: new Date(holiday.startDate),
                    end: new Date(holiday.endDate),
                }) && !holiday.solidarity,
        );
        return isWithinHoliday;
    });
    const shiftIsHolidayWarning = shiftIsHolidayCheck ? (
        <Tip intention='warning' highlight={true}>
            <p>{i18n.t("order_summary_shifts_warning_holiday")}</p>
            <a href={i18n.t("shift_warning_holiday_FAQ_link")} target='_blank' rel='noreferrer'>
                {i18n.t("order_summary_shifts_warning_link_text")}
            </a>
        </Tip>
    ) : null;

    const orderSections = [
        {
            label: i18n.t("order_summary_name_title"),
            content: task?.name ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_jobTitle_title"),
            content: task?.jobTitle ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_subtasks_title"),
            content: (
                <ul>
                    {task?.subtasks?.map((subtask, i) => {
                        return (
                            <li key={i} className='order-form__summary__li'>
                                {subtask.name}
                            </li>
                        );
                    })}
                </ul>
            ),
        },
        {
            label: i18n.t("job-descriptions_summary_salary_title"),
            content: (
                <div className='job-description__summary__salary'>
                    <p className='job-description__summary__salary__label'>
                        {i18n.t("job-descriptions_creation_salary_raw")}
                    </p>
                    <p className='job-description__summary__salary__value'>{task?.hourlyRate}€</p>
                </div>
            ),
        },
        {
            label: i18n.t("job-descriptions_summary_status_title"),
            content: (
                <div className='job-description__summary__status'>
                    <p className='job-description__summary__status__value'>
                        {task?.executiveStatus &&
                            i18n.t(`job-descriptions_status_${task?.executiveStatus}`)}
                    </p>
                </div>
            ),
        },
        {
            label: i18n.t("order_summary_address_title"),
            content: task?.location?.address ?? "-",
            action: () => navigate({ to: "/taskPosting/$taskId/details", params: { taskId } }),
        },
        {
            label: i18n.t("order_summary_manager_title"),
            content: (
                <div className='order-form__summary__manager'>
                    {task?.manager ? (
                        <div>
                            <span>{`${task?.manager?.firstName} ${task?.manager?.lastName}`}</span>
                        </div>
                    ) : (
                        <span>{i18n.t(`manager-form_choice_later`)}</span>
                    )}
                </div>
            ),
            action: () =>
                navigate({
                    to: "/taskPosting/$taskId/details",
                    params: { taskId },
                    hash: "manager",
                }),
        },
        {
            label: i18n.t("order_summary_shifts_title"),
            content: holidays ? <ShiftsTable shifts={sortedShifts} holidays={holidays} /> : "-",
            action: () =>
                navigate({
                    to: "/taskPosting/$taskId/shifts",
                    params: { taskId },
                }),
            layout: "block",
            contentBelow: [
                consecutiveWarning,
                shiftIsHolidayWarning,
                noShiftForHolidaysInPeriodWarning,
            ],
        },
        {
            label: i18n.t("order_summary_workers_title"),
            content: task?.requestedSiders ? <UsersList users={task?.requestedSiders} /> : "-",
            action: () =>
                navigate({
                    to: "/taskPosting/$taskId/workers",
                    params: { taskId },
                }),
            contentBelow: task?.requestedSidersOnly ? (
                <Tip intention='warning' highlight={true}>
                    {i18n.t("order_summary_workers_wa_warning")}
                </Tip>
            ) : null,
        },
        {
            label: i18n.t("order_summary_type_title"),
            content: (
                <div>
                    <div>{i18n.t(`order_summary_type_${task?.type}_title`)}</div>
                    <div className='order-form__summary__type-description'>
                        {i18n.t(`order_summary_type_${task?.type}_description`)}
                    </div>
                </div>
            ),
        },
        {
            label: i18n.t("order_summary_motive_title"),
            // from camelCase to underscore separated
            content: (() => {
                const motiveToDisplay = motive?.reason || task?.motive?.reason;
                return motiveToDisplay
                    ? i18n.t(
                          `order_motives_label_${motiveToDisplay
                              .split(/(?=[A-Z])/)
                              .join("_")
                              .toLowerCase()}`,
                      )
                    : "-";
            })(),

            action: () =>
                navigate({
                    to: "/taskPosting/$taskId/motive",
                    params: { taskId },
                }),
        },
        {
            label: i18n.t("order_summary_justification_title"),
            content: (() => {
                if (motive && motive.justification) {
                    return motive.justification;
                } else if (task?.motive?.justification) {
                    return task?.motive?.justification;
                }
                return "-";
            })(),
            action: () =>
                navigate({
                    to: "/taskPosting/$taskId/motive",
                    params: { taskId },
                }),
        },
        {
            label: i18n.t("job-descriptions_summary_context_title"),
            content: task?.purpose ?? "-",
        },
        {
            label: i18n.t("order_summary_info_title"),
            content: task?.missionInformation ?? "-",
            action: () =>
                navigate({
                    to: "/taskPosting/$taskId/details",
                    params: { taskId },
                    hash: "additional-informations",
                }),
        },
        {
            label: i18n.t("job-descriptions_summary_safetyEquipment_title"),
            content:
                task?.equipments
                    ?.filter((equipment) => equipment.group === "Safety")
                    ?.map((safetyEquipment) => {
                        return i18n.t(safetyEquipment.name, { defaultValue: safetyEquipment.name });
                    })
                    .join(", ") ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_devices_title"),
            content:
                task?.equipments
                    ?.filter((equipment) => equipment.group === "Other")
                    ?.map((device) => {
                        return i18n.t(device.name, { defaultValue: device.name });
                    })
                    .join(", ") ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_dresscode_title"),
            content: task?.dressCode ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_risks_title"),
            content:
                task?.risks?.length || task?.workConditions ? (
                    <div>
                        <ul>
                            {task?.risks?.map((risk, i) => (
                                <li key={i} className='order-form__summary__li'>
                                    {i18n.t(risk.name)}
                                </li>
                            ))}
                        </ul>
                        {task?.workConditions ? <p>{task?.workConditions}</p> : null}
                    </div>
                ) : (
                    "-"
                ),
        },
        {
            label: i18n.t("job-descriptions_summary_languages_title"),
            content: task?.languages?.map((language) => i18n.t(language.ISOCode)).join(", ") ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_tools_title"),
            content:
                task?.tools
                    ?.map((tool) => i18n.t(tool.name, { defaultValue: tool.name }))
                    .join(", ") ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_licences_title"),
            content: task?.licences?.map((licence) => i18n.t(licence.name)).join(", ") ?? "-",
        },
        {
            label: i18n.t("job-descriptions_summary_experiences_title"),
            content: task?.experiences ?? "-",
        },
    ];

    useEffect(() => {
        if (task?.shifts) {
            refetchQuotation();
        }
    }, [task?.shifts]);

    function openQuotationModal() {
        ModalsService.openModal({
            id: `PRICE_ESTIMATION`,
            content: (
                <ModalSimple
                    title='Estimation du prix'
                    hideModal={() => ModalsService.closeModal(`PRICE_ESTIMATION`)}
                    withCloseButton={true}
                >
                    <Quotation quotation={quotation} withTaxes={true} />
                </ModalSimple>
            ),
        });
    }

    async function confirmOrder() {
        try {
            const res = await backend.confirmTask({
                taskId,
                sideNote: sideNoteInput.value,
            });

            if (res.status === 200) {
                ModalsService.openModal({
                    id: `ORDER_SUCCESS_MODAL`,
                    content: (
                        <div className='order-form__summary__succes__modal'>
                            <ModalSimple withCloseButton={false} hideModal={() => null}>
                                <div className='order-form__summary__succes__modal__icon'>
                                    <Done />
                                </div>

                                <h2 className='order-form__summary__succes__modal__title'>
                                    {i18n.t("order_success_title")}
                                </h2>

                                <div className='order-form__summary__succes__modal__item'>
                                    <span className='order-form__summary__succes__modal__item__count'>
                                        1
                                    </span>
                                    {i18n.t("order_success_item_1")}
                                </div>
                                <div className='order-form__summary__succes__modal__item'>
                                    <span className='order-form__summary__succes__modal__item__count'>
                                        2
                                    </span>
                                    {i18n.t("order_success_item_2")}
                                </div>
                                <div className='order-form__summary__succes__modal__item'>
                                    <span className='order-form__summary__succes__modal__item__count'>
                                        3
                                    </span>
                                    {i18n.t("order_success_item_3")}
                                </div>

                                <div className='order-form__summary__succes__modal__button'>
                                    <Button
                                        onClick={() => {
                                            const planningURLParams = task?.shifts?.[0] && {
                                                week: getWeek(
                                                    new Date(task?.shifts[0]?.startDate),
                                                    {
                                                        weekStartsOn: 1,
                                                    },
                                                ),
                                                year: new Date(
                                                    new Date(task?.shifts[0]?.startDate),
                                                ).getFullYear(),
                                            };
                                            navigate({
                                                to: `/planning`,
                                                search: planningURLParams,
                                            }).then(() => {
                                                // TODO: remove this and invalidate planning cache after planning refacto
                                                window.location.reload();
                                            });
                                            ModalsService.closeModal(`ORDER_SUCCESS_MODAL`);
                                        }}
                                    >
                                        {i18n.t("order_succes_display_planning")}
                                    </Button>
                                </div>
                            </ModalSimple>
                        </div>
                    ),
                });
            } else {
                const error = await res.json();
                handleError(error);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const [shadow, setShadow] = useState(false);
    const recapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const isScrollable = function (ele) {
            if (!ele) return;
            const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
            const overflowYStyle = window.getComputedStyle(ele).overflowY;
            const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1;

            return hasScrollableContent && !isOverflowHidden;
        };
        const handleScroll = (e) => {
            // check if scrolled to the bottom
            if (e.target?.scrollTop + e.target?.clientHeight >= e.target?.scrollHeight - 16) {
                setShadow(false);
            } else {
                setShadow(true);
            }
        };
        if (isScrollable(recapRef.current)) {
            recapRef.current?.addEventListener("scroll", handleScroll);
            setShadow(true);
        }

        return () => {
            if (recapRef.current) {
                recapRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [recapRef.current]);

    const quotationTotal = quotation?.price;

    return (
        <div className='order-form__summary'>
            <div className='order-form__summary__order__wrapper'>
                <div className='order-form__summary__order'>
                    <SectionHeader title={i18n.t("order_summary_title")} icon='Trophy' />
                    <div className='order-form__summary__order__items'>
                        {orderSections.map(
                            (section) =>
                                section?.content &&
                                section?.label && (
                                    <SummaryTableRow
                                        key={section.label}
                                        label={section.label}
                                        content={section.content}
                                        action={section?.action ? section.action : null}
                                        actionIcon='Pen'
                                        layout={section.layout}
                                        contentBelow={section.contentBelow}
                                    />
                                ),
                        )}
                    </div>
                </div>
            </div>

            <div ref={recapRef} className='order-form__summary__confirmation'>
                <RecapConfirmation
                    title={i18n.t("order_summary_confirmation_title")}
                    confirmationButtonLabel={i18n.t("order_summary_confirmation_button")}
                    confirmationButtonAction={confirmOrder}
                    recapNote={i18n.t("order_summary_confirmation_cancellation_fees")}
                    checkboxLabel={i18n.t("order_summary_confirmation_checkbox")}
                    sticky={true}
                    stickyNegativeMargin={40}
                    shadow={shadow}
                >
                    <div className='order-form__summary__price-estimate'>
                        <div className='order-form__summary__price-estimate__label'>
                            {i18n.t("order_summary_estimate_total_without_taxes")}
                        </div>
                        <div className='order-form__summary__price-estimate__amount'>
                            {quotationTotal ? parseFloat(quotationTotal).toFixed(2) : 0}€
                        </div>
                    </div>
                    <div className='order-form__summary__price-estimate-tax'>
                        <div className='order-form__summary__price-estimate-tax__label'>
                            {i18n.t("order_summary_estimate_total_with_taxes")}
                        </div>
                        <div className='order-form__summary__price-estimate-tax__amount'>
                            {(parseFloat(quotationTotal) * 1.2)?.toFixed(2)}€
                        </div>
                    </div>
                    <button
                        className='order-form__summary__confirmation__price__button'
                        onClick={() => openQuotationModal()}
                        type='button'
                    >
                        {i18n.t(`order_summary_estimate_details`)}
                    </button>
                    <TextArea
                        id='side-note'
                        label={i18n.t(`order_summary_confirmation_sidenote`)}
                        placeholder={i18n.t(`order_summary_confirmation_sidenote_placeholder`)}
                        value={sideNoteInput.value}
                        onChange={(e) => setSideNoteInput(e.target.value)}
                    />
                </RecapConfirmation>
            </div>
        </div>
    );
}
