import { RefObject, useEffect } from "react";

const useOnClickOutside = (
    ref: RefObject<HTMLElement>,
    handler: (e: MouseEvent | TouchEvent) => void,
) => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref?.current || (event.target && ref.current.contains(event.target as Node))) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
