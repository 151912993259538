import { cloneDeep } from "lodash";

import { GetJobDescriptionsResponse } from "@lib/api";

export const EQUIPMENT_GROUP = {
    SAFETY: "Safety",
    OTHER: "Other",
};

const filteredEquipments = {
    [EQUIPMENT_GROUP.SAFETY]: [],
    [EQUIPMENT_GROUP.OTHER]: [],
};

const reduceEquipments = (equipments) =>
    equipments?.reduce((acc, equipment) => {
        if (equipment?.group === EQUIPMENT_GROUP.SAFETY) {
            if (!acc[EQUIPMENT_GROUP.SAFETY].find(({ id }) => id === equipment.id)) {
                acc[EQUIPMENT_GROUP.SAFETY].push({
                    id: equipment.id,
                    name: equipment.name,
                    group: equipment.group,
                    custom: equipment.custom,
                    providedBy: equipment.providedBy,
                });
            }
        }

        if (equipment?.group === EQUIPMENT_GROUP.OTHER) {
            if (!acc[EQUIPMENT_GROUP.OTHER].find(({ id }) => id === equipment.id)) {
                acc[EQUIPMENT_GROUP.OTHER].push({
                    id: equipment.id,
                    name: equipment.name,
                    group: equipment.group,
                    custom: equipment.custom,
                    providedBy: equipment.providedBy,
                });
            }
        }

        return acc;
    }, cloneDeep(filteredEquipments));

export const normalizeJobDescription = (jobDescription: GetJobDescriptionsResponse[number]) => {
    // destructuring with fallback
    const { Safety: safety, Other: other } =
        reduceEquipments(jobDescription.equipment) || cloneDeep(filteredEquipments);

    return {
        id: jobDescription.id,
        organisationId: jobDescription.organisationId,
        updatedAt: new Date(jobDescription.updatedAt),
        createdAt: new Date(jobDescription.createdAt),
        suffix: jobDescription.suffix,
        enabled: jobDescription.enabled,
        subtasks: jobDescription.subtasks || [],
        job: {
            jobTitle: jobDescription.jobTitle,
            subtasks: jobDescription.subtasks || [],
        },
        workConditions: {
            context: jobDescription.purpose,
            safetyEquipmentList: safety,
            devices: other,
            risks: jobDescription.risks,
            aboutRisks: jobDescription.workConditions,
            dressCode: jobDescription.dressCode,
        },
        skills: {
            languages: jobDescription.languages,
            tools: jobDescription.tools,
            licences: jobDescription.licences,
            experiences: jobDescription.experiences,
        },
        location: jobDescription.location,
        hourlyRate: jobDescription.hourlyRate,
        managerId: jobDescription.managerId,
        pricing: jobDescription.pricing,
        pricingOrigin: jobDescription.pricingOrigin,
        origin: jobDescription.origin,
        executiveStatus: jobDescription.executiveStatus,
        preSelection: jobDescription.preSelection,
    };
};

export const normalizeTools = (t) => {
    if (!t?.length) {
        return [];
    }

    return t.reduce((result, { id, name, custom, level }) => {
        // coining base object with "generic" properties
        result[name] = {
            // eslint-disable-line no-param-reassign
            ...result[name],
            name,
            custom,
            id,
        };
        // if level exists then populate the levels array
        // with the OptionLevel type object
        if (level) {
            if (name in result) {
                // reduce allows params re-assign
                result[name] = {
                    // eslint-disable-line no-param-reassign
                    ...result[name],

                    levels: result[name]?.levels?.length
                        ? result[name]?.levels?.concat({
                              id,
                              label: level,
                              value: level,
                          })
                        : ([] as any[]).concat({
                              id,
                              label: level,
                              value: level,
                          }),
                };
                return result;
            }
            // reduce allows params re-assign
            result[name] = {
                // eslint-disable-line no-param-reassign
                ...result[name],
                levels: ([] as any[]).concat({
                    id,
                    label: level,
                    value: level,
                }),
            };

            return result;
        }
        // there's no level property which means
        // there's no need for levels to populate the select
        result[name] = {
            // eslint-disable-line no-param-reassign
            ...result[name],
        };

        return result;
    }, {});
};

export const normalizeJobDescriptions = (data: GetJobDescriptionsResponse) =>
    data.map(normalizeJobDescription);

const languageLevels = {
    BEGINNER: "beginner",
    INTERMEDIATE: "intermediate",
    FLUENT: "fluent",
    NATIVE: "native",
};

// Used to sort language levels
const levelSortMap = {
    [languageLevels.BEGINNER]: 0,
    [languageLevels.INTERMEDIATE]: 1,
    [languageLevels.FLUENT]: 2,
    [languageLevels.NATIVE]: 3,
};

/*
  Takes an array of languages and create a dictionary
  of unique language and adds all levels to a levels array:

  {
    en: {
      id: 1,
      name: 'fr',
      levels: [{
        id: ekaljkfejaf,
        label: 'Débutant',
        value: 'beginner',
      }], // etc.
    }
  }
*/
export const sortLanguagesByLevel = (languages) =>
    languages?.sort((prev, next) => {
        if (prev.levelGrade < next.levelGrade) return -1;
        if (prev.levelGrade > next.levelGrade) return 1;

        return 0;
    });

export const normalizeLanguages = (l) => {
    // reduce allows params re-assign
    return (l?.reduce((result, { id, ISOCode, level }, index) => {
        if (ISOCode in result) {
            result[ISOCode] = {
                // eslint-disable-line no-param-reassign
                id: `${ISOCode}-${index}`,
                label: ISOCode,
                // concat to levels and add it to an array

                levels: result[ISOCode]?.levels?.length
                    ? result[ISOCode]?.levels?.concat({
                          id,
                          label: level,
                          value: level, // redundant but needed in Select
                          name: ISOCode,
                          levelGrade: levelSortMap[level],
                      })
                    : ([] as any[]).concat({
                          id,
                          label: level,
                          value: level, // redundant but needed in Select
                          name: ISOCode,
                          levelGrade: levelSortMap[level],
                      }),
            };

            return result;
        }
        result[ISOCode] = {
            // eslint-disable-line no-param-reassign
            id: `${ISOCode}-${index}`,
            label: ISOCode,
            levels: ([] as any[]).concat({
                id,
                name: ISOCode,
                label: level,
                value: level, // redundant but needed in Select since label will i18n-ized
                levelGrade: levelSortMap[level],
            }),
        };

        return result;
    }, {}) ?? {}) as Record<
        string,
        {
            id: string;
            label: string;
            levels: {
                id: string;
                name: string;
                label: string;
                value: string;
                levelGrade: string;
            }[];
        }
    >;
};

export const normalizeData = (data) => {
    if (!data?.length) {
        return [];
    }

    return data.reduce((acc, val) => {
        if (!acc[val.name]) {
            acc[val.name] = [val];
            return acc;
        }
        acc[val.name].push(val);
        return acc;
    }, {});
};
// normalize for Select component
export const normalizeManagersOptions = (companyManagers) => {
    if (!companyManagers.length) {
        return [];
    }

    return companyManagers.map(({ _id, firstName, lastName }) => ({
        id: _id,
        label: `${firstName} ${lastName}`,
        value: _id,
    }));
};
