import { createRoot } from "react-dom/client";

import initTranslations from "./config/i18n";
import createStore from "./store/createStore";
import App from "./AppContainer";

import "./styles/core.scss";

initTranslations();

export const store = createStore();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App store={store} />);
