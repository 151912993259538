import { connect } from "react-redux";

import { updateHourType } from "../../../../store/company";

import HolidaysHoursForm from "./HolidaysHours";

const mapDispatchToProps = (dispatch) => ({
    updateHourType: (data) => dispatch(updateHourType(data)),
});

export default connect(null, mapDispatchToProps)(HolidaysHoursForm);
