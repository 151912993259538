import fetch from "isomorphic-fetch";

import { Indemnities } from "../../store/types/company";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export function convertIndemnitiesToAPI(data: Indemnities) {
    return {
        lunch: data.lunch.map((indemnity) => ({
            type: indemnity.type,
            amount: indemnity.amount,
            coverRate: indemnity.coverRate,
            otherParameters: indemnity.otherParameters && {
                hoursPerDay: indemnity.otherParameters.hoursPerDay && {
                    threshold: indemnity.otherParameters.hoursPerDay.threshold,
                },
                seniority: indemnity.otherParameters.seniority && {
                    daysThreshold: indemnity.otherParameters.seniority.daysThreshold,
                },
                taskType: indemnity.otherParameters.taskType && {
                    jobTypes: indemnity.otherParameters.taskType.jobTypes,
                },
                zti: indemnity.otherParameters.zti && {
                    isApplicable: indemnity.otherParameters.zti.isApplicable,
                },
            },
        })),
        transportation: data.transportation.map((indemnity) => ({
            publicCoverRate: indemnity.publicCoverRate,
            otherParameters: indemnity.otherParameters && {
                hoursPerDay: indemnity.otherParameters.hoursPerDay && {
                    threshold: indemnity.otherParameters.hoursPerDay.threshold,
                },
                seniority: indemnity.otherParameters.seniority && {
                    daysThreshold: indemnity.otherParameters.seniority.daysThreshold,
                },
                taskType: indemnity.otherParameters.taskType && {
                    jobTypes: indemnity.otherParameters.taskType.jobTypes,
                },
                zti: indemnity.otherParameters.zti && {
                    isApplicable: indemnity.otherParameters.zti.isApplicable,
                },
            },
        })),
        other: data.other.map((indemnity) => ({
            category: indemnity.category,
            name: indemnity.name,
            amount: indemnity.amount,
            frequency: indemnity.frequency,
            otherParameters: indemnity.otherParameters && {
                hoursPerDay: indemnity.otherParameters.hoursPerDay && {
                    threshold: indemnity.otherParameters.hoursPerDay.threshold,
                },
                seniority: indemnity.otherParameters.seniority && {
                    daysThreshold: indemnity.otherParameters.seniority.daysThreshold,
                },
                taskType: indemnity.otherParameters.taskType && {
                    jobTypes: indemnity.otherParameters.taskType.jobTypes,
                },
                zti: indemnity.otherParameters.zti && {
                    isApplicable: indemnity.otherParameters.zti.isApplicable,
                },
            },
        })),
    };
}

export function updateCompanyIndemnities(params: { organisationId: string; data: Indemnities }) {
    const indemnities = convertIndemnitiesToAPI(params.data);

    return fetch(
        withAuthHeaders(`${apiConf.BACK_URL}/organisations/${params.organisationId}/indemnities`, {
            method: `PATCH`,
            body: JSON.stringify(indemnities),
        }),
    );
}
