import { createSelector } from "reselect";

const getPlanning = (state) => state.planning;

export const getSelectedWeek = createSelector(
    getPlanning,

    (planning) => /we_(?<year>\d{4})_(?<week>\d{1,2})/gm.exec(planning?.selectedWeek)?.groups,
);

export const getSelectedWeekPlanning = createSelector(
    getPlanning,
    (planning) => (planning?.selectedWeek && planning?.weeks[planning.selectedWeek]) || {},
);
