import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GetCompanyRemunerationInfoParams = { organisationId: string };
export type GetCompanyRemunerationInfoResponse = {
    hoursTypesConfirmation: HoursTypesConfirmation;
    indemnitiesConfirmation: HoursTypesConfirmation;
    hoursTypes: HoursTypes;
    indemnities: Indemnities;
};

type Indemnities = {
    lunch: Lunch[];
    transportation: Transportation[];
    other: any[];
    valid: boolean;
};

type Transportation = {
    _id: string;
    publicCoverRate: number;
    otherParameters: OtherParameters;
};

type Lunch = {
    _id: string;
    type: string;
    amount: number;
    otherParameters: OtherParameters;
};

type OtherParameters = object;

type HoursTypes = {
    extra: Extra;
    night: Night;
    sundays: Sundays;
    bankHolidays: BankHolidays;
    valid: boolean;
};

type BankHolidays = {
    increase: number;
    solidarity: string;
};

type Sundays = {
    increase: number;
};

type Night = {
    startHour: number;
    endHour: number;
    increase: number;
};

type Extra = {
    thresholds: Threshold[];
};

type Threshold = {
    threshold: number;
    increase: number;
};

type HoursTypesConfirmation = {
    date: string;
    userId: string;
};
export function getCompanyRemunerationInfo(params: GetCompanyRemunerationInfoParams) {
    debug.addBreadcrumb({
        message: `Get remuneration informations`,
        category: `action`,
        data: {
            organisationId: params.organisationId,
        },
    });

    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/remuneration-policy`,
        ),
    );
}
