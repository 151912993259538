export const normalizeHoursTypes = (hoursTypes) => ({
    extra: {
        thresholds:
            hoursTypes && hoursTypes.extra
                ? hoursTypes.extra.thresholds.map((threshold) => ({
                      threshold: threshold.threshold,
                      increase: threshold.increase,
                  }))
                : [],
    },
    night: (hoursTypes || {}).night
        ? {
              startHour: hoursTypes.night.startHour,
              endHour: hoursTypes.night.endHour,
              increase: hoursTypes.night.increase,
              seniority: hoursTypes.night.seniority && {
                  daysThreshold: hoursTypes.night.seniority.daysThreshold,
                  increase: hoursTypes.night.seniority.increase,
              },
          }
        : {},
    sundays: (hoursTypes || {}).sundays
        ? {
              increase: hoursTypes.sundays.increase,
              seniority: hoursTypes.sundays.seniority && {
                  daysThreshold: hoursTypes.sundays.seniority.daysThreshold,
                  increase: hoursTypes.sundays.seniority.increase,
              },
              ztiIncrease: hoursTypes.sundays.ztiIncrease,
          }
        : {},
    bankHolidays: (hoursTypes || {}).bankHolidays
        ? {
              increase: hoursTypes.bankHolidays.increase,
              seniority: hoursTypes.bankHolidays.seniority && {
                  daysThreshold: hoursTypes.bankHolidays.seniority.daysThreshold,
                  increase: hoursTypes.bankHolidays.seniority.increase,
              },
              solidarity: hoursTypes.bankHolidays.solidarity,
          }
        : {},
    valid: (hoursTypes || {}).valid,
});

export const normalizeIndemnities = (indemnities) => ({
    lunch: (indemnities || {}).lunch
        ? indemnities.lunch.map((indemnity) => ({
              id: indemnity._id,
              type: indemnity.type,
              amount: indemnity.amount,
              coverRate: indemnity.coverRate,
              otherParameters: indemnity.otherParameters && {
                  hoursPerDay: indemnity.otherParameters.hoursPerDay && {
                      threshold: indemnity.otherParameters.hoursPerDay.threshold,
                  },
                  seniority: indemnity.otherParameters.seniority && {
                      daysThreshold: indemnity.otherParameters.seniority.daysThreshold,
                  },
                  taskType: indemnity.otherParameters.taskType && {
                      jobTypes: indemnity.otherParameters.taskType.jobTypes,
                  },
                  zti: indemnity.otherParameters.zti && {
                      isApplicable: indemnity.otherParameters.zti.isApplicable,
                  },
              },
          }))
        : [],
    transportation: indemnities?.transportation
        ? indemnities.transportation.map((indemnity) => ({
              id: indemnity._id,
              publicCoverRate: indemnity.publicCoverRate,
              otherParameters: indemnity.otherParameters && {
                  hoursPerDay: indemnity.otherParameters.hoursPerDay && {
                      threshold: indemnity.otherParameters.hoursPerDay.threshold,
                  },
                  seniority: indemnity.otherParameters.seniority && {
                      daysThreshold: indemnity.otherParameters.seniority.daysThreshold,
                  },
                  taskType: indemnity.otherParameters.taskType && {
                      jobTypes: indemnity.otherParameters.taskType.jobTypes,
                  },
                  zti: indemnity.otherParameters.zti && {
                      isApplicable: indemnity.otherParameters.zti.isApplicable,
                  },
              },
          }))
        : [],
    other: (indemnities || {}).other
        ? indemnities.other.map((indemnity) => ({
              id: indemnity._id,
              category: indemnity.category,
              name: indemnity.name,
              amount: indemnity.amount,
              frequency: indemnity.frequency,
              otherParameters: indemnity.otherParameters && {
                  hoursPerDay: indemnity.otherParameters.hoursPerDay && {
                      threshold: indemnity.otherParameters.hoursPerDay.threshold,
                  },
                  seniority: indemnity.otherParameters.seniority && {
                      daysThreshold: indemnity.otherParameters.seniority.daysThreshold,
                  },
                  taskType: indemnity.otherParameters.taskType && {
                      jobTypes: indemnity.otherParameters.taskType.jobTypes,
                  },
                  zti: indemnity.otherParameters.zti && {
                      isApplicable: indemnity.otherParameters.zti.isApplicable,
                  },
              },
          }))
        : [],
    valid: (indemnities || {}).valid,
});

const normalizeRemunerationInfo = (data) => {
    const remunerationInfo = {
        hoursTypesConfirmation: data.hoursTypesConfirmation && {
            date: data.hoursTypesConfirmation.date,
            userId: data.hoursTypesConfirmation.userId,
        },
        indemnitiesConfirmation: data.indemnitiesConfirmation && {
            date: data.indemnitiesConfirmation.date,
            userId: data.indemnitiesConfirmation.userId,
        },
        hoursTypes: normalizeHoursTypes(data.hoursTypes),
        // we're emptying indemnities upon invalid ones so that companies
        // input them again to be sure of what they did.
        indemnities: normalizeIndemnities(data.indemnities),
    };

    return remunerationInfo;
};

export default normalizeRemunerationInfo;
