import { createSelector } from "reselect";

import type { State } from "../types";

export const getSidersState = (state: State) => state.siders;

export const getSiders = createSelector(getSidersState, (siders) => siders.sidersList);
export const getSelectedSider = createSelector(getSidersState, (siders) => siders.selectedSider);

export const getSiderIsLoading = createSelector(getSidersState, (siders) => siders.isLoading);
