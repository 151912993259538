import "./BlankTab.scss";

const BlankTab = (props) => {
    const { title, subtitle } = props;

    return (
        <div className='blank-tab'>
            <div className='blank-tab__inner'>
                {title && <h1 className='heading-1'>{title}</h1>}
                {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
            </div>
        </div>
    );
};

export default BlankTab;
