import i18n from "i18n-js";
import { ModalSimple, ModalsService } from "side-ui";

const DeleteModal = (props) => (
    <ModalSimple
        action={props.deleteTask}
        validateLabel={i18n.t(`delete`)}
        cancelLabel={i18n.t(`cancel`)}
        title={i18n.t(`modal_delete_title`)}
        hideModal={() => ModalsService.closeModal(`DELETE_TASK`)}
    >
        <p>{i18n.t(`modal_delete_description`)}</p>
    </ModalSimple>
);

export default DeleteModal;
