import { Suspense } from "react";
import clsx from "clsx";
import I18n from "i18n-js";
import { fonts, Tip } from "sui";

import { queries } from "@lib/queries";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";

import { PreselectionTable } from "./PreselectionTable";

import styles from "./Preselection.module.css";

export function Preselection() {
    const route = getRouteApi("/globalLayout/preselection/$taskId");
    const { taskId } = route.useParams();
    const { data: task } = useSuspenseQuery(queries.task.detail(taskId));
    const { data: preSelection } = useQuery(queries.taskPreSelection.list(taskId));

    const isLogas = Boolean(localStorage.getItem(`side_team_logas_email`));
    const noneVisible = preSelection?.every((sider) => !sider.visible);

    return (
        <div className={styles.container}>
            <h1 className={clsx(fonts.sans32Medium, styles.h1)}>{I18n.t("preselection_h1")}</h1>
            <div className={styles.spacer24} />
            {isLogas && noneVisible ? (
                <>
                    <Tip highlight intention='warning'>
                        {I18n.t("preselect_tip_no_visible")}
                    </Tip>
                    <div className={styles.spacer24} />
                </>
            ) : null}
            <p className={clsx(fonts.sans20Medium, styles.taskName)}>{task.name}</p>
            <div className={styles.spacer16} />
            <Suspense fallback='loading...'>
                <PreselectionTable />
            </Suspense>
        </div>
    );
}
