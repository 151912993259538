import type { Action, State } from "./types/display";
import type { Dispatch } from "./types";

//------------------------------------
// Constants
//------------------------------------

export const UPDATE_LOCALE = `UPDATE_LOCALE` as const;
export const SET_ERROR = `SET_ERROR` as const;
export const HIDE_ERROR = `HIDE_ERROR` as const;
export const ADD_FILTER = `ADD_FILTER` as const;
export const SET_SHOW_SIDER_PROFILE_PANEL = `SET_SHOW_SIDER_PROFILE_PANEL` as const;
export const UPDATE_NAVIGATION_MODE = `UPDATE_NAVIGATION_MODE` as const;

//------------------------------------
// Actions
//------------------------------------
export const setError = (error: string, title?: string) => ({
    type: SET_ERROR,
    title,
    error,
});
export const hideError = () => ({
    type: HIDE_ERROR,
});
export const updateLocale = (locale: "fr" | "en") => ({
    type: UPDATE_LOCALE,
    locale,
});
export const addFilter = (key: string, value: object) => ({
    type: ADD_FILTER,
    key,
    value,
});

export const updateFilter = (key: string, value: object) => (dispatch: Dispatch) => {
    dispatch(addFilter(key, value));
};

export const setShowSiderProfilePanel = (
    showSiderProfilePanel: boolean,
    taskCategory?: string,
) => ({
    type: SET_SHOW_SIDER_PROFILE_PANEL,
    showSiderProfilePanel,
    taskCategory,
});

export const updateNavigationMode = (navigationMode: string) => ({
    type: UPDATE_NAVIGATION_MODE,
    navigationMode,
});

//------------------------------------
// Reducer
//------------------------------------

const initialState: State = {
    locale: "fr",
    error: ``,
    errorTitle: ``,
    errorDisplay: false,
    filters: {},
    showSiderProfilePanel: false,
    siderProfilePanelTaskCategory: ``,
    navigationMode: "expanded",
};

const displayReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                errorTitle: action.title ?? "",
                error: action.error,
                errorDisplay: true,
            };
        case HIDE_ERROR:
            return { ...state, errorDisplay: false };
        case UPDATE_LOCALE:
            return { ...state, locale: action.locale };
        case ADD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value,
                },
            };
        case SET_SHOW_SIDER_PROFILE_PANEL:
            return {
                ...state,
                showSiderProfilePanel: action.showSiderProfilePanel,
                siderProfilePanelTaskCategory: action.taskCategory ?? "",
            };
        case UPDATE_NAVIGATION_MODE: {
            const { navigationMode } = action;

            return {
                ...state,
                navigationMode,
            };
        }
        default:
            return state;
    }
};

export default displayReducer;
