const translation = {
  "EQ_AUDITIVE_PROTECTION": "Protections auditives",
  "EQ_BIKE": "Vélo",
  "EQ_FACIAL_SCREEN": "Écran facial",
  "EQ_GLOVES": "Gants de travail",
  "EQ_HARD_HAT": "Casque de protection",
  "EQ_HEADPHONES_AND_MIC": "Casque avec micro intégré",
  "EQ_LAPTOP": "Ordinateur portable",
  "EQ_PROTECTIVE_CLOTHING": "Vêtements protecteurs",
  "EQ_PROTECTIVE_GLASSES": "Lunettes de protection",
  "EQ_RESPIRATORY_PROTECTION": "Protection respiratoire (masque)",
  "EQ_SAFETY_SHOES": "Chaussures de sécurité",
  "EQ_SMARTPHONE": "Smartphone",
  "EQ_YELLOW_JACKET": "Gilet jaune",
  "LI_CACES_1": "CACES 1",
  "LI_CACES_3": "CACES 3",
  "LI_CACES_5": "CACES 5",
  "LI_DRIVING_LICENSE_B": "Permis B",
  "RIO_LOAD_HEAVY": "Charges lourdes (+15kg)",
  "RIO_LOAD_LIGHT": "Charges légères (moins de 5kg)",
  "RIO_LOAD_MEDIUM": "Charges intermédiaires (5 à 15kg)",
  "RIO_TEMP_CHILL": "Frais (3 à 5°C)",
  "RIO_TEMP_COLD": "Froid (0 à 3°C)",
  "RIO_TEMP_HOT": "Chaud (\u003e=30°C)",
  "RIO_TEMP_NEGATIVE_COLD": "Froid négatif (\u003c0°C)",
  "RI_ALTERNATING_TEAMS": "Travail en équipes successives alternantes",
  "RI_CHEMICALS_DUST_FUMES": "Agents chimiques dangereux, y compris poussières et fumées",
  "RI_EXTREME_TEMPERATURES": "Températures extrêmes",
  "RI_HARD_POSTURES": "Postures pénibles",
  "RI_HYPERBARIC_ENV": "Activités exercées en milieu hyperbare",
  "RI_MANUAL_HANDLING": "Manutentions manuelles de charges",
  "RI_NIGHT_WORK": "Travail de nuit",
  "RI_NOISE": "Bruit",
  "RI_REPETITIVE_WORK": "Travail répétitif",
  "RI_VIBRATIONS": "Vibrations mécaniques",
  "TO_EXCEL": "Excel",
  "TO_GOOGLE_DRIVE": "Google Drive",
  "TO_INTERCOM": "Intercom",
  "TO_NOTION": "Notion",
  "TO_POWER_POINT": "Powerpoint",
  "TO_SLACK": "Slack",
  "TO_ZENDESK": "Zendesk",
  "action_cancel": "Annuler",
  "action_confirm": "Confirmer",
  "action_unauthorized": "Non autorisé",
  "action_unauthorized_extended": "Vous n’êtes pas autorisé à effectuer cette action.",
  "action_unauthorized_extended_logas": "Vous ne pouvez pas effectuer cette action en log as.",
  "activate_account_form_title": "Finalisation d'inscription",
  "activate_account_link_broken": "Votre lien d'activation de compte semble caduc.\\n\\n\n\nDemandez à un membre de votre équipe de vous renvoyer un lien d'invitation.",
  "activate_account_log_in": "Activer mon compte",
  "activate_account_logo_company": "Entreprise",
  "activate_account_or": "ou",
  "activate_account_password_placeholder": "Mot de passe",
  "activate_account_title": "Connectez-vous à Side",
  "activate_account_with_google": "Activer mon compte avec Google",
  "add": "Ajouter",
  "advanced": "Avancé",
  "alb": "Albanais",
  "ara": "Arabe",
  "arm": "Arménien",
  "at": "à",
  "aym": "Aymara",
  "aze": "Azéri",
  "back": "Retour",
  "beginner": "Débutant",
  "bel": "Biélorusse",
  "ben": "Bengali",
  "bul": "Bulgare",
  "bur": "Birman",
  "cancel": "Annuler",
  "cat": "Catalan",
  "chi": "Chinois (mandarin)",
  "cnr": "Monténégrin",
  "completed_section_row_status_cell_subtitle_contact_plural": "Retrouver les %{siderNumber} Siders",
  "completed_section_row_status_cell_subtitle_contact_singular": "Retrouver le Sider",
  "completed_section_row_status_cell_title_selection_done": "Mission terminée",
  "completed_section_row_task_cell_managed_by": "Gérée par ",
  "completed_section_title": "Missions terminées",
  "confirm": "Confirmer",
  "continue": "Continuer",
  "contract_contestation_confirmation_toast": "",
  "contract_signature_confirmation_block_agreement": "En signant ce document vous acceptez nos \u003ca href='https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/cgu-fr.pdf' target='_blank'\u003econditions d'utilisation\u003c/a\u003e.",
  "contract_signature_confirmation_block_checkbox": "J’ai lu et vérifié les informations de l'ensemble des contrats présents dans ce document",
  "contract_signature_confirmation_block_error": "Un problème est survenu lors de la signature du document !",
  "contract_signature_confirmation_block_notice": "Une fois le document signé, vous ne pouvez pas le contester.",
  "contract_signature_confirmation_block_number": "Nombre de contrats",
  "contract_signature_confirmation_block_title": "Signature",
  "contract_signature_confirmation_toast": "Le document a bien été signé. Retrouvez vos contracts dans la section \\\"Signés\\\".",
  "contract_signature_document_error_button_label": "Revenir à la liste de contrats",
  "contract_signature_document_error_subtitle": "L’affichage des contrats n’est pas disponible pour le moment.\nSi le problème persiste veuillez contacter le support.",
  "contract_signature_document_error_title": "Une erreur est survenue !",
  "contract_signature_pagination_last_page": "Rendez-vous sur la dernière page pour pouvoir signer vos contrats",
  "contract_signature_phone_number_required": "Vous devez d'abord renseigner votre numéro de téléphone dans les paramètres afin de pouvoir signer vos contrats.",
  "contract_signature_regenerate_document_label": "Re-générer le document",
  "contract_signature_regeneration_success": "Le document est en cours de re-génération. Cela peut prendre quelques secondes.",
  "contract_signature_report_button_label": "Une erreur avec un contrat ?",
  "contract_signature_see_contestation_button_label": "Voir le motif de contestation",
  "contract_signature_sign_button_label": "Signer",
  "contracts_batch_row_mobile_date": "Date de début : ",
  "contracts_batch_row_mobile_manager": "Géré par",
  "contracts_batch_row_mobile_number": "contrat(s)",
  "contracts_batch_row_status_contested": "contesté",
  "contracts_batch_row_status_contested_button_label": "Voir",
  "contracts_batch_row_status_disputed_tooltip_content": "Vous avez contesté ce document. Une nouvelle version corrigée est en chemin. ",
  "contracts_batch_row_status_disputed_tooltip_title": "Depuis le %{contestationDate}",
  "contracts_batch_row_status_signed": "signé",
  "contracts_batch_row_status_signed_button_label": "Télécharger",
  "contracts_batch_row_status_signed_tooltip_content": "Signé par %{signatureAuthorName} le %{signatureDate} à %{signatureTime}.",
  "contracts_batch_row_status_tosign": "à signer",
  "contracts_batch_row_status_tosign_button_label": "Vérifier et signer",
  "contracts_batch_row_status_tosign_late_tooltip_content": "Les contrats doivent être signés 48h avant le début de la mission",
  "contracts_batch_row_status_tosign_late_tooltip_title": "%{numberOfDays} jours de retard",
  "contracts_batches_list_header_date": "Date de début",
  "contracts_batches_list_header_manager": "Gestionnaire",
  "contracts_batches_list_header_number": "Nombre de contrats",
  "contracts_batches_list_header_status": "Statut",
  "contracts_batches_list_header_task": "Mission",
  "contracts_batches_signed_blank_state_content": "Vous n'avez pas encore de contrat signé pour le moment.",
  "contracts_batches_signed_blank_state_title": "Aucun contrat signé",
  "contracts_batches_tosign_blank_state_content": "Vous n'avez aucun contrat à signer pour le moment.",
  "contracts_batches_tosign_blank_state_title": "C'est tout bon !",
  "contracts_contestation_modal_cancel": "Annuler",
  "contracts_contestation_modal_details": "Contesté le %{contestationDate} par %{contestationAuthorName}.",
  "contracts_contestation_modal_input_label": "Motif de contestation",
  "contracts_contestation_modal_input_placeholder": "Indiquez les éléments à corriger dans le document",
  "contracts_contestation_modal_send": "Envoyer",
  "contracts_contestation_modal_subtitle_contested": "Les contrats de cette mission font l’objet d’une contestation. Une nouvelle version corrigée est en chemin. ",
  "contracts_contestation_modal_subtitle_tocontest": "Précisez à notre équipe le motif de la contestation. Une fois votre demande traitée, vous pourrez consulter et signer la version modifiée du document. ",
  "contracts_contestation_modal_title": "Contester les informations du contrat",
  "contracts_top_menu_filter_signed": "Signés",
  "contracts_top_menu_filter_to_sign": "À signer",
  "countries_belgium": "Belgique",
  "countries_denmark": "Danemark",
  "countries_estonia": "Estonie",
  "countries_france": "France",
  "countries_germany": "Allemagne",
  "countries_great_britain": "Royaume Uni",
  "countries_italy": "Italie",
  "countries_netherlands": "Pays-bas",
  "countries_portugal": "Portugal",
  "countries_spain": "Espagne",
  "country_albania": "Albanie",
  "country_algeria": "Algérie",
  "country_andorra": "Andorre",
  "country_angola": "Angola",
  "country_antigua_and_barbuda": "Antigua-et-Barbuda",
  "country_argentina": "Argentine",
  "country_armenia": "Arménie",
  "country_australia": "Australie",
  "country_austria": "Autriche",
  "country_azerbaijan": "Azerbaïdjan",
  "country_bahamas": "Bahamas",
  "country_bahrain": "Bahreïn",
  "country_bangladesh": "Bangladesh",
  "country_barbados": "Barbade",
  "country_belarus": "Biélorussie",
  "country_belgium": "Belgique",
  "country_belize": "Belize",
  "country_benin": "Bénin",
  "country_bhutan": "Bhoutan",
  "country_bolivia": "Bolivie",
  "country_bosnia_and_herzegovina": "Bosnie-Herzégovine",
  "country_botswana": "Botswana",
  "country_brazil": "Brésil",
  "country_brunei": "Brunéi",
  "country_bulgaria": "Bulgarie",
  "country_burkina_faso": "Burkina Faso",
  "country_burundi": "Burundi",
  "country_cambodia": "Cambodge",
  "country_cameroon": "Cameroun",
  "country_canada": "Canada",
  "country_cape_verde": "Cap-Vert",
  "country_chad": "Tchad",
  "country_chile": "Chili",
  "country_china": "Chine",
  "country_colombia": "Colombie",
  "country_comoros": "Comores",
  "country_congo_brazzaville": "Congo-Brazzaville",
  "country_congo_kinshasa": "Congo-Kinshasa",
  "country_cook_islands": "Îles Cook",
  "country_costa_rica": "Costa Rica",
  "country_croatia": "Croatie",
  "country_cuba": "Cuba",
  "country_cyprus": "Chypre",
  "country_czech_republic": "Tchéquie",
  "country_denmark": "Danemark",
  "country_djibouti": "Djibouti",
  "country_dominica": "Dominique",
  "country_ecuador": "Équateur",
  "country_egypt": "Égypte",
  "country_el_salvador": "Salvador",
  "country_equatorial_guinea": "Guinée Équatoriale",
  "country_eritrea": "Érythrée",
  "country_estonia": "Estonie",
  "country_ethiopia": "Éthiopie",
  "country_fiji": "Fidji",
  "country_finland": "Finlande",
  "country_france": "France",
  "country_gabon": "Gabon",
  "country_gambia": "Gambie",
  "country_georgia": "Géorgie",
  "country_germany": "Allemagne",
  "country_ghana": "Ghana",
  "country_greece": "Grèce",
  "country_grenada": "Grenade",
  "country_guatemala": "Guatemala",
  "country_guinea": "Guinée",
  "country_guinea_bissau": "Guinée-Bissau",
  "country_guyana": "Guyana",
  "country_haiti": "Haïti",
  "country_honduras": "Honduras",
  "country_hungary": "Hongrie",
  "country_iceland": "Islande",
  "country_india": "Inde",
  "country_indonesia": "Indonésie",
  "country_iran": "Iran",
  "country_iraq": "Irak",
  "country_ireland": "Irlande",
  "country_israel": "Israël",
  "country_italy": "Italie",
  "country_jamaica": "Jamaïque",
  "country_japan": "Japon",
  "country_jordan": "Jordanie",
  "country_kazakhstan": "Kazakhstan",
  "country_kenya": "Kenya",
  "country_kiribati": "Kiribati",
  "country_kosovo": "Kosovo",
  "country_kuwait": "Koweït",
  "country_kyrgyzstan": "Kirghizistan",
  "country_laos": "Laos",
  "country_latvia": "Lettonie",
  "country_lebanon": "Liban",
  "country_lesotho": "Lesotho",
  "country_liberia": "Libéria",
  "country_libya": "Libye",
  "country_liechtenstein": "Liechtenstein",
  "country_lithuania": "Lituanie",
  "country_luxembourg": "Luxembourg",
  "country_macedonia": "Macédoine",
  "country_madagascar": "Madagascar",
  "country_malawi": "Malawi",
  "country_malaysia": "Malaisie",
  "country_maldives": "Maldives",
  "country_mali": "Mali",
  "country_malta": "Malte",
  "country_marshall_islands": "Îles Marshall",
  "country_mauritania": "Mauritanie",
  "country_mauritius": "Maurice",
  "country_mexico": "Mexique",
  "country_micronesia": "Micronésie",
  "country_moldova": "Moldavie",
  "country_monaco": "Monaco",
  "country_mongolia": "Mongolie",
  "country_montenegro": "Monténégro",
  "country_morocco": "Maroc",
  "country_mozambique": "Mozambique",
  "country_myanmar": "Birmanie",
  "country_namibia": "Namibie",
  "country_nauru": "Nauru",
  "country_nepal": "Népal",
  "country_netherlands": "Pays-Bas",
  "country_new_zealand": "Nouvelle-Zélande",
  "country_nicaragua": "Nicaragua",
  "country_niger": "Niger",
  "country_nigeria": "Nigeria",
  "country_north_korea": "Corée du Nord",
  "country_norway": "Norvège",
  "country_oman": "Oman",
  "country_pakistan": "Pakistan",
  "country_palau": "Palaos",
  "country_palestine": "Palestine",
  "country_panama": "Panama",
  "country_papua_new_guinea": "Papouasie-Nouvelle-Guinée",
  "country_paraguay": "Paraguay",
  "country_peru": "Pérou",
  "country_philippines": "Philippines",
  "country_poland": "Pologne",
  "country_portugal": "Portugal",
  "country_qatar": "Qatar",
  "country_romania": "Roumanie",
  "country_russia": "Russie",
  "country_rwanda": "Rwanda",
  "country_saint_kitts_and_nevis": "Saint-Christophe-et-Niévès",
  "country_saint_lucia": "Sainte-Lucie",
  "country_saint_vincent_and_the_grenadines": "Saint-Vincent-et-les Grenadines",
  "country_samoa": "Samoa",
  "country_san_marino": "Saint-Marin",
  "country_sao_tome_and_principe": "Sao Tomé-et-Principe",
  "country_saudi_arabia": "Arabie Saoudite",
  "country_senegal": "Sénégal",
  "country_serbia": "Serbie",
  "country_seychelles": "Seychelles",
  "country_sierra_leone": "Sierra Leone",
  "country_singapore": "Singapour",
  "country_slovakia": "Slovaquie",
  "country_slovenia": "Slovénie",
  "country_solomon_islands": "Îles Salomon",
  "country_somalia": "Somalie",
  "country_south_africa": "Afrique du Sud",
  "country_south_korea": "Corée du Sud",
  "country_south_sudan": "Soudan du Sud",
  "country_spain": "Espagne",
  "country_sri_lanka": "Sri Lanka",
  "country_sudan": "Soudan",
  "country_suriname": "Suriname",
  "country_swaziland": "Swaziland",
  "country_sweden": "Suède",
  "country_switzerland": "Suisse",
  "country_syria": "Syrie",
  "country_tajikistan": "Tadjikistan",
  "country_tanzania": "Tanzanie",
  "country_thailand": "Thaïlande",
  "country_togo": "Togo",
  "country_tonga": "Tonga",
  "country_trinidad_and_tobago": "Trinité-et-Tobago",
  "country_tunisia": "Tunisie",
  "country_turkey": "Turquie",
  "country_turkmenistan": "Turkménistan",
  "country_tuvalu": "Tuvalu",
  "country_uganda": "Ouganda",
  "country_ukraine": "Ukraine",
  "country_united_arab_emirates": "Émirats Arabes Unis",
  "country_united_kingdom": "Royaume-Uni",
  "country_united_states": "États-Unis",
  "country_uruguay": "Uruguay",
  "country_uzbekistan": "Ouzbékistan",
  "country_vanuatu": "Vanuatu",
  "country_vatican": "Vatican",
  "country_venezuela": "Venezuela",
  "country_vietnam": "Vietnam",
  "country_yemen": "Yémen",
  "country_zambia": "Zambie",
  "country_zimbabwe": "Zimbabwe",
  "cze": "Tchèque",
  "dan": "Danois",
  "deadline": "Date limite",
  "delete": "Supprimer",
  "delete_draft": "Supprimer le brouillon",
  "details_completion_hoursTypesInformations": "Politique de rémunération - Types d'heures",
  "details_completion_indemnitiesInformations": "Politique de rémunération - Primes et indemnités",
  "details_completion_legalInformations": "Informations légales",
  "details_completion_paymentInformations": "Informations de paiement",
  "details_completion_profile": "Profil de l'entreprise",
  "details_completion_status_incomplete": "Incomplet",
  "details_completion_validate_label": "Compléter mes informations",
  "doc_title_contracts": "Contrats",
  "doc_title_contracts_signature": "Signature de contracts",
  "doc_title_home": "Accueil",
  "doc_title_invoices": "Factures",
  "doc_title_job-descriptions": "Fiches de poste",
  "doc_title_logas": "Admin",
  "doc_title_not_found": "Introuvable",
  "doc_title_planning": "Planning",
  "doc_title_posting": "Nouvelle demande",
  "doc_title_preselection": "Présélection",
  "doc_title_settings": "Paramètres",
  "doc_title_tasks": "Missions",
  "doc_title_timesheets": "Relevés d'heures",
  "draft_deleted_success": "Brouillon supprimé !",
  "draft_section_row_status_cell_subtitle": "Finaliser le brouillon",
  "draft_section_row_status_cell_title": "Brouillon",
  "draft_section_row_task_cell_created_by": "Créée par ",
  "draft_section_title": "Brouillons",
  "duplicate": "Dupliquer",
  "duplicate_draft": "Renouveler la mission",
  "duplicate_task": "Renouveler la prestation",
  "empty_placeholder_all_subtitle": "Toutes vos missions avec Side s'afficheront ici.\u003cbr\u003ePour démarrer, il suffit de cliquer sur le bouton \\\"+ Nouvelle demande\\\" en bas de la barre de navigation de gauche.",
  "empty_placeholder_all_title": "Bonjour %{firstName} !",
  "empty_placeholder_completed_subtitle": "Retrouvez ici toutes les missions finalisées.\u003cbr\u003e\nUn Sider vous a plu ? N'hésitez pas à \\\"Renouveler la mission\\\", s'il est partant et disponible, vous pourrez refaire appel à lui directement.",
  "empty_placeholder_completed_title": "Aucune mission finalisée",
  "empty_placeholder_draft_subtitle": "Des demandes non finalisées ? Vous pourrez les retrouver ici et les compléter.",
  "empty_placeholder_draft_title": "Aucun brouillon en attente",
  "empty_placeholder_ongoing_subtitle": "Retrouvez ici toutes les missions sur lesquelles des Siders sont en train de travailler.",
  "empty_placeholder_ongoing_title": "Aucune mission en cours",
  "empty_placeholder_planning_cta": "Nouvelle demande",
  "empty_placeholder_planning_subtitle": "Vous n'avez pas de créneau prévu cette semaine. Affichez une autre semaine ou postez une nouvelle mission.",
  "empty_placeholder_planning_title": "Aucun créneau cette semaine",
  "empty_placeholder_upcoming_subtitle": "Retrouvez ici toutes les missions n'ayant pas encore demarré.",
  "empty_placeholder_upcoming_title": "Aucune mission à venir",
  "eng": "Anglais",
  "error_confirmation_not_equal_password": "Les deux mots de passe sont différents",
  "error_field_is_required": "Ce champ est obligatoire.",
  "error_field_is_required_checkbox": "Cette réponse est obligatoire. Veuillez sélectionner au moins une option.",
  "error_field_required": "Ce champ est obligatoire",
  "error_file_size": "La taille du fichier dépasse la limite de %{maxSize}Mo",
  "error_invalid_shift": "Le créneau que vous avez ajouté n'est pas valide. Sa durée doit être comprise entre 2h et 10h de travail, pause comprise.",
  "error_no_auth": "Une erreur est survenue : nous n'avons pas pu vous authentifier.",
  "error_password_length_8": "Votre mot de passe doit faire au moins 8 caractères",
  "error_password_special_char": "Votre mot de passe doit contenir au moins un caractère spécial",
  "error_phone_number_invalid": "Numéro de téléphone invalide",
  "errors.organisation.forbidden.blocked": "",
  "errors.task_salary.forbidden.too_low": "La fiche de poste n’a pas pu être créée, la rémunération doit être égale ou supérieure au SMIC en vigueur.",
  "errors_email_conflict": "Email déjà utilisé. Connectez-vous",
  "errors_generic": "Une erreur est survenue",
  "errors_organisation_forbidden_blocked": "Mince, vous n’avez pas l’autorisation de poster une mission, veuillez vous rapprocher de votre chargé·e de compte.\n",
  "errors_shift_conflict_duplicated": "Ce créneau a déjà été renseigné, vous ne pouvez pas le dupliquer.",
  "errors_shift_forbidden_post_in_the_past": "Cette mission contient des créneaux dans le passé, vous pouvez les supprimer ou vous tourner vers notre équipe support.",
  "errors_sider_conflict_already-exists": "Ce sider est déjà inscrit sur la plateforme Side. Contactez le support si vous souhaitez quand même l'ajouter à vos siders gestion.",
  "errors_slot_forbidden_requested_siders_count": "Mince, vous avez sélectionné trop de Siders par rapport au nombre de places disponibles pour la mission.",
  "errors_task_forbidden_not_enough_siders_selected": "Pas assez de Siders sélectionnés",
  "errors_token_forbidden": "Votre lien d'activation est expiré ou invalide.\\n\\n\n\nDemandez à un membre de votre équipe de vous renvoyer un lien d'invitation.",
  "errors_user_unauthorized_login_failed": "Connexion impossible. Vérifiez vos identifiants puis réessayez.",
  "est": "Estonien",
  "expert": "Expert",
  "feedback_dialog_content": "Les modifications que vous avez apportées ne seront pas prises en compte.",
  "feedback_dialog_title": "Quitter sans sauvegarder ?",
  "feedback_form_comment_question": "Avez-vous autre chose à ajouter à propos de %{name} ?",
  "feedback_form_comment_question_label": "Commentaire privé (facultatif)",
  "feedback_form_comment_question_placeholder": "Exemple : J’ai apprécié son dynamisme mais il faut faire attention à la rigueur…",
  "feedback_form_general_answer_1": "Oui",
  "feedback_form_general_answer_1_notice": "Je suis satisfait",
  "feedback_form_general_answer_2": "Non",
  "feedback_form_general_answer_2_notice": "Je ne suis pas satisfait",
  "feedback_form_general_question": "Seriez-vous prêt à retravailler avec %{name} sur des missions similaires ?",
  "feedback_form_issue_choice_1": "Professionnalisme",
  "feedback_form_issue_choice_1_notice": "Le comportement du Sider n’était pas assez professionnel.",
  "feedback_form_issue_choice_2": "Performance",
  "feedback_form_issue_choice_2_notice": "Le Sider n’a pas su atteindre ses objectifs.",
  "feedback_form_issue_description": "Nous sommes désolés d’apprendre que cette prestation ne correspondait pas à vos attentes. Précisez ce qui n'allait pas avec ce sider.",
  "feedback_form_issue_question": "Quel était le problème avec %{name} ?",
  "feedback_form_send_no_comment": "Envoyer\u003cspan class=\\\"send-hide-mobile\\\"\u003e sans commentaire\u003c/span\u003e",
  "feedback_modal_exit_description": "Les modifications que vous avez apportées ne seront pas prises en compte",
  "feedback_modal_exit_title": "Quitter sans sauvegarder ?",
  "feedback_modal_title": "Votre avis sur…",
  "feedback_tip_description": "Dites-nous si vous avez été satisfaits ou non de la prestation de ce Sider. Ceci nous aidera à vous proposer en priorité les profils que vous avez aimés. Vous pouvez modifier votre avis à tout moment.",
  "feedback_tip_description_rated": "N’oubliez pas de nous indiquer si vous êtes satisfaits ou non de la prestation de vos Siders. Ceci nous aidera à vous proposer en priorité les profils que vous avez aimés. Vous pouvez modifier votre avis à tout moment.",
  "feedback_tip_title": "Seriez-vous prêt à retravailler avec ce Sider ?",
  "feedback_tip_title_rated": "Avez-vous changé d'avis à propos de ce Sider?",
  "feedback_toast_not_satisfied": "Vous n’êtes pas satisfaits de la prestation de %{name}. Ce sider sera par défaut retiré de votre liste de siders favoris.",
  "feedback_toast_not_saved": "Votre avis sur la prestation de %{name} n’a pas été pris en compte.",
  "feedback_toast_satisfied": "Vous êtes satisfaits de la prestation de %{name} ! Ce Sider a été ajouté à votre liste de Siders favoris, vous pourrez refaire appel à ce Sider en priorité.",
  "feedback_tooltip_not_satisfied": "Je ne suis pas satisfait de ce Sider",
  "feedback_tooltip_satisfied": "Je suis satisfait de ce Sider",
  "fin": "Finnois",
  "finish": "Terminer",
  "finish_draft": "Finaliser le brouillon",
  "fluent": "Courant",
  "fre": "Français",
  "ger": "Allemand",
  "go_to_contracts_signature_page": "Signer les contrats",
  "gre": "Grec",
  "grn": "Guarani",
  "guard_modal_incomplete": "incomplet",
  "guard_modal_legal": "Informations légales",
  "guard_modal_payment": "Informations de paiement",
  "guard_modal_policy_hours": "Politique de rémunération - Type d’heures",
  "guard_modal_policy_indemnities": "Politique de rémunération - Indemnités",
  "guard_modal_profile": "Profil de l'entreprise",
  "guard_modal_submit": "Compléter mes informations",
  "guard_modal_subtitle": "Finissez de compléter vos informations pour pouvoir poster des missions",
  "guard_modal_title": "Complétez vos informations",
  "header_backhome": "Accueil",
  "header_contracts": "Contrats",
  "header_conversations": "Messages",
  "header_cta_task": "Nouvelle demande",
  "header_cta_team": "Mon équipe",
  "header_dashboard": "Tableau de bord",
  "header_dropdown_help": "Centre d'aide",
  "header_dropdown_language": "Switch to English",
  "header_dropdown_logout": "Se déconnecter",
  "header_dropdown_payment_settings": "Informations de paiement",
  "header_dropdown_personal_settings": "Informations personnelles",
  "header_dropdown_profile": "Profil entreprise",
  "header_dropdown_settings": "Paramètres de l'établissement",
  "header_dropdown_team": "Équipe",
  "header_invoices": "Factures",
  "header_job-descriptions": "Fiches de poste",
  "header_payments": "Paiements",
  "header_planning": "Planning",
  "header_search_company": "Recherche",
  "header_settings": "Paramètres",
  "header_siders": "Siders",
  "header_switch_search_placeholder": "Rechercher",
  "header_tasks": "Prestations",
  "header_team": "Équipe",
  "header_timesheets": "Relevés d'heures",
  "heb": "Hébreu",
  "hin": "Hindi",
  "home__company-logo__button__tooltip": "Mettre à jour le logo",
  "home_connecttochild_button": "Accéder à l'espace",
  "home_search_child_label": "Recherchez un établissement",
  "home_search_child_placeholder": "Nom ou adresse de l'établissement",
  "home_welcome_subtitle": "Postez et gérez vos missions en vous rendant sur l'espace de votre établissement",
  "home_welcome_title": "Bienvenue sur %{groupName}",
  "horizon_acquisition_argument_0": "Des premiers CV qualifiés en \u003cb\u003e1 semaine\u003c/b\u003e",
  "horizon_acquisition_argument_1": "Des \u003cb\u003eprofils qualifiés\u003c/b\u003e en visio d’au moins 30 mins",
  "horizon_acquisition_argument_2": "Des retours \u003cb\u003etransparents\u003c/b\u003e à chaque étape",
  "horizon_acquisition_title": "Un expert recrutement dédié",
  "horizon_dialog_message": "Un expert recrutement Side vous est maintenant dédié pour en discuter et élaborer avec vous votre offre sur-mesure.",
  "horizon_dialog_primary_action": "Réserver un appel",
  "horizon_dialog_secondary_action": "Plus tard",
  "horizon_dialog_title": "Merci ! Nous avons bien noté votre besoin en CDI !",
  "horizon_differentiate_title": "Ce qui nous distingue",
  "horizon_modal_address_label": "Localisation du poste",
  "horizon_modal_commentary_label": "Informations complémentaires",
  "horizon_modal_commentary_placeholder": "Lien d’une fiche de poste ou autre élément à partager",
  "horizon_modal_confirmation": "Votre besoin en CDI a bien été soumis. Nous revenons vers vous au plus vite !",
  "horizon_modal_jobdesc_document_label": "Document",
  "horizon_modal_jobdesc_label": "Fiche de poste ou offre d'emploi",
  "horizon_modal_jobdesc_link_label": "Lien",
  "horizon_modal_jobdesc_none_label": "Pas disponible pour l’instant",
  "horizon_modal_jobtitle_label": "Intitulé du poste",
  "horizon_modal_positions_label": "Nombre de besoin en CDI",
  "horizon_modal_salary_label": "Salaire annuel brut du poste",
  "horizon_modal_startdate_label": "Date de démarrage envisagée",
  "horizon_modal_title": "Besoin de recruter en CDI",
  "horizon_nav_button_desc": "Découvrez l'offre Horizon !",
  "horizon_nav_button_title": "Un besoin en CDI ?",
  "horizon_pool_argument_0": "Une application mobile \u003cb\u003edédiée\u003c/b\u003e pour les candidats",
  "horizon_pool_argument_1": "\u003cb\u003e100K candidats\u003c/b\u003e en recherche de CDI en France",
  "horizon_pool_argument_2": "Historique des candidats ayant \u003cb\u003edéjà\u003c/b\u003e travaillé avec nous",
  "horizon_pool_title": "Un vivier inédit \u0026 motivé",
  "horizon_reassurance_0": "Facturé \u003cb\u003euniquement\u003c/b\u003e au succès",
  "horizon_reassurance_1": "Basé sur le \u003cb\u003esalaire annuel brut\u003c/b\u003e HT",
  "horizon_reassurance_2": "Pas d’acompte ni d'exclusivité",
  "horizon_reassurance_3": "\u003cb\u003eGarantie\u003c/b\u003e 30 jours",
  "horizon_step_0": "Soumettez votre besoin",
  "horizon_step_1": "Élaborez une offre sur-mesure avec votre expert dédié",
  "horizon_step_2": "Recevez les meilleurs CV et rapports d’entretiens",
  "horizon_step_3": "Poursuivez votre process de recrutement interne",
  "horizon_step_4": "Notre talent est maintenant le vôtre !",
  "horizon_steps_title": "5 moments clés",
  "horizon_subtitle": "Recrutez en CDI selon un processus simple, efficace et transparent.",
  "horizon_title": "Recruter en CDI avec l’offre Horizon",
  "hrv": "Croate",
  "hun": "Hongrois",
  "ice": "Islandais",
  "ind": "Indonésien",
  "industry_logistics": "Logistique",
  "industry_office": "Office",
  "industry_retail": "Retail",
  "intermediate": "Intermédiaire",
  "invoices_amount": "Montant TTC",
  "invoices_date": "Période",
  "invoices_download": "Télécharger la facture",
  "invoices_download_error": "Facture indisponible",
  "invoices_monthly_download": "Télécharger toutes les factures pour %{month}",
  "invoices_monthly_download_failed": "Télécharger les factures disponibles pour %{month}",
  "invoices_monthly_error_body": "Veuillez contacter le support pour plus d'informations.",
  "invoices_monthly_error_title": "Une ou plusieurs factures n'ont pu être générées.",
  "invoices_monthly_notice": "En %{month} vous avez dépensé %{amount} € sur la plateforme. Nous avons regroupé toutes vos factures pour votre comptabilité juste ici !",
  "invoices_monthly_notice_failed": "En %{month} vous avez dépensé %{amount} € sur la plateforme. Nous avons regroupé les factures disponibles pour votre comptabilité juste ici !",
  "invoices_number": "Numéro de document",
  "invoices_sider": "Sider",
  "invoices_status": "Statut",
  "invoices_status_paid": "Payé",
  "invoices_status_unpaid": "À payer",
  "invoices_task": "Prestation",
  "invoices_type": "Type",
  "invoices_type_creditNote": "Avoir",
  "invoices_type_invoice": "Facture",
  "ita": "Italien",
  "job-description_creation_success": "Votre fiche de poste a bien été créée",
  "job-description_edit_success": "Votre fiche de poste a bien été mise à jour",
  "job-descriptions_copy_id": "ID copié !",
  "job-descriptions_create_button": "Créer",
  "job-descriptions_creation_conditions_context_goals_characters": "400 caractères",
  "job-descriptions_creation_conditions_context_goals_placeholder": "Vous ferez partie d'une équipe de 5 personnes et votre périmètre inclura...",
  "job-descriptions_creation_conditions_context_goals_title": "Contexte et objectifs",
  "job-descriptions_creation_conditions_context_subtitle": "Si vous le souhaitez, vous pouvez préciser quelques éléments de contexte personnalisés qui permettront aux Siders de se projeter dans la mission.",
  "job-descriptions_creation_conditions_context_title": "À propos",
  "job-descriptions_creation_conditions_equipment_provider_company": "Fourni par vous",
  "job-descriptions_creation_conditions_equipment_provider_side": "Fourni par Side",
  "job-descriptions_creation_conditions_equipment_provider_sider": "Fourni par le Sider",
  "job-descriptions_creation_conditions_equipment_table_header_label": "Type d'équipement",
  "job-descriptions_creation_conditions_equipment_table_header_value": "Détails",
  "job-descriptions_creation_conditions_equipments_dresscode_placeholder": "Si besoin, précisez le dress code demandé",
  "job-descriptions_creation_conditions_equipments_dresscode_title": "Dress code",
  "job-descriptions_creation_conditions_equipments_other_add_button": "Ajouter du matériel",
  "job-descriptions_creation_conditions_equipments_other_add_custom_button": "Ajouter un élement personnalisé",
  "job-descriptions_creation_conditions_equipments_other_custom_modal_input_label": "Nom du matériel",
  "job-descriptions_creation_conditions_equipments_other_custom_modal_tip": "Si vous n'avez pas trouvé l'équipement correspondant à votre besoin dans la liste, vous pouvez en ajouter un nouveau ci-dessous.",
  "job-descriptions_creation_conditions_equipments_other_delete_button": "Supprimer ce matériel",
  "job-descriptions_creation_conditions_equipments_other_subtitle": "Sélectionnez le matériel que les Siders devront apporter.",
  "job-descriptions_creation_conditions_equipments_other_title": "Autre matériel",
  "job-descriptions_creation_conditions_equipments_risks_label1": "Facteurs de pénibilité",
  "job-descriptions_creation_conditions_equipments_risks_label2": "Précisions supplémentaires",
  "job-descriptions_creation_conditions_equipments_risks_options": "Sélectionnez une option",
  "job-descriptions_creation_conditions_equipments_risks_placeholder": "Ajoutez les détails que vous considérez important dans la prise en compte de cette pénibilité",
  "job-descriptions_creation_conditions_equipments_risks_subtitle": "Précisez les facteurs de pénibilité qui s’appliquent à cette mission, ils sont reconnus par le code du travail et nécessitent la mise en place de mesures de prévention.",
  "job-descriptions_creation_conditions_equipments_risks_title": "Risques et pénibilité",
  "job-descriptions_creation_conditions_equipments_security_add_button": "Ajouter un EPI",
  "job-descriptions_creation_conditions_equipments_security_add_custom_button": "Ajouter un élement personnalisé",
  "job-descriptions_creation_conditions_equipments_security_custom_modal_input_label": "Nom de l'EPI",
  "job-descriptions_creation_conditions_equipments_security_custom_modal_tip": "Si vous n'avez pas trouvé l'équipement correspondant à votre besoin dans la liste, vous pouvez en ajouter un nouveau ci-dessous.",
  "job-descriptions_creation_conditions_equipments_security_delete_button": "Supprimer cet équipement",
  "job-descriptions_creation_conditions_equipments_security_modal_choice_no": "Non",
  "job-descriptions_creation_conditions_equipments_security_modal_choice_yes": "Oui",
  "job-descriptions_creation_conditions_equipments_security_modal_label": "Fournissez-vous cet équipement ?",
  "job-descriptions_creation_conditions_equipments_security_modal_subtitle": "Précisez si cet EPI est fourni par vos soins",
  "job-descriptions_creation_conditions_equipments_security_subtitle": "Sélectionnez les EPI nécessaires pour assurer la sécurité des Siders.",
  "job-descriptions_creation_conditions_equipments_security_title": "Équipements de sécurité",
  "job-descriptions_creation_conditions_equipments_subtitle": "Précisez les EPI et autre matériel nécessaires au bon déroulement de la mission.",
  "job-descriptions_creation_conditions_equipments_title": "Équipements",
  "job-descriptions_creation_conditions_step_title": " Conditions de travail",
  "job-descriptions_creation_job_jobtitle_subtasks": "%{numberOfTasks} tâches sélectionnées",
  "job-descriptions_creation_job_jobtitle_subtitle": "Cette information nous permettra de sélectionner des Siders qualifiés pour le poste.",
  "job-descriptions_creation_job_jobtitle_title": "Intitulé du poste",
  "job-descriptions_creation_job_name_title": "Nom de la fiche",
  "job-descriptions_creation_job_step_title": "Poste",
  "job-descriptions_creation_job_subtasks_custom_add_button": "Ajouter un élément personnalisé",
  "job-descriptions_creation_job_subtasks_custom_add_button_input": "Ajouter '%{searchInput}'",
  "job-descriptions_creation_job_subtasks_custom_modal_label": "Nom de la tâche",
  "job-descriptions_creation_job_subtasks_custom_modal_placeholder": "Tâche personnalisée",
  "job-descriptions_creation_job_subtasks_custom_modal_subtitle": "Si vous n'avez pas trouvé la tâche correspondant à votre besoin dans la liste, vous pouvez en ajouter une nouvelle ci-dessous.",
  "job-descriptions_creation_job_subtasks_custom_modal_title": "Ajouter un élément personnalisé",
  "job-descriptions_creation_job_subtasks_subtitle": "Sélectionnez les tâches que les Siders réaliseront lors de cette mission",
  "job-descriptions_creation_job_subtasks_title": "Tâches",
  "job-descriptions_creation_location_step_title": "Lieu",
  "job-descriptions_creation_salary_input_error": "Merci de vérifier votre saisie",
  "job-descriptions_creation_salary_input_error_underflow": "Merci de saisir un montant supérieur ou égal au salaire minimum",
  "job-descriptions_creation_salary_input_label": "Salaire de référence (horaire)",
  "job-descriptions_creation_salary_input_placeholder": "Supérieur ou égal à %{minimumHourlyRate}€/heure",
  "job-descriptions_creation_salary_label2": "Coûts horaires et coefficients indicatifs",
  "job-descriptions_creation_salary_raw": "Brut horaire",
  "job-descriptions_creation_salary_step_title": "Paie",
  "job-descriptions_creation_salary_subtitle": "Renseignez le salaire de référence correspondant à ce poste, ceci nous permettra de calculer une estimation du prix de vos futures commandes. Conformément au principe d’égalité de traitement, indiquez le salaire de référence pratiqué à poste égal dans votre entreprise.",
  "job-descriptions_creation_salary_title": "Rémunération",
  "job-descriptions_creation_skills_experience_label": "Types d'expériences souhaitées",
  "job-descriptions_creation_skills_experience_placeholder": "Nous recherchons de préférence des profils connaissant l'industrie de...",
  "job-descriptions_creation_skills_experience_subtitle": "Précisez les éventuelles expériences souhaitées, cela permettra aux candidats d'évaluer leur chance d'être sélectionnés.",
  "job-descriptions_creation_skills_experience_title": "Expérience",
  "job-descriptions_creation_skills_languages_add_button": "Ajouter une langue",
  "job-descriptions_creation_skills_languages_edit_disclaimer": "Cliquez sur Enregistrer pour supprimer toutes les langues.",
  "job-descriptions_creation_skills_languages_modal_label": "Niveau de compétence souhaité",
  "job-descriptions_creation_skills_languages_modal_placeholder": "Sélectionnez un choix",
  "job-descriptions_creation_skills_languages_modal_subtitle": "Précisez le niveau souhaité, celui-ci prendra en compte à la fois l'oral et l'écrit.",
  "job-descriptions_creation_skills_languages_modal_title": "Langues",
  "job-descriptions_creation_skills_languages_subtitles": "Sélectionnez les langues que les Siders doivent maîtriser.",
  "job-descriptions_creation_skills_languages_title": "Langues",
  "job-descriptions_creation_skills_languages_tools_subtitle": "Précisez les éventuelles compétences linguistiques ou techniques que les Siders doivent maîtriser.",
  "job-descriptions_creation_skills_languages_tools_title": "Langues et outils",
  "job-descriptions_creation_skills_licence_add_button": "Ajouter un permis",
  "job-descriptions_creation_skills_licence_label": "Permis \u0026 habilitations",
  "job-descriptions_creation_skills_licence_subtitle": "Précisez les permis et habilitations dont les Siders doivent disposer",
  "job-descriptions_creation_skills_licence_title": "Documents",
  "job-descriptions_creation_skills_step_title": "Compétences",
  "job-descriptions_creation_skills_tools_add_button": "Ajouter un outil",
  "job-descriptions_creation_skills_tools_add_custom_button": "Ajouter un élément personnalisé",
  "job-descriptions_creation_skills_tools_custom_modal_label": "Nom de l'outil",
  "job-descriptions_creation_skills_tools_custom_modal_placeholder": "Photoshop, Teams, Salesforce...",
  "job-descriptions_creation_skills_tools_custom_modal_tip": "Si vous n'avez pas trouvé l'outil correspondant à votre besoin dans la liste, vous pouvez en ajouter un nouveau ci-dessous.",
  "job-descriptions_creation_skills_tools_modal_excel_feedback": "- Débutant : Saisie de données, formules et calculs simples, impression de document, mise en forme de cellules.\n- Intermédiaire : Formules (SI, MAX, MIN, SOMME.SI, NB.SI), graphiques (création, mise en forme), fonctions de tri/filtres, création d’un tableau croisé dynamique, recherche de données, validation de données.\n- Avancé : Automatisation de tâches : exécuter des macros enregistrées, calculs avancés (calculs multi-feuilles/classeurs), fonctions avancées et formules imbriquées, fonctions de base de données, graphiques de TCD.\n- Expert : VBA, formules complexes (matricielles, financières), maîtrise experte de la mise en forme, calculs, TCD, outils d’analyse.",
  "job-descriptions_creation_skills_tools_modal_placeholder": "Sélectionnez un choix",
  "job-descriptions_creation_skills_tools_modal_subtitle": "Précisez le niveau de maîtrise souhaité",
  "job-descriptions_creation_skills_tools_subtitle": "Sélectionnez les outils que les Siders doivent maîtriser.",
  "job-descriptions_creation_skills_tools_title": "Outils et logiciels",
  "job-descriptions_creation_status_subtitle": "Le statut de vos Siders sera reporté dans leur contrat de travail et déterminera le montant des cotisations sociales à payer.",
  "job-descriptions_creation_status_title": "Statut",
  "job-descriptions_draft": "Brouillon",
  "job-descriptions_edit_context_subtitle": "Vous pouvez modifier les éléments de contexte personnalisés qui permettront aux Siders de se projeter dans la mission.",
  "job-descriptions_edit_devices_subtitle": "Vous pouvez modifier le matériel que les Siders devront apporter.",
  "job-descriptions_edit_dresscode_subtitle": "Vous pouvez modifier le dress code demandé.",
  "job-descriptions_edit_experiences_subtitle": "Vous pouvez modifier les éventuelles expériences souhaitées.",
  "job-descriptions_edit_jobTitle_subtitle": "Vous pouvez modifier l'intitulé du poste, vous devrez ensuite définir de nouveau les tâches liées à ce poste.",
  "job-descriptions_edit_languages_subtitle": "Vous pouvez modifier les compétences linguistiques ou techniques que les Siders doivent maîtriser.",
  "job-descriptions_edit_licences_subtitle": "Vous pouvez modifier les permis et habilitations dont les Siders doivent disposer.",
  "job-descriptions_edit_location_subtitle": "Vous pouvez modifier les informations liées au lieu de travail.",
  "job-descriptions_edit_manager_subtitle": "Vous pouvez modifier le nom du manager pour ce poste.",
  "job-descriptions_edit_name_subtitle": "Vous pouvez renommer votre fiche de poste pour la différencier des autres, ce nouveau nom sera utilisé en tant que référence sur vos factures.",
  "job-descriptions_edit_preselection": "Préselection de candidats",
  "job-descriptions_edit_risks_subtitle": "Vous pouvez modifier les facteurs de pénibilité qui s'appliquent à cette mission.",
  "job-descriptions_edit_safetyEquipment_subtitle": "Vous pouvez modifier les EPI et autre matériel nécessaires au bon déroulement de la mission.",
  "job-descriptions_edit_salary_subtitle": "Vous pouvez modifier le salaire de référence correspondant à ce poste.",
  "job-descriptions_edit_subtasks_subtitle": "Vous pouvez modifier les tâches que les Siders réaliseront lors de cette mission.",
  "job-descriptions_edit_tools_subtitle": "Vous pouvez modifier les outils que les Siders doivent maîtriser.",
  "job-descriptions_empty_create_button": "Créer une fiche de poste",
  "job-descriptions_empty_subtitle": "Vos fiches de poste vous permettront de facilement passer commande.",
  "job-descriptions_empty_subtitle_link_label": "En savoir plus",
  "job-descriptions_empty_title": "Aucune fiche de poste",
  "job-descriptions_instructions": "Pour poster une nouvelle demande de mission, sélectionnez une fiche de poste existante ou créez la fiche de poste correspondant à votre besoin.",
  "job-descriptions_instructions_account": "Pour poster une nouvelle demande de mission, sélectionnez une fiche de poste existante ou créez la fiche de poste correspondant à votre besoin.",
  "job-descriptions_instructions_network": "Vous pouvez créer et modifier des fiches de poste qui correspondent à vos besoins.\\nElles seront utilisables directement par l’ensemble des comptes de votre groupe.",
  "job-descriptions_jobtitle": "Fiche de poste",
  "job-descriptions_list_network_description": "Ces fiches de poste proviennent de votre groupe, vous pouvez les utiliser directement pour créer vos missions.",
  "job-descriptions_new_title": "Créer une fiche de poste",
  "job-descriptions_rename_field_placeholder": "Nom personnalisé",
  "job-descriptions_rename_tip": "Ce nouveau nom ne sera pas visible par les Siders.",
  "job-descriptions_status_ETAM": "Employé technicien et Agent de Maîtrise (ETAM)",
  "job-descriptions_status_executive": "Cadre",
  "job-descriptions_status_worker": "Ouvrier",
  "job-descriptions_summary_context_title": "Contexte",
  "job-descriptions_summary_devices_title": "Autre matériel",
  "job-descriptions_summary_dresscode_title": "Dress code",
  "job-descriptions_summary_experiences_title": "Expériences",
  "job-descriptions_summary_jobTitle_title": "Intitulé du poste",
  "job-descriptions_summary_languages_title": "Langues",
  "job-descriptions_summary_licences_title": "Permis",
  "job-descriptions_summary_location_title": "Adresse",
  "job-descriptions_summary_manager_title": "Manager",
  "job-descriptions_summary_name_title": "Nom de la fiche",
  "job-descriptions_summary_risks_title": "Risques et pénibilité",
  "job-descriptions_summary_safetyEquipment_title": "Équipements de sécurité",
  "job-descriptions_summary_salary_title": "Rémunération",
  "job-descriptions_summary_status_title": "Statut",
  "job-descriptions_summary_subtasks_title": "Tâches",
  "job-descriptions_summary_tools_title": "Outils et logiciels",
  "job-descriptions_unauthorized_custom": "Vous ne pouvez pas modifier vos fiches de poste car vous avez bénéficié d’une configuration particulière. Contactez-nous pour effectuer des changements",
  "job-descriptions_unauthorized_description": "Vous n’êtes pas autorisé à modifier cette fiche de poste",
  "job-descriptions_unauthorized_network": "Cette fiche de poste provient de votre groupe, vous n’êtes pas autorisé à la modifier",
  "job-descriptions_unauthorized_network_redirect_description": "Vous pouvez modifier cette fiche de poste depuis l’espace du groupe",
  "job-descriptions_unauthorized_network_redirect_title": "Action indisponible",
  "job_types_contact_content": "Contactez-nous par chat ou écrivez-nous à \u003ca href='mailto:in@side.co'\u003ein@side.co\u003c/a\u003e, nous avons certainement une solution pour vous !",
  "job_types_contact_title": "Vous ne trouvez pas ce que vous cherchez ?",
  "job_types_more": "Et plus...",
  "job_types_subtitle": "Choisissez votre prestation, Side s'occupe ensuite de la sélection des meilleurs profils et simplifie les démarches administratives.",
  "job_types_title": "Démarrer une nouvelle prestation",
  "jpn": "Japonais",
  "just_posted_section_row_status_cell_subtitle": "Analyse de votre besoin en cours...",
  "just_posted_section_row_status_cell_title": "La sélection va bientôt commencer...",
  "just_posted_section_row_task_cell_managed_by": "Gérée par ",
  "just_posted_section_table_header_start_date": "Date de début",
  "just_posted_section_table_header_status": "Information",
  "just_posted_section_table_header_task": "Mission",
  "just_posted_section_title": "Mission en préparation",
  "khm": "Khmer",
  "kon": "Kikongo",
  "kor": "Coréen",
  "latest_task_title": "Dernière mission",
  "lav": "Letton",
  "learn_more": "En savoir plus",
  "lin": "Lingala",
  "lit": "Lituanien",
  "location-form_favorite_list_addition": "L'adresse a bien été ajoutée à votre liste de favoris",
  "location-form_favorite_list_deletion": "L'adresse a bien été supprimée de votre liste de favoris",
  "location-form_favorite_list_update": "Votre liste de favoris a bien été mise à jour",
  "location-form_no_street_number": "Précisez un numéro de rue",
  "location-form_workplace_favorite_input_label": "Adresse",
  "location-form_workplace_favorite_input_title_label": "Intitulé",
  "location-form_workplace_favorite_input_title_placeholder": "Site A",
  "location-form_workplace_favorite_label": "Adresses favorites",
  "location-form_workplace_favorite_placeholder": "Saisissez l'adresse",
  "location-form_workplace_favorite_subtitle": "Entrez les adresses les plus souvent utilisées pour vos missions.",
  "location-form_workplace_favorite_title": "Ajouter une adresse favorite",
  "location-form_workplace_label1": "Adresse",
  "location-form_workplace_label2": "Informations complémentaires",
  "location-form_workplace_option1": "Possibilité de télétravail",
  "location-form_workplace_option2": "Le Sider devra être motorisé",
  "location-form_workplace_placeholder1": "Saisissez l'adresse",
  "location-form_workplace_placeholder2": "Précisez les informations qui seront utiles aux Siders pour trouver leur chemin le premier jour.",
  "location-form_workplace_subtitle": "Ces informations nous permettront de sélectionner des Siders pouvant se rendre facilement sur le lieu de travail.",
  "location-form_workplace_title": "Lieu de travail",
  "logas_impersonate_button": "Se connecter en tant que ",
  "logas_input_label": "Saisir un nom, email ou id",
  "logas_logout_button": "Se déconnecter",
  "mac": "Macédonien",
  "manager-form_choice_later": "Choisir plus tard",
  "manager-form_choice_now": "Choisir maintenant",
  "manager-form_label": "Sélectionnez le manager",
  "manager-form_subtitle": "Sélectionnez la personne en charge de ce poste, elle recevra les communications et sera en charge de valider les heures.",
  "manager-form_title": "Manager",
  "manager_update_success": "Manager modifié !",
  "may": "Malais",
  "mlg": "Malgache",
  "modal_confirmation_answer_1": "Une fois votre demande validée par notre équipe, la sélection des Siders est lancée. Vous recevrez un e-mail de confirmation vous indiquant la date et l’heure prévues de finalisation de la sélection. Vous pourrez également suivre l’avancée du processus directement sur votre compte Side. ",
  "modal_confirmation_answer_2": "Une fois votre mission validée, nous vous garantissons de remplir l’ensemble des créneaux demandés. En l'absence de l’un des Siders pour cas de force majeure, il sera remplacé par un indépendant tout aussi qualifié. 90% des missions Side sont remplies en moins de 24h.",
  "modal_confirmation_answer_3": "Lors de leur inscription, chaque Sider renseigne le détail de ses formations et expériences. Il s’entretient avec un membre de l’équipe et passe des tests ciblés en fonction de chaque type de missions. L’analyse de ces données permet de sélectionner les profils correspondant le mieux à votre demande.",
  "modal_confirmation_answer_4": "Sur Side, le paiement suit un rythme hebdomadaire. Lors de leurs missions, vos Siders entrent les heures travaillées du lundi au dimanche grâce à un système de check-in/check-out. Ces heures sont automatiquement envoyées sur votre plateforme entreprise et vous pouvez les modifier en cas de besoin. Les paiements sont validés tous les mardi et une facture à la semaine est générée automatiquement.",
  "modal_confirmation_description": "Et maintenant ?\u003cbr /\u003e Voici les réponses à 4 questions que vous pourriez vous poser à propos des prochaines étapes.",
  "modal_confirmation_faq": "Pour plus d'informations, \u003ca href='https://help.side.co/hc/' target='_blank'\u003econsultez notre FAQ\u003c/a\u003e. En cas d'urgence, une équipe est à votre disposition sur notre chat en ligne pour répondre à vos questions en un rien de temps.",
  "modal_confirmation_question_1": "Que se passe-t-il une fois ma mission postée ? ",
  "modal_confirmation_question_2": "Comment m'assurer que ma mission sera bien remplie ?",
  "modal_confirmation_question_3": "Quel est le processus de sélection ?",
  "modal_confirmation_question_4": "Comment se passe le paiement ?",
  "modal_confirmation_subtitle": "C'est parti",
  "modal_confirmation_tip_4": "Si vous souhaitez mettre en place un règlement sur mesure adapté à vos contraintes comptables, écrivez-nous à l’adresse in@side.co. Un membre de l'équipe étudiera votre demande rapidement.",
  "modal_confirmation_title": "Demande bien reçue !",
  "modal_delete_description": "Êtes-vous sûr de vouloir supprimer ce brouillon ?",
  "modal_delete_job_description_content": "Une fois supprimée, vous ne pourrez plus accéder à cette fiche de poste",
  "modal_delete_job_description_title": "Souhaitez-vous vraiment supprimer cette fiche de poste ?",
  "modal_delete_title": "Supprimer le brouillon",
  "modal_duplicate_description": "Pas d'inquiétude, vous pourrez l'éditer avant de la publier !",
  "modal_duplicate_title": "Êtes-vous sûr de vouloir dupliquer cette mission ?",
  "modal_manager_update_field_tip": "Cette personne aura la responsabilité de valider les heures travaillées par les Siders, veuillez vous assurer qu'elle sera bien disponible pour le faire.\n\u003cbr\u003eSans cette validation, nous ne pouvons pas payer les Siders.",
  "modal_redirect_button": "Accéder aux fiches de poste",
  "modal_redirect_description": "Le système de commande de missions a changé. Pour effectuer une nouvelle demande suivez nos conseils.",
  "modal_redirect_step_1": "Rendez-vous sur la page \u003cem\u003eFiches de poste\u003c/em\u003e",
  "modal_redirect_step_2": "Créez une nouvelle fiche de poste ou utilisez celle de votre choix pour poster vos futurs créneaux.",
  "modal_redirect_title": "Duplication impossible",
  "modal_rename_button_cancel": "Annuler",
  "modal_rename_button_ok": "Continuer",
  "modal_rename_description": "Si vous le souhaitez, vous pouvez personnaliser le nom de votre mission pour la différencier des autres et vous organiser. Ce nouveau nom sera utilisé en tant que référence sur vos factures.",
  "modal_rename_error_too_long": "Le nouveau nom doit faire 25 caractères maximum.",
  "modal_rename_field_label": "Nom de la mission",
  "modal_rename_field_placeholder": "Nom personnalisé",
  "modal_rename_field_tip": "Ce nouveau nom ne sera pas visible par les Siders.",
  "modal_rename_title": "Comment voulez-vous nommer cette mission ?",
  "modal_update_manager_input_label": "Gestionnaire",
  "modal_update_manager_title": "Changer le gestionnaire",
  "modify": "Modifier",
  "mon": "Mongol",
  "motive_activity_increase_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_activity_increase_explaination_link_label": "dans cet article",
  "motive_activity_increase_explaination_tip": "La loi impose d'indiquer au contrat la \\\\\\\"justification précise\\\\\\\" de votre accroissement d'activité pour vérifier que le recours à un emploi temporaire est justifié. Plus d'informations ",
  "motive_activity_increase_label": "Justification",
  "motive_activity_increase_length": "caractères restants",
  "motive_activity_increase_placeholder": "Ex : Pic d’activité, soldes, inventaire annuel de magasin...",
  "motive_activity_increase_subtitle": "Vous devez ici préciser au maximum la raison pour laquelle l'entreprise connaît une surcharge de travail temporaire. Plus d’informations",
  "motive_activity_increase_title": "Accroissement temporaire d'activité",
  "motive_activity_increase_validation": "Saisir au minimum 6 caractères",
  "motive_recruitment_count": "Personne recrutée n°%{index}",
  "motive_recruitment_date_label": "Date d'arrivée",
  "motive_recruitment_date_placeholder": "jj/mm/aaaa",
  "motive_recruitment_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_recruitment_explaination_link_label": "dans cet article",
  "motive_recruitment_explaination_tip": "Ce motif de recours est possible uniquement dans l'attente de l'arrivée d'une personne déjà recrutée en CDI. Plus d'informations ",
  "motive_recruitment_name_label": "Nom et prénom du futur salarié",
  "motive_recruitment_name_placeholder": "Ex: Hugo Marchand",
  "motive_recruitment_position_label": "Poste du futur salarié",
  "motive_recruitment_position_placeholder": "Ex : vendeur en magasin",
  "motive_recruitment_subtitle": "Ce motif de recours est possible uniquement dans l'attente de l'arrivée d'une personne déjà recrutée en CDI. Plus d’informations",
  "motive_recruitment_title": "En attente d'un recrutement",
  "motive_replacement_count": "Personne à remplacer n°%{index}",
  "motive_replacement_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_replacement_explaination_link_label": "dans cet article",
  "motive_replacement_explaination_tip": "Pour que le contrat soit valide, la loi impose de fournir les informations suivantes concernant le salarié à remplacer. Plus d'informations ",
  "motive_replacement_name_label": "Nom et prénom",
  "motive_replacement_name_placeholder": "Ex : Hugo Marchand",
  "motive_replacement_position_label": "Poste de la personne remplacée",
  "motive_replacement_position_placeholder": "Ex : vendeur en magasin",
  "motive_replacement_reason_label": "Motif de l’absence",
  "motive_replacement_reason_placeholder": "Ex : congé maladie",
  "motive_replacement_subtitle": "Vous devez obligatoirement indiquer dans le contrat de votre Sider qui il doit remplacer et pourquoi cette personne est absente. Plus d’informations",
  "motive_replacement_title": "Remplacement d’un salarié absent",
  "motive_seasonal_activity_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_seasonal_activity_explaination_link_label": "dans cet article",
  "motive_seasonal_activity_explaination_tip": "Les secteurs concernés par les emplois saisonniers sont définis par décret (agriculture, le tourisme et l'agro-alimentaire). Plus d'informations ",
  "motive_seasonal_activity_label": "Justifiez votre motif de recours à Side",
  "motive_seasonal_activity_length": "caractères restants",
  "motive_seasonal_activity_placeholder": "Ex : Vendanges de septembre, sport d'hiver (moniteur de ski)...",
  "motive_seasonal_activity_subtitle": "Vous ne pouvez recourir à ce motif que pour certaines activités qui se répètent chaque année à la même période. Plus d’informations",
  "motive_seasonal_activity_title": "Emploi à caractère saisonnier",
  "motive_seasonal_activity_validation": "Saisir au minimum 6 caractères",
  "motive_temporary_contract_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_temporary_contract_explaination_link_label": "dans cet article",
  "motive_temporary_contract_explaination_tip": "Les travaux temporaires par usage sont compris dans la liste de l'article D. 1251-1 du code du travail et l'accord interprofessionnel du 24/03/90. Plus d'informations ",
  "motive_temporary_contract_label": "Justifiez votre motif de recours à Side",
  "motive_temporary_contract_length": "caractères restants",
  "motive_temporary_contract_placeholder": "Ex : Centres de loisirs et de vacances, audiovisuel...",
  "motive_temporary_contract_subtitle": "Les travaux temporaires d'usages ne sont possibles que dans des cas strictement limités et determinés par l'article D1251-1 du code du travail. Plus d’informations",
  "motive_temporary_contract_title": "Contrat temporaire d'usage",
  "motive_temporary_contract_validation": "Saisir au minimum 6 caractères",
  "native": "Langue maternelle",
  "new": "Nouveau",
  "nld": "Néerlandais",
  "no": "Non",
  "no_invoice_company": "Vous n'avez pas encore reçu de facture pour le moment.",
  "no_invoice_month": "Vous n'avez pas reçu de factures pour ce mois.",
  "nor": "Norvégien",
  "oci": "Occitan",
  "onboarding-modal__intro": "Vous êtes le premier administrateur de votre compte entreprise. Vous pouvez dès maintenant inviter vos collaborateurs en leur attribuant le rôle adéquat",
  "onboarding-modal__title": "Bienvenue sur Side !",
  "onboarding-modal_steps_1_content": "Complétez votre profil, les paramètres de votre compte et mettez en ligne votre première mission.",
  "onboarding-modal_steps_1_title": "Partagez vos besoins",
  "onboarding-modal_steps_2_content": "Side s’occupe de trouver exactement les profils qu’il vous faut en quelques heures. Vous pourrez ensuite accéder à votre planning en temps réel et signer vos contrats en quelques clics.",
  "onboarding-modal_steps_2_title": "Suivez la sélection et signez vos contrats",
  "onboarding-modal_steps_3_content": "Retrouvez toutes les heures de vos Siders en un seul et même endroit. Bénéficiez de notre système de validation simplifié et recevez vos factures automatiquement chaque semaine.",
  "onboarding-modal_steps_3_title": "Validez vos heures et suivez vos factures",
  "onboarding-modal_understood": "C'est compris",
  "ongoing_section_display_planning": "Afficher le planning",
  "ongoing_section_display_preselection": "Afficher la sélection",
  "ongoing_section_row_status_cell_subtitle_contact_plural": "Voir les %{siderNumber} Siders",
  "ongoing_section_row_status_cell_subtitle_contact_singular": "Voir le Sider",
  "ongoing_section_row_status_cell_title_selection_done": "Sélection finalisée !",
  "ongoing_section_row_task_cell_managed_by": "Gérée par ",
  "ongoing_section_title": "Missions en cours",
  "optional": "facultatif",
  "order_details_label": "Infos supplémentaires \u0026 mesures sanitaires en place (optionnel)",
  "order_details_placeholder": "Ex : masque, gel et gants à disposition pour lutter contre le Covid-19, les déplacements peuvent être pris en charge à hauteur d'une heure payée, une partie de la mission peut avoir lieu dans une chambre froide à 4°C, etc.",
  "order_details_subtitle": "Profitez de cet espace pour préciser des infos supplémentaires que les Siders devraient avoir. Précisez également les mesures sanitaires prises pour protéger et rassurer vos Siders contre le Covid-19.",
  "order_details_title": "Informations supplémentaires",
  "order_justifications_add": "Ajouter une nouvelle justification",
  "order_justifications_create_action": "Créer une justification",
  "order_justifications_delete_confirmation": "Etes-vous sûr(e) de vouloir supprimer ?",
  "order_justifications_label": "Choix d'une justification",
  "order_justifications_placeholder": "Sélectionnez une justification parmi la liste",
  "order_justifications_tip": "Le choix de la justification sera reporté dans le contrat de vos siders. Si vous renouvelez un contrat en utilisant la même justification, un avenant de prolongation sera généré automatiquement.",
  "order_motives_add": "Ajouter une personne à remplacer",
  "order_motives_error_6_chars": "Saisir au minimum 6 caractères",
  "order_motives_label": "Choix d'un motif",
  "order_motives_label_activity_increase": "Accroissement temporaire d'activité",
  "order_motives_label_replacement": "Remplacement d'un salarié absent",
  "order_motives_label_seasonal_activity": "Emploi à caractère saisonnier",
  "order_motives_label_temporary_contract": "Contrat temporaire d'usage",
  "order_motives_label_waiting_recruitment": "En attente d'un recrutement",
  "order_motives_placeholder": "Sélectionnez un motif parmi la liste",
  "order_motives_show_details": "Voir le détail",
  "order_motives_subtitle": "Veuillez indiquer ci-dessous la raison pour laquelle vous avez recours à Side",
  "order_motives_title": "Motif de recours",
  "order_noresults_button_label": "Continuer",
  "order_noresults_subtitle": "Vous retrouverez ici les profils avec lesquels vous avez apprécié travailler la prochaine fois.",
  "order_noresults_title": "Pas encore de Siders ?",
  "order_preliminary_details_cancel_label": "Annuler",
  "order_preliminary_details_coefficient_column": "Coefficient",
  "order_preliminary_details_delegation_description": "Nos recruteurs vous accompagnent dans le recrutement d’intérimaires, avec lesquels vous avez déjà travaillés ou non.",
  "order_preliminary_details_delegation_label": "Je recherche des intérimaires",
  "order_preliminary_details_gestion_description": "Ajoutez sur Side les profils que vous sourcez et automatisez 100% de vos démarches administratives.",
  "order_preliminary_details_gestion_label": "J'ai déjà mes profils",
  "order_preliminary_details_hourlyrate_column": "Coût",
  "order_preliminary_details_hourtype_column": "Type d'heure",
  "order_preliminary_details_increaserate_column": "Majoration",
  "order_preliminary_details_modal_title": "Créer une nouvelle mission",
  "order_preliminary_details_pricing_table_title": "Coûts horaires et coefficients indicatifs",
  "order_preliminary_details_subtitle": "Choisissez l'option qui correspond à votre besoin",
  "order_preliminary_details_taskname_label": "Personnaliser le nom",
  "order_preliminary_details_taskname_optional": "facultatif",
  "order_preliminary_details_taskname_placeholder": "Ajouter un suffixe au nom de la mission",
  "order_preliminary_details_taskname_tip": "La personnalisation du nom de la mission ne sera pas visible par les Siders.",
  "order_preliminary_details_tasktype_label": "Définir mon besoin",
  "order_preliminary_details_title": "Nouvelle demande",
  "order_preliminary_details_type_delegation_label": "Side Source (Délégation)",
  "order_preliminary_details_type_gestion_label": "Side Flash (Gestion)",
  "order_preliminary_details_type_label": "Type de mission",
  "order_preliminary_details_validate_label": "Continuer",
  "order_quotation_price": "Coût (€/h)",
  "order_quotation_quantity": "Qté",
  "order_quotation_total_ht": "Total HT",
  "order_quotation_type": "Type",
  "order_shifts_subtitle": "Détaillez les créneaux et le nombre de Siders que vous prévoyez pour cette mission",
  "order_shifts_title": "Créneaux",
  "order_stepper_details": "Détails",
  "order_stepper_motive": "Motif",
  "order_stepper_shifts": "Créneaux",
  "order_stepper_summary": "Récapitulatif",
  "order_stepper_workers": "Siders",
  "order_succes_display_planning": "Afficher mon planning",
  "order_success_item_1": "Votre mission est en ligne et la sélection démarre automatiquement",
  "order_success_item_2": "Notre équipe sélectionne les Siders le plus pertinents pour cette mission",
  "order_success_item_3": "Vous pouvez suivre l'avancée de la sélection depuis votre planning",
  "order_success_title": "Votre mission est postée",
  "order_summary_address_title": "Adresse",
  "order_summary_base_wage_disclaimer": "Le salaire de référence pour cette mission est configuré au niveau de votre fiche de poste",
  "order_summary_confirmation_button": "Confirmer",
  "order_summary_confirmation_cancellation_fees": "Une fois votre demande validée, si vous décidez de l'annuler ou de la modifier, des frais d'annulation seront automatiquement débités",
  "order_summary_confirmation_checkbox": "J’ai compris les conditions d'annulation",
  "order_summary_confirmation_footnote": "Vous serez facturé à intervalle régulier en fonction des heures validées de vos intérimaires. Une fois votre besoin validé, vous recevrez une confirmation par email.",
  "order_summary_confirmation_sidenote": "Commentaire pour l'équipe Side",
  "order_summary_confirmation_sidenote_placeholder": "Saisissez votre commentaire",
  "order_summary_confirmation_title": "Confirmez votre demande",
  "order_summary_estimate_base_wage": "Salaire de référence (heures normales)",
  "order_summary_estimate_details": "Afficher le détail",
  "order_summary_estimate_disclaimer": "Ce prix inclut les indemnités de fin de mission et de congés payés mais ne prend pas en compte les primes et indemnités spécifiques. Ces éléments peuvent impacter le prix final. ",
  "order_summary_estimate_title": "Estimation du prix",
  "order_summary_estimate_total_with_taxes": "Estimation Totale (20% TVA)",
  "order_summary_estimate_total_without_taxes": "Estimation Totale HT",
  "order_summary_from_to_date": "de %{startDate} à %{endDate}",
  "order_summary_info_title": "Informations complémentaires",
  "order_summary_justification_title": "Justification",
  "order_summary_manager_title": "Manager",
  "order_summary_motive_title": "Motif de recours",
  "order_summary_name_title": "Nom de la mission",
  "order_summary_shifts_title": "Créneaux",
  "order_summary_shifts_warning_FAQ_link": "https://help.side.co/hc/fr",
  "order_summary_shifts_warning_holiday": "Vous avez ajouté un créneau sur un jour férié dont la rémunération est majorée selon votre politique de rémunération. Vérifiez que ce jour est bien travaillé dans votre entreprise.",
  "order_summary_shifts_warning_holiday_interval": "Votre période de mission comprend un jour férié. Les jours fériés non travaillés sont rémunérés au tarif habituel s'ils font partie des jours habituels de mission.",
  "order_summary_shifts_warning_link_text": "Consultez notre FAQ pour plus d'informations.",
  "order_summary_title": "Récapitulatif de commande",
  "order_summary_type_delegation_description": "Vous travaillez avec des profils recrutés par nos équipes",
  "order_summary_type_delegation_title": "Délégation",
  "order_summary_type_gestion_description": "Vous avez trouvé des profils par vous-mêmes, nous gérons uniquement l’administratif.",
  "order_summary_type_gestion_title": "Gestion",
  "order_summary_type_title": "Type de mission",
  "order_summary_workers_title": "Siders demandés en priorité",
  "order_summary_workers_wa_warning": "Seuls les Siders que vous avez sélectionnés pourront postuler et être positionnés sur la mission. Veuillez confirmer avec eux leurs disponibilités. S'ils ne sont pas disponibles, nous annulerons la mission.",
  "order_workers_filters_button_label": "Filtres (%{numberOfFilters})",
  "order_workers_filters_modal_button_delete": "Réinitialisez les filtres",
  "order_workers_filters_modal_experience_label": "Expérience",
  "order_workers_filters_modal_experience_placeholder": "Ajoutez un intitulé de poste",
  "order_workers_filters_modal_isrecommended_false": "Afficher tous les Siders",
  "order_workers_filters_modal_isrecommended_label": "Avis",
  "order_workers_filters_modal_isrecommended_placeholder": "Sélectionnez un type d'avis",
  "order_workers_filters_modal_isrecommended_true": "Afficher seulement les Siders avec un avis positif",
  "order_workers_filters_modal_last-task_1": "Moins d'un mois",
  "order_workers_filters_modal_last-task_3": "Moins de 3 mois",
  "order_workers_filters_modal_last-task_all": "Afficher tous les Siders",
  "order_workers_filters_modal_last-task_label": "Dernière mission réalisée",
  "order_workers_filters_modal_last-task_placeholder": "Sélectionnez une période",
  "order_workers_filters_modal_subtitle": "Appliquez des filtres pour préciser votre recherche ",
  "order_workers_filters_modal_title": "Filtrer les Siders",
  "order_workers_filters_noresults_button_label": "Modifier les filtres",
  "order_workers_filters_noresults_subtitle": "Modifiez les filtres pour afficher plus de Siders",
  "order_workers_filters_noresults_title": "Aucun résultat",
  "order_workers_gestion_amount": "Sélectionnez encore %{numberOfRemainingSlots} Siders pour pouvoir continuer",
  "order_workers_gestion_noresults_subtitle": "Ajoutez vos profils afin de travailler avec eux sur cette mission",
  "order_workers_gestion_noresults_title": "Pas encore de Siders ?",
  "order_workers_gestion_profile-add_button_label": "Ajouter un profil",
  "order_workers_gestion_profile-add_modal_error": "Ce profil a déjà un compte sur Side. Si vous souhaitez le recruter, contactez le support via le chat ou à l’adresse care@sidetemp.com",
  "order_workers_gestion_profile-add_modal_firstname_label": "Prénom",
  "order_workers_gestion_profile-add_modal_firstname_placeholder": "Elise",
  "order_workers_gestion_profile-add_modal_lastname_label": "Nom",
  "order_workers_gestion_profile-add_modal_lastname_placeholder": "Dupont",
  "order_workers_gestion_profile-add_modal_phone_label": "Numéro de téléphone",
  "order_workers_gestion_profile-add_modal_phone_placeholder": "(0)6 07 08 09 10",
  "order_workers_gestion_profile-add_modal_subtitle": "Complétez les informations du profil avec lequel vous souhaitez travailler, nous nous chargeons du reste.",
  "order_workers_gestion_profile-add_modal_title": "Ajouter un profil",
  "order_workers_list_network_label": "Mon réseau (%{numberOfSharedWorkers})",
  "order_workers_list_orga_label": "Mes Siders (%{numberOfOrganisationWorkers})",
  "order_workers_list_selected_label": "Sélection (%{numberOfSelectedWorkers})",
  "order_workers_network_noresults_button_label": "Voir les Siders de mon réseau",
  "order_workers_network_noresults_counter": "%{numberOfWorkers} Siders disponibles",
  "order_workers_network_noresults_subtitle": "Faites appel aux Siders déjà formés de votre réseau !",
  "order_workers_network_noresults_title": "Pas encore de Siders ?",
  "order_workers_search_noresults_subtitle": "Modifiez votre recherche pour afficher plus de Siders",
  "order_workers_search_noresults_title": "Aucun résultat",
  "order_workers_search_placeholder": "Nom du Sider",
  "order_workers_subtitle": "Travaillez à nouveau avec vos Siders",
  "order_workers_title": "Siders",
  "order_workers_wa-only_modal_subtitle": "Vous avez choisi l’option d'ouvrir la mission exclusivement aux Siders sélectionnés, seuls ces siders pourront postuler et effectuer la mission.",
  "order_workers_wa-only_modal_title": "Ouvrir la mission exclusivement aux Siders sélectionnés",
  "order_workers_wa-only_modal_warning": "Attention, cette option nécessite que les siders sélectionnés soient bien disponibles pour la mission. Ne confirmez que si vous en avez parlé avec eux. S'ils ne sont pas disponibles, aucun sider ne sera sélectionné.",
  "order_workers_wa-only_toggle_amount": "Sélectionnez encore %{numberOfRemainingSlots} Siders pour pouvoir utiliser cette option",
  "order_workers_wa-only_toggle_label": "Ouvrir la mission exclusivement aux Siders sélectionnés",
  "order_workers_wa-only_toggle_tooltip_content_activated": "Seuls les Siders auxquels vous avez refait appel pourront postuler et être sélectionnés sur la mission. Side ne complètera pas la sélection avec d’autres Siders si ces derniers ne sont pas disponibles.",
  "order_workers_wa-only_toggle_tooltip_content_deactivated": "En activant cette option, seulement les siders que vous avez cochés dans la liste pourront postuler à la mission. Ne cochez cette case que si vous avez validé avec ces siders qu'ils sont bien disponibles.",
  "order_workers_wa-only_toggle_tooltip_title_activated": "Option activée",
  "order_workers_wa-only_toggle_tooltip_title_deactivated": "Option désactivée",
  "per": "Persan",
  "personal_informations": "Informations personnelles",
  "personal_informations_email": "Email",
  "personal_informations_email_placeholder": "votremail@nomdedomaine.com",
  "personal_informations_firstname": "Prénom",
  "personal_informations_firstname_placeholder": "Votre prénom",
  "personal_informations_lastname": "Nom de famille",
  "personal_informations_lastname_placeholder": "Votre nom de famille",
  "personal_informations_new_password": "Nouveau mot de passe",
  "personal_informations_new_password_confirmation": "Confirmation",
  "personal_informations_new_password_confirmation_placeholder": "**********",
  "personal_informations_new_password_placeholder": "**********",
  "personal_informations_phone": "Numéro de téléphone",
  "personal_informations_phone_placeholder": "06 XX XX XX XX",
  "personal_informations_profile_saved": "Informations sauvegardées",
  "planning_download_as": "Télécharger en %{downloadFormat}",
  "planning_select_current_week": "Aujourd'hui",
  "planning_sheet_empty_user": "Sélection en cours",
  "planning_short_week": "S ",
  "planning_sider_worked_hours_week": "%{siderName} travaille %{workedHoursInWeek} heure%{plural} cette semaine",
  "pol": "Polonais",
  "por": "Portugais",
  "preselect_actions_tooltip_approve": "Approuver ce candidat",
  "preselect_actions_tooltip_download": "Télécharger le CV",
  "preselect_actions_tooltip_refuse": "Refuser ce candidat",
  "preselect_actions_tooltip_upload": "Téléverser le CV",
  "preselect_availabilities_from": "À partir du",
  "preselect_availabilities_until": "Jusqu'au",
  "preselect_cv_uploaded": "CV téléversé avec succès",
  "preselect_no_result_client": "Nous préparons votre mission et reviendrons très prochainement vers vous avec des candidats sélectionnés par nos soins",
  "preselect_no_result_logas": "Sélection en cours",
  "preselect_refusal_modal_button": "Refuser ce candidat",
  "preselect_refusal_modal_description": "Expliquez-nous pourquoi ce candidat ne correspond pas afin que nous puissions affiner notre recherche. (ex : ce candidat m’a déjà été présenté)",
  "preselect_refusal_modal_placeholder": "Expliquez-nous...",
  "preselect_refusal_modal_title": "Ce candidat ne vous convient pas ?",
  "preselect_textarea_hint": "500 charactères maximum",
  "preselect_textarea_placeholder": "Ecrivez une note...",
  "preselect_tip_no_visible": "Attention, aucun candidat n’est visible par votre client ! Pour donner de la visibilité à votre client, vous devez cliquer sur l’icone “oeil” qui se trouve à gauche du tableau.",
  "preselection_h1": "Candidats préselectionnés",
  "preselection_status_approved": "Approuvé",
  "preselection_status_refused": "Refusé",
  "preselection_status_sent": "À valider",
  "preselection_table_header_applicant": "Candidat",
  "preselection_table_header_availabilities": "Disponibilité",
  "preselection_table_header_notes": "Notes",
  "preselection_table_header_status": "Statut",
  "preselection_table_header_visibility": "Visibilité",
  "que": "Quechua",
  "rename": "Renommer",
  "rename_task": "Renommer la mission",
  "rum": "Roumain",
  "rus": "Russe",
  "save": "Enregistrer",
  "save_quit": "Enregistrer et quitter",
  "search": "Rechercher",
  "send": "Envoyer",
  "settings_actions_content": "Vous avez modifié certaines informations, voulez-vous enregistrer ces changements ?",
  "settings_actions_discard": "Annuler",
  "settings_actions_save": "Enregistrer",
  "settings_legal_ape": "Code APE",
  "settings_legal_ape_placeholder": "0000A",
  "settings_legal_ape_tip": "le code APE est composé de quatre chiffres et d’une lettre, et correspond à une classification, la Nomenclature des Activités Françaises (NAF), raison pour laquelle on parle également de code NAF. Il se trouve aussi dans la rubrique \\\"activité\\\" sur le site https://www.societe.com",
  "settings_legal_business_name": "Raison sociale",
  "settings_legal_business_name_detail": "(si différente)",
  "settings_legal_business_name_placeholder": "Raison sociale",
  "settings_legal_business_name_tip": "C'est le nom de l'entité qui figurera sur les contrats et sera facturée",
  "settings_legal_error_ape_format": "Le code APE doit comporter 4 chiffres suivis d'une lettre",
  "settings_legal_error_siret_format": "Le format de ce champ n'est pas valide",
  "settings_legal_name": "Nom de l'entreprise",
  "settings_legal_name_placeholder": "Nom de l'entreprise",
  "settings_legal_siret": "SIRET",
  "settings_legal_siret_placeholder": "000 000 000 00000",
  "settings_legal_siret_tip": "Le numéro de SIRET est un identifiant de 14 chiffres de votre entreprise, vous pouvez le trouver facilement sur le site https://www.societe.com",
  "settings_legal_subtitle": "Ces informations figureront sur les contrats de mise à disposition",
  "settings_legal_title": "Informations légales",
  "settings_nav_account": "Mon profil",
  "settings_nav_company_info": "Profil de l'entreprise",
  "settings_nav_legal_info": "Informations légales",
  "settings_nav_payment_info": "Paiement",
  "settings_nav_payment_info_accounting": "Contact comptabilité",
  "settings_nav_payment_info_billing": "Adresse de facturation",
  "settings_nav_payment_info_method": "Mode de paiement",
  "settings_nav_payment_info_remuneration": "Politique de rémunération",
  "settings_nav_payment_info_remuneration_compensations": "Primes et indemnités",
  "settings_nav_payment_info_remuneration_hours": "Types d'heures",
  "settings_nav_team": "Équipe",
  "settings_payment_accounting_email": "Email",
  "settings_payment_accounting_email_placeholder": "nom@entreprise.com",
  "settings_payment_accounting_firstname": "Prénom",
  "settings_payment_accounting_firstname_placeholder": "Prénom",
  "settings_payment_accounting_name": "Nom",
  "settings_payment_accounting_name_placeholder": "Nom",
  "settings_payment_accounting_phone_number": "Numéro de téléphone",
  "settings_payment_accounting_phone_number_placeholder": "06 00 00 00 00",
  "settings_payment_accounting_subtitle": "Renseignez les informations de la personne qui recevra les factures.",
  "settings_payment_accounting_title": "Contact comptabilité",
  "settings_payment_api_error": "Une erreur s'est produite, veuillez réessayer ultérieurement ou contacter le service client.",
  "settings_payment_billing_address": "Adresse",
  "settings_payment_billing_address_placeholder": "Entrez votre adresse",
  "settings_payment_billing_city": "Ville",
  "settings_payment_billing_city_placeholder": "Ville",
  "settings_payment_billing_country": "Pays",
  "settings_payment_billing_country_placeholder": "Selectionnez un pays",
  "settings_payment_billing_postal_code": "Code Postal",
  "settings_payment_billing_postal_code_placeholder": "00000",
  "settings_payment_billing_subtitle": "Ces informations nous permettront de créer automatiquement vos factures aux normes comptables en fin de prestation.",
  "settings_payment_billing_title": "Adresse de facturation",
  "settings_payment_billing_tva_intro": "Vous utilisez une adresse de facturation située dans un pays étranger, veuillez indiquer votre numéro de TVA intracommunautaire.",
  "settings_payment_billing_tva_label": "Numéro de TVA intra-communautaire",
  "settings_payment_billing_tva_placeholder": "XX 0000000000000",
  "settings_payment_billing_tva_tip": "This numéro permettra la bonne déclaration de TVA",
  "settings_payment_error_card_check": "Il y a un problème avec cette carte bancaire.",
  "settings_payment_error_card_format": "Ce numéro de Carte Bleue n'est pas valide.",
  "settings_payment_error_city_format": "Le format de ce champ n'est pas valide",
  "settings_payment_error_country_format": "Le format de ce champ n'est pas valide",
  "settings_payment_error_digits_only_format": "Ce champ ne peut contenir que des chiffres.",
  "settings_payment_error_email_format": "Le format de votre email n'est pas valide",
  "settings_payment_error_exp_date_format": "Veuillez entrer une date valide.",
  "settings_payment_error_iban_format": "Cet IBAN n'est pas valide.",
  "settings_payment_error_phone_number_format": "Le format de votre numéro de téléphone n'est pas valide",
  "settings_payment_error_postal_code_format": "Le code postal doit être composé de 5 chiffres",
  "settings_payment_error_tos": "Veuillez accepter les conditions d'utilisation.",
  "settings_payment_error_tva_number_format": "Le format de votre numéro de TVA n'est pas valide, il doit commencer par 2 lettres",
  "settings_payment_method_card_cvv": "CVV",
  "settings_payment_method_card_cvv_placeholder": "123",
  "settings_payment_method_card_desc": "le montant de vos factures sera prelevé sur le compte bancaire associé à cette carte.",
  "settings_payment_method_card_exp_date": " Date d’expiration",
  "settings_payment_method_card_exp_date_placeholder": "MM/AA",
  "settings_payment_method_card_label": "Prélèvement carte bancaire",
  "settings_payment_method_card_modify": "Modifier la carte",
  "settings_payment_method_card_number": "Numéro carte",
  "settings_payment_method_card_number_placeholder": "0000 - 0000 - 0000 - 0000",
  "settings_payment_method_card_saved": "Carte enregistrée",
  "settings_payment_method_cs_desc": "Vous êtes responsable du paiement de vos factures à échéance",
  "settings_payment_method_cs_label": "Virement manuel",
  "settings_payment_method_label": "Je souhaiterais payer par…",
  "settings_payment_method_order_form": "Je souhaiterais des préfactures",
  "settings_payment_method_sepa": "IBAN",
  "settings_payment_method_sepa_confirmation": "En cliquant ici, vous acceptez les conditions d’utilisation du prélèvement \u003ca href=\\\"https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/sepa-mandate-fr.pdf\\\" target=\\\"_blank\\\"\u003e SEPA Direct Debit Stripe\u003c/a\u003e.",
  "settings_payment_method_sepa_country": "Pays",
  "settings_payment_method_sepa_country_placeholder": "Selectionnez un pays",
  "settings_payment_method_sepa_currency": "Devise",
  "settings_payment_method_sepa_currency_placeholder": "Selectionnez une devise",
  "settings_payment_method_sepa_desc": "Le montant de vos factures sera prelevé sur votre compte bancaire.",
  "settings_payment_method_sepa_euros": "Euros",
  "settings_payment_method_sepa_france": "France",
  "settings_payment_method_sepa_gb": "Grande-Bretagne",
  "settings_payment_method_sepa_label": "Prélèvement SEPA",
  "settings_payment_method_sepa_modify": "Modifier l'IBAN",
  "settings_payment_method_sepa_placeholder": "XX00 0000 0000 0000 0000 0000 000",
  "settings_payment_method_sepa_pounds": "Livres",
  "settings_payment_method_sepa_saved": "IBAN enregistré",
  "settings_payment_method_stripe": "Le paiement et le stockage sont entièrement gérés par Stripe, nous ne stockons aucune donnée.",
  "settings_payment_method_subtitle": "Ces informations sont nécessaires pour lancer la sélection des Siders. Pas de panique, vous ne serez prélevé qu’en fin de prestation.",
  "settings_payment_method_title": "Mode de paiement",
  "settings_payment_title": "Informations de paiement",
  "settings_personal_email": "Email",
  "settings_personal_email_placeholder": "nom@entreprise.com",
  "settings_personal_firstname": "Prénom",
  "settings_personal_firstname_placeholder": "Prénom",
  "settings_personal_lastname": "Nom",
  "settings_personal_lastname_placeholder": "Nom",
  "settings_personal_password_confirm": "Confirmer",
  "settings_personal_password_new": "Nouveau mot de passe",
  "settings_personal_password_placeholder": "•••••••••••",
  "settings_personal_phonenumber": "Numéro de téléphone",
  "settings_personal_phonenumber_placeholder": "06 00 00 00 00",
  "settings_personal_subtitle": "Ces informations vous permettent de vous connecter à la plateforme",
  "settings_personal_title": "Mon compte",
  "settings_profile_title": "Profil de l'entreprise",
  "settings_remuneration_hours_after_months_seniority": "\u003cb\u003eAprès %{months} mois\u003c/b\u003e d’ancienneté.",
  "settings_remuneration_hours_before_months_seniority": "\u003cb\u003eAvant %{months} mois\u003c/b\u003e d’ancienneté.",
  "settings_remuneration_hours_edit_tooltip": "Modifier les paramètres de ce type d’heure",
  "settings_remuneration_hours_extra_content": "Majoration appliquée lorsqu'un Sider travaille :",
  "settings_remuneration_hours_extra_title": "Heures supplémentaires",
  "settings_remuneration_hours_help": "Vous ne retrouvez pas tous vos types d’heures dans la liste ci-dessus ? Contactez-nous sur billing@sidetemp.com.",
  "settings_remuneration_hours_holiday_content": "Majoration appliquée lorsqu'un Sider travaille lors d’un jour férié.",
  "settings_remuneration_hours_holiday_solidarity": "\u003cb\u003eJournée de solidarité : \u003c/b\u003e %{solidarity}",
  "settings_remuneration_hours_holiday_solidarity_undefined": "non précisée",
  "settings_remuneration_hours_holiday_title": "Heures jours fériés travaillés",
  "settings_remuneration_hours_hour_threshold": "\u003cb\u003ePlus de %{count}h\u003c/b\u003e sur une semaine.",
  "settings_remuneration_hours_in_zti": "Lorsque la mission se passe dans une \u003cb\u003eZTI\u003c/b\u003e.",
  "settings_remuneration_hours_night_content": "Majoration appliquée lorsqu'un Sider travaille \u003cb\u003eentre %{startTime} et %{endTime}\u003c/b\u003e.",
  "settings_remuneration_hours_night_title": "Heures de nuit",
  "settings_remuneration_hours_normal_content": "Type d'heure par défaut quand aucun autre type d'heure ne s'applique. Le Sider sera payé sur la base du salaire de référence sans majoration.",
  "settings_remuneration_hours_normal_title": "Heures normales",
  "settings_remuneration_hours_not_zti": "Lorsque la mission se passe hors \u003cb\u003eZTI\u003c/b\u003e.",
  "settings_remuneration_hours_see_holidays": "Voir la liste des jours fériés",
  "settings_remuneration_hours_subtitle": "Définissez les règles de rémunération des Siders. Si les règles par défaut ne correspondent pas à celles de votre entreprise, vous pouvez les modifier.",
  "settings_remuneration_hours_sunday_content": "Majoration appliquée lorsqu'un Sider travaille le dimanche.",
  "settings_remuneration_hours_sunday_title": "Heures dimanches",
  "settings_remuneration_hours_table_type": "Type d’heure et conditions d’application",
  "settings_remuneration_hours_table_value": "Majoration",
  "settings_remuneration_hours_title": "Types d’heures",
  "settings_remuneration_hours_types_custom_disclaimer": "Votre configuration spécifique a nécessité un paramétrage manuel de la part de notre équipe (c'est pourquoi vous ne voyez rien s'afficher ici), mais elle a bien été prise en compte. Merci de vous rapprocher de votre contact chez Side en cas de question.",
  "settings_remuneration_hours_validation_button": "Confirmer",
  "settings_remuneration_hours_validation_content": "Avant de pouvoir poster une mission, vérifier les informations les types d'heures et majorations associées sont bien celles qui s'appliquent dans votre entreprise.",
  "settings_remuneration_hours_validation_label": "Les informations ci-dessous sont correctes",
  "settings_remuneration_hours_validation_title": "Valider la configuration de vos types d’heures",
  "settings_remuneration_indemnities_add_label": "Quel type d’indemnité souhaitez-vous ajouter ?",
  "settings_remuneration_indemnities_add_option_lunch": "Indemnité repas",
  "settings_remuneration_indemnities_add_option_other": "Autre",
  "settings_remuneration_indemnities_add_option_transport": "Indemnité transport",
  "settings_remuneration_indemnities_add_placeholder": "Sélectionnez un type d'indémnité",
  "settings_remuneration_indemnities_add_title": "Ajouter une indemnité",
  "settings_remuneration_indemnities_bank_holidays_hours_rate_error_max": "La majoration ne peut excéder +200%",
  "settings_remuneration_indemnities_bank_holidays_hours_rate_error_min": "La majoration doit être supérieure à 0%",
  "settings_remuneration_indemnities_bank_holidays_rate": "Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées sur des jours fériés",
  "settings_remuneration_indemnities_bank_holidays_solidarity": "Sélectionnez votre journée de solidarité (jour férié payé au même tarif qu'un jour normal)",
  "settings_remuneration_indemnities_bank_holidays_solidarity_placeholder": "Journée de solidarité",
  "settings_remuneration_indemnities_bank_holidays_subtitle": "Ce type d’heure s’appliquera par défaut en fonction de ses règles d’utilisation lorqu’aucun autre, mieux rémunéré, ne peut être appliqué. ",
  "settings_remuneration_indemnities_bank_holidays_title": "Heures jours fériés travaillés",
  "settings_remuneration_indemnities_custom_disclaimer": "Votre configuration spécifique a nécessité un paramétrage manuel de la part de notre équipe (c'est pourquoi vous ne voyez rien s'afficher ici), mais elle a bien été prise en compte. Merci de vous rapprocher de votre contact chez Side en cas de question.",
  "settings_remuneration_indemnities_edit_tooltip": "Modifier les paramètres de cette indemnité",
  "settings_remuneration_indemnities_empty": "Vous n'avez pas encore défini de prime ou indemnité",
  "settings_remuneration_indemnities_extra_hour_increase_error_max": "La majoration ne peut exceder +200%",
  "settings_remuneration_indemnities_extra_hour_increase_error_min": "La majoration doit être supérieure à 0%",
  "settings_remuneration_indemnities_extra_hour_min": "Veuillez renseigner au moins 1 seuil",
  "settings_remuneration_indemnities_extra_hour_threshold_format": "Merci de saisir une valeur comprise entre 35 et 48h",
  "settings_remuneration_indemnities_extra_hour_threshold_must_be_higher": "Ce seuil doit être supérieur au précédent",
  "settings_remuneration_indemnities_extra_hours_extra_threshold": "Seuil n°%{number} à partir duquel se déclenchent les heures supplémentaires",
  "settings_remuneration_indemnities_extra_hours_rate": "Majoration (par rapport aux heures normales)",
  "settings_remuneration_indemnities_extra_hours_subtitle": "Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées au delà de la durée hebdomadaire, et donc considérées comme heures supplémentaires",
  "settings_remuneration_indemnities_extra_hours_threshold": "Seuil à partir duquel se déclenchent les heures supplémentaires ?",
  "settings_remuneration_indemnities_extra_hours_threshold_unit": "heures travaillées",
  "settings_remuneration_indemnities_extra_hours_title": "Heures supplémentaires",
  "settings_remuneration_indemnities_help": "Vous ne parvenez pas à configurer l’une de vos indemnités à l’aide des options qui vous sont proposées ? Contactez-nous sur billing@sidetemp.com",
  "settings_remuneration_indemnities_lunch_equivalent_amount": "soit %{amount}€",
  "settings_remuneration_indemnities_lunch_indeminity_option": "Verser une indemnité repas",
  "settings_remuneration_indemnities_lunch_indemnity_label": "Montant de l’indemnité (par jour)",
  "settings_remuneration_indemnities_lunch_indemnity_type": "Versement d’une indemnité repas",
  "settings_remuneration_indemnities_lunch_max_error": "Le montant doit être inférieur à 30 euros",
  "settings_remuneration_indemnities_lunch_min_error": "Le montant doit être supérieur à 0 euros",
  "settings_remuneration_indemnities_lunch_subtitle": "Voici les règles qui s'appliqueront pour les indemnités repas.",
  "settings_remuneration_indemnities_lunch_ticket_type": "Distribution de ticket restaurant (par l’entreprise)",
  "settings_remuneration_indemnities_lunch_title": "Repas",
  "settings_remuneration_indemnities_lunch_tr_option": "Donner des tickets restaurants",
  "settings_remuneration_indemnities_lunch_tr_rate": "Part patronale (montant pris en charge par vous)",
  "settings_remuneration_indemnities_lunch_tr_rate_format_error": "La part patronale doit être un nombre compris entre 0 et 100",
  "settings_remuneration_indemnities_lunch_tr_tip": "Les Tickets restaurants doivent être remis par l’entreprise utilisatrice. Side s'occupera de déduire la part salariale de la paie du salarié et de votre facture. Si vous ne pouvez pas les fournir, choississez \\\"indemnités repas\\\" ci-dessus.",
  "settings_remuneration_indemnities_lunch_tr_value_label": "Valeur du titre repas (par jour)",
  "settings_remuneration_indemnities_lunch_type_error": "Veuillez sélectionner un type d'indemnité repas",
  "settings_remuneration_indemnities_modal_cancel": "Annuler",
  "settings_remuneration_indemnities_modal_delete": "Supprimer cette indemnité",
  "settings_remuneration_indemnities_modal_save": "Enregistrer",
  "settings_remuneration_indemnities_night_hour_end": "Fin des heures de nuit",
  "settings_remuneration_indemnities_night_hour_end_before_start_error": "Les heures de nuit ne peuvent se terminer avant qu'elles ne commencent",
  "settings_remuneration_indemnities_night_hour_end_error": "Les heures de nuit ne peuvent se terminer après 9:00",
  "settings_remuneration_indemnities_night_hour_increase_error": "La majoration ne peut excéder +200%",
  "settings_remuneration_indemnities_night_hour_rate": "Majoration (par rapport aux heures normales)",
  "settings_remuneration_indemnities_night_hour_start": "Début des heures de nuit",
  "settings_remuneration_indemnities_night_hour_start_error": "Les heures de nuit ne peuvent commencer avant 18:00",
  "settings_remuneration_indemnities_night_hour_subtitle": "Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées durant la plage d'horaires que vous définissez être la plage de nuit.",
  "settings_remuneration_indemnities_night_hour_title": "Heures de nuit",
  "settings_remuneration_indemnities_option_button": "Ajouter",
  "settings_remuneration_indemnities_other_amount": "Montant de l’indemnité",
  "settings_remuneration_indemnities_other_category": "Indemnité liée",
  "settings_remuneration_indemnities_other_category_select_placeholder": "Sélectionnez une valeur",
  "settings_remuneration_indemnities_other_events_christmas-bonus_indemnity_title": "Prime de noël",
  "settings_remuneration_indemnities_other_events_indemnity_select_label": "A un événement",
  "settings_remuneration_indemnities_other_events_indemnity_title": "Événement",
  "settings_remuneration_indemnities_other_events_other-bonus_indemnity_title": "Autre",
  "settings_remuneration_indemnities_other_events_year-end-bonus_indemnity_title": "Prime de fin d'année",
  "settings_remuneration_indemnities_other_frequency": "Fréquence d’application",
  "settings_remuneration_indemnities_other_frequency_daily": "Chaque jour",
  "settings_remuneration_indemnities_other_frequency_monthly": "Chaque mois",
  "settings_remuneration_indemnities_other_frequency_once": "Une seule fois",
  "settings_remuneration_indemnities_other_frequency_select_placeholder": "Sélectionnez une valeur",
  "settings_remuneration_indemnities_other_frequency_weekly": "Chaque semaine",
  "settings_remuneration_indemnities_other_labor-agreement_holiday-bonus_indemnity_title": "Prime de vacances",
  "settings_remuneration_indemnities_other_labor-agreement_indemnity_select_label": "À la convention collective",
  "settings_remuneration_indemnities_other_labor-agreement_indemnity_title": "Convention collective",
  "settings_remuneration_indemnities_other_labor-agreement_other-bonus_indemnity_title": "Autre",
  "settings_remuneration_indemnities_other_labor-agreement_thirteenth-month-bonus_indemnity_title": "Prime 13ème mois",
  "settings_remuneration_indemnities_other_other_indemnity_select_label": "À autre chose",
  "settings_remuneration_indemnities_other_other_indemnity_title": "Autre",
  "settings_remuneration_indemnities_other_other_other-bonus_indemnity_title": "Prime exceptionnelle",
  "settings_remuneration_indemnities_other_other_parameters_hours_per_day": "L’indemnité dépend du nombre d’heures travaillées par jour",
  "settings_remuneration_indemnities_other_other_parameters_hours_per_day_label": "Nombre d’heures à effectuer pour bénéficier de l’indemnité",
  "settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit": "heures travaillées",
  "settings_remuneration_indemnities_other_other_parameters_jobtype": "L’indemnité dépend du type de mission",
  "settings_remuneration_indemnities_other_other_parameters_jobtype_label": "Sélectionnez les missions concernées",
  "settings_remuneration_indemnities_other_other_parameters_seniority": "L’indemnité dépend de l’ancienneté",
  "settings_remuneration_indemnities_other_other_parameters_seniority_threshold": "Seuil d’application de l’indemnité",
  "settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit": "jours d'ancienneté (travaillés)",
  "settings_remuneration_indemnities_other_other_parameters_switch": "L’indemnité dépend d'autres paramètres",
  "settings_remuneration_indemnities_other_other_parameters_zti": "L’indemnité s'applique en Zone Touristique Internationale (ZTI)",
  "settings_remuneration_indemnities_other_parameters_hours_per_day_error": "Ce nombre doit être compris entre 1 et 12",
  "settings_remuneration_indemnities_other_parameters_job_types_error": "Vous devez sélectionner au moins un type de mission",
  "settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0": "Ce nombre doit être supérieur à 0",
  "settings_remuneration_indemnities_other_performances_diligence-bonus_indemnity_title": "Prime d'assiduité",
  "settings_remuneration_indemnities_other_performances_indemnity_select_label": "Aux performances",
  "settings_remuneration_indemnities_other_performances_indemnity_title": "Performances",
  "settings_remuneration_indemnities_other_performances_other-bonus_indemnity_title": "Autre",
  "settings_remuneration_indemnities_other_performances_productivity-bonus_indemnity_title": "Prime de rendement",
  "settings_remuneration_indemnities_other_performances_target-based-bonus_indemnity_title": "Prime sur objectifs",
  "settings_remuneration_indemnities_other_subtitle": "Configurez les paramètres d’application de votre indémnité.",
  "settings_remuneration_indemnities_other_title": "Ajouter une indémnité",
  "settings_remuneration_indemnities_other_type": "Nom de la prime",
  "settings_remuneration_indemnities_other_type_error_already_exists": "Vous ne pouvez ajouter cette indemnité car vous en avez déjà défini une du même type. Vous pouvez modifier celle existante si besoin.",
  "settings_remuneration_indemnities_other_type_select_placeholder": "Sélectionnez une valeur",
  "settings_remuneration_indemnities_other_work-condition_cold-bonus_indemnity_title": "Prime de froid",
  "settings_remuneration_indemnities_other_work-condition_hardship-bonus_indemnity_title": "Prime de pénibilité",
  "settings_remuneration_indemnities_other_work-condition_indemnity_select_label": "Au type de travail",
  "settings_remuneration_indemnities_other_work-condition_indemnity_title": "Type de travail",
  "settings_remuneration_indemnities_other_work-condition_other-bonus_indemnity_title": "Autre",
  "settings_remuneration_indemnities_other_work-condition_risk-bonus_indemnity_title": "Prime de risque/danger",
  "settings_remuneration_indemnities_other_work-condition_unhealthy-bonus_indemnity_title": "Prime d'insalubrité ou de salissure",
  "settings_remuneration_indemnities_per_day": "/jour",
  "settings_remuneration_indemnities_per_month": "/mois",
  "settings_remuneration_indemnities_seniority_condition_after": "Après %{duration}.",
  "settings_remuneration_indemnities_seniority_condition_before": "Avant %{duration}.",
  "settings_remuneration_indemnities_seniority_rate": "Majoration (par rapport aux heures normales)",
  "settings_remuneration_indemnities_seniority_switch": "La majoration dépend de l’ancienneté",
  "settings_remuneration_indemnities_seniority_threshold": "Seuil d’application de la majoration",
  "settings_remuneration_indemnities_seniority_unit": "jours d'ancienneté (jours travaillés)",
  "settings_remuneration_indemnities_subtitle": "Définissez les indémnités que peuvent percevoir les Siders et leurs règles d’application (indemnités de repas ou transports, primes)",
  "settings_remuneration_indemnities_sunday_hours_rate": "Majoration (par rapport aux heures normales)",
  "settings_remuneration_indemnities_sunday_hours_rate_error_max": "La majoration ne peut excéder +200%",
  "settings_remuneration_indemnities_sunday_hours_rate_error_min": "La majoration doit être supérieure à 0%",
  "settings_remuneration_indemnities_sunday_hours_subtitle": "Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées les dimanches",
  "settings_remuneration_indemnities_sunday_hours_title": "Heures dimanche",
  "settings_remuneration_indemnities_sunday_hours_zti": "Une majoration spéciale s'applique en Zone Touristique Internationale",
  "settings_remuneration_indemnities_table_type": "Type d’indemnité et conditions d’application",
  "settings_remuneration_indemnities_table_value": "Valeur",
  "settings_remuneration_indemnities_threshold_addition": "Ajouter un seuil",
  "settings_remuneration_indemnities_threshold_deletion_tooltip": "Supprimer ce seuil",
  "settings_remuneration_indemnities_threshold_number": "Seuil n°",
  "settings_remuneration_indemnities_title": "Primes et indemnités",
  "settings_remuneration_indemnities_transport_personal_switch": "Indemniser les trajets domicile - travail en transport personnel",
  "settings_remuneration_indemnities_transport_personal_tip": "S’applique si la résidence habituelle du salarié ou votre lieu de travail sont situés en dehors de la région Île-de-France et d'une zone desservie par les transports urbains, ou que l'utilisation d'un véhicule personne est indispensable en raison des horaires de travail.",
  "settings_remuneration_indemnities_transport_personal_type": "Prise en charge transport personnel",
  "settings_remuneration_indemnities_transport_public_rate": "Taux de prise en charge de l'abonnement aux transports publics ou vélos en libre service",
  "settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred": "Le taux ne peut pas être supérieur à 100%",
  "settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty": "Le taux doit être supérieur ou égal à 50%",
  "settings_remuneration_indemnities_transport_public_type": "Prise en charge transport public",
  "settings_remuneration_indemnities_transport_subtitle": "Paramétrez ci-dessous votre participation aux frais de transport domicile - travail.",
  "settings_remuneration_indemnities_transport_title": "Transport",
  "settings_remuneration_indemnities_validation_button": "Confirmer",
  "settings_remuneration_indemnities_validation_content": "Avant de pouvoir poster une mission, vérifier les informations relatives aux indemnités.",
  "settings_remuneration_indemnities_validation_label": "Les informations ci-dessous sont correctes",
  "settings_remuneration_indemnities_validation_title": "Valider la configuration de vos primes et indemnités",
  "settings_remuneration_indemnities_zti_condition": " si le lieu de la mission se trouve en ZTI",
  "settings_remuneration_non_worked_holidays_help": "Attention : les jours fériés non travaillés sont dûs aux intérimaires sur une base de 7h maximum payées au taux des heures normales.",
  "settings_remuneration_non_worked_holidays_help_link": "Plus d'informations ici.",
  "settings_remuneration_non_worked_holidays_title": "Jours fériés non travaillés",
  "settings_remuneration_title": "Politique de rémunération",
  "settings_submit_success": "Vos changements ont été enregistrés avec succès !",
  "settings_team_add_user": "Inviter un utilisateur",
  "settings_team_add_user_email": "Email",
  "settings_team_add_user_email_placeholder": "Email",
  "settings_team_add_user_firstname": "Prénom",
  "settings_team_add_user_firstname_placeholder": "Prénom",
  "settings_team_add_user_lastname": "Nom",
  "settings_team_add_user_lastname_placeholder": "Nom",
  "settings_team_add_user_role": "Rôle",
  "settings_team_add_user_role_placeholder": "Rôle",
  "settings_team_add_user_success": "Utilisateur invité !",
  "settings_team_deactivate_user": "Retirer l'utilisateur de votre équipe",
  "settings_team_deactivate_user_dialog_content": "Voulez-vous vraiment retirer %{name} de votre équipe ? Il ne pourra plus gérer vos missions en cours ou valider les heures de vos intérimaires.",
  "settings_team_deactivate_user_success": "Utilisateur retiré de votre équipe",
  "settings_team_edit_role": "Modifier le rôle de l'utilisateur",
  "settings_team_edit_user": "Modifier le rôle de l'utilisateur",
  "settings_team_edit_user_role": "Rôle",
  "settings_team_edit_user_role_placeholder": "Rôle",
  "settings_team_edit_user_success": "Utilisateur modifié !",
  "settings_team_profile_description": "Description",
  "settings_team_profile_description_placeholder": "Présentez votre entreprise en quelques mots...",
  "settings_team_profile_media": "Media",
  "settings_team_profile_media_picture": "Utiliser une image",
  "settings_team_profile_media_upload": "Ajouter un fichier",
  "settings_team_profile_media_valid_formats": "Format de fichier acceptés: .jpg, .png",
  "settings_team_profile_media_video": "Utiliser une vidéo youtube",
  "settings_team_profile_name": "Nom de l'entreprise",
  "settings_team_profile_name_placeholder": "Mon entreprise",
  "settings_team_profile_saved": "Vos changements ont été enregistrés",
  "settings_team_profile_upload_logo": "Uploader une image",
  "settings_team_profile_upload_logo_label": "Logo de votre entreprise",
  "settings_team_profile_upload_logo_search_tooltip": "Chercher sur google",
  "settings_team_profile_website": "Site internet",
  "settings_team_profile_website_error": "Votre site internet doit être un URL sécurisé valide (https)",
  "settings_team_profile_website_placeholder": "https://",
  "settings_team_profile_youtube_url": "URL youtube de votre vidéo",
  "settings_team_profile_youtube_url_error": "L'URL doit être un lien youtube valide",
  "settings_team_profile_youtube_url_placeholder": "https://youtube.com...",
  "settings_team_reinvited_success": "Lien ré-envoyé !",
  "settings_team_resend_activation_link": "Renvoyer le lien d'activation",
  "settings_team_role": "Rôle",
  "settings_team_role_admin": "Administrateur",
  "settings_team_role_admin_description": "Tous les droits",
  "settings_team_role_collaborator": "Collaborateur",
  "settings_team_role_collaborator_description": "Validation des heures et évaluation des Siders sur toutes les missions",
  "settings_team_role_description_title": "Description des rôles",
  "settings_team_role_missionManager": "Utilisateur",
  "settings_team_role_missionManager_description": "Configuration des paramètres du compte et administration des missions dont il est gestionnaire",
  "settings_team_title": "Mon équipe",
  "settings_team_user": "Utilisateur",
  "settings_title": "Paramètres",
  "settings_warning_save_dialog_content": "Les modifications que vous avez effectuées seront perdues si vous quittez la page sans enregistrer.",
  "settings_warning_save_dialog_discard": "Quitter",
  "settings_warning_save_dialog_save": "Enregistrer",
  "settings_warning_save_dialog_title": "Vous n’avez pas enregistré vos modifications !",
  "shift_warning_holiday_FAQ_link": "https://help.side.co/hc/fr/articles/360009843398-Jours-f%C3%A9ri%C3%A9s-travaill%C3%A9s-et-non-travaill%C3%A9s-comment-%C3%A7a-marche",
  "shifts_add_shift": "Créer un créneau",
  "shifts_break_105_label": "1h45 min",
  "shifts_break_120_label": "2h",
  "shifts_break_135_label": "2h15 min",
  "shifts_break_150_label": "2h30 min",
  "shifts_break_165_label": "2h45 min",
  "shifts_break_180_label": "3h",
  "shifts_break_195_label": "3h15 min",
  "shifts_break_20_label": "20 min",
  "shifts_break_210_label": "3h30 min",
  "shifts_break_225_label": "3h45 min",
  "shifts_break_240_label": "4h",
  "shifts_break_255_label": "4h15 min",
  "shifts_break_270_label": "4h30 min",
  "shifts_break_285_label": "4h45 min",
  "shifts_break_300_label": "5h",
  "shifts_break_30_label": "30 min",
  "shifts_break_45_label": "45 min",
  "shifts_break_60_label": "60 min",
  "shifts_break_75_label": "1h15 min",
  "shifts_break_90_label": "1h30 min",
  "shifts_break_label": "Pause",
  "shifts_break_none_label": "Aucune",
  "shifts_break_placeholder": "Durée",
  "shifts_date_label": "Date",
  "shifts_duplicate_modal_subtitle": "Sélectionnez les dates pour lesquelles vous souhaitez dupliquer ce créneau",
  "shifts_duplicate_modal_success": "Le ou les créneaux ont bien été dupliqués.",
  "shifts_duplicate_modal_title": "Dupliquer le créneau",
  "shifts_endTime_label": "Fin",
  "shifts_siders_count": "Nb de Siders",
  "shifts_startTime_label": "Début",
  "shifts_summary_column_break": "Pause",
  "shifts_summary_column_date": "Date",
  "shifts_summary_column_total_hours": "Total d'heures",
  "shifts_summary_column_total_number": "Siders",
  "shifts_summary_time_from": "de",
  "shifts_summary_time_to": "à",
  "shifts_warning_7_consecutive": "Vous avez ajouté des créneaux sur 7 jours consécutifs. Nous ne pourrons attribuer tous vos créneaux à la même personne (les journées de travail consécutives sont limitées à 6). ",
  "shifts_warning_breaktime_subtitle": "La loi prévoit un temps de pause de 11h entre 2 jours travaillés (hors accord spécifique). Le cas échéant, nous pourrons positionner deux profils différents.",
  "shifts_warning_breaktime_title": "Temps de repos obligatoire",
  "shifts_warning_new_year_day_subtitle": "Le jour de l'an est férié",
  "shifts_warning_new_year_day_title": "Jour de l'an",
  "shifts_warning_public_holiday_period": "Votre période de mission comprend un jour férié. Les jours fériés non travaillés sont rémunérés au tarif habituel s'ils font partie des jours habituels de mission.",
  "shifts_warning_public_holiday_selected": "Vous avez ajouté un créneau sur un jour férié dont la rémunération est majorée selon votre politique de rémunération. Vérifiez que ce jour est bien travaillé dans votre entreprise.",
  "sider_profile_card_ongoing": "En cours",
  "sider_profile_copy_email": "Copier l'email du Sider",
  "sider_profile_email_copied": "L'email a bien été copié",
  "sider_profile_panel_educations": "Formations",
  "sider_profile_panel_experiences": "Expériences",
  "sider_profile_panel_experiences_industry": "Expérience%{plural} passée%{plural} en %{industryName} (%{count})",
  "sider_profile_panel_experiences_other": "Expérience%{plural} passée%{plural} (%{count})",
  "sider_profile_panel_hours": "%{numberOfHours} heure%{plural}",
  "sider_profile_panel_months": "%{numberOfMonths} mois",
  "sider_profile_panel_tasks": "%{numberOfTasks} mission%{plural}",
  "sider_profile_panel_tasks_company": "Mission%{plural} passée%{plural} chez %{companyName} (%{count})",
  "sider_profile_panel_tasks_ongoing": "Mission%{plural} en cours",
  "sider_profile_panel_tasks_other": "Mission%{plural} passée%{plural} (%{count})",
  "sider_profile_panel_tasks_side": "Missions Side",
  "sider_profile_panel_weeks": "\u003c 1 mois",
  "sider_profile_panel_years": "%{numberOfYears} an%{plural}",
  "sign_in_create_account": "Créer un compte entreprise",
  "sign_in_email_placeholder": "vous@entreprise.com",
  "sign_in_forgot_password": "J'ai oublié mon mot de passe",
  "sign_in_form_title": "Se connecter",
  "sign_in_log_in": "Se connecter",
  "sign_in_logo_company": "Entreprise",
  "sign_in_no_account_yet": "Vous n'avez pas encore de compte entreprise ?",
  "sign_in_or": "ou",
  "sign_in_password_placeholder": "Mot de passe",
  "sign_in_title": "Connectez-vous à Side",
  "sign_in_with_google": "Se connecter avec Google",
  "sign_in_work_on_side": "Je suis un talent",
  "sign_up_accept": "J'accepte",
  "sign_up_company_label": "Nom de l'entreprise",
  "sign_up_company_message_label": "Avez-vous un message pour notre équipe ?",
  "sign_up_company_need_label": "Votre besoin",
  "sign_up_company_need_learn_about_side": "Je cherche à en savoir plus sur Side",
  "sign_up_company_need_less_than_7_days": "Je cherche à recruter rapidement (\u003c 7 jours)",
  "sign_up_company_need_more_than_7_days": "Je cherche à recruter prochainement (\u003e 7 jours)",
  "sign_up_company_need_placeholder": "Je cherche à en savoir plus sur Side",
  "sign_up_company_placeholder": "Le nom de votre entreprise",
  "sign_up_company_size_label": "Nombre d'employés",
  "sign_up_company_size_placeholder": "Nombre d'employés",
  "sign_up_create_account": "Créer un compte ",
  "sign_up_email_label": "Email",
  "sign_up_email_placeholder": "vous@entreprise.com",
  "sign_up_form_subtitle_second_step": "Dites nous-en un peu plus à propos de vous et votre entreprise avant de créer une mission",
  "sign_up_form_title": "Créez votre compte entreprise",
  "sign_up_form_title_second_step": "Une dernière chose...",
  "sign_up_fullname_label": "Prénom et nom",
  "sign_up_fullname_placeholder": "Votre prénom et nom",
  "sign_up_industry_fashion": "Fashion",
  "sign_up_industry_gsa": "Grandes Surfaces Alimentaires",
  "sign_up_industry_gss": "Grandes Surfaces Spécialisées",
  "sign_up_industry_label": "industrie",
  "sign_up_industry_logistics": "Logistique/Transports",
  "sign_up_industry_other": "Autres",
  "sign_up_industry_placeholder": "Votre industrie",
  "sign_up_industry_services": "Services",
  "sign_up_industry_tech": "Technologie",
  "sign_up_log_in": "Connectez-vous",
  "sign_up_logo_company": "Entreprise",
  "sign_up_no_account_yet": "Vous avez déjà un compte entreprise ?",
  "sign_up_or": "ou",
  "sign_up_password_label": "Mot de passe",
  "sign_up_password_placeholder": "Choisissez un mot de passe",
  "sign_up_phone_label": "Numéro de téléphone",
  "sign_up_phone_placeholder": "Votre numéro de téléphone",
  "sign_up_size_to": "à",
  "sign_up_temp_worker": "S'inscrire en tant que travailleur intérimaire",
  "sign_up_terms_and_conditions": "les termes et conditions",
  "sign_up_title": "Créez votre compte entreprise",
  "sign_up_with_google": "Créez un compte entreprise avec Google",
  "sign_up_work_on_side": "Travailler sur Side",
  "signup_error_invalid_email": "Entrez un email valide",
  "slo": "Slovaque",
  "slv": "Slovène",
  "smo": "Samoa",
  "sot": "Sesotho",
  "spa": "Espagnol",
  "sr_alert_disputed": "Vous avez ajusté ces heures, la modification est en cours d'acceptation par le Sider.",
  "sr_alert_forgotten": "%{name} a modifié manuellement le détail de ses heures pour ce créneau. Nous vous conseillons de faire une vérification supplémentaire.",
  "srp": "Serbe",
  "submit": "Soumettre",
  "swa": "Swahili",
  "swe": "Suédois",
  "table_header_end_date": "Date de fin",
  "table_header_start_date": "Date de début",
  "table_header_status": "Statut",
  "table_header_task": "Mission",
  "tam": "Tamoul",
  "task_shifts_add": "Ajouter des créneaux",
  "task_shifts_cost_estimation": "Estimation totale HT",
  "task_shifts_dialog_message": "Cette action impacterait les créneaux de la mission.",
  "task_shifts_dialog_title": "Êtes-vous sûr(e) ?",
  "task_shifts_footer_delete_button": "Supprimer les créneaux",
  "task_shifts_modal_break_label": "Pause",
  "task_shifts_modal_break_placeholder": "Aucune",
  "task_shifts_modal_break_tip_label": "(non rémunérée)",
  "task_shifts_modal_bulk_selection_all_shifts": "Tous les créneaux de la répétition",
  "task_shifts_modal_bulk_selection_label_delete": "Quel(s) créneau(x) souhaitez-vous supprimer ?",
  "task_shifts_modal_bulk_selection_label_edit": "Quel(s) créneau(x) souhaitez-vous éditer ?",
  "task_shifts_modal_bulk_selection_this_shift_and_following": "Ce créneau et les créneaux suivants de la répétition",
  "task_shifts_modal_bulk_selection_this_shift_only": "Ce créneau seulement",
  "task_shifts_modal_creation_title": "Ajouter un ou plusieurs créneaux",
  "task_shifts_modal_date_label": "Date",
  "task_shifts_modal_days_error": "Au moins un jour doit-être sélectionné.",
  "task_shifts_modal_deletion_title": "Supprimer un ou plusieurs créneaux",
  "task_shifts_modal_duration_error": "La durée de travail effectif doit être comprise entre 2h et 10h.",
  "task_shifts_modal_edition_title": "Modifier un ou plusieurs créneaux",
  "task_shifts_modal_label_each": "Chaque",
  "task_shifts_modal_label_every": "Tous les",
  "task_shifts_modal_label_until": "Jusqu'au",
  "task_shifts_modal_option_monthly": "Mois",
  "task_shifts_modal_option_weekly": "Semaine",
  "task_shifts_modal_radio_1_of_the_month": "Premiers",
  "task_shifts_modal_radio_2_of_the_month": "Seconds",
  "task_shifts_modal_radio_3_of_the_month": "Troisièmes",
  "task_shifts_modal_radio_4_of_the_month": "Quatrièmes",
  "task_shifts_modal_radio_last_of_the_month": "Derniers",
  "task_shifts_modal_radio_of_the_month": "du mois",
  "task_shifts_modal_schedule_end_label": "Horaire de fin",
  "task_shifts_modal_schedule_start_label": "Horaire de début",
  "task_shifts_modal_siders_label": "Nombre de Siders",
  "task_shifts_modal_slots_error": "Le nombre de slots doit être compris entre 1 et 50.",
  "task_shifts_modal_switch_holidays": "Inclut les jours fériés",
  "task_shifts_modal_switch_repeat": "Se répète",
  "task_shifts_modal_until_error": "La date de fin doit être postérieure à la date de début.",
  "task_shifts_no_shifts_description": "Ajoutez vos créneaux, individuellement ou en série.",
  "task_shifts_no_shifts_title": "Aucun créneau pour le moment",
  "task_shifts_success_toast_creation": "Le ou les créneaux ont bien été créés.",
  "task_shifts_success_toast_deletion": "Le ou les créneaux ont bien été supprimés.",
  "task_shifts_success_toast_edition": "Le ou les créneaux ont bien été modifiés.",
  "task_shifts_table_break": "Pause",
  "task_shifts_table_hours": "Horaires",
  "task_shifts_table_shifts": "Créneaux",
  "task_shifts_title": "Créneaux",
  "task_status_closed": "Terminé",
  "task_status_done": "Terminé",
  "task_status_draft": "Brouillon",
  "task_status_new": "Nouveau",
  "task_status_ongoing": "En cours",
  "task_status_upcoming": "À venir",
  "te_dispute_add_break": "Ajouter une pause",
  "te_dispute_break": "Pauses",
  "te_dispute_cancel": "Annuler",
  "te_dispute_did_not_work": "Le sider n’a pas travaillé",
  "te_dispute_did_not_work_tip_after_confirmed": "Vous avez déclaré que ce Sider n'a pas travaillé sur ce créneau. L'information a été remontée aux équipes de Side.",
  "te_dispute_did_not_work_tip_confirmation": "Vous êtes sur le point de contester l'ensemble des heures déclarées par le Sider sur ce créneau. Cette action est irréversible.",
  "te_dispute_review_info": "Heures validées par %{managerName} le %{date} à %{hour}.",
  "te_dispute_send": "Envoyer",
  "te_dispute_shift": "Heure de début - Heure de fin",
  "te_dispute_shift_end": "Fin",
  "te_dispute_shift_start": "Début",
  "te_dispute_subtitle": "Modifier les informations n’étant pas correctes.",
  "te_dispute_title": "Heures déclarées",
  "te_tip_disputed_content": "Vous ne pouvez pas modifier ces heures une seconde fois.",
  "te_tip_edit_content": "Si vous êtes en désaccord, modifiez simplement les heures ici.",
  "te_tip_howto_content": "Ces ajustements sont envoyés à %{name} pour validation.",
  "te_tip_review_content": "Ces heures ont été ajustées et sont en cours de validation.",
  "textarea_counter_label": "caractère%{s} restant%{s}",
  "tha": "Thaï",
  "top_menu_dropdown_default": "Tous les collaborateurs",
  "top_menu_filter_all": "Missions",
  "top_menu_filter_all_invoices": "Toutes les factures",
  "top_menu_filter_completed": "Terminées",
  "top_menu_filter_draft": "Brouillons",
  "top_menu_filter_ongoing": "En cours",
  "top_menu_filter_paid_ts": "Payés",
  "top_menu_filter_siders": "Tous les Siders",
  "top_menu_filter_to_be_paid": "Ouverts à la relecture",
  "top_menu_filter_to_review": "À vérifier",
  "top_menu_filter_upcoming": "À venir",
  "top_menu_filter_validated": "Validées",
  "top_menu_planning": "Planning",
  "ts_attendance_disputed_success": "Les heures ont été modifiées avec succès.",
  "ts_confirm_attendances": "Valider les relevés",
  "ts_confirm_attendances_mobile": "Valider",
  "ts_edit_form_break_error_after": "La pause se termine après la fin du créneau, merci de modifier votre saisie.",
  "ts_edit_form_break_error_before": "La pause commence avant le début du créneau, merci de modifier votre saisie.",
  "ts_edit_form_break_title": "Pauses déclarées",
  "ts_edit_form_breaks_add": "Ajouter une pause",
  "ts_edit_form_description": "Éditer les informations n'étant pas correctes.",
  "ts_edit_form_hours_24_hours_error": "Le créneau déclaré est supérieur à 24 heures ! ",
  "ts_edit_form_hours_label": "Début \u0026 fin",
  "ts_edit_form_hours_title": "Heures déclarées",
  "ts_export_csv": "Télécharger le relevé d'heures en CSV",
  "ts_export_csv_error_body": "Veuillez contacter le support pour plus d'informations.",
  "ts_export_csv_error_title": "Le fichier CSV n'a pu être généré.",
  "ts_file_import_button": "Importer",
  "ts_file_import_dropzone_button": "Télécharger un fichier",
  "ts_file_import_dropzone_hint": "Télécharger un csv, maximum 1Mo.",
  "ts_file_import_dropzone_text": "ou glisser/déposer ici",
  "ts_file_import_modal_button": "Importer",
  "ts_file_import_modal_cancel": "Annuler",
  "ts_file_import_modal_column_error": "Colonnes manquantes :",
  "ts_file_import_modal_db_att_duplicate_error": "Les lignes suivantes ont déjà été importés :",
  "ts_file_import_modal_description": "Le fichier doit être au format csv et contenir les colonnes suivantes : siderId, startDate, endDate, breakDuration.",
  "ts_file_import_modal_empty_error": "Les lignes suivantes contiennent des cellules vides :",
  "ts_file_import_modal_end_before_start_error": "Les lignes suivantes contiennent une date de fin antérieure à la date de début :",
  "ts_file_import_modal_file_att_duplicate_error": "Les lignes suivantes sont des doublons d'une autre ligne du fichier, merci de les supprimer avant de réimporter :",
  "ts_file_import_modal_format_error": "Les lignes suivantes contiennent des formats invalides :",
  "ts_file_import_modal_import": "Importer",
  "ts_file_import_modal_import_success": "%{count} relevés d'heures ont été importés avec succès",
  "ts_file_import_modal_shifts_not_found_error": "Les lignes suivantes ne correspondent à aucun shift paramétré :",
  "ts_file_import_modal_sider_id_error": "Les lignes suivantes contiennent des siderIds invalides :",
  "ts_file_import_modal_template_link": "Accéder au template du fichier",
  "ts_file_import_modal_title": "Importer un fichier Excel (.csv uniquement)",
  "ts_file_import_modal_with_error_main_button": "Poursuivre l'import",
  "ts_file_import_modal_with_error_secondary_button": "Importer un autre fichier",
  "ts_file_import_modal_with_error_title": "Ce fichier contient des erreurs. Poursuivre l’import ?",
  "ts_filters_all": "Toutes les dates",
  "ts_filters_day": "Jour",
  "ts_filters_month": "Mois",
  "ts_filters_search_item_empty": "Pas de résultats trouvés",
  "ts_filters_search_manager": "Recherche par nom du Task manager",
  "ts_filters_search_sider": "Recherche par nom du Sider",
  "ts_filters_status": "Statut",
  "ts_filters_type": "Type",
  "ts_filters_type_hours_different_description": " N’affichera que les Siders ayant saisies des heures différentes aux heures prévues lors de la planification de la mission.",
  "ts_filters_type_hours_different_label": "Heures saisies différentes des heures prévues.",
  "ts_filters_type_hours_similar_description": "N’affichera que les Siders ayant saisies des heures similaires aux heures prévues lors de la planification de la mission.",
  "ts_filters_type_hours_similar_label": "Heures saisies similaires aux heures prévues",
  "ts_filters_week": "Semaine",
  "ts_footer_paragraph": "Sélectionnez un/des Siders pour relever leurs heures ou justifier qu'ils n'ont pas travaillé.",
  "ts_footer_selected_sider": "1 relevé sélectionné",
  "ts_footer_selected_siders": "%{count} relevé sélectionnés",
  "ts_footer_sider_didnt_work": "Le Sider n'a pas travaillé",
  "ts_footer_siders_didnt_work": "Les Siders n'ont pas travaillé",
  "ts_footer_tovalidate_attendance": "Valider 1 relevé",
  "ts_footer_tovalidate_attendances": "Valider les %{count} relevés",
  "ts_footer_validate_hours": "Valider les heures",
  "ts_hours_validation_faq_label": "Pourquoi dois-je valider les relevés?",
  "ts_hours_validation_faq_link": "https://help.side.co/hc/fr/articles/360000325737-Comment-v%C3%A9rifier-les-heures-d%C3%A9clar%C3%A9es-par-mes-Siders-",
  "ts_hours_validation_modal_agreement": "Je suis d'accord avec toutes les heures ci-dessus et je les valide définitivement",
  "ts_hours_validation_modal_attendances_count": "Nombre de relevés",
  "ts_hours_validation_modal_failure": "%{count} relevé n’a pas pu être validé. Nous vous invitons à retenter de l'envoyer dans quelques minutes.",
  "ts_hours_validation_modal_failure_plural": "%{count} relevés n’ont pas pu être validés. Nous vous invitons à retenter de les envoyer dans quelques minutes.",
  "ts_hours_validation_modal_submit_button": "Valider et envoyer",
  "ts_hours_validation_modal_submit_button_mobile": "Valider",
  "ts_hours_validation_modal_success": "%{count} relevé a été validé avec succès. ",
  "ts_hours_validation_modal_success_plural": "%{count} relevés ont été validés avec succès. ",
  "ts_hours_validation_modal_tip_content": "Une fois qu'un relevé d'heures est validé, cela signifie que vous ne pouvez plus modifier les heures et que vous vous engagez à les payer à Side",
  "ts_hours_validation_modal_tip_title": "Confirmation",
  "ts_hours_validation_modal_title": "Validation définitive des heures",
  "ts_hours_validation_modal_worked_hours": "Total des heures",
  "ts_modal_confirm_didntwork_content": "Vous vous apprêtez à contester l'ensemble des heures déclarées sur le(s) relevé(s) sélectionné(s). ",
  "ts_modal_confirm_didntwork_tip": "Votre déclaration sera envoyée au Sider pour confirmation. Cette action est irréversible.",
  "ts_modal_confirm_didntwork_title": "Confirmation d'absence",
  "ts_modal_confirm_submit": "Confirmer et envoyer",
  "ts_modal_confirm_validation_content": "Veuillez confirmer que vous souhaitez valider définitivement le(s) relevé(s) sélectionné(s). ",
  "ts_modal_confirm_validation_tip": "Vous ne pouvez plus modifier les relevés validés. Ils apparaîtront dans votre prochaine facture. ",
  "ts_modal_confirm_validation_title": "Confirmation",
  "ts_panel_add_break": "Ajouter une pause",
  "ts_panel_imported_hours": "Heures importées par l'entreprise",
  "ts_panel_scheduled_hours": "Heures prévues",
  "ts_panel_sider_breaks": "Pause%{plural}",
  "ts_panel_sider_didnt_work": "Le Sider n'a pas travaillé",
  "ts_panel_sider_hours": "Heures saisies par le Sider",
  "ts_panel_submit": "Envoyer mes modifications",
  "ts_select": "Sélectionner",
  "ts_selected_attendances_count": "%{count} relevé%{plural} à valider",
  "ts_shifts_break": "de pause",
  "ts_shifts_break_hour": "1 heure de pause",
  "ts_shifts_break_mins": "min de pause",
  "ts_shifts_deadline": "Deadline:",
  "ts_shifts_expected_hours": "environ %{hours} heures ",
  "ts_shifts_hide_entries": "Cacher les heures déjà vérifiés",
  "ts_shifts_in": "Créneaux de la mission",
  "ts_shifts_mark_seen": "Marquer comme lu",
  "ts_shifts_no_time_day": "Aucune heure déclarée le %{date}.",
  "ts_shifts_no_time_shift": "Pas de nouvelles heures déclarées",
  "ts_shifts_show_entries": "Voir les heures déjà vérifiées",
  "ts_shifts_unplanned_day": "Créneaux supplémentaires du",
  "ts_shifts_unplanned_in": "Créneaux supplémentaires pour la mission",
  "ts_table_add_comment": "Ajouter un commentaire sur ce Sider.",
  "ts_table_conflict_company_description": "Vous avez modifié les heures déclarées pour ce créneau. Votre version est en attente de validation par %{siderName}.",
  "ts_table_conflict_company_title": "En attente de validation",
  "ts_table_conflict_insider_description": "Vous avez modifié les heures déclarées pour ce créneau. Votre version est en attente de validation par %{siderName}.",
  "ts_table_conflict_insider_title": "En attente de validation",
  "ts_table_conflict_sider_description": "%{siderName} a refusé votre modification pour ce créneau. Contactez le support pour débloquer la situation.",
  "ts_table_conflict_sider_title": "Version refusée",
  "ts_table_diff_break_description": "Il y a une différence de %{delta} par rapport aux pauses prévues.",
  "ts_table_diff_break_label": "%{delta} de différence",
  "ts_table_diff_hours_description": "Il y a une différence de %{delta} par rapport aux heures prévues.",
  "ts_table_diff_hours_label": "%{delta} de différence",
  "ts_table_edit_comment": "Editer le commentaire",
  "ts_table_head_duration": "Durée ",
  "ts_table_head_duration_tag": "Durée totale saisie par les Siders",
  "ts_table_head_mission": "Mission",
  "ts_table_head_sider_breaks": "Pause(s)",
  "ts_table_head_sider_hours": "Heures saisies",
  "ts_table_head_start_end": "Début \u0026 fin",
  "ts_table_head_status": "Statut",
  "ts_table_no_attendances_description": "Modifiez vos critères de recherche ou revenez plus tard.",
  "ts_table_no_attendances_title": "Aucun créneau à afficher",
  "ts_table_validated_tag_content": "Ce créneau a été validé le %{validationDate}.",
  "ts_table_validated_tag_content_validated_by": "Ce créneau a été validé par %{validatedBy} le %{validationDate}.",
  "ts_table_validated_tag_title": "Créneau validé",
  "ts_tag_disputed": "Contesté",
  "ts_tag_event_log": "Fichier importé le %{date} à %{time}",
  "ts_tag_external_file_origin": "Fichier externe",
  "ts_tag_validated": "Validé",
  "ts_tag_waiting": "À valider",
  "ts_tip_archives_content": "Cet onglet récapitule l'ensemble des paiements envoyés aux Siders. Pour rappel, vous pouvez ajuster les heures déclarées par les Siders et les paiements sont ensuite validés tous les mardi. ",
  "ts_tip_archives_title": "C'est payé !",
  "ts_tip_date_review": "Vous avez jusqu'au %{date} à 22h pour vérifier les relevés d'heures placés ci-dessous. Après cette date, les paiements seront validés automatiquement.",
  "ts_tip_hours_validation_content": "Pour que l'on puisse payer les Siders, vous devez vérifier puis valider définitivement toutes les heures qu'ils ont déclarées",
  "ts_tip_hours_validation_title": "Vérification des heures",
  "ts_tip_review_content": "Gardez le fil de vos relevés d'heures en cochant\u003ci class=\\\"icon-checkmark\\\"\u003e\u003c/i\u003eles créneaux déjà vérifiés.",
  "ts_tip_review_title": "Marquer comme lu",
  "ts_tip_unplanned_content": "Cette partie récapitule les heures travaillées sur des créneaux que vous n'avez initialement pas entrés sur la plateforme.",
  "ts_tip_unplanned_title": "Créneaux supplémentaires",
  "ts_to_validate_blank_state_content": "Vous n'avez aucune heure à vérifier pour le moment.",
  "ts_to_validate_blank_state_title": "C'est tout bon !",
  "ts_unselect_all_attendances": "Tout déselectionner",
  "ts_update": "Modifier",
  "ts_validated_blank_state_content": "Vous n'avez aucun relevé validé pour le moment.",
  "ts_validated_blank_state_title": "Vous êtes tranquille !",
  "ts_warning_cicos_content": "Certains Siders n'ont pas enregistré leurs heures le jour même. Assurez-vous de bien vérifier toutes les heures marquées du tag ",
  "ts_warning_cicos_tab_tooltip": "Certaines heures ont été ajoutées en retard. Nous vous conseillons de faire une vérification supplémentaire.",
  "ts_warning_cicos_tag": "Ajouté en retard",
  "ts_warning_cicos_tag_tooltip": "Ces heures n'ont pas été enregistrées le jour même.",
  "ts_warning_cicos_title": "Certaines heures ont été ajoutées en retard",
  "tur": "Turc",
  "ukr": "Ukrainien",
  "undo": "Annuler",
  "until": "jusqu'à",
  "until_date": "jusqu'au",
  "upcoming_section_button_view_more": "Voir toutes les missions à venir (%{numberOfNotDisplayedUpcoming})",
  "upcoming_section_row_status_cell_subtitle_contact_plural": "Contacter les %{siderNumber} Siders",
  "upcoming_section_row_status_cell_subtitle_contact_singular": "Contacter le Sider",
  "upcoming_section_row_status_cell_subtitle_selection_ongoing": "Fin prévue le %{hiringEndDateDay} à %{hiringEndDateHour}",
  "upcoming_section_row_status_cell_title_selection_almost_done": "%{selectedSiderNumber}/%{totalSidersNumberExpected} Siders sélectionnés",
  "upcoming_section_row_status_cell_title_selection_done": "Sélection terminée",
  "upcoming_section_row_status_cell_title_selection_ongoing": "Sélection en cours",
  "upcoming_section_row_task_cell_managed_by": "Gérée par ",
  "upcoming_section_title": "Missions à venir",
  "update_manager": "Modifier le manager",
  "urd": "Ourdou",
  "use": "Utiliser",
  "uzb": "Ouzbek",
  "various_geolocation_multiple_places": " lieux différents",
  "various_geolocation_online": "En ligne",
  "various_time_deadline": "Date limite",
  "vie": "Vietnamien",
  "view_invoices": "Voir les factures \u0026 paiements",
  "view_siders": "Voir les Siders",
  "view_task": "Voir la mission",
  "week": "Semaine",
  "yes": "Oui",
  "zgh": "Amazigh (berbère)"
}

export default translation;