import i18n from "i18n-js";
import { ModalSimple, SummaryTableRow } from "side-ui";

// import './OrderDetailsModal.scss';

const OrderDetailsModal = ({ order, hideModal }) => {
    const name = order?.name;
    const jobTitle = order?.job?.jobTitle;
    const subtasks = order?.job?.subtasks?.map((subtask) => subtask.name).join(", ");
    const context = order?.workConditions?.context;
    const safetyEquipmentList = order?.workConditions?.safetyEquipmentList
        ?.map((safetyEquipment) =>
            i18n.t(safetyEquipment.name, { defaultValue: safetyEquipment.name }),
        )
        .join(", ");
    const devices = order?.workConditions?.devices
        ?.map((device) => i18n.t(device.name, { defaultValue: device.name }))
        .join(", ");
    const dressCode = order?.workConditions?.dressCode;
    const risks = (order?.workConditions?.risks || order?.workConditions?.aboutRisks) && (
        <div>
            <p>{order?.workConditions?.risks?.map((risk) => i18n.t(risk.name)).join(", ")}</p>
            <p>{order?.workConditions?.aboutRisks}</p>
        </div>
    );
    const languages = order?.skills?.languages
        ?.map((language) => i18n.t(language.ISOCode))
        .join(", ");
    const tools = order?.skills?.tools
        ?.map((tool) => i18n.t(tool.name, { defaultValue: tool.name }))
        .join(", ");
    const licences = order?.skills?.licences?.map((licence) => i18n.t(licence.name)).join(", ");
    const experiences = order?.skills?.experiences;
    const address = order?.location?.address;
    const manager = (
        <div className='job-description__summary__manager'>
            {order?.manager ? (
                <div>
                    <span>{`${order?.manager?.firstName} ${order?.manager?.lastName}`}</span>
                </div>
            ) : (
                <span>{i18n.t(`manager-form_choice_later`)}</span>
            )}
        </div>
    );

    const hourlyRate = (
        <div className='job-description__summary__salary'>
            <p className='job-description__summary__salary__label'>
                {i18n.t("job-descriptions_creation_salary_raw")}
            </p>
            <p className='job-description__summary__salary__value'>{order?.hourlyRate}€</p>
        </div>
    );

    const orderDetailsTable = [
        {
            label: i18n.t("job-descriptions_summary_jobTitle_title"),
            content: jobTitle || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_subtasks_title"),
            content: subtasks || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_context_title"),
            content: context || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_safetyEquipment_title"),
            content: safetyEquipmentList || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_devices_title"),
            content: devices || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_dresscode_title"),
            content: dressCode || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_risks_title"),
            content: risks || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_languages_title"),
            content: languages || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_tools_title"),
            content: tools || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_licences_title"),
            content: licences || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_experiences_title"),
            content: experiences || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_location_title"),
            content: address || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_manager_title"),
            content: manager || "-",
        },
        {
            label: i18n.t("job-descriptions_summary_salary_title"),
            content: hourlyRate || "-",
        },
    ];
    return (
        <ModalSimple title={name} hideModal={hideModal} withCloseButton={true}>
            {orderDetailsTable.map(
                ({ label, content }) =>
                    content &&
                    label && <SummaryTableRow key={label} label={label} content={content} />,
            )}
        </ModalSimple>
    );
};

export default OrderDetailsModal;
