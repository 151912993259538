import { connect } from "react-redux";

import { countContractsBatches } from "../../store/contracts";
import { getNumberOfContractsBatchesToSign } from "../../store/selectors/contracts";

import Navigation from "./Navigation";

const mapStateToProps = (state) => ({
    tasks: state.tasks,
    contracts: state.contracts,
    invoices: state.invoices,
    toSignCount: getNumberOfContractsBatchesToSign(state),
});

const mapDispatchToProps = (dispatch) => ({
    countContractsBatchesToSign: () => dispatch(countContractsBatches()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
