import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type ConfirmTaskParams = {
    taskId: string;
    sideNote: string;
};
export function confirmTask(params: ConfirmTaskParams) {
    debug.addBreadcrumb({
        message: `Confirm Task`,
        category: `action`,
        data: {
            taskId: params.taskId,
        },
    });

    return fetch(
        withAuthHeaders(`${apiConf.BACK_URL}/tasks/${params.taskId}/confirm`, {
            method: `POST`,
            body: JSON.stringify({
                sideNote: params.sideNote,
            }),
        }),
    );
}
