import i18n from "i18n-js";
import * as yup from "yup";

export const legalInfoValidationSchema = () => {
    const schemaDef = {
        name: yup.string().required(i18n.t(`error_field_is_required`)),
        businessName: yup.string(),
        siret: yup
            .string()
            .matches(/^((\d{3}) ){3}(\d{5})/, i18n.t(`settings_legal_error_siret_format`))
            .max(17, i18n.t(`settings_legal_error_siret_format`))
            .required(i18n.t(`error_field_is_required`)),
        apeCode: yup
            .string()
            .matches(/^[0-9]+ [a-zA-Z]/, i18n.t(`settings_legal_error_ape_format`))
            .max(6, i18n.t(`settings_legal_error_siret_format`))
            .required(i18n.t(`error_field_is_required`)),
    };
    return yup.object().shape(schemaDef);
};

export const submitLegalInfo = async (
    values,
    { setSubmitting, resetForm },
    updateCompanyLegalInfo,
) => {
    const dataToSend = {
        name: values.name,
        businessName: values.businessName?.length ? values.businessName : values.name,
        siret: values.siret,
        apeCode: values.apeCode,
    };
    const dataToStore = {
        name: values.name,
        businessName: values.businessName,
        siret: values.siret,
        apeCode: values.apeCode,
    };
    updateCompanyLegalInfo(dataToSend, dataToStore)
        .then(() => {
            setSubmitting(false);
            resetForm(values);
        })
        .catch(() => {
            setSubmitting(false);
        });
};
