import { z } from "zod";

import { debug } from "@side.co/client-debug";

import { apiRequest } from "./apiHelpers/apiRequest";
import { apiConf } from ".";

const uploadSchema = z.object({
    url: z.string(),
});

export async function upload({ type, id, file }: { type: string; id: string; file: FormData }) {
    debug.addBreadcrumb({
        message: `upload`,
    });
    const url = `${apiConf.BACK_URL}/upload/${type}/${id}`;
    return apiRequest({
        url,
        responseSchema: uploadSchema,
        fetchOptions: {
            method: "POST",
            body: file,
        },
    });
}
