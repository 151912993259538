import { queryClient } from "@App";
import { updateTask } from "@lib/api";
import { useMutation } from "@tanstack/react-query";

export function usePatchTask(options?: {
    onMutate?: (variables: Parameters<typeof updateTask>[0]) => void;
    onSuccess?: () => void;
    onSettled?: () => void;
}) {
    const { mutate: patchTask, isPending } = useMutation({
        mutationFn: (args: Parameters<typeof updateTask>[0]) => {
            return updateTask(args);
        },
        onSuccess: () => {
            if (options && options.onSuccess) {
                options.onSuccess();
            }
        },
        onSettled: () => {
            if (options && options.onSettled) {
                options.onSettled();
            } else {
                queryClient.invalidateQueries({ queryKey: ["task"] });
            }
        },
        onMutate: async (variables) => {
            if (options && options.onMutate) {
                options.onMutate(variables);
            }
        },
    });

    return { patchTask, isPending };
}
