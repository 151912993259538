import { z } from "zod";

import { debug } from "@side.co/client-debug";

import { apiRequest } from "./apiHelpers/apiRequest";
import { apiConf } from "./index";

const deleteCompanyMotiveSchema = z
    .object({
        id: z.string(),
    })
    .strict();

type DeleteCompanyMotiveParams = z.infer<typeof deleteCompanyMotiveSchema>;

// 204 No Content
const postMotiveResSchema = z.undefined();

export async function deleteCompanyMotive(params: DeleteCompanyMotiveParams, signal?: AbortSignal) {
    debug.addBreadcrumb({
        message: `deleteCompanyMotive`,
        data: params,
    });
    const url = `${apiConf.BACK_URL}/motives/${params.id}`;
    const validatedParams = deleteCompanyMotiveSchema.parse(params);
    return apiRequest({
        url,
        responseSchema: postMotiveResSchema,
        fetchOptions: {
            method: `DELETE`,
            body: JSON.stringify(validatedParams),
            signal,
        },
    });
}
