import { connect } from "react-redux";

import { confirmHoursTypes } from "../../../../store/company";
import { areHoursTypesConfirmed } from "../../../../store/selectors/company";

import HoursTypes from "./HoursTypes";

const mapStateToProps = (state) => ({
    areHoursTypesConfirmed: areHoursTypesConfirmed(state),
});

const mapDispatchToProps = (dispatch) => ({
    confirmHoursTypes: () => dispatch(confirmHoursTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HoursTypes);
