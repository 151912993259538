import { Component } from "react";
import i18n from "i18n-js";

import BlankTab from "../../../components/BlankTab";

import ContractsBatchRow from "./ContractsBatchRow";

import "./ContractsBatchesList.scss";

class ContractsBatchesList extends Component {
    render() {
        const { contractsBatches } = this.props;

        if (!contractsBatches || (contractsBatches && !contractsBatches.length)) {
            let emptyTitle = i18n.t(`contracts_batches_tosign_blank_state_title`);
            let emptyContent = i18n.t(`contracts_batches_tosign_blank_state_content`);
            if (window.location.pathname === "/contracts/archives") {
                emptyTitle = i18n.t(`contracts_batches_signed_blank_state_title`);
                emptyContent = i18n.t(`contracts_batches_signed_blank_state_content`);
            }

            return <BlankTab title={emptyTitle} subtitle={emptyContent} />;
        }

        const sortedContractsBatches =
            contractsBatches &&
            contractsBatches.sort((contract1, contract2) => {
                const date1 = contract1.startDate;
                const date2 = contract2.startDate;
                if (date1 > date2) return -1;
                if (date1 < date2) return 1;
                return 0;
            });
        return (
            <div className='contracts-list'>
                <div className='row contracts-list__headers m-hidden'>
                    <div className='col-xs-6 col-sm-4 contracts-list__col'>
                        {i18n.t(`contracts_batches_list_header_task`)}
                    </div>
                    <div className='col-xs-6 col-sm-3 contracts-list__col'>
                        {i18n.t(`contracts_batches_list_header_manager`)}
                    </div>
                    <div className='col-sm-2 contracts-list__col align-center'>
                        {i18n.t(`contracts_batches_list_header_number`)}
                    </div>
                    <div className='col-sm-2 contracts-list__col align-center'>
                        {i18n.t(`contracts_batches_list_header_status`)}
                    </div>
                    <div className='col-sm-2 contracts-list__col align-left'>
                        {i18n.t(`contracts_batches_list_header_date`)}
                    </div>
                    <div className='col-sm-1 contracts-list__col align-center'></div>
                </div>
                {/* This div is here to allow us to have a first and last child */}
                <div>
                    {sortedContractsBatches &&
                        sortedContractsBatches.length &&
                        sortedContractsBatches.map((contractsBatch, i) => (
                            <ContractsBatchRow
                                key={contractsBatch.contractsIds.join("-") + i}
                                {...contractsBatch}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default ContractsBatchesList;
