import { differenceInCalendarDays } from "date-fns";

import { sumShiftBreaks } from "../../../../../utils";

import AttendanceEditBreak from "./AttendanceEditBreak/AttendanceEditBreak";
import AttendanceEditHours from "./AttendanceEditHours/AttendanceEditHours";

const AttendanceEdit = ({
    editAttendanceHours,
    editAttendanceBreaks,
    currentVersion,
    slotConflict,
    breakConflict,
    readOnly,
}) => {
    const startDate = new Date(currentVersion.startDate);
    const endDate = new Date(currentVersion.endDate);

    return (
        <div className='timesheets-table__panel__attendance__edit__wrapper__form'>
            <div className='timesheets-table__panel__attendance__edit__wrapper__form__item'>
                <AttendanceEditHours
                    // we still want to display informations
                    // but disable update actions
                    readOnly={readOnly}
                    startTime={startDate}
                    endTime={endDate}
                    editHours={editAttendanceHours}
                    slotConflict={slotConflict}
                />
            </div>

            <div className='timesheets-table__panel__attendance__edit__wrapper__form__item'>
                <AttendanceEditBreak
                    // we still want to display informations
                    // but disable update actions
                    readOnly={readOnly}
                    breaks={currentVersion?.breaks}
                    startTime={new Date(currentVersion.startDate)}
                    endTime={new Date(currentVersion.endDate)}
                    editBreaks={editAttendanceBreaks}
                    breakConflict={breakConflict}
                    spanOverOneDay={Boolean(differenceInCalendarDays(endDate, startDate))}
                    breakDuration={{
                        value: sumShiftBreaks(currentVersion?.breaks),
                        label: `${sumShiftBreaks(currentVersion?.breaks)}min`,
                    }}
                />
            </div>
        </div>
    );
};

export default AttendanceEdit;
