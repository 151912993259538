import { memo } from "react";
import { UserWithTag } from "side-ui";

type SiderCellProps = {
    pictureUrl?: string;
    firstName: string;
    lastName: string;
};

const SiderCell = memo(function SiderCell({
    // eslint-disable-line
    pictureUrl = "https://s3.eu-west-3.amazonaws.com/in.side-assets/placeholder-sider.png",
    firstName,
    lastName,
}: SiderCellProps) {
    const siderName = `${firstName} ${lastName}`;

    return (
        <span className='timesheets-table__cell__sider'>
            <UserWithTag pictureUrl={pictureUrl} userName={siderName} />
        </span>
    );
});

export default SiderCell;
