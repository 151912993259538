import { useEffect, useState } from "react";
import i18n from "i18n-js";
import { Blank, Loader, ToastsService } from "side-ui";

import { useOrganisationType } from "@lib/hooks";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useJobDescriptions } from "@routes/JobDescriptions/hooks/useJobDescriptions";
import { useQuery } from "@tanstack/react-query";

import { JobDescriptionList } from "../JobDescriptionList/JobDescriptionList";
import { JobDescriptionPanel } from "../JobDescriptionPanel/JobDescriptionPanel";

import styles from "./JobDescriptions.module.css";

export const JobDescriptions = () => {
    const { data: company } = useQuery(queries.company.detail());
    const [panelMode, setPanelMode] = useState<"create" | "edit" | "summary" | "">("");
    const {
        jdid,
        isLoading,
        jobDescription,
        jobDescriptions,
        setJobDescription,
        createJobDescriptionMutation,
        duplicateJobDescriptionMutation,
    } = useJobDescriptions();
    const { organisationType, networkId, accountId } = useOrganisationType();

    const { data: user } = useQuery(queries.user.detail());

    useEffect(() => {
        if (!jobDescription) return;
        if (jobDescription?.hourlyRate && jobDescription?.executiveStatus) {
            showJobDescriptionSummary(jobDescription);
        } else {
            showContinueJobDescriptionCreation(jobDescription);
        }
    }, [jobDescription?.id]);

    useEffect(() => {
        const escapeListener = (e: KeyboardEvent) => {
            if (jdid && e.key === "Escape") setJobDescription();
        };
        window.addEventListener("keydown", escapeListener);
        return () => window.removeEventListener("keydown", escapeListener);
    }, []);

    const showJobDescriptionSummary = (jobDescription) => {
        setPanelMode("summary");
        setJobDescription(jobDescription.id);
    };

    const showJobDescriptionEdit = (jobDescription) => {
        setPanelMode("edit");
        setJobDescription(jobDescription.id);
    };

    const showJobDescriptionCreation = () => {
        createJobDescriptionMutation(undefined);
        setPanelMode("create");

        trackEvent({
            name: "job-descriptions - create job description",
            params: {
                source: organisationType,
                organisationId: accountId || networkId,
            },
        });
    };

    const showContinueJobDescriptionCreation = (jobDescription) => {
        setPanelMode("create");
        setJobDescription(jobDescription.id);
    };

    const saveAndSwitchToSummary = (source) => {
        setPanelMode("summary");
        if (source === "create") {
            ToastsService.addToast({
                id: "jobDescCreateSuccess",
                icon: "Checkmark",
                content: i18n.t("job-description_creation_success"),
                type: "confirmation",
            });
        }

        if (source === "edit") {
            ToastsService.addToast({
                id: "jobDescEditSuccess",
                icon: "Checkmark",
                content: i18n.t("job-description_edit_success"),
                type: "confirmation",
            });
        }
    };

    const duplicateJobDescription = (jobDescription) => {
        if (!jobDescription.id) return;

        duplicateJobDescriptionMutation(jobDescription);
        setPanelMode("summary");

        trackEvent({
            name: "job-descriptions - create job description",
            params: {
                source: organisationType,
                organisationId: accountId || networkId,
            },
        });
    };

    if (isLoading) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        );
    }

    if (jobDescriptions.length === 0 && !isLoading) {
        return (
            <div className={styles.blank}>
                <Blank
                    title={i18n.t(`job-descriptions_empty_title`)}
                    subtitle={
                        <>
                            {i18n.t(`job-descriptions_empty_subtitle`)}
                            .&nbsp;
                            <a href='https://help.side.co/hc/fr/articles/360000263378-Comment-poster-une-mission-sur-Side-'>
                                {i18n.t(`job-descriptions_empty_subtitle_link_label`)}
                            </a>
                        </>
                    }
                    icon='Bolt'
                    iconColor='blue'
                    buttonLabel={i18n.t(`job-descriptions_empty_create_button`)}
                    buttonColor='blue'
                    buttonAction={showJobDescriptionCreation}
                    buttonLeftIcon='Plus'
                />
            </div>
        );
    }

    const userHasAccessToTheNetwork = !!networkId;
    const networkJobDescriptions = jobDescriptions.filter(
        (jobDescription) => jobDescription.origin === "network",
    );
    const accountJobDescriptions = jobDescriptions.filter(
        (jobDescription) => jobDescription.origin === "account",
    );
    const customPricingExists = jobDescriptions.some((jd) => jd?.pricingOrigin === "detached");

    return (
        <div className={styles.jobDescriptions}>
            <div className={styles.header}>
                <h1 className={styles.title}>{i18n.t(`job-descriptions_jobtitle`)}</h1>
                <p className={styles.instructions}>
                    {i18n.t(`job-descriptions_instructions_${organisationType}`)}
                </p>
            </div>

            {(organisationType == "network" ||
                (organisationType == "account" && networkJobDescriptions.length === 0)) && (
                <JobDescriptionList
                    jobDescriptions={jobDescriptions}
                    organisationType={organisationType}
                    jobDescriptionsOrigin={organisationType}
                    user={user!}
                    showJobDescriptionCreation={showJobDescriptionCreation}
                    unauthorized={customPricingExists && !user?.isInsider}
                    unauthorizedTitle={i18n.t(`action_unauthorized`)}
                    unauthorizedDescription={i18n.t(`job-descriptions_unauthorized_custom`)}
                />
            )}

            {organisationType == "account" && networkJobDescriptions.length > 0 && (
                <>
                    <JobDescriptionList
                        title={company?.parent || company?.organisation.name}
                        description={i18n.t(`job-descriptions_list_network_description`)}
                        // We only display the network job-descriptions that
                        // are not drafts
                        jobDescriptions={networkJobDescriptions.filter((jd) => !!jd?.hourlyRate)}
                        organisationType={organisationType}
                        jobDescriptionsOrigin='network'
                        user={user!}
                        showJobDescriptionCreation={showJobDescriptionCreation}
                        unauthorized={true}
                        unauthorizedTitle={
                            // This component displays network job-descriptions, if the user has access to the network
                            // space, we indicate the user to update them from there.
                            userHasAccessToTheNetwork
                                ? i18n.t(`job-descriptions_unauthorized_network_redirect_title`)
                                : i18n.t(`action_unauthorized`)
                        }
                        unauthorizedDescription={
                            // This component displays network job-descriptions, if the user has access to the network
                            // space, we indicate the user to update them from there.
                            userHasAccessToTheNetwork
                                ? i18n.t(
                                      `job-descriptions_unauthorized_network_redirect_description`,
                                  )
                                : i18n.t(`job-descriptions_unauthorized_network`)
                        }
                    />
                    <JobDescriptionList
                        title={user?.organisation.name}
                        jobDescriptions={accountJobDescriptions}
                        organisationType={organisationType}
                        jobDescriptionsOrigin='account'
                        user={user!}
                        showJobDescriptionCreation={showJobDescriptionCreation}
                        unauthorized={customPricingExists && !user?.isInsider}
                        unauthorizedTitle={i18n.t(`action_unauthorized`)}
                        unauthorizedDescription={i18n.t(`job-descriptions_unauthorized_custom`)}
                    />
                </>
            )}
            <JobDescriptionPanel
                mode={panelMode}
                duplicateJobDescription={duplicateJobDescription}
                saveAndSwitchToSummary={saveAndSwitchToSummary}
                showJobDescriptionEdit={showJobDescriptionEdit}
                organisationType={organisationType}
                unauthorized={
                    (customPricingExists && !user?.isInsider) ||
                    jobDescription?.origin === "network" ||
                    user?.role === "collaborator"
                }
                unauthorizedTitle={
                    // This component displays network job-descriptions, if the user has access to the network
                    // space, we indicate the user to update them from there.
                    user?.role !== "collaborator" &&
                    userHasAccessToTheNetwork &&
                    jobDescription?.origin === "network"
                        ? i18n.t(`job-descriptions_unauthorized_network_redirect_title`)
                        : i18n.t(`action_unauthorized`)
                }
                unauthorizedDescription={
                    // This component displays network job-descriptions, if the user has access to the network
                    // space, we indicate the user to update them from there.
                    user?.role === "collaborator" || !userHasAccessToTheNetwork
                        ? i18n.t("job-descriptions_unauthorized_description")
                        : userHasAccessToTheNetwork && jobDescription?.origin === "network"
                        ? i18n.t(`job-descriptions_unauthorized_network_redirect_description`)
                        : i18n.t(`job-descriptions_unauthorized_custom`)
                }
            />
        </div>
    );
};
