import { useEffect, useRef } from "react";
import clsx from "clsx";
import I18n from "i18n-js";
import { atom, useAtom, useSetAtom } from "jotai";
import { Loader } from "side-ui";
import { DocumentLines, fonts } from "sui";

import { Task } from "@lib/api";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";

import { taskFormRoute } from "../route";
import { disabledSubmitAtom } from "../TaskFormFooter";

import { Blank } from "./Blank/Blank";
import { ShiftModal as ShiftModalOld } from "./ShiftModal/ShiftModal";
import { ShiftModal as ShiftModalEditFlag } from "./ShiftModal/ShiftModalEditFlag";
import { ShiftsTable } from "./ShiftsTable/ShiftsTable";

import styles from "./ShiftsStep.module.css";

type ModalState = {
    open: boolean;
    mode: "creation" | "deletion" | "edition";
    shift: null | Task["shifts"][number];
};
export const modalShiftsStateAtom = atom<ModalState>({
    open: false,
    mode: "creation",
    shift: null,
});
export function ShiftsStep() {
    const { taskId } = taskFormRoute.useParams();
    const [modalState, setModalState] = useAtom(modalShiftsStateAtom);
    const setDisabledSubmit = useSetAtom(disabledSubmitAtom);
    const { data: task, isLoading: isTaskLoading } = useQuery(queries.task.detail(taskId));
    const modalRef = useRef<HTMLDialogElement>(null);
    const shiftsLength = task?.shifts?.length;

    useEffect(() => {
        setDisabledSubmit(!shiftsLength);
    }, [shiftsLength]);

    useEffect(() => {
        if (modalState.open) {
            modalRef.current?.showModal();
        }
    }, [modalState.open]);

    const editFlagEnabled = useFlag("team-posting-shift-recurrency-edition");

    return (
        <div className={clsx(styles.shiftsStep, !task?.shifts.length && styles.blank)}>
            {!shiftsLength && isTaskLoading && <Loader />}
            {shiftsLength ? (
                <>
                    <div className={styles.heading}>
                        <div className={styles.icon}>
                            <DocumentLines />
                        </div>
                        <h2 className={fonts.sans22Regular}>
                            {I18n.t("task_shifts_title")} <span>({task?.shifts.length})</span>
                        </h2>
                    </div>
                    <ShiftsTable
                        shifts={task?.shifts}
                        openModal={(mode: "edition" | "deletion", shift) => {
                            setModalState({ open: true, mode, shift });
                            modalRef.current?.showModal();
                        }}
                    />
                </>
            ) : (
                <Blank
                    openModal={() => {
                        setModalState({ open: true, mode: "creation", shift: null });
                        modalRef.current?.showModal();
                    }}
                />
            )}
            {editFlagEnabled ? (
                <ShiftModalEditFlag
                    key={Number(modalState.open)}
                    ref={modalRef}
                    motiveReason={task?.motive?.reason}
                />
            ) : (
                <ShiftModalOld
                    key={Number(modalState.open)}
                    ref={modalRef}
                    motiveReason={task?.motive?.reason}
                />
            )}
        </div>
    );
}
