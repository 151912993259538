import { Component } from "react";
import { FieldArray, Formik, getIn } from "formik";
import i18n from "i18n-js";
import { Button, InputNumber, ModalSimple, ModalsService } from "side-ui";

import FormCard from "../../../../components/FormCard";
import { normalizeNumber } from "../../../../lib/utils/inputsNormalizers";

import { extraHoursValidationSchema, submitExtraHours } from "./remunerationFormsValidation";

class ExtraHoursForm extends Component {
    getInputs(threshold, index, values, touched, errors, handleBlur, setFieldValue) {
        return (
            <div>
                <div className='indemnity__field'>
                    <InputNumber
                        id={`thresholds[${index}].threshold`}
                        onChange={(e) =>
                            onFieldChange(
                                `thresholds[${index}].threshold`,
                                e.target.value,
                                values.thresholds[index].threshold,
                                setFieldValue,
                            )
                        }
                        label={
                            index === 0
                                ? i18n.t(`settings_remuneration_indemnities_extra_hours_threshold`)
                                : i18n.t(
                                      `settings_remuneration_indemnities_extra_hours_extra_threshold`,
                                      { number: index + 1 },
                                  )
                        }
                        placeholder={50}
                        onBlur={handleBlur}
                        smallInput={true}
                        value={values.thresholds[index].threshold}
                        error={
                            getIn(touched, `thresholds[${index}].threshold`) &&
                            getIn(errors, `thresholds[${index}].threshold`)
                        }
                        hint={i18n.t(
                            `settings_remuneration_indemnities_extra_hours_threshold_unit`,
                        )}
                    />
                </div>
                <div className='indemnity__field'>
                    <InputNumber
                        id={`thresholds[${index}].increase`}
                        onChange={(e) =>
                            onFieldChange(
                                `thresholds[${index}].increase`,
                                e.target.value,
                                values.thresholds[index].increase,
                                setFieldValue,
                            )
                        }
                        label={i18n.t(`settings_remuneration_indemnities_extra_hours_rate`)}
                        placeholder={50}
                        unit='%'
                        prefix='+'
                        onBlur={handleBlur}
                        smallInput={true}
                        value={values.thresholds[index].increase}
                        error={
                            getIn(touched, `thresholds[${index}].increase`) &&
                            getIn(errors, `thresholds[${index}].increase`)
                        }
                        customClass='indemnity__small-input'
                    />
                </div>
            </div>
        );
    }

    render() {
        const { extraHours, updateHourType } = this.props;

        const initialVal = {
            thresholds: extraHours.thresholds || [{ threshold: "", increase: "" }],
        };

        return (
            <Formik
                initialValues={initialVal}
                onSubmit={(values, actions) =>
                    submitExtraHours(values, actions, initialVal, updateHourType)
                }
                validationSchema={extraHoursValidationSchema()}
                validateOnChange={false}
                validateOnBlur={true}
                render={({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    submitForm,
                    isSubmitting,
                    initialValues,
                    setFieldValue,
                }) => {
                    const lastThreshold = values.thresholds[values.thresholds.length - 1];
                    return (
                        <ModalSimple
                            action={submitForm}
                            validateLabel={i18n.t(`settings_remuneration_indemnities_modal_save`)}
                            cancelLabel={i18n.t(`settings_remuneration_indemnities_modal_cancel`)}
                            title={i18n.t(`settings_remuneration_indemnities_extra_hours_title`)}
                            subtitle={i18n.t(
                                `settings_remuneration_indemnities_extra_hours_subtitle`,
                            )}
                            hideModal={() => ModalsService.closeModal(`EXTRA_HOURS_FORM`)}
                        >
                            <FieldArray
                                name='thresholds'
                                render={(arrayHelpers) => (
                                    <div>
                                        {values.thresholds.map((threshold, index) => (
                                            <div key={index} className='indemnity__field'>
                                                {index === 0 ? (
                                                    this.getInputs(
                                                        threshold,
                                                        index,
                                                        values,
                                                        touched,
                                                        errors,
                                                        handleBlur,
                                                        setFieldValue,
                                                    )
                                                ) : (
                                                    <FormCard
                                                        title={
                                                            i18n.t(
                                                                `settings_remuneration_indemnities_threshold_number`,
                                                            ) +
                                                            (index + 1)
                                                        }
                                                        tooltipLabel={i18n.t(
                                                            `settings_remuneration_indemnities_threshold_deletion_tooltip`,
                                                        )}
                                                        iconAction={() =>
                                                            arrayHelpers.remove(index)
                                                        }
                                                    >
                                                        {this.getInputs(
                                                            threshold,
                                                            index,
                                                            values,
                                                            touched,
                                                            errors,
                                                            handleBlur,
                                                            setFieldValue,
                                                        )}
                                                    </FormCard>
                                                )}
                                            </div>
                                        ))}
                                        <Button
                                            color='blue'
                                            iconBefore='PlusXL'
                                            disabled={
                                                lastThreshold &&
                                                (!lastThreshold.increase ||
                                                    !lastThreshold.threshold)
                                            }
                                            action={() =>
                                                arrayHelpers.push({ threshold: "", increase: "" })
                                            }
                                        >
                                            {i18n.t(
                                                `settings_remuneration_indemnities_threshold_addition`,
                                            )}
                                        </Button>
                                    </div>
                                )}
                            />
                        </ModalSimple>
                    );
                }}
            />
        );
    }
}

const onFieldChange = (fieldName, value, prevValue, setFieldValue) => {
    let newValue = value;
    if (fieldName.indexOf("threshold") !== -1) {
        newValue = normalizeNumber(value, prevValue);
    } else if (fieldName.indexOf("increase") !== -1) {
        newValue = normalizeNumber(value, prevValue);
    }
    setFieldValue(fieldName, newValue);
};

export default ExtraHoursForm;
