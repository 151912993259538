import { useLocalStorage } from ".";

export const OrganisationTypeValues = {
    Network: "network",
    Account: "account",
} as const;

export type OrganisationType = (typeof OrganisationTypeValues)[keyof typeof OrganisationTypeValues];

export function useOrganisationType() {
    const [groupId] = useLocalStorage("side_team_groupId");
    const [activeOrganisationId] = useLocalStorage("side_team_activeOrganisationId");
    return {
        organisationType:
            groupId != null && activeOrganisationId == null
                ? OrganisationTypeValues.Network
                : OrganisationTypeValues.Account,
        networkId: groupId,
        accountId: activeOrganisationId,
    };
}
