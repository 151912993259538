import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const jobTypesSchema = z
    .object({
        jobTypes: z.array(
            z.object({
                _id: z.string(),
                name: z.string(),
                category: z.string(),
                postingData: z.any(),
                pricing: z.any(),
                demandCategoryId: z.string(),
                supplyCategoryId: z.string(),
                questions: z.any(),
                description: z.string(),
                order: z.number(),
                en: z.object({
                    translatedName: z.string(),
                    translatedCategory: z.string(),
                }),
                fr: z.object({
                    translatedName: z.string(),
                    translatedCategory: z.string(),
                }),
            }),
        ),
    })
    .transform((data) => data.jobTypes.map((jobType) => ({ ...jobType, id: jobType._id })));

export async function getJobTypes() {
    debug.addBreadcrumb({
        message: `Get job types`,
        category: `action`,
    });

    const organisationId = localStorage.getItem("side_team_activeOrganisationId") || "";

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/organisations/${organisationId}/job_types`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("GetJobTypes"));
        }

        const data = await resp.json();

        return jobTypesSchema.parse(data);
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("GetJobTypes"));
    }
}
