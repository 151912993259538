import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type ConfirmIndemnitiesTypesParams = {
    alias?: string;
    organisationId: string;
};
export function confirmIndemnities(params: ConfirmIndemnitiesTypesParams) {
    debug.addBreadcrumb({
        message: `Confirm indemnities`,
        category: `action`,
        data: {
            alias: params?.alias,
        },
    });

    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/confirm_indemnities`,
            { method: `PUT` },
        ),
    );
}
