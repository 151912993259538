import { useEffect } from "react";
import i18n from "i18n-js";
import { Loader } from "side-ui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import BlankTab from "../../../components/BlankTab";
import trackEvent from "../../../lib/trackers";
import { tasksListHomeRoute } from "../route";

import TasksListTable from "./TaskListTable";

import "./TasksListHome.scss";

const TasksListHome = ({
    getHomeTasks,
    managerIdFilter,
    finishedLoading,
    statuses,
    hasNoTasks,
    tasksByStatuses,
    paginationByStatuses,
    getStatuses,
}) => {
    const match = tasksListHomeRoute.useMatch();
    const { data: user } = useQuery(queries.user.detail());
    const { firstName, organisation: company } = user;

    useEffect(() => {
        getHomeTasks();
        getStatuses();
    }, [managerIdFilter]);

    useEffect(() => {
        if (finishedLoading) {
            const loadTimeStart = parseInt(sessionStorage.getItem(`load_time_start`), 10);

            if (loadTimeStart) {
                trackEvent({
                    client: `loadTime`,
                    name: `loadTracker`,
                    params: {
                        label: `company`,
                        pageVisited: `tasks`,
                        name: company && company.name,
                        timeElapsed: Date.now() - loadTimeStart,
                        service: `team`,
                    },
                });
            }
        }
    }, [finishedLoading, company]);

    useEffect(() => {
        sessionStorage.setItem(`load_time_start`, Date.now().toString());
    }, []);

    if (!finishedLoading) {
        return (
            <div className='loader--centered'>
                <Loader />
            </div>
        );
    }

    if (finishedLoading && hasNoTasks) {
        return (
            <BlankTab
                title={i18n.t(`empty_placeholder_all_title`, { firstName })}
                subtitle={i18n.t(`empty_placeholder_all_subtitle`)}
            />
        );
    }

    return (
        <div className='tasks__statuses'>
            {statuses &&
                statuses
                    .filter(
                        (st) =>
                            ["upcoming", "ongoing"].includes(st) && tasksByStatuses[st]?.length > 0,
                    )
                    .map((status) => (
                        <div className='tasks-list' key={status}>
                            <TasksListTable
                                key={status}
                                status={status}
                                items={tasksByStatuses[status]}
                                pagination={paginationByStatuses[status]}
                                isLoading={false}
                                hasPagination={status === `completed`}
                                match={match}
                            />
                        </div>
                    ))}
        </div>
    );
};

export default TasksListHome;
