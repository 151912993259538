import i18n from "i18n-js";
import { Button, IconButton } from "side-ui";

const EditCell = ({ setDisplayPanel }) => (
    <span
        className='
      timesheets-table__cell
      timesheets-table__cell--action
    '
    >
        <Button
            color='white'
            iconAfter='PenXL'
            action={() => setDisplayPanel(true)}
            customClass='timesheets-table__cell--action--withlabel'
        >
            {i18n.t("ts_update")}
        </Button>
        <IconButton color='grey' action={() => setDisplayPanel(true)} icon='PenXL' />
    </span>
);

export default EditCell;
