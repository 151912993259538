import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const HolidaySchema = z.object({
    name: z.string(),
    startDate: z
        .string()
        .datetime({ offset: true })
        .transform((val) => new Date(val)),
    endDate: z
        .string()
        .datetime({ offset: true })
        .transform((val) => new Date(val)),
    solidarity: z.boolean(),
});
export type Holiday = z.infer<typeof HolidaySchema>;
const HolidayResponseSchema = z
    .object({
        holidays: z.array(HolidaySchema),
    })
    .transform((val) => val.holidays);
export type HolidayResponse = z.infer<typeof HolidayResponseSchema>;
export async function getHolidays(year: number) {
    debug.addBreadcrumb({
        message: `Get holidays`,
        data: year,
    });
    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisations/holidays?year=${year}`),
        );
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("GetHolidays"));
        }

        const json = await resp.json();

        return HolidayResponseSchema.parse(json);
    } catch (e) {
        debug.catch(e);
        handleError(e, 500);
        return Promise.reject(new Error("GetHolidays"));
    }
}
