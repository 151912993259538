const PUBLIC_URL = process.env.PUBLIC_URL != null ? process.env.PUBLIC_URL : "";

const ORGA_URL = process.env.REACT_APP_ORGA_URL != null ? process.env.REACT_APP_ORGA_URL : "";

const RESET_PASSWORD_URL =
    process.env.REACT_APP_RESET_PASSWORD_URL != null
        ? process.env.REACT_APP_RESET_PASSWORD_URL
        : "";

const DOMAIN = process.env.REACT_APP_DOMAIN != null ? process.env.REACT_APP_DOMAIN : "";

const API_URL =
    process.env.REACT_APP_TEAM_API || (import.meta.env.DEV && import.meta.env.VITE_API_URL);

const FLAGS_API_URL =
    process.env.REACT_APP_FLAGS_API_URL != null ? process.env.REACT_APP_FLAGS_API_URL : "";

const FLAGS_API_KEY =
    process.env.REACT_APP_FLAGS_API_KEY != null ? process.env.REACT_APP_FLAGS_API_KEY : "";

const ANALYTICS_URL =
    process.env.REACT_APP_ANALYTICS_URL ||
    import.meta.env.VITE_API_URL.replace("team-api", "analytics") ||
    "";

process.env.REACT_APP_ANALYTICS_URL != null ? process.env.REACT_APP_ANALYTICS_URL : "";

const JOB_POSTING_URL =
    process.env.REACT_APP_JOB_POSTING_URL != null ? process.env.REACT_APP_JOB_POSTING_URL : "";

const STRIPE_PUBLIC_KEY =
    process.env.REACT_APP_STRIPE_PUBLIC_KEY != null
        ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
        : "pk_test_5GdRDKdNtzOgNgskESc6Y9mM";

export default {
    PUBLIC_URL,
    ORGA_URL,
    RESET_PASSWORD_URL,
    API_URL,
    FLAGS_API_URL,
    FLAGS_API_KEY,
    DOMAIN,
    ANALYTICS_URL,
    JOB_POSTING_URL,
    STRIPE_PUBLIC_KEY,
};

export const AlgoliaConfig = {
    appId:
        process.env.REACT_APP_ALGOLIA_APP_ID != null
            ? process.env.REACT_APP_ALGOLIA_APP_ID
            : "B1ARJ7GA5A",
    apiKey:
        process.env.REACT_APP_ALGOLIA_APP_KEY != null
            ? process.env.REACT_APP_ALGOLIA_APP_KEY
            : "9124287257a8d22cfe9169bf0fc139a6",
    organisationsIndexName:
        process.env.REACT_APP_ALGOLIA_ORGANISATION_INDEX != null
            ? process.env.REACT_APP_ALGOLIA_ORGANISATION_INDEX
            : "dev_organisation",
};

if (!window.location.origin) {
    // Typescript is complaining about origin being a readonly property, which
    // is not true, just the recommended usage. We could rewrite this at some point.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location.origin = `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
    }`;
}

const location = window.location.origin;
export const isDev = location.indexOf(`dev`) !== -1;
export const isStaging = location.indexOf(`staging`) !== -1;
export const isLocal = location.indexOf(`jumper`) !== -1 || location.indexOf(`localhost`) !== -1;
export const isProd = !isLocal && !isDev && !isStaging;
export const ENVIRONMENT =
    (isDev && `development`) || (isStaging && `staging`) || (isLocal && `local`) || `production`;
