import i18n from "i18n-js";
import moment from "moment";
import { ModalSimple, ModalsService } from "side-ui";

import "./CommentsModal.scss";

const CommentsModal = ({
    feedbacks = [],
    jobTypeFeedbacks = [],
    tasksCount,
    duration = "",
    title,
}) => {
    return (
        <ModalSimple
            title={title}
            hideModal={() => ModalsService.closeModal(`COMMENTS_MODAL`)}
            withCloseButton={true}
        >
            <div className='comments__modal'>
                <div className='comments__modal__details'>
                    {tasksCount && (
                        <p className='comments__modal__details__tasks-count'>
                            {i18n.t("sider_profile_panel_tasks", {
                                tasksCount,
                                plural: tasksCount > 1 ? `s` : ``,
                                numberOfTasks: tasksCount,
                            })}
                        </p>
                    )}
                    {duration && (
                        <p
                            className={`${
                                tasksCount
                                    ? `comments__modal__details__duration`
                                    : `comments__modal__details__tasks-count`
                            }`}
                        >
                            {duration}
                        </p>
                    )}
                </div>
                <div className='comments__modal__list'>
                    {}
                    {feedbacks?.map(
                        (feedback, i) =>
                            feedback.comment && (
                                <div key={i} className='comments__modal__comment'>
                                    <div className='comments__modal__comment__details'>
                                        <p className='comments__modal__comment__details__author'>
                                            {feedback.authorName}
                                        </p>
                                        <p className='comments__modal__comment__details__date'>
                                            {moment(feedback.createdAt).format(`DD/MM/YYYY`)}
                                        </p>
                                    </div>
                                    <p className='comments__modal__comment__text'>
                                        {feedback.comment}
                                    </p>
                                </div>
                            ),
                    )}
                    {}
                    {jobTypeFeedbacks?.map(
                        (jobTypeFeedback, i) =>
                            jobTypeFeedback.length > 0 && (
                                <div key={i} className='comments__modal__comment'>
                                    <p className='comments__modal__comment__text'>
                                        {jobTypeFeedback}
                                    </p>
                                </div>
                            ),
                    )}
                </div>
            </div>
        </ModalSimple>
    );
};

export default CommentsModal;
