import { Component } from "react";
import i18n from "i18n-js";
import moment from "moment";
import { ModalSimple, ModalsService, Textarea } from "side-ui";

import "./ContestationModal.scss";

class ContestationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasValue: false,
            formValue: "",
        };
    }

    handleCommentChange(value) {
        this.setState({
            formValue: value,
            hasValue: !!value.length,
        });
    }

    render() {
        const { submit, contractsBatchStatus, contestation, documentId } = this.props;

        const { hasValue, formValue } = this.state;

        const dataToSend = {
            documentId: documentId && documentId,
            description: formValue,
        };
        const contestationAuthorName = contestation && contestation.managerName;
        const contestationDate = contestation && moment(contestation.date).format("DD/MM/YYYY");

        return (
            <ModalSimple
                action={() => {
                    submit(dataToSend);
                }}
                withCloseButton={true}
                disableButton={!hasValue}
                cancelFunction={() => ModalsService.closeModal(`CONTRACTS_CONTESTATION`)}
                cancelLabel={
                    contractsBatchStatus !== "contested" &&
                    i18n.t(`contracts_contestation_modal_cancel`)
                }
                validateLabel={
                    contractsBatchStatus !== "contested" &&
                    i18n.t(`contracts_contestation_modal_send`)
                }
                title={i18n.t(`contracts_contestation_modal_title`)}
                subtitle={
                    contractsBatchStatus !== "contested"
                        ? i18n.t(`contracts_contestation_modal_subtitle_tocontest`)
                        : i18n.t(`contracts_contestation_modal_subtitle_contested`)
                }
                hideModal={() => ModalsService.closeModal(`CONTRACTS_CONTESTATION`)}
            >
                <div className='contestation-modal'>
                    {contractsBatchStatus !== "contested" ? (
                        <div className='contestation-form'>
                            <Textarea
                                id='contestation-form'
                                label={i18n.t(`contracts_contestation_modal_input_label`)}
                                placeholder={i18n.t(
                                    `contracts_contestation_modal_input_placeholder`,
                                )}
                                value={formValue}
                                onChange={(value) => this.handleCommentChange(value)}
                            />
                        </div>
                    ) : (
                        <div className='contestation-feedback'>
                            <div className='contestation-feedback--description'>
                                <p>{contestation && contestation.description}</p>
                            </div>
                            <p className='contestation-feedback--details'>
                                {i18n.t("contracts_contestation_modal_details", {
                                    contestationDate,
                                    contestationAuthorName,
                                })}
                            </p>
                        </div>
                    )}
                </div>
            </ModalSimple>
        );
    }
}

export default ContestationModal;
