const normalizeInvoices = (data) => {
    const userID = localStorage.getItem("Meteor.userId") || "";
    const logasID = localStorage.getItem("side_team_logasId");
    const token = localStorage.getItem("Meteor.loginToken") || "";

    if (!data || !data.invoices) {
        return {
            invoices: [],
            firstPaymentDate: data.firstPaymentDate,
        };
    }
    const invoices = data.invoices.map((invoice) => ({
        amount: invoice.amount,
        createdAt: invoice.createdAt,
        currency: invoice.currency,
        id: invoice.id,
        paid: invoice.paid,
        referenceNumber: invoice.referenceNumber,
        sideInvoiceUrl: `${invoice.sideInvoiceUrl}?X-User-ID=${userID}&X-Auth-Token=${token}${
            logasID ? `&X-Logas-ID=${logasID}` : ""
        }`,
        type: invoice.type,
        weekEndDate: invoice.weekEndDate,
        weekStartDate: invoice.weekStartDate,
    }));

    return {
        invoices,
        firstPaymentDate: data.firstPaymentDate,
    };
};

export default normalizeInvoices;
