import fetch from "isomorphic-fetch";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

type GetSiderParams = {
    siderId: string;
    withOngoing?: boolean;
    withTasks?: boolean;
    withExperiences?: boolean;
    withEducations?: boolean;
};
export type GetSiderResponse = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    pictureUrl: string;
    ongoing: Ongoing[];
};

type Ongoing = {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
};
export function getSider(params: GetSiderParams) {
    return fetch(
        withAuthHeaders(
            withURLParam(`${apiConf.BACK_URL}/siders/${params.siderId}`, {
                withOngoing: params.withOngoing || false,
                withTasks: params.withTasks || false,
                withExperiences: params.withExperiences || false,
                withEducations: params.withEducations || false,
            }),
        ),
    );
}
