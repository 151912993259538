import { useState } from "react";
import i18n from "i18n-js";
import { Button, Chip, ModalsService } from "side-ui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import { CustomSafetyEquipmentModal } from "./CustomSafetyEquipmentModal";
import { EquipmentProviderModal } from "./EquipmentProviderModal";

import styles from "./SafetyEquipmentListForm.module.css";

export function SafetyEquipmentListForm({
    selectedSafetyEquipmentList,
    setSelectedSafetyEquipmentList,
}) {
    const [displayModal, setDisplayModal] = useState(false);
    const [selectedEquipmentList, setSelectedEquipmentList] = useState(null);

    const { data: safetyEquipmentList } = useQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) =>
            data?.equipmentList?.filter((equipment) => equipment.group.includes("Safety")),
    });

    function handleChange(equipment) {
        if (selectedSafetyEquipmentList?.find(({ name }) => name === equipment?.name)) {
            const selectedFilteredSafetyEquipmentList = selectedSafetyEquipmentList.filter(
                ({ name }) => name !== equipment?.name,
            );
            setSelectedSafetyEquipmentList(selectedFilteredSafetyEquipmentList);
        } else {
            setSelectedSafetyEquipmentList(selectedSafetyEquipmentList?.concat(equipment));
        }
    }

    return (
        <>
            <div className={styles.safetyEquipment}>
                {selectedSafetyEquipmentList?.length ? (
                    <div className={styles.selected}>
                        {selectedSafetyEquipmentList.map(({ id, name, custom }) => (
                            <span key={id} style={{ marginRight: "8px" }}>
                                <Button
                                    color='light'
                                    action={() => handleChange({ id, name })}
                                    iconAfter='CrossXL'
                                >
                                    {custom ? name : i18n.t(name)}
                                </Button>
                            </span>
                        ))}
                    </div>
                ) : null}

                {safetyEquipmentList?.reduce((checkboxes, { id, name, custom }) => {
                    if (selectedSafetyEquipmentList?.find((selected) => selected.id === id)) {
                        return checkboxes;
                    }
                    return [
                        ...checkboxes,
                        <Chip
                            key={name}
                            label={i18n.t(name)}
                            value={name}
                            id={id}
                            onChange={() => {
                                setSelectedEquipmentList({ id, name, custom });
                                setDisplayModal(true);
                            }}
                            checked={selectedSafetyEquipmentList?.find((s) => s.name === name)}
                            // here we can replace by false, because if it is actually selected,
                            // you will never display the chip according to the condition above
                            name='safety-equipment'
                        />,
                    ];
                }, [])}
                <Button
                    color='blue'
                    iconAfter='Plus'
                    customClass={styles.button}
                    action={() =>
                        ModalsService.openModal({
                            id: `ADD_CUSTOM_SAFETY_EQUIPMENT`,
                            content: (
                                <CustomSafetyEquipmentModal
                                    submitEquipment={(customEquipment) =>
                                        handleChange(customEquipment)
                                    }
                                />
                            ),
                        })
                    }
                >
                    {i18n.t(
                        `job-descriptions_creation_conditions_equipments_security_add_custom_button`,
                    )}
                </Button>
            </div>

            {displayModal ? (
                <EquipmentProviderModal
                    equipment={selectedEquipmentList}
                    setEquipment={(equipment) => {
                        handleChange(equipment);
                        setDisplayModal(false);
                    }}
                    hideModal={() => setDisplayModal(false)}
                />
            ) : null}
        </>
    );
}
