import fetch from "isomorphic-fetch";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

type GetContractsBatchesParams = {
    organisationId: string;
    signed: boolean;
    fetchAll: boolean;
    limit: number;
    offset: number;
};
export type GetContractsBatchesResponse = {
    documents: Document[];
    offset: number;
    limit: number;
    total: number;
};

type Document = {
    _id: string;
    taskId: string;
    taskName: string;
    jobTypeId: string;
    managerName: string;
    contractIds: string[];
    startDate: string;
    status: string;
    url: string;
};

export function getContractsBatches(params: GetContractsBatchesParams) {
    return fetch(
        withAuthHeaders(
            withURLParam(`${apiConf.BACK_URL}/organisations/${params.organisationId}/documents`, {
                signed: params.signed,
                limit: (params.fetchAll && params.limit) || "",
                offset: (params.fetchAll && params.offset > 0 && params.offset) || "0",
            }),
        ),
    );
}
