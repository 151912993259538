import { memo } from "react";
import i18n from "i18n-js";
import { Check, CheckboxButton } from "side-ui";

type CheckboxCellProps = {
    checked: boolean;
    selectAttendance: any;
    id: string;
};

const CheckboxCell = memo(function CheckboxCell({
    // eslint-disable-line
    checked,
    selectAttendance,
    id,
}: CheckboxCellProps) {
    return (
        <span className='timesheets-table__cell timesheets-table__cell--checkbox'>
            <CheckboxButton
                id={id}
                checked={checked}
                name='select-attendance'
                value={id}
                label={i18n.t("ts_select")}
                onChange={() => selectAttendance(id)}
                customClass='timesheets-table__cell--checkbox--withlabel'
            />
            <Check
                checked={checked}
                onChange={() => selectAttendance(id)}
                label=''
                inputId={id}
                name='select-attendance'
            />
        </span>
    );
});

export default CheckboxCell;
