import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type RegenerateDocumentsParams = { documentIds: string[] };
export function regenerateDocuments(params: RegenerateDocumentsParams) {
    debug.addBreadcrumb({
        message: `Regenerate documents`,
        category: `action`,
        data: params,
    });

    const organisationId = localStorage.getItem("side_team_activeOrganisationId");
    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${organisationId}/documents/regenerate`,
            {
                method: `PUT`,
                body: JSON.stringify({
                    documentIds: params.documentIds,
                }),
            },
        ),
    );
}
