import * as React from "react";
import { Modal } from "side-ui";

import "./ModalForm.scss";

const ModalForm = (props) => {
    const { header, children, onClose, shouldCloseOnOverlayClick, hideModal } = props;

    return (
        <Modal
            customClass='modal--form'
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            hideModal={hideModal}
        >
            <div className='modal__header'>
                {header}
                <a onClick={() => onClose()} className='modal__close'>
                    <i className='icon icon-delete' />
                </a>
            </div>
            <div className='modal__body'>{children}</div>
        </Modal>
    );
};

export default ModalForm;
