import { forwardRef, useState } from "react";
import I18n from "i18n-js";
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, TextField } from "sui";

import { postCompanyMotive } from "@lib/api/postCompanyMotive";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { SEASONAL_ACTIVITY } from "./MotiveStep";

import styles from "./motiveModals.module.css";

type Props = {
    setSelectedJustification: React.Dispatch<
        React.SetStateAction<{
            label: string;
            value: string;
        } | null>
    >;
};
export const SeasonalJobModal = forwardRef<HTMLDialogElement, Props>(function SeasonalJobModal(
    { setSelectedJustification },
    ref,
) {
    const { data: company } = useSuspenseQuery(queries.company.detail());
    const [justification, setJustification] = useState("");

    const queryClient = useQueryClient();

    const { mutate: postCompanyMotiveMutation, isPending } = useMutation({
        mutationFn: postCompanyMotive,
        onSuccess: (res, req) => {
            setSelectedJustification({
                label: req.justification,
                value: res.id,
            });
            setJustification("");
            trackEvent({
                name: `companymotives - created justification`,
                params: {
                    orgId: company.organisation.id,
                    reason: SEASONAL_ACTIVITY,
                },
            });
            (ref as React.MutableRefObject<HTMLDialogElement | null>).current?.close();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queries.company.companyMotives());
        },
    });

    return (
        <Modal
            ref={ref as React.MutableRefObject<HTMLDialogElement | null>}
            onSubmit={() => {
                postCompanyMotiveMutation({
                    reason: SEASONAL_ACTIVITY,
                    justification: justification,
                });
            }}
            onClose={() => {
                setJustification("");
            }}
        >
            <ModalHeader
                title={I18n.t("motive_seasonal_activity_title")}
                description={
                    <p>
                        {I18n.t("motive_seasonal_activity_subtitle")}
                        &nbsp;
                        <a
                            href={I18n.t("motive_seasonal_activity_explaination_link")}
                            target='_blank'
                            rel='noopener noreferrer external'
                            className={styles.link}
                        >
                            {I18n.t("motive_seasonal_activity_explaination_link_label")}
                        </a>
                    </p>
                }
            />
            <ModalContent>
                <TextField
                    label={I18n.t("motive_seasonal_activity_label")}
                    placeholder={I18n.t("motive_seasonal_activity_placeholder")}
                    value={justification}
                    onChange={(e) => setJustification(e.target.value)}
                    required
                    className={styles.requiredField}
                />
            </ModalContent>
            <ModalFooter
                mainButton={
                    <Button loading={isPending} disabled={justification === ""}>
                        {I18n.t("order_justifications_create_action")}
                    </Button>
                }
                cancelButtonLabel={I18n.t("cancel")}
            />
        </Modal>
    );
});
