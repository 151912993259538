import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GoogleLoginReq = {
    code: string;
};
type LoginRes = {
    token: string;
    email: string;
    userId: string;
    organisationId: string;
    organisationType: string;
    firstName: string;
    lastName: string;
};
export async function googleLogin(req: GoogleLoginReq): Promise<LoginRes | null> {
    debug.addBreadcrumb({
        message: `GoogleLogin`,
        category: `action`,
        data: {
            code: req.code,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/auth/google/token`, {
                method: `POST`,
                credentials: "omit",
                body: JSON.stringify({
                    code: req.code,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
