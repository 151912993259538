import { useEffect, useRef } from "react";
import { ModalsRoot } from "side-ui";

import { useRouter } from "@tanstack/react-router";

import Navigation from "../components/Navigation";

import "./OrganisationLayout.scss";

declare global {
    interface Window {
        analytics: {
            page: () => void;
        };
    }
}

const OrganisationLayout = ({ children }) => {
    const { history } = useRouter();
    const subscribed = useRef(false);

    useEffect(() => {
        if (subscribed.current) return;
        function onNavigate() {
            if (window.analytics) {
                window.analytics.page();
            }
        }
        history.subscribe(() => {
            onNavigate();
        });
        // also run on intial load
        onNavigate();
        subscribed.current = true;
    }, []);

    return (
        <div className='organisation__layout'>
            <Navigation />
            <div className={`content`}>{children}</div>
            <ModalsRoot />
        </div>
    );
};

export default OrganisationLayout;
