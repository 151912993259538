import { Component } from "react";
import { getISOWeek, isFuture } from "date-fns";
import i18n from "i18n-js";
import { capitalize } from "lodash";
import moment from "moment";
import { ButtonOptions, ModalsService } from "side-ui";

import { queryClient } from "@App";
import { queries } from "@lib/queries";
import { router } from "@routes";
import { Link } from "@tanstack/react-router";

import env from "../../lib/env";
import trackEvent from "../../lib/trackers";
import getJobPostingUrl from "../../lib/utils/getJobPostingUrl";
import DeleteModal from "../../routes/TasksList/containers/DeleteModal";
import DuplicateModal from "../../routes/TasksList/containers/DuplicateModal";
import ManagerUpdateModal from "../../routes/TasksList/containers/ManagerUpdateModal";
import RenameModal from "../../routes/TasksList/containers/RenameModal";

import RedirectModal from "./RedirectModal";

import "./TableRow.scss";

export const taskSelectionStatus = {
    NEW: "new",
    ONGOING: "ongoing",
    CLOSED: "closed",
    READY: "ready",
};

export const taskStatus = {
    DRAFT: "draft",
    SUBMITTED: "submitted",
};

class TableRow extends Component {
    constructor(props) {
        super(props);
    }

    onClickMobile(url) {
        if (window.outerWidth < 768) {
            window.location = url;
        }
    }

    getReferenceDate() {
        const { item, status } = this.props;
        const { startDate, endDate } = item;

        if (!startDate) {
            return <div>-</div>;
        }

        switch (status) {
            case taskStatus.DRAFT && !startDate:
                return <div>-</div>;

            case taskStatus.SUBMITTED && isFuture(new Date(startDate)):
                return (
                    <div>
                        <div className='table-row__title'>
                            {moment().add(4, "days").isAfter(moment(startDate))
                                ? capitalize(moment(startDate).fromNow())
                                : moment(startDate).format(`DD/MM/YYYY`)}
                        </div>
                        <div>{`${i18n.t(`at`)} ${moment(startDate).format(`HH:mm`)}`}</div>
                    </div>
                );

            case taskStatus.SUBMITTED:
                return (
                    <div>
                        <div className='table-row__title'>
                            {moment(startDate).format(`DD/MM/YYYY`)}
                        </div>
                        <div>{`${i18n.t(`until_date`)} ${moment(endDate).format(`DD/MM`)}`}</div>
                    </div>
                );

            case taskSelectionStatus.ONGOING:
                return (
                    <div>
                        <div className='table-row__title'>
                            {moment().subtract(4, "days").isBefore(moment(startDate))
                                ? capitalize(moment(startDate).fromNow())
                                : moment(startDate).format(`DD/MM/YYYY`)}
                        </div>
                        <div>{`${i18n.t(`until_date`)} ${moment(endDate).format(`DD/MM`)}`}</div>
                    </div>
                );

            default:
                return (
                    <div>
                        <div className='table-row__title'>
                            {moment().subtract(4, "days").isBefore(moment(startDate))
                                ? capitalize(moment(startDate).fromNow())
                                : moment(startDate).format(`DD/MM/YYYY`)}
                        </div>
                        <div>{`${i18n.t(`until_date`)} ${moment(endDate).format(`DD/MM`)}`}</div>
                    </div>
                );
        }
    }

    getLocationFormatted() {
        const { item } = this.props;

        if (!item?.location) {
            return null;
        }

        if (item.location?.remote) {
            return <p>Online</p>;
        }

        return <p>{item.location?.address}</p>;
    }

    getStatus() {
        const { item, status } = this.props;

        if (status === taskStatus.DRAFT) {
            return (
                <div>
                    <div className='table-row__title'>
                        {i18n.t(`draft_section_row_status_cell_title`)}
                    </div>
                </div>
            );
        }

        switch (item.selectionStatus) {
            case taskSelectionStatus.ONGOING:
            case taskSelectionStatus.NEW:
                return (
                    <div className='table-row__wrapper'>
                        <div className='table-row__title'>
                            {i18n.t(`upcoming_section_row_status_cell_title_selection_ongoing`)}
                            {item.preSelection ? (
                                <Link
                                    className='table-row__link-button'
                                    to='/preselection/$taskId'
                                    params={{ taskId: item.id }}
                                >
                                    {i18n.t("ongoing_section_display_preselection")}
                                </Link>
                            ) : null}
                        </div>
                    </div>
                );

            case taskSelectionStatus.COMPLETED || taskSelectionStatus.CLOSED: {
                const planningURLParams = item?.startDate && {
                    week: getISOWeek(new Date(item?.startDate)),
                    year: new Date(item.startDate).getFullYear(),
                };

                return (
                    <div className='table-row__wrapper'>
                        <div className='table-row__title'>
                            {i18n.t(`upcoming_section_row_status_cell_title_selection_done`)}

                            {item?.hiredSidersCount === 0 ? (
                                <div className='table-row--light'>
                                    {item?.hiredSidersCount}
                                    &nbsp; Sider
                                </div>
                            ) : (
                                <div>
                                    <button
                                        className='table-row__link-button'
                                        onClick={() =>
                                            router.navigate({
                                                to: `/planning`,
                                                search: planningURLParams,
                                            })
                                        }
                                        type='button'
                                    >
                                        {i18n.t("ongoing_section_display_planning")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }

            default:
                return <div />;
        }
    }

    getSidersUrl() {
        const { item } = this.props;
        return `${env.ORGA_URL}${env.DOMAIN}/company/hired/profiles/${item.id}`;
    }

    getEditUrl() {
        const { item } = this.props;
        return getJobPostingUrl(`edit`, item.id, item.jobTypeId, false);
    }

    // get the right actions based on the joboffer status, selection state etc.
    getActions() {
        const {
            item,
            status,
            match, // needed for the trackers
        } = this.props;

        const user = queryClient.getQueryData(queries.user.detail().queryKey);

        const isCollaborator = user?.role === `collaborator`;

        const allActions = [
            // Rename task
            {
                action: {
                    label: i18n.t(`rename_task`),
                    action: () =>
                        ModalsService.openModal({
                            id: `RENAME_TASK`,
                            content: <RenameModal match={match} task={item} />,
                        }),
                },
                validator: () => !item.archived,
            },
            // Delete draft
            {
                action: {
                    label: i18n.t(`delete_draft`),
                    action: () =>
                        ModalsService.openModal({
                            id: `DELETE_TASK`,
                            content: <DeleteModal match={match} task={item} />,
                        }),
                },
                validator: () => status === taskStatus.DRAFT,
            },
            {
                action: {
                    label: i18n.t(`view_task`),
                    action: () => {
                        router.navigate({
                            to: `/tasks/view/$taskId`,
                            params: { taskId: item.id },
                        });
                        trackEvent({
                            name: `taskOptionButton`,
                            params: {
                                optionClick: `viewTask`,
                                page: !match || !match.params.status ? `all` : status,
                                taskStatus: status,
                            },
                        });
                    },
                },
                validator: () => !item.archived && !isCollaborator,
            },
            //  Change task manager
            {
                action: {
                    label: i18n.t(`update_manager`),
                    action: () =>
                        ModalsService.openModal({
                            id: `UPDATE_MANAGER`,
                            content: <ManagerUpdateModal task={item} />,
                        }),
                },
                validator: () => status !== taskStatus.DRAFT && !item.archived && !isCollaborator,
            },
        ];
        const allCTAs = [
            // Finish draft
            {
                action: {
                    label: i18n.t(`finish_draft`),
                    action: () => {
                        trackEvent({
                            name: `taskOptionButton`,
                            params: {
                                optionClick: `finishDraft`,
                                page: !match || !match.params.status ? `all` : status,
                                taskStatus: status,
                            },
                        });

                        router.navigate({
                            to: `/taskPosting/$taskId/motive`,
                            params: { taskId: item.id },
                        });
                    },
                    // If jobtype is archived then set the disabled as true
                    // all the way down to the CTA button
                    disabled: item.archived || isCollaborator,
                },
                validator: () => status === taskStatus.DRAFT,
            },
            // Duplicate task
            {
                action: {
                    label: i18n.t(`duplicate_task`),
                    action: () =>
                        ModalsService.openModal({
                            id: item.migrated ? `REDIRECT_MODAL` : `DUPLICATE_TASK`,
                            content: item.migrated ? (
                                <RedirectModal />
                            ) : (
                                <DuplicateModal match={match} task={item} />
                            ),
                        }),
                    // If jobtype is archived then set the disabled as true
                    // all the way down to the CTA button
                    disabled: item.archived || isCollaborator,
                },
                validator: () => status !== taskStatus.DRAFT && !isCollaborator,
            },
            // View task (for Collaborators)
            {
                action: {
                    label: i18n.t(`view_task`),
                    action: () => {
                        trackEvent({
                            name: `taskOptionButton`,
                            params: {
                                optionClick: `viewTask`,
                                page: !match || !match.params.status ? `all` : status,
                                taskStatus: status,
                            },
                        });
                        window.location = this.getEditUrl();
                    },
                    disabled: item.archived,
                },
                validator: () => status !== taskStatus.DRAFT,
            },
        ];

        // Fallback should never happen
        const fallbackCTA = {
            label: i18n.t(`view_task`),
            action: () => {},
            disabled: true,
        };
        const actions = allActions.filter((a) => a.validator()).map((a) => a.action);
        const actionCta = allCTAs.find((a) => a.validator())?.action || fallbackCTA;

        return {
            actionCta,
            actions,
        };
    }

    render() {
        const { item, latestTask, singleTask, status } = this.props;
        const companyInfos = queryClient.getQueryData(queries.company.detail().queryKey);
        const companyMembers = companyInfos?.members || [];

        const manager = companyMembers.find(({ _id }) => _id === item.managerId);
        const creator = companyMembers.find(({ _id }) => _id === item.createdBy);

        const { actionCta, actions } = this.getActions();

        return (
            <div
                className={`table__row table-row row 
          ${latestTask ? `active` : ``} 
          ${singleTask ? `single-task` : ``}
        `}
            >
                <div className='table__col offset-sm-1 col-sm-2'>
                    <div className='table__col__inner'>{this.getReferenceDate()}</div>
                </div>
                <div className='table__col table__col--link col-sm-5'>
                    <div className='table__col__inner'>
                        <div className='table-row__title table-row__title--bold table-row__title--inline'>
                            <span>{item.name}</span>
                        </div>
                        {this.getLocationFormatted()}
                        {item?.manager ? (
                            <p>
                                Manager: &nbsp;
                                {item.manager?.firstName}
                                &nbsp;
                                {item.manager?.lastName}
                            </p>
                        ) : null}

                        <p>
                            {item.uniqueIdentifier && <span>{`${item.uniqueIdentifier} • `}</span>}
                            {creator && status === taskStatus.DRAFT ? (
                                <span>
                                    {i18n.t(`draft_section_row_task_cell_created_by`)}
                                    {` ${creator.firstName} ${creator.lastName.substring(0, 1)}.`}
                                </span>
                            ) : (
                                manager && (
                                    <span>
                                        {i18n.t(`just_posted_section_row_task_cell_managed_by`)}
                                        {` ${manager.firstName} ${manager.lastName.substring(
                                            0,
                                            1,
                                        )}.`}
                                    </span>
                                )
                            )}
                        </p>
                    </div>
                </div>
                <div className='table__col col-sm-7'>
                    <div className='table__col__inner'>
                        {this.getStatus()}
                        <ButtonOptions
                            ctaAction={actionCta.action}
                            ctaLabel={actionCta.label}
                            ctaDisabled={actionCta.disabled}
                            items={actions}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default TableRow;
