import { useState } from "react";
import i18n from "i18n-js";
import { ModalSimple, RadioButtons } from "side-ui";

import styles from "./SafetyEquipmentListForm.module.css";

export function EquipmentProviderModal({ equipment, setEquipment, hideModal }) {
    const [selectedEquipment, setSelectedEquipment] = useState(null);

    function handleEquipementSelection({ target }) {
        setSelectedEquipment({
            id: equipment.id,
            value: target.value,
            name: equipment.name,
            group: equipment.group,
            providedBy: target.value,
        });
    }

    return (
        <ModalSimple
            action={() => setEquipment(selectedEquipment)}
            validateLabel={i18n.t(`add`)}
            cancelLabel={i18n.t(`cancel`)}
            title={i18n.t(equipment?.name)}
            hideModal={hideModal}
            disableButton={!selectedEquipment?.value}
        >
            <p className={styles.provider}>
                {i18n.t(`job-descriptions_creation_conditions_equipments_security_modal_subtitle`)}
            </p>

            <RadioButtons
                label={i18n.t(
                    `job-descriptions_creation_conditions_equipments_security_modal_label`,
                )}
                value={{ [selectedEquipment?.value]: true }}
                options={[
                    { value: "sider", label: i18n.t(`no`) },
                    { value: "company", label: i18n.t(`yes`) },
                ]}
                onChange={handleEquipementSelection}
            />
        </ModalSimple>
    );
}
