import { debug } from "@side.co/client-debug";

const checkResForError = (res) => {
    if (res.status === 403) {
        if (window.location.pathname !== "/signin") {
            window.location = `/signin`;
        }
        return true;
    }
    if (res.status >= 400) {
        debug.log(`API call error: ${res.statusText}`, {
            level: `warning`,
            extra: {
                res: JSON.stringify(res),
            },
        });
        return true;
    }
    return false;
};

export default checkResForError;
