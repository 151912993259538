import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";
export const CreateLocationSchema = z.object({
    id: z.string(),
    organisationId: z.string(),
    postalCode: z.string(),
    city: z.string(),
    lat: z.number(),
    lng: z.number(),
    country: z.string(),
    confirmed: z.boolean(),
    address: z.string(),
    description: z.string(),
    online: z.boolean(),
    favorite: z.boolean(),
});

export type CreateLocation = z.infer<typeof CreateLocationSchema>;

type CreateLocationParams = { organisationId: string; location: Omit<CreateLocation, "id"> };
export async function createLocation(params: CreateLocationParams) {
    debug.addBreadcrumb({
        message: `Update existing location`,
        category: `action`,
        data: params.location,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/organisations/${params.organisationId}/locations`,
                {
                    method: `POST`,
                    body: JSON.stringify(params.location),
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        const json = await resp.json();

        return CreateLocationSchema.parse(json);
    } catch (e) {
        handleError(e, 500);
    }
}
