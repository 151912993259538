const normalizeContracts = (data) => {
    const userID = localStorage.getItem("Meteor.userId") || "";
    const logasID = localStorage.getItem("side_team_logasId");
    const token = localStorage.getItem("Meteor.loginToken") || "";

    const contractsBatches = {};
    if (!data) return contractsBatches;
    data.forEach((document) => {
        contractsBatches[document._id] = {
            documentId: document._id,
            taskId: document.taskId,
            taskName: document.taskName,
            status: document.status,
            managerName: document.managerName,
            generation: {
                date: new Date().toISOString(),
                url: `${document.url}?X-User-ID=${userID}&X-Auth-Token=${token}${
                    logasID ? `&X-Logas-ID=${logasID}` : ""
                }`,
            },
            contractsIds: document.contractIds,
            startDate: document.startDate,
            signature: document.signature
                ? {
                      url: `${document.signature.url}?X-User-ID=${userID}&X-Auth-Token=${token}${
                          logasID ? `&X-Logas-ID=${logasID}` : ""
                      }`,
                      date: document.signature.date,
                      managerName: document.signature.name,
                  }
                : null,
            contestation: document.contestation
                ? {
                      managerName: document.contestation.name,
                      date: document.contestation.date,
                      description: document.contestation.description,
                  }
                : null,
        };
    });

    return {
        contractsBatches,
    };
};

export default normalizeContracts;
