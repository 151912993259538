import type { Action, State, userToImpersonate } from "./types/logas";

//------------------------------------
// Constants
//------------------------------------

export const SET_USER_TO_IMPERSONATE = `SET_USER_TO_IMPERSONATE` as const;
export const SET_POSSIBLE_USERS = `SET_POSSIBLE_USERS` as const;

//------------------------------------
// Actions
//------------------------------------
export const setUserToImpersonate = (selectedUserToImpersonate: userToImpersonate) => ({
    type: SET_USER_TO_IMPERSONATE,
    data: selectedUserToImpersonate,
});

export const setPossibleUsers = (possibleUsersToImpersonate: userToImpersonate[]) => ({
    type: SET_POSSIBLE_USERS,
    data: possibleUsersToImpersonate,
});

//------------------------------------
// Actions Handlers
//------------------------------------

const ACTION_HANDLERS = {
    [SET_USER_TO_IMPERSONATE]: (state, action) => ({
        ...state,
        selectedUserToImpersonate: action.data,
    }),
    [SET_POSSIBLE_USERS]: (state, action) => ({
        ...state,
        possibleUsersToImpersonate: action.data,
    }),
};

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    selectedUserToImpersonate: {
        _id: "",
        firstName: "",
        lastName: "",
        sublabel: "",
        label: "",
        complementarylabel: "",
        isParent: false,
    },
    possibleUsersToImpersonate: [
        {
            _id: "",
            firstName: "",
            lastName: "",
            sublabel: "",
            label: "",
            complementarylabel: "",
            isParent: false,
        },
    ],
};

const logasReducer = (state: State = initialState, action: Action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};

export default logasReducer;
