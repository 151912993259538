/* eslint-disable react/require-default-props */

import i18n from "i18n-js";
import { Loader } from "side-ui";

import Pagination from "../../../components/Pagination";
import TableLayout from "../../../components/TableLayout";

const TasksListTable = ({
    match, // passed to TableLayout for the trackers
    items,
    status,
    hasPagination,
    pagination,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <div className='loader--centered'>
                <Loader />
            </div>
        );
    }

    if (!items || !items.length) {
        return null;
    }

    return (
        <div className='tasks__list__table'>
            <h2 className='heading-1'>{status && i18n.t(`${status}_section_title`)}</h2>

            {isLoading ? (
                <Loader />
            ) : (
                <TableLayout
                    items={items}
                    status={status}
                    hasStatusSort={status === `upcoming`}
                    match={match}
                />
            )}

            {hasPagination &&
                pagination &&
                pagination.count > pagination.itemsPerPage &&
                pagination.pageCount && (
                    <Pagination
                        pageCount={pagination.pageCount}
                        path={status ? `/tasks/${status}` : ``}
                        page={!match || !match.params.status ? `all` : status}
                    />
                )}
        </div>
    );
};

export default TasksListTable;
