import { memo } from "react";
import { connect } from "react-redux";

import { setShowSiderProfilePanel } from "../../../store/display";
import {
    getSiderProfilePanelDisplay,
    getSiderProfilePanelTaskCategory,
} from "../../../store/selectors/display";
import { getSelectedSider, getSiderIsLoading } from "../../../store/selectors/siders";
import { getSiderById } from "../../../store/siders";
import SiderProfilePanel from "../components/SiderProfilePanel";

const mapStateToProps = (state) => ({
    isVisible: getSiderProfilePanelDisplay(state),
    selectedSider: getSelectedSider(state),

    taskCategory: getSiderProfilePanelTaskCategory(state),
    isLoading: getSiderIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    getSider: ({ siderId, withOngoing, withTasks, withExperiences, withEducations }) =>
        dispatch(getSiderById(siderId, withOngoing, withTasks, withExperiences, withEducations)),
    // or setSiderProfileVisibility ? I don't know which wording should be put there
    setSiderProfileVisibility: (isVisible) => dispatch(setShowSiderProfilePanel(isVisible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(SiderProfilePanel));
