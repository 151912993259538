import i18n from "i18n-js";
import fetch from "isomorphic-fetch";
import { ToastsService } from "side-ui";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export const shiftShchema = z.object({
    startDate: z.string().datetime(),
    endDate: z.string().datetime(),
    breakDuration: z.number(),
    slots: z.number(),
    recurrence: z
        .object({
            type: z.enum(["weekly", "monthly"]),
            interval: z.number().min(1).max(50),
            until: z.string().datetime(),
            withHolidays: z.boolean().catch(false),
            days: z.array(z.number().min(0).max(6)).optional(),
            setPos: z.array(z.number().min(-4).max(4)).optional(),
        })
        .optional(),
});

const addShiftParamsSchema = z.object({
    taskId: z.string(),
    shift: shiftShchema,
});

export type AddShiftParams = z.input<typeof addShiftParamsSchema>;
export async function addShift({ taskId, shift }: AddShiftParams) {
    debug.addBreadcrumb({
        message: `addShift`,
        category: `action`,
        data: shift,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${taskId}/shifts`, {
                method: `POST`,
                body: JSON.stringify(shift),
            }),
        );

        if (resp.status === 400) {
            ToastsService.addToast({
                id: "patch-shifts",
                icon: "Cross",
                content: i18n.t("error_invalid_shift"),
                isClosable: true,
                type: "error",
            });
            return {};
        }

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("addShift"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("addShift"));
    }
}
