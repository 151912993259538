import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf, JobDescriptionSchema } from "./index";

const message = "Update existing job description";
type UpdateJobDescriptionParams = z.infer<typeof JobDescriptionSchema>;
export async function updateJobDescription(jobDescription: UpdateJobDescriptionParams) {
    debug.addBreadcrumb({
        message,
        category: `action`,
        data: jobDescription,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/job-descriptions/${jobDescription.id}`, {
                method: `PATCH`,
                body: JSON.stringify(jobDescription),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }
        return JobDescriptionSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
