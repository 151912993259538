import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type GetNumberOfContractsBatchesResponse = {
    count: number;
};
export function getNumberOfContractsBatches() {
    debug.addBreadcrumb({
        message: `Get Number Of contractsBatches`,
        category: `action`,
    });

    const organisationId = localStorage.getItem("side_team_activeOrganisationId");
    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${organisationId}/documents/unsigned/count`,
        ),
    );
}
