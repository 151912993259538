const translation = {
  "EQ_AUDITIVE_PROTECTION": "Auditive protection",
  "EQ_BIKE": "Bike",
  "EQ_FACIAL_SCREEN": "Facial screen",
  "EQ_GLOVES": "Gloves",
  "EQ_HARD_HAT": "Hard hat",
  "EQ_HEADPHONES_AND_MIC": "Headphones \u0026 microphone",
  "EQ_LAPTOP": "Laptop",
  "EQ_PROTECTIVE_CLOTHING": "Protective clothing",
  "EQ_PROTECTIVE_GLASSES": "Protective glasses",
  "EQ_RESPIRATORY_PROTECTION": "Respiratory protection",
  "EQ_SAFETY_SHOES": "Safety shoes",
  "EQ_SMARTPHONE": "Smartphone",
  "EQ_YELLOW_JACKET": "Yellow jacket",
  "LI_CACES_1": "CACES 1",
  "LI_CACES_3": "CACES 3",
  "LI_CACES_5": "CACES 5",
  "LI_DRIVING_LICENSE_B": "Driving license",
  "RIO_LOAD_HEAVY": "Heavy load (+15kg)",
  "RIO_LOAD_LIGHT": "Light load (less than 5kg)",
  "RIO_LOAD_MEDIUM": "Medium load (5 to 15kg)",
  "RIO_TEMP_CHILL": "Chill (3 to 5°C)",
  "RIO_TEMP_COLD": "Cold (0 to 3°C)",
  "RIO_TEMP_HOT": "Hot (\u003e=30°C)",
  "RIO_TEMP_NEGATIVE_COLD": "Negative cold (\u003c0°C)",
  "RI_ALTERNATING_TEAMS": "Work in successive alternating teams",
  "RI_CHEMICALS_DUST_FUMES": "Chemical agents including dusts and fumes",
  "RI_EXTREME_TEMPERATURES": "Extreme temperatures",
  "RI_HARD_POSTURES": "Hard Postures",
  "RI_HYPERBARIC_ENV": "Activites practised in hyperbaric environment",
  "RI_MANUAL_HANDLING": "Manual handling",
  "RI_NIGHT_WORK": "Night work",
  "RI_NOISE": "Noise",
  "RI_REPETITIVE_WORK": "Repetitive work",
  "RI_VIBRATIONS": "Mechanical vibrations",
  "TO_EXCEL": "Excel",
  "TO_GOOGLE_DRIVE": "Google Drive",
  "TO_INTERCOM": "Intercom",
  "TO_NOTION": "Notion",
  "TO_POWER_POINT": "Powerpoint",
  "TO_SLACK": "Slack",
  "TO_ZENDESK": "Zendesk",
  "action_cancel": "Cancel",
  "action_confirm": "Confirm",
  "action_unauthorized": "Unauthorized",
  "action_unauthorized_extended": "You are not authorized to perform this action.",
  "action_unauthorized_extended_logas": "You cannot perform this action in log as.",
  "activate_account_form_title": "Activate my account",
  "activate_account_link_broken": "Your activation link seems not to work anymore.\\n\\n\n\nAsk a coworker to send you an other activation link.",
  "activate_account_log_in": "Activate my account",
  "activate_account_logo_company": "Company",
  "activate_account_or": "or",
  "activate_account_password_placeholder": "Password",
  "activate_account_title": "Sign in to Side",
  "activate_account_with_google": "Activate account with google",
  "add": "Add",
  "advanced": "Advanced",
  "alb": "Albanian",
  "ara": "Arabic",
  "arm": "Armenian",
  "at": "at",
  "aym": "Aymara",
  "aze": "Azerbaijani",
  "back": "Back",
  "beginner": "Beginner",
  "bel": "Belarusian",
  "ben": "Bengali",
  "bul": "Bulgarian",
  "bur": "Burmese",
  "cancel": "Cancel",
  "cat": "Catalan",
  "chi": "Chinese",
  "cnr": "Montenegrin",
  "completed_section_row_status_cell_subtitle_contact_plural": "Find the %{siderNumber} Siders",
  "completed_section_row_status_cell_subtitle_contact_singular": "Find the Sider",
  "completed_section_row_status_cell_title_selection_done": "Mission completed!",
  "completed_section_row_task_cell_managed_by": "Managed by ",
  "completed_section_title": "Completed Missions",
  "confirm": "Confirm",
  "continue": "Continue",
  "contract_contestation_confirmation_toast": "",
  "contract_signature_confirmation_block_agreement": "By signing the contracts, you accept our \u003ca href='https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/cgu-uk.pdf' target='_blank'\u003eterms and conditions\u003c/a\u003e.",
  "contract_signature_confirmation_block_checkbox": "I read and checked all information of all the contracts in this document",
  "contract_signature_confirmation_block_error": "There was an issue while signing the contracts!",
  "contract_signature_confirmation_block_notice": "Once the contracts are signed, you agree to pay the Siders.",
  "contract_signature_confirmation_block_number": "Number of contracts",
  "contract_signature_confirmation_block_title": "Contracts signature",
  "contract_signature_confirmation_toast": "The document has just been signed. You can find your contracts in the signed section.",
  "contract_signature_document_error_button_label": "Go back to the contracts list",
  "contract_signature_document_error_subtitle": "The document is actually unavailable. If the problem persists, please contact the support.",
  "contract_signature_document_error_title": "An error occured!",
  "contract_signature_pagination_last_page": "Go to last page of the document in order to sign your contracts. ",
  "contract_signature_phone_number_required": "You must first configure your phone number in your settings to be able to sign contracts.",
  "contract_signature_regenerate_document_label": "Re-generate the document",
  "contract_signature_regeneration_success": "The document is being re-generated. This can takes few seconds.",
  "contract_signature_report_button_label": "An error on a contract?",
  "contract_signature_see_contestation_button_label": "See the contestation",
  "contract_signature_sign_button_label": "Sign the contracts",
  "contracts_batch_row_mobile_date": "Start date: ",
  "contracts_batch_row_mobile_manager": "Managed by",
  "contracts_batch_row_mobile_number": "contract(s)",
  "contracts_batch_row_status_contested": "contested",
  "contracts_batch_row_status_contested_button_label": "See",
  "contracts_batch_row_status_disputed_tooltip_content": "You contested this document, we'll soon come back to you with an updated version.",
  "contracts_batch_row_status_disputed_tooltip_title": "Since %{contestationDate}",
  "contracts_batch_row_status_signed": "signed",
  "contracts_batch_row_status_signed_button_label": "Download",
  "contracts_batch_row_status_signed_tooltip_content": "Signed by %{signatureAuthorName} on %{signatureDate} at %{signatureTime}.",
  "contracts_batch_row_status_tosign": "to sign",
  "contracts_batch_row_status_tosign_button_label": "Review and sign",
  "contracts_batch_row_status_tosign_late_tooltip_content": "Contracts must be signed within 48h after the beginning of the task",
  "contracts_batch_row_status_tosign_late_tooltip_title": "%{numberOfDays} days late",
  "contracts_batches_list_header_date": "Start date",
  "contracts_batches_list_header_manager": "Manager",
  "contracts_batches_list_header_number": "Number of contracts",
  "contracts_batches_list_header_status": "Status",
  "contracts_batches_list_header_task": "Task",
  "contracts_batches_signed_blank_state_content": "You don’t have any signed contracts.",
  "contracts_batches_signed_blank_state_title": "Nothing to see here!",
  "contracts_batches_tosign_blank_state_content": "You have no pending contracts to sign.",
  "contracts_batches_tosign_blank_state_title": "All good here!",
  "contracts_contestation_modal_cancel": "Cancel",
  "contracts_contestation_modal_details": "Contested on %{contestationDate} by %{contestationAuthorName}.",
  "contracts_contestation_modal_input_label": "Contestation motive",
  "contracts_contestation_modal_input_placeholder": "Tell us what is wrong with the document",
  "contracts_contestation_modal_send": "Send",
  "contracts_contestation_modal_subtitle_contested": "The contracts of this task have been contested, we'll soon come back to you with an updated version.",
  "contracts_contestation_modal_subtitle_tocontest": "Explain why you contest this contract so that our team can get back to you with a corrected version of the document.",
  "contracts_contestation_modal_title": "Contest contract information",
  "contracts_top_menu_filter_signed": "Signed",
  "contracts_top_menu_filter_to_sign": "To sign",
  "countries_belgium": "Belgium",
  "countries_denmark": "Denmark",
  "countries_estonia": "Estonia",
  "countries_france": "France",
  "countries_germany": "Germany",
  "countries_great_britain": "United Kingdom",
  "countries_italy": "Italy",
  "countries_netherlands": "Netherlands",
  "countries_portugal": "Portugal",
  "countries_spain": "Spain",
  "country_albania": "Albania",
  "country_algeria": "Algeria",
  "country_andorra": "Andorra",
  "country_angola": "Angola",
  "country_antigua_and_barbuda": "Antigua And Barbuda",
  "country_argentina": "Argentina",
  "country_armenia": "Armenia",
  "country_australia": "Australia",
  "country_austria": "Austria",
  "country_azerbaijan": "Azerbaijan",
  "country_bahamas": "Bahamas",
  "country_bahrain": "Bahrain",
  "country_bangladesh": "Bangladesh",
  "country_barbados": "Barbados",
  "country_belarus": "Belarus",
  "country_belgium": "Belgium",
  "country_belize": "Belize",
  "country_benin": "Benin",
  "country_bhutan": "Bhutan",
  "country_bolivia": "Bolivia",
  "country_bosnia_and_herzegovina": "Bosnia And Herzegovina",
  "country_botswana": "Botswana",
  "country_brazil": "Brazil",
  "country_brunei": "Brunei",
  "country_bulgaria": "Bulgaria",
  "country_burkina_faso": "Burkina Faso",
  "country_burundi": "Burundi",
  "country_cambodia": "Cambodia",
  "country_cameroon": "Cameroon",
  "country_canada": "Canada",
  "country_cape_verde": "Cape Verde",
  "country_chad": "Chad",
  "country_chile": "Chile",
  "country_china": "China",
  "country_colombia": "Colombia",
  "country_comoros": "Comoros",
  "country_congo_brazzaville": "Congo Brazzaville",
  "country_congo_kinshasa": "Congo Kinshasa",
  "country_cook_islands": "Cook Islands",
  "country_costa_rica": "Costa Rica",
  "country_croatia": "Croatia",
  "country_cuba": "Cuba",
  "country_cyprus": "Cyprus",
  "country_czech_republic": "Czech Republic",
  "country_denmark": "Denmark",
  "country_djibouti": "Djibouti",
  "country_dominica": "Dominica",
  "country_ecuador": "Ecuador",
  "country_egypt": "Egypt",
  "country_el_salvador": "El Salvador",
  "country_equatorial_guinea": "Equatorial Guinea",
  "country_eritrea": "Eritrea",
  "country_estonia": "Estonia",
  "country_ethiopia": "Ethiopia",
  "country_fiji": "Fiji",
  "country_finland": "Finland",
  "country_france": "France",
  "country_gabon": "Gabon",
  "country_gambia": "Gambia",
  "country_georgia": "Georgia",
  "country_germany": "Germany",
  "country_ghana": "Ghana",
  "country_greece": "Greece",
  "country_grenada": "Grenada",
  "country_guatemala": "Guatemala",
  "country_guinea": "Guinea",
  "country_guinea_bissau": "Guinea Bissau",
  "country_guyana": "Guyana",
  "country_haiti": "Haiti",
  "country_honduras": "Honduras",
  "country_hungary": "Hungary",
  "country_iceland": "Iceland",
  "country_india": "India",
  "country_indonesia": "Indonesia",
  "country_iran": "Iran",
  "country_iraq": "Iraq",
  "country_ireland": "Ireland",
  "country_israel": "Israel",
  "country_italy": "Italy",
  "country_jamaica": "Jamaica",
  "country_japan": "Japan",
  "country_jordan": "Jordan",
  "country_kazakhstan": "Kazakhstan",
  "country_kenya": "Kenya",
  "country_kiribati": "Kiribati",
  "country_kosovo": "Kosovo",
  "country_kuwait": "Kuwait",
  "country_kyrgyzstan": "Kyrgyzstan",
  "country_laos": "Laos",
  "country_latvia": "Latvia",
  "country_lebanon": "Lebanon",
  "country_lesotho": "Lesotho",
  "country_liberia": "Liberia",
  "country_libya": "Libya",
  "country_liechtenstein": "Liechtenstein",
  "country_lithuania": "Lithuania",
  "country_luxembourg": "Luxembourg",
  "country_macedonia": "Macedonia",
  "country_madagascar": "Madagascar",
  "country_malawi": "Malawi",
  "country_malaysia": "Malaysia",
  "country_maldives": "Maldives",
  "country_mali": "Mali",
  "country_malta": "Malta",
  "country_marshall_islands": "Marshall Islands",
  "country_mauritania": "Mauritania",
  "country_mauritius": "Mauritius",
  "country_mexico": "Mexico",
  "country_micronesia": "Micronesia",
  "country_moldova": "Moldova",
  "country_monaco": "Monaco",
  "country_mongolia": "Mongolia",
  "country_montenegro": "Montenegro",
  "country_morocco": "Morocco",
  "country_mozambique": "Mozambique",
  "country_myanmar": "Myanmar",
  "country_namibia": "Namibia",
  "country_nauru": "Nauru",
  "country_nepal": "Nepal",
  "country_netherlands": "Netherlands",
  "country_new_zealand": "New Zealand",
  "country_nicaragua": "Nicaragua",
  "country_niger": "Niger",
  "country_nigeria": "",
  "country_north_korea": "North Korea",
  "country_norway": "Norway",
  "country_oman": "Oman",
  "country_pakistan": "Pakistan",
  "country_palau": "Palau",
  "country_palestine": "Palestine",
  "country_panama": "Panama",
  "country_papua_new_guinea": "Papua New Guinea",
  "country_paraguay": "Paraguay",
  "country_peru": "Peru",
  "country_philippines": "Philippines",
  "country_poland": "Poland",
  "country_portugal": "Portugal",
  "country_qatar": "Qatar",
  "country_romania": "Romania",
  "country_russia": "Russia",
  "country_rwanda": "Rwanda",
  "country_saint_kitts_and_nevis": "Saint Kitts And Nevis",
  "country_saint_lucia": "Saint Lucia",
  "country_saint_vincent_and_the_grenadines": "Saint Vincent And The Grenadines",
  "country_samoa": "Samoa",
  "country_san_marino": "San Marino",
  "country_sao_tome_and_principe": "Sao Tome And Principe",
  "country_saudi_arabia": "Saudi Arabia",
  "country_senegal": "Senegal",
  "country_serbia": "Serbia",
  "country_seychelles": "Seychelles",
  "country_sierra_leone": "Sierra Leone",
  "country_singapore": "Singapore",
  "country_slovakia": "Slovakia",
  "country_slovenia": "Slovenia",
  "country_solomon_islands": "Solomon Islands",
  "country_somalia": "Somalia",
  "country_south_africa": "South Africa",
  "country_south_korea": "South Korea",
  "country_south_sudan": "South Sudan",
  "country_spain": "Spain",
  "country_sri_lanka": "Sri Lanka",
  "country_sudan": "Sudan",
  "country_suriname": "Suriname",
  "country_swaziland": "Swaziland",
  "country_sweden": "Sweden",
  "country_switzerland": "Switzerland",
  "country_syria": "Syria",
  "country_tajikistan": "Tajikistan",
  "country_tanzania": "Tanzania",
  "country_thailand": "Thailand",
  "country_togo": "Togo",
  "country_tonga": "Tonga",
  "country_trinidad_and_tobago": "Trinidad And Tobago",
  "country_tunisia": "Tunisia",
  "country_turkey": "Turkey",
  "country_turkmenistan": "Turkmenistan",
  "country_tuvalu": "Tuvalu",
  "country_uganda": "Uganda",
  "country_ukraine": "Ukraine",
  "country_united_arab_emirates": "United Arab Emirates",
  "country_united_kingdom": "United Kingdom",
  "country_united_states": "United States",
  "country_uruguay": "Uruguay",
  "country_uzbekistan": "Uzbekistan",
  "country_vanuatu": "Vanuatu",
  "country_vatican": "Vatican",
  "country_venezuela": "Venezuela",
  "country_vietnam": "Vietnam",
  "country_yemen": "Yemen",
  "country_zambia": "Zambia",
  "country_zimbabwe": "Zimbabwe",
  "cze": "Czech",
  "dan": "Danish",
  "deadline": "Deadline",
  "delete": "Delete",
  "delete_draft": "Delete draft",
  "details_completion_hoursTypesInformations": "Remuneration policy - Hour types",
  "details_completion_indemnitiesInformations": "Remuneration policy - Benefits",
  "details_completion_legalInformations": "Legal information",
  "details_completion_paymentInformations": "Payment information",
  "details_completion_profile": "Company profile",
  "details_completion_status_incomplete": "Incomplete",
  "details_completion_validate_label": "Complete my information",
  "doc_title_contracts": "Contracts",
  "doc_title_contracts_signature": "Contracts signature",
  "doc_title_home": "Home",
  "doc_title_invoices": "Invoices",
  "doc_title_job-descriptions": "Job Descriptions",
  "doc_title_logas": "Admin",
  "doc_title_not_found": "Not found",
  "doc_title_planning": "Planning",
  "doc_title_posting": "New task",
  "doc_title_preselection": "Preselection",
  "doc_title_settings": "Settings",
  "doc_title_tasks": "Missions",
  "doc_title_timesheets": "Timesheets",
  "draft_deleted_success": "Draft deleted !",
  "draft_section_row_status_cell_subtitle": "Finish draft",
  "draft_section_row_status_cell_title": "Draft",
  "draft_section_row_task_cell_created_by": "Created by ",
  "draft_section_title": "Drafts",
  "duplicate": "Duplicate",
  "duplicate_draft": "Duplicate draft",
  "duplicate_task": "Duplicate task",
  "empty_placeholder_all_subtitle": "All your mission will appear here.\u003cbr\u003eTo start, click the \\\"+ Post a new task\\\" button at the bottom of the left navigation.",
  "empty_placeholder_all_title": "Hello %{firstName}!",
  "empty_placeholder_completed_subtitle": "You'll be able to find here every completed missions.\u003cbr\u003e\nOne of the Siders did a great job? Don't hesitate to \\\"Duplicate the mission\\\", if he/she is ok and available, you'll be able to directly ask for him/her.",
  "empty_placeholder_completed_title": "No completed mission",
  "empty_placeholder_draft_subtitle": "Any unfinished needs? You'll be able to find and complete them here.",
  "empty_placeholder_draft_title": "No pending draft",
  "empty_placeholder_ongoing_subtitle": "You'll be able to find here every mission on which the Siders are working.",
  "empty_placeholder_ongoing_title": "No ongoing task",
  "empty_placeholder_planning_cta": "Post a new mission",
  "empty_placeholder_planning_subtitle": "You don't have any mission planned this week. Display an other week or post a new one",
  "empty_placeholder_planning_title": "Nothing planned for this week. You can relax!",
  "empty_placeholder_upcoming_subtitle": "You'll be able to find every upcoming mission here.",
  "empty_placeholder_upcoming_title": "No upcoming task",
  "eng": "English",
  "error_confirmation_not_equal_password": "The two passwords are different",
  "error_field_is_required": "This field is required.",
  "error_field_is_required_checkbox": "This answer is required. Please select at least one option.",
  "error_field_required": "This field is mandatory",
  "error_file_size": "File size exceeds the limit of %{maxSize}MB",
  "error_invalid_shift": "The shift you created is not valid. Its duration must be between 2h and 10h of work break included.",
  "error_no_auth": "An error occured: we could not authenticate you.",
  "error_password_length_8": "Your password must be at least 8 characters long",
  "error_password_special_char": "Your password must contain at least one special character",
  "error_phone_number_invalid": "Invalid phone number",
  "errors.organisation.forbidden.blocked": "",
  "errors.task_salary.forbidden.too_low": "The job description couldn't be created, the pay must be equal or above the current minimum wage.",
  "errors_email_conflict": "Email already in use. Please connect instead",
  "errors_generic": "An error occured",
  "errors_organisation_forbidden_blocked": "Oops, you aren’t authorized to post tasks, please get in touch with your account manager.",
  "errors_shift_conflict_duplicated": "This shift has already been posted, you can't duplicate it.",
  "errors_shift_forbidden_post_in_the_past": "This task includes shifts posted in the past, you can either delete them or get in touch with our support team",
  "errors_sider_conflict_already-exists": "This sider already exists on Side's platform. Please reach the support team if you still want to add it to your Side Flash siders.",
  "errors_slot_forbidden_requested_siders_count": "Oops, you requested too many Siders given the number of slots available for this task.",
  "errors_task_forbidden_not_enough_siders_selected": "Not enough Siders selected",
  "errors_token_forbidden": "Your activation link is expired or invalid.\\n\\n\n\nAsk a coworker to send you an other activation link.",
  "errors_user_unauthorized_login_failed": "Failed to log in. Check your credentials and try again.",
  "est": "Estonian",
  "expert": "Expert",
  "feedback_dialog_content": "Changes you made won’t be taken into account.",
  "feedback_dialog_title": "Don't save changes?",
  "feedback_form_comment_question": "Do you have anything to add about %{name}?",
  "feedback_form_comment_question_label": "Private comment (optional)",
  "feedback_form_comment_question_placeholder": "E.g. I really liked how dynamic he was but he could be more thorough...",
  "feedback_form_general_answer_1": "Yes",
  "feedback_form_general_answer_1_notice": "I'm satisfied",
  "feedback_form_general_answer_2": "No",
  "feedback_form_general_answer_2_notice": "I'm not satisfied",
  "feedback_form_general_question": "Would you be ready to work again with %{name} on similar tasks?",
  "feedback_form_issue_choice_1": "Professionalism",
  "feedback_form_issue_choice_1_notice": "The Sider's behaviour wasn't professional enough",
  "feedback_form_issue_choice_2": "Performance",
  "feedback_form_issue_choice_2_notice": "The Sider couldn't reach his goals.",
  "feedback_form_issue_description": "We are sorry to hear it didn't match with your expectations.Please tell us what went wrong with this Sider.",
  "feedback_form_issue_question": "What was the issue with %{name}?",
  "feedback_form_send_no_comment": "Send\u003cspan class=\\\"send-hide-mobile\\\"\u003e without any comment\u003c/span\u003e",
  "feedback_modal_exit_description": "Changes you made won’t be taken into account.",
  "feedback_modal_exit_title": "Don’t save changes ?",
  "feedback_modal_title": "Your feedback about...",
  "feedback_tip_description": "Tell us if you were satisfied with this Sider. This will help us suggest to you only the profiles that you liked. You can edit this feedback whenever you want.",
  "feedback_tip_description_rated": "Don't forget to tell us whether you were satisfied or not with your Siders. This will help us suggest to you only the profiles that you liked. You can edit this feedback whenever you want.",
  "feedback_tip_title": "Would you be ready to work with this Sider again?",
  "feedback_tip_title_rated": "Have you changed your mind about this Sider?",
  "feedback_toast_not_satisfied": "You aren't satisfied with %{name}'s service. The Sider has been removed from your favourite Siders's list",
  "feedback_toast_not_saved": "Your feedback about %{name} wasn't saved.",
  "feedback_toast_satisfied": "You are satisfied with %{name}'s service! The Sider has been added to your favourite Siders' list, you'll be able to request this Sider in priority.",
  "feedback_tooltip_not_satisfied": "I'm not satisfied with this Sider",
  "feedback_tooltip_satisfied": "I'm satisfied with this Sider",
  "fin": "Finnish",
  "finish": "Finish",
  "finish_draft": "Finish draft",
  "fluent": "Fluent",
  "fre": "French",
  "ger": "German",
  "go_to_contracts_signature_page": "Sign contracts",
  "gre": "Greek",
  "grn": "Guarani",
  "guard_modal_incomplete": "incomplete",
  "guard_modal_legal": "Legal information",
  "guard_modal_payment": "Payment information",
  "guard_modal_policy_hours": "Salary policy - Hours type",
  "guard_modal_policy_indemnities": "Salary policy - Benefits",
  "guard_modal_profile": "Company profile",
  "guard_modal_submit": "Update my information",
  "guard_modal_subtitle": "Complete your profile to post missions",
  "guard_modal_title": "Update your information",
  "header_backhome": "Home",
  "header_contracts": "Contracts",
  "header_conversations": "Messages",
  "header_cta_task": "Post a new task",
  "header_cta_team": "My team",
  "header_dashboard": "Dashboard",
  "header_dropdown_help": "Help center",
  "header_dropdown_language": "Voir en Français",
  "header_dropdown_logout": "Sign out",
  "header_dropdown_payment_settings": "Payment information",
  "header_dropdown_personal_settings": "Personal information",
  "header_dropdown_profile": "Company profile",
  "header_dropdown_settings": "Entity settings",
  "header_dropdown_team": "Team",
  "header_invoices": "Invoices",
  "header_job-descriptions": "Job Descriptions",
  "header_payments": "Payments",
  "header_planning": "Planning",
  "header_search_company": "Search",
  "header_settings": "Settings",
  "header_siders": "Siders",
  "header_switch_search_placeholder": "Search",
  "header_tasks": "Tasks",
  "header_team": "Team",
  "header_timesheets": "Timesheets",
  "heb": "Hebrew",
  "hin": "Hindi",
  "home__company-logo__button__tooltip": "Update logo",
  "home_connecttochild_button": "Access the workspace",
  "home_search_child_label": "Search for an entity",
  "home_search_child_placeholder": "Name or address of the entity",
  "home_welcome_subtitle": "Post and manage your tasks by accessing your entity's workspace",
  "home_welcome_title": "Welcome to %{groupName}",
  "horizon_acquisition_argument_0": "First qualified CVs in \u003cb\u003e1 week\u003c/b\u003e",
  "horizon_acquisition_argument_1": "\u003cb\u003eQualified profiles\u003c/b\u003e in video of at least 30 mins",
  "horizon_acquisition_argument_2": "\u003cb\u003eTransparent\u003c/b\u003e feedback at every stage",
  "horizon_acquisition_title": "A dedicated recruitment expert",
  "horizon_dialog_message": "A Side recruitment expert is now dedicated to you to discuss it and develop your tailor-made offer with you.",
  "horizon_dialog_primary_action": "Book a call",
  "horizon_dialog_secondary_action": "Later",
  "horizon_dialog_title": "Thank you! We have noted your need for a permanent contract!",
  "horizon_differentiate_title": "What sets us apart",
  "horizon_modal_address_label": "Address",
  "horizon_modal_commentary_label": "Further information",
  "horizon_modal_commentary_placeholder": "Link to a job description or other element to share",
  "horizon_modal_confirmation": "Your permanent contract requirement has been submitted. We will get back to you as soon as possible!",
  "horizon_modal_jobdesc_document_label": "Document",
  "horizon_modal_jobdesc_label": "Job description or job offer",
  "horizon_modal_jobdesc_link_label": "Link",
  "horizon_modal_jobdesc_none_label": "Not available at the moment",
  "horizon_modal_jobtitle_label": "Job title",
  "horizon_modal_positions_label": "Number of permanent contract requirements",
  "horizon_modal_salary_label": "Gross annual salary",
  "horizon_modal_startdate_label": "Planned start date",
  "horizon_modal_title": "Need to recruit on a permanent basis",
  "horizon_nav_button_desc": "Discover the Horizon offer!",
  "horizon_nav_button_title": "Need a permanent contract?",
  "horizon_pool_argument_0": "A \u003cb\u003ededicated\u003c/b\u003e mobile application for candidates",
  "horizon_pool_argument_1": "\u003cb\u003e100K candidates\u003c/b\u003e looking for permanent contracts in France",
  "horizon_pool_argument_2": "History of candidates who have \u003cb\u003ealready\u003c/b\u003e worked with us",
  "horizon_pool_title": "A unique \u0026 motivated pool",
  "horizon_reassurance_0": "Charged \u003cb\u003eonly\u003c/b\u003e on success",
  "horizon_reassurance_1": "Based on \u003cb\u003egross annual salary\u003c/b\u003e excluding tax",
  "horizon_reassurance_2": "No deposit or exclusivity",
  "horizon_reassurance_3": "\u003cb\u003eGuarantee\u003c/b\u003e 30 days",
  "horizon_step_0": "Submit your need",
  "horizon_step_1": "Develop a tailor-made offer with your dedicated expert",
  "horizon_step_2": "Receive the best CVs and interview reports",
  "horizon_step_3": "Continue your internal recruitment process",
  "horizon_step_4": "Our talent is now yours!",
  "horizon_steps_title": "5 key moments",
  "horizon_subtitle": "Recruit on a permanent basis using a simple, efficient and transparent process.",
  "horizon_title": "Recruit on a permanent contract with the Horizon offer",
  "hrv": "Croatian",
  "hun": "Hungarian",
  "ice": "Icelandic",
  "ind": "Indonesian",
  "industry_logistics": "Logistics",
  "industry_office": "Office",
  "industry_retail": "Retail",
  "intermediate": "Intermediate",
  "invoices_amount": "Amount Incl. tax",
  "invoices_date": "Date",
  "invoices_download": "Download the invoice",
  "invoices_download_error": "Invoice unavailable",
  "invoices_monthly_download": "Download all invoices for %{month}",
  "invoices_monthly_download_failed": "Download available invoices for %{month}",
  "invoices_monthly_error_body": "Please contact our online support for more information.",
  "invoices_monthly_error_title": "One invoice or more couldn't be issued.",
  "invoices_monthly_notice": "In %{month} you spent %{amount} € on the platform. We bundled all your invoices for your accounting right here!",
  "invoices_monthly_notice_failed": "In %{month} you spent %{amount} € on the platform. We bundled your available invoices for your accounting right here!",
  "invoices_number": "Document number",
  "invoices_sider": "Sider",
  "invoices_status": "Status",
  "invoices_status_paid": "Paid",
  "invoices_status_unpaid": "To pay",
  "invoices_task": "Task",
  "invoices_type": "Type",
  "invoices_type_creditNote": "Credit note",
  "invoices_type_invoice": "Invoice",
  "ita": "Italian",
  "job-description_creation_success": "Your job description was successfully created",
  "job-description_edit_success": "Your job description was successfully updated",
  "job-descriptions_copy_id": "ID copied!",
  "job-descriptions_create_button": "Create",
  "job-descriptions_creation_conditions_context_goals_characters": "400 signs",
  "job-descriptions_creation_conditions_context_goals_placeholder": "You'll be part of a team of 5 and your scope will include...",
  "job-descriptions_creation_conditions_context_goals_title": "Context and goals",
  "job-descriptions_creation_conditions_context_subtitle": "You can give some indications about the context to help Siders understand the mission better.",
  "job-descriptions_creation_conditions_context_title": "About",
  "job-descriptions_creation_conditions_equipment_provider_company": "Provided by you",
  "job-descriptions_creation_conditions_equipment_provider_side": "Provided by Side",
  "job-descriptions_creation_conditions_equipment_provider_sider": "Provided by the Sider",
  "job-descriptions_creation_conditions_equipment_table_header_label": "Equipment type",
  "job-descriptions_creation_conditions_equipment_table_header_value": "Details",
  "job-descriptions_creation_conditions_equipments_dresscode_placeholder": "If needed, indicate the required dress code",
  "job-descriptions_creation_conditions_equipments_dresscode_title": "Dress code",
  "job-descriptions_creation_conditions_equipments_other_add_button": "Add equipment",
  "job-descriptions_creation_conditions_equipments_other_add_custom_button": "Add a custom element",
  "job-descriptions_creation_conditions_equipments_other_custom_modal_input_label": "Equipment name",
  "job-descriptions_creation_conditions_equipments_other_custom_modal_tip": "If you didn't find the equipment you want in the list, you can add a custom one just below.",
  "job-descriptions_creation_conditions_equipments_other_delete_button": "Delete this equipment",
  "job-descriptions_creation_conditions_equipments_other_subtitle": "Select the equipment that the Siders will need to bring.",
  "job-descriptions_creation_conditions_equipments_other_title": "Other equipment",
  "job-descriptions_creation_conditions_equipments_risks_label1": "Occupational health risks",
  "job-descriptions_creation_conditions_equipments_risks_label2": "Additional information",
  "job-descriptions_creation_conditions_equipments_risks_options": "Select an option",
  "job-descriptions_creation_conditions_equipments_risks_placeholder": "Add any detail you might find important to take the risks into account.",
  "job-descriptions_creation_conditions_equipments_risks_subtitle": "Indicate the occupational health risks for this mission, they are set by labor law and require to implement prevention measures.",
  "job-descriptions_creation_conditions_equipments_risks_title": "Risks and difficult working conditions",
  "job-descriptions_creation_conditions_equipments_security_add_button": "Add PPE",
  "job-descriptions_creation_conditions_equipments_security_add_custom_button": "Add a custom item",
  "job-descriptions_creation_conditions_equipments_security_custom_modal_input_label": "Name of PPE",
  "job-descriptions_creation_conditions_equipments_security_custom_modal_tip": "If you didn't find the equipment you want in the list, you can add a custom one just below",
  "job-descriptions_creation_conditions_equipments_security_delete_button": "Delete this equipment",
  "job-descriptions_creation_conditions_equipments_security_modal_choice_no": "No",
  "job-descriptions_creation_conditions_equipments_security_modal_choice_yes": "Yes",
  "job-descriptions_creation_conditions_equipments_security_modal_label": "Do you provide this equipment?",
  "job-descriptions_creation_conditions_equipments_security_modal_subtitle": "Indicate if you provide the safety equipment",
  "job-descriptions_creation_conditions_equipments_security_subtitle": "Select the necessary PPE to ensure Siders safety.",
  "job-descriptions_creation_conditions_equipments_security_title": "Safety Equipment",
  "job-descriptions_creation_conditions_equipments_subtitle": "Indicate the Personal Protective Equipment (PPE) and other items that are necessary for the mission.",
  "job-descriptions_creation_conditions_equipments_title": "Equipment",
  "job-descriptions_creation_conditions_step_title": "Work conditions",
  "job-descriptions_creation_job_jobtitle_subtasks": "%{numberOfTasks} tasks selected",
  "job-descriptions_creation_job_jobtitle_subtitle": "This information will allow us to select Siders that will match the job.",
  "job-descriptions_creation_job_jobtitle_title": "Job title",
  "job-descriptions_creation_job_name_title": "Name of the job description",
  "job-descriptions_creation_job_step_title": "Job",
  "job-descriptions_creation_job_subtasks_custom_add_button": "Add a custom element",
  "job-descriptions_creation_job_subtasks_custom_add_button_input": "Add '%{searchInput}'",
  "job-descriptions_creation_job_subtasks_custom_modal_label": "Task name",
  "job-descriptions_creation_job_subtasks_custom_modal_placeholder": "Custom task",
  "job-descriptions_creation_job_subtasks_custom_modal_subtitle": "If you didn't find the task you need in the list, you can add a custom one just below.",
  "job-descriptions_creation_job_subtasks_custom_modal_title": "Add a custom element",
  "job-descriptions_creation_job_subtasks_subtitle": "Select tasks that Siders will need to fill during the mission",
  "job-descriptions_creation_job_subtasks_title": "Tasks",
  "job-descriptions_creation_location_step_title": "Location",
  "job-descriptions_creation_salary_input_error": "Please check the value you entered",
  "job-descriptions_creation_salary_input_error_underflow": "Please input a salary that is equal to or above the minimal wage",
  "job-descriptions_creation_salary_input_label": "Base salary (hourly)",
  "job-descriptions_creation_salary_input_placeholder": "Superior or equal to %{minimumHourlyRate}€/hour",
  "job-descriptions_creation_salary_label2": "Hourly rates and coefficient",
  "job-descriptions_creation_salary_raw": "Gross hourly rate",
  "job-descriptions_creation_salary_step_title": "Salary",
  "job-descriptions_creation_salary_subtitle": "Indicate the base salary for this job, it will allow us to give you a price estimate for your next orders. According to the equality of treatment principle, enter the base salary that matches your company's grid for permanent employees.",
  "job-descriptions_creation_salary_title": "Pay",
  "job-descriptions_creation_skills_experience_label": "Type of work experience",
  "job-descriptions_creation_skills_experience_placeholder": "We wish to find profiles that know about the industry...",
  "job-descriptions_creation_skills_experience_subtitle": "Indicate if you wish Siders to have specific work exeperience, this will enable Siders to assess chances to be selected for the mission.",
  "job-descriptions_creation_skills_experience_title": "Experience",
  "job-descriptions_creation_skills_languages_add_button": "Add a language",
  "job-descriptions_creation_skills_languages_edit_disclaimer": "Click the Save button to delete all languages.",
  "job-descriptions_creation_skills_languages_modal_label": "Level required",
  "job-descriptions_creation_skills_languages_modal_placeholder": "Select an option",
  "job-descriptions_creation_skills_languages_modal_subtitle": "Indicate the required level, it will be used to assess oral and written skills.",
  "job-descriptions_creation_skills_languages_modal_title": "Languages",
  "job-descriptions_creation_skills_languages_subtitles": "Select the languages that the Siders should master.",
  "job-descriptions_creation_skills_languages_title": "Languages",
  "job-descriptions_creation_skills_languages_tools_subtitle": "Indicate whether the Siders need to master any specific languages or tools.",
  "job-descriptions_creation_skills_languages_tools_title": "Languages and tools",
  "job-descriptions_creation_skills_licence_add_button": "Add a licence",
  "job-descriptions_creation_skills_licence_label": "Licence",
  "job-descriptions_creation_skills_licence_subtitle": "Indicate which types of licence are needed",
  "job-descriptions_creation_skills_licence_title": "Documents",
  "job-descriptions_creation_skills_step_title": "Skills",
  "job-descriptions_creation_skills_tools_add_button": "Add a tool",
  "job-descriptions_creation_skills_tools_add_custom_button": "Add a custom element",
  "job-descriptions_creation_skills_tools_custom_modal_label": "Tool name",
  "job-descriptions_creation_skills_tools_custom_modal_placeholder": "Photoshop, Teams, Salesforce...",
  "job-descriptions_creation_skills_tools_custom_modal_tip": "In case you didn't find the tool you want in the list, you can add a custom one just below.",
  "job-descriptions_creation_skills_tools_modal_excel_feedback": "- Débutant : Saisie de données, formules et calculs simples, impression de document, mise en forme de cellules.\n- Intermédiaire : Formules (SI, MAX, MIN, SOMME.SI, NB.SI), graphiques (création, mise en forme), fonctions de tri/filtres, création d’un tableau croisé dynamique, recherche de données, validation de données.\n- Avancé : Automatisation de tâches : exécuter des macros enregistrées, calculs avancés (calculs multi-feuilles/classeurs), fonctions avancées et formules imbriquées, fonctions de base de données, graphiques de TCD.\n- Expert : VBA, formules complexes (matricielles, financières), maîtrise experte de la mise en forme, calculs, TCD, outils d’analyse.",
  "job-descriptions_creation_skills_tools_modal_placeholder": "Select an option",
  "job-descriptions_creation_skills_tools_modal_subtitle": "Indicate the level needed",
  "job-descriptions_creation_skills_tools_subtitle": "Select the tools that the Siders should master.",
  "job-descriptions_creation_skills_tools_title": "Tools and software",
  "job-descriptions_creation_status_subtitle": "The status of your Siders will be reported in their employment contract and will determine the amount of social security contributions to be paid.",
  "job-descriptions_creation_status_title": "Status",
  "job-descriptions_draft": "Draft",
  "job-descriptions_edit_context_subtitle": "You can edit the indications about the context and the goals of the mission.",
  "job-descriptions_edit_devices_subtitle": "You can edit the equipment that the Siders will need to bring.",
  "job-descriptions_edit_dresscode_subtitle": "You can change the required dress code.",
  "job-descriptions_edit_experiences_subtitle": "You can edit the work experience you wish the Siders to have.",
  "job-descriptions_edit_jobTitle_subtitle": "You can change the job title, you will need to select the tasks related to this job title again.",
  "job-descriptions_edit_languages_subtitle": "You can edit the languages or tools the Siders should know.",
  "job-descriptions_edit_licences_subtitle": "You can edit which types of licence are needed.",
  "job-descriptions_edit_location_subtitle": "You can edit the information related to the mission location.",
  "job-descriptions_edit_manager_subtitle": "You can edit the name of the manager for this job.",
  "job-descriptions_edit_name_subtitle": "You can rename your job description to easily differentiate it, the custom name will be used as a reference on your invoices",
  "job-descriptions_edit_preselection": "Pre-selection of candidates",
  "job-descriptions_edit_risks_subtitle": "You can edit the occupational health risks for this mission.",
  "job-descriptions_edit_safetyEquipment_subtitle": "You can edit the list of safety equipment and other material required for the mission.",
  "job-descriptions_edit_salary_subtitle": "You can edit the base salary for this job.",
  "job-descriptions_edit_subtasks_subtitle": "You can edit the tasks that Siders will fill during this mission.",
  "job-descriptions_edit_tools_subtitle": "You can edit the tools that the Siders should master.",
  "job-descriptions_empty_create_button": "Create a job description",
  "job-descriptions_empty_subtitle": "Your job descriptions will allow you to place an order.",
  "job-descriptions_empty_subtitle_link_label": "Learn more",
  "job-descriptions_empty_title": "No job description",
  "job-descriptions_instructions": "In order to place a new order, please select an existing job description or create the job description that fits your needs.",
  "job-descriptions_instructions_account": "In order to place a new order, please select an existing job description or create the job description that fits your needs.",
  "job-descriptions_instructions_network": "You can create and update job descriptions that fit your needs.\\nThey will be usable directly by every account in your group.",
  "job-descriptions_jobtitle": "Job title",
  "job-descriptions_list_network_description": "These job descriptions come from your network, you can use them directly to create your orders.",
  "job-descriptions_new_title": "Create a job description",
  "job-descriptions_rename_field_placeholder": "Custom task name",
  "job-descriptions_rename_tip": "This new name will not be visible by Siders.",
  "job-descriptions_status_ETAM": "Technician employee and Supervisor (ETAM)",
  "job-descriptions_status_executive": "Executive",
  "job-descriptions_status_worker": "Worker",
  "job-descriptions_summary_context_title": "Context",
  "job-descriptions_summary_devices_title": "Other material",
  "job-descriptions_summary_dresscode_title": "Dress code",
  "job-descriptions_summary_experiences_title": "Experiences",
  "job-descriptions_summary_jobTitle_title": "Job Title",
  "job-descriptions_summary_languages_title": "Languages",
  "job-descriptions_summary_licences_title": "Licence",
  "job-descriptions_summary_location_title": "Address",
  "job-descriptions_summary_manager_title": "Manager",
  "job-descriptions_summary_name_title": "Name",
  "job-descriptions_summary_risks_title": "Risks and difficult working conditions",
  "job-descriptions_summary_safetyEquipment_title": "Safety equipment",
  "job-descriptions_summary_salary_title": "Pay",
  "job-descriptions_summary_status_title": "Status",
  "job-descriptions_summary_subtasks_title": "Tasks",
  "job-descriptions_summary_tools_title": "Tools and software",
  "job-descriptions_unauthorized_custom": "You can’t update your job descriptions because you benefited from a custom configuration. Contact us to operate changes",
  "job-descriptions_unauthorized_description": "You are not allowed to edit this job description",
  "job-descriptions_unauthorized_network": "This job description comes from your network, you are not authorized to update it.",
  "job-descriptions_unauthorized_network_redirect_description": "You can edit this job description from the network space",
  "job-descriptions_unauthorized_network_redirect_title": "Action unavailable",
  "job_types_contact_content": "Contact us using the chat or at \u003ca href='mailto:in@side.co'\u003ein@side.co\u003c/a\u003e, we might be able to help you!",
  "job_types_contact_title": "Can’t find what you're looking for?",
  "job_types_more": "And more...",
  "job_types_subtitle": "Side selects the best profiles for your company within hours and automates all the admin work.",
  "job_types_title": "Create a new task",
  "jpn": "Japanese",
  "just_posted_section_row_status_cell_subtitle": "Assessing your needs...",
  "just_posted_section_row_status_cell_title": "Selection is about to start...",
  "just_posted_section_row_task_cell_managed_by": "Managed by ",
  "just_posted_section_table_header_start_date": "Start date",
  "just_posted_section_table_header_status": "Information",
  "just_posted_section_table_header_task": "Mission",
  "just_posted_section_title": "Task in preparation",
  "khm": "Khmer",
  "kon": "Kongo",
  "kor": "Korean",
  "latest_task_title": "Latest mission",
  "lav": "Latvian",
  "learn_more": "Learn more",
  "lin": "Lingala",
  "lit": "Lithuanian",
  "location-form_favorite_list_addition": "This address was successfully added to you favorite list",
  "location-form_favorite_list_deletion": "This address was successfully deleted from your favorite list",
  "location-form_favorite_list_update": "Your favorite list was successfully updated",
  "location-form_no_street_number": "Add a street number",
  "location-form_workplace_favorite_input_label": "Address",
  "location-form_workplace_favorite_input_title_label": "Title",
  "location-form_workplace_favorite_input_title_placeholder": "Site #1",
  "location-form_workplace_favorite_label": "Favorite addresses",
  "location-form_workplace_favorite_placeholder": "Enter the address",
  "location-form_workplace_favorite_subtitle": "Enter the most common addresses you will use.",
  "location-form_workplace_favorite_title": "Add a favorite address",
  "location-form_workplace_label1": "Address",
  "location-form_workplace_label2": "Additional details",
  "location-form_workplace_option1": "Remote work is allowed",
  "location-form_workplace_option2": "Siders need to have their own transport",
  "location-form_workplace_placeholder1": "Enter the address",
  "location-form_workplace_placeholder2": "Add details that could help Siders to find their way to the workplace.",
  "location-form_workplace_subtitle": "These information will allow us to select Siders who can easily get access to the mission.",
  "location-form_workplace_title": "Work location",
  "logas_impersonate_button": "Log as ",
  "logas_input_label": "Name, email or id",
  "logas_logout_button": "Log out",
  "mac": "Macedonian",
  "manager-form_choice_later": "Select later",
  "manager-form_choice_now": "Select now",
  "manager-form_label": "Select the manager",
  "manager-form_subtitle": "Select the task manager, this person will get all the information about the task and will be in charge of approving timesheets.",
  "manager-form_title": "Manager",
  "manager_update_success": "Manager updated !",
  "may": "Malay",
  "mlg": "Malagasy",
  "modal_confirmation_answer_1": "Once your order has been approved by our team, selection starts immediately. You'll receive a confirmation email specifying the date and time of the final selection. You'll also be able to follow the exact selection process directly on your Side account.",
  "modal_confirmation_answer_2": "Once your task has been validated, we guarantee to fill all the shifts you requested. If one of your Siders is missing due to a major unexpected event, they'll be replaced by another equally qualified Sider. On Side, 90% of tasks are filled within 24 hours.",
  "modal_confirmation_answer_3": "During the selection process, Siders enter their education and previous experience. They go through an interview and must pass targeted tests according to each job type. Data analysis enables our team to select the profiles that are best suited to match your needs.",
  "modal_confirmation_answer_4": "On Side, we implement a weekly payment cycle. From Monday to Sunday, Siders enter their hours thanks to a clock in/clock out system. Time entries are automatically sent to your account and you can edit those hours if you need to. Payments are triggered every Tuesday and a weekly invoice is generated automatically.",
  "modal_confirmation_description": "What's next?\u003cbr /\u003eHere are 4 questions you might be asking yourself right now.",
  "modal_confirmation_faq": "\u003ca href='https://help.side.co/hc/' target='_blank'\u003eCheck out our FAQs\u003c/a\u003e for any questions you might have. In cases of emergency contact us via our online chat and we'll get back to you very quickly!",
  "modal_confirmation_question_1": "What happens once I post my task?",
  "modal_confirmation_question_2": "How do I know all my shifts will be filled?",
  "modal_confirmation_question_3": "What is the selection process?",
  "modal_confirmation_question_4": "How and when do I pay?",
  "modal_confirmation_subtitle": "Roger that",
  "modal_confirmation_tip_4": "If you think you'd benefit from a custom-made payment solution adapted to your accounting system, please email in@side.co. A team member will review your request and be in touch as soon as possible.",
  "modal_confirmation_title": "We've received your task!",
  "modal_delete_description": "Are you sure you want to delete this draft?",
  "modal_delete_job_description_content": "Once deleted, you will not be able to access this job description anymore",
  "modal_delete_job_description_title": "Do you really want to delete this job description ?",
  "modal_delete_title": "Delete draft",
  "modal_duplicate_description": "Don’t worry, you’ll be able to edit it before it gets published!",
  "modal_duplicate_title": "Are you sure you want to duplicate this task?",
  "modal_manager_update_field_tip": "This person will have the responsibility to validate the hours worked by the Siders, so please make sure that she/he will be available to do it.\u003cbr\u003e\nWithout this validation, we can not pay the Siders.",
  "modal_redirect_button": "Go to job descriptions",
  "modal_redirect_description": "The mission command system has changed. To make a new request, follow the following steps.",
  "modal_redirect_step_1": "Go the \u003cem\u003eJob descriptions\u003c/em\u003e page",
  "modal_redirect_step_2": "Create a new Job description or choose the one of your choice to post your future shifts.",
  "modal_redirect_title": "Unable to duplicate",
  "modal_rename_button_cancel": "Cancel",
  "modal_rename_button_ok": "Continue",
  "modal_rename_description": "If you wish, you can give a customised name to this task in order to differentiate it from the others. This new name will be used as a reference on your invoices.",
  "modal_rename_error_too_long": "New name can't be longer than 25 characters.",
  "modal_rename_field_label": "Task name",
  "modal_rename_field_placeholder": "Customised name",
  "modal_rename_field_tip": "This new name won't be visible to Siders.",
  "modal_rename_title": "How do you want to call this task?",
  "modal_update_manager_input_label": "Task manager",
  "modal_update_manager_title": "Change task manager",
  "modify": "Modify",
  "mon": "Mongolian",
  "motive_activity_increase_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_activity_increase_explaination_link_label": "in this article",
  "motive_activity_increase_explaination_tip": "For the contract to be compliant, you need to explain in details why you have an activity increase. Learn more ",
  "motive_activity_increase_label": "Justification",
  "motive_activity_increase_length": "remaining characters",
  "motive_activity_increase_placeholder": "E.g: Peak activity, sales, annual store inventory...",
  "motive_activity_increase_subtitle": "Here you must specify as much as possible the reason why the company is experiencing a temporary workload. More information",
  "motive_activity_increase_title": "Temporary activity increase",
  "motive_activity_increase_validation": "Type at least 6 characters",
  "motive_recruitment_count": "Hired person n°%{index}",
  "motive_recruitment_date_label": "Arrival date",
  "motive_recruitment_date_placeholder": "dd/mm/yyyy",
  "motive_recruitment_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_recruitment_explaination_link_label": "in this article",
  "motive_recruitment_explaination_tip": "For the contract to be compliant, you need to fill the following information to justify your motive choice. Learn more",
  "motive_recruitment_name_label": "Name of new hire",
  "motive_recruitment_name_placeholder": "E.g. Hugo Marchand",
  "motive_recruitment_position_label": "Position of new hire",
  "motive_recruitment_position_placeholder": "E.g. cashier",
  "motive_recruitment_subtitle": "This reason for appeal is only possible while awaiting the arrival of a person already recruited on a permanent contract. More information",
  "motive_recruitment_title": "Waiting for an employee on permanent contract",
  "motive_replacement_count": "Person to replace n°%{index}",
  "motive_replacement_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_replacement_explaination_link_label": "in this article",
  "motive_replacement_explaination_tip": "For the contract to be compliant, you need to fill the following information to justify your motive choice. Learn more ",
  "motive_replacement_name_label": "First and lastname",
  "motive_replacement_name_placeholder": "E.g. Hugo Marchand",
  "motive_replacement_position_label": "Occupation of replaced employee",
  "motive_replacement_position_placeholder": "E.g. cashier",
  "motive_replacement_reason_label": "Reason for absence",
  "motive_replacement_reason_placeholder": "E.g. sick leave",
  "motive_replacement_subtitle": "You must indicate in your Sider's contract who he or she must replace and why this person is absent. More information",
  "motive_replacement_title": "Replacement of a missing employee",
  "motive_seasonal_activity_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_seasonal_activity_explaination_link_label": "in this article",
  "motive_seasonal_activity_explaination_tip": "For the contract to be compliant, you have to explain in details why your need justifies the use of temporary work. Learn more",
  "motive_seasonal_activity_label": "Specify why you need Side",
  "motive_seasonal_activity_length": "remaining characters",
  "motive_seasonal_activity_placeholder": "E.g: September harvest, winter sport (ski instructor)...",
  "motive_seasonal_activity_subtitle": "You can only use this reason for certain activities that repeat each year at the same time. More information",
  "motive_seasonal_activity_title": "Seasonal employment",
  "motive_seasonal_activity_validation": "Type at least 6 characters",
  "motive_temporary_contract_explaination_link": "https://help.side.co/hc/fr/articles/360002721818-Quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire",
  "motive_temporary_contract_explaination_link_label": "in this article",
  "motive_temporary_contract_explaination_tip": "For the contract to be compliant, you have to explain in details why your need justifies the use of temporary work. Learn more ",
  "motive_temporary_contract_label": "Specify why you need Side",
  "motive_temporary_contract_length": "remaining characters",
  "motive_temporary_contract_placeholder": "E.g: Leisure and vacation centers, audiovisual...",
  "motive_temporary_contract_subtitle": "Temporary customary works are only possible in strictly limited cases and determined by article D1251-1 of the labor code. More information",
  "motive_temporary_contract_title": "Temporary contract",
  "motive_temporary_contract_validation": "Type at least 6 characters",
  "native": "Native",
  "new": "New",
  "nld": "Dutch",
  "no": "No",
  "no_invoice_company": "You haven't received any invoice so far.",
  "no_invoice_month": "You didn't get any invoice this month.",
  "nor": "Norwegian",
  "oci": "Occitan",
  "onboarding-modal__intro": "You are the first administrator in your company. You can already invite your co-workers and assign roles.",
  "onboarding-modal__title": "Welcome to Side!",
  "onboarding-modal_steps_1_content": "Complete your profile, settings and put your first task online.",
  "onboarding-modal_steps_1_title": "Share your needs",
  "onboarding-modal_steps_2_content": "Side takes care of finding the exact profiles you need within hours. You also get access to your real-time planning and sign your contracts online.",
  "onboarding-modal_steps_2_title": "Follow the hiring process and sign your contracts",
  "onboarding-modal_steps_3_content": "You can find all your Siders' timesheets in one-single place. Benefit from our simple validation system and automatically get your invoices weekly.",
  "onboarding-modal_steps_3_title": "Validate hours and receive your invoices",
  "onboarding-modal_understood": "Understood",
  "ongoing_section_display_planning": "Display planning",
  "ongoing_section_display_preselection": "Display selection",
  "ongoing_section_row_status_cell_subtitle_contact_plural": "View the %{siderNumber} Siders",
  "ongoing_section_row_status_cell_subtitle_contact_singular": "View the Sider",
  "ongoing_section_row_status_cell_title_selection_done": "Selection done!",
  "ongoing_section_row_task_cell_managed_by": "Managed by ",
  "ongoing_section_title": "Ongoing Missions",
  "optional": "optional",
  "order_details_label": "Additional info (optional)",
  "order_details_placeholder": "Eg: masks, hydro-alcoholic solution, and hand gloves are free of use in order to fight Covid-19 spread.",
  "order_details_subtitle": "Write additional information that Siders should have",
  "order_details_title": "Additionnal information",
  "order_justifications_add": "Add a new justification",
  "order_justifications_create_action": "Create a justification",
  "order_justifications_delete_confirmation": "Are you sure you want to delete?",
  "order_justifications_label": "Justification",
  "order_justifications_placeholder": "Select a justification from the list",
  "order_justifications_tip": "The choice of justification will be reported in your siders' contract. If you renew a contract using the same justification, a contract prolongation will be automatically generated.",
  "order_motives_add": "Add a substitute",
  "order_motives_error_6_chars": "Type at least 6 characters",
  "order_motives_label": "Reason",
  "order_motives_label_activity_increase": "Temporary activity increase",
  "order_motives_label_replacement": "Replacement of one employee",
  "order_motives_label_seasonal_activity": "Seasonal employment",
  "order_motives_label_temporary_contract": "Temporary contract",
  "order_motives_label_waiting_recruitment": "Waiting for an employee on permanent contract",
  "order_motives_placeholder": "Select a reason from the list",
  "order_motives_show_details": "Show details",
  "order_motives_subtitle": "Select the reason why you are asking for one or multiple Siders",
  "order_motives_title": "Reason",
  "order_noresults_button_label": "Continue",
  "order_noresults_subtitle": "You'll find here the profiles with whom you liked working next time.",
  "order_noresults_title": "No Siders yet?",
  "order_preliminary_details_cancel_label": "Cancel",
  "order_preliminary_details_coefficient_column": "Coefficient",
  "order_preliminary_details_delegation_description": "Our recruiters support you in recruiting temporary workers, with whom you have already worked or not.",
  "order_preliminary_details_delegation_label": "I am looking for temporary workers",
  "order_preliminary_details_gestion_description": "Add the profiles you source to Side and automate 100% of your administrative procedures.",
  "order_preliminary_details_gestion_label": "I already have my profiles",
  "order_preliminary_details_hourlyrate_column": "Cost",
  "order_preliminary_details_hourtype_column": "Hour type",
  "order_preliminary_details_increaserate_column": "Increase rate",
  "order_preliminary_details_modal_title": "Create a new mission",
  "order_preliminary_details_pricing_table_title": "Hourly rates and indicative coefficient",
  "order_preliminary_details_subtitle": "Select the option that fits your needs",
  "order_preliminary_details_taskname_label": "Personalize the name",
  "order_preliminary_details_taskname_optional": "optional",
  "order_preliminary_details_taskname_placeholder": "Add a suffix to the mission name",
  "order_preliminary_details_taskname_tip": "Mission name customization will not be visible to Siders.",
  "order_preliminary_details_tasktype_label": "Define my needs",
  "order_preliminary_details_title": "New order",
  "order_preliminary_details_type_delegation_label": "Side Source (Hiring)",
  "order_preliminary_details_type_gestion_label": "Side Flash (Payroll Management)",
  "order_preliminary_details_type_label": "Mission type",
  "order_preliminary_details_validate_label": "Continue",
  "order_quotation_price": "Cost (€/h)",
  "order_quotation_quantity": "Qty",
  "order_quotation_total_ht": "Total (w/o VAT)",
  "order_quotation_type": "Type",
  "order_shifts_subtitle": "Specify the shifts and the number of Siders per shift for this task",
  "order_shifts_title": "Shifts",
  "order_stepper_details": "Details",
  "order_stepper_motive": "Motive",
  "order_stepper_shifts": "Shifts",
  "order_stepper_summary": "Summary",
  "order_stepper_workers": "Siders",
  "order_succes_display_planning": "Display my planning",
  "order_success_item_1": "Your mission is live and will start automatically",
  "order_success_item_2": "Our team is selecting the most pertinent Siders",
  "order_success_item_3": "You can check the status of the selection on your planning",
  "order_success_title": "Your mission has been posted",
  "order_summary_address_title": "Address",
  "order_summary_base_wage_disclaimer": "Base wage is configured in your job description",
  "order_summary_confirmation_button": "Confirm",
  "order_summary_confirmation_cancellation_fees": "Once your order is confirmed, cancellation or edition fees may be applied",
  "order_summary_confirmation_checkbox": "I understand the cancellation policy",
  "order_summary_confirmation_footnote": "You will be charged based on the validated timesheets of your temp workers. Once your need is validated, you will receive a confirmation e-mail",
  "order_summary_confirmation_sidenote": "Note for Side team",
  "order_summary_confirmation_sidenote_placeholder": "Enter your comment",
  "order_summary_confirmation_title": "Confirm your order",
  "order_summary_estimate_base_wage": "Base wage (normal hours)",
  "order_summary_estimate_details": "Show details",
  "order_summary_estimate_disclaimer": "This estimate includes end of mission fees and paid holidays but does not include bonus and additional salary which can impact the final price",
  "order_summary_estimate_title": "Price Estimate",
  "order_summary_estimate_total_with_taxes": "Total Estimate (20% VAT)",
  "order_summary_estimate_total_without_taxes": "Total Estimate Without Taxes",
  "order_summary_from_to_date": "from %{startDate} to %{endDate}",
  "order_summary_info_title": "Additional information",
  "order_summary_justification_title": "Justification",
  "order_summary_manager_title": "Manager",
  "order_summary_motive_title": "Reason",
  "order_summary_name_title": "Order name",
  "order_summary_shifts_title": "Shifts",
  "order_summary_shifts_warning_FAQ_link": "https://help.side.co/hc/fr",
  "order_summary_shifts_warning_holiday": "You have added a slot on a public holiday whose remuneration is increased according to your remuneration policy. Check that this day is well worked in your company.",
  "order_summary_shifts_warning_holiday_interval": "Your mission period includes a public holiday. Public holidays not worked are remunerated at the usual rate if they are part of the usual mission days.",
  "order_summary_shifts_warning_link_text": "See our FAQ for more information.",
  "order_summary_title": "Order summary",
  "order_summary_type_delegation_description": "You work with profiles recruited by our teams",
  "order_summary_type_delegation_title": "Delegation",
  "order_summary_type_gestion_description": "You have found profiles by yourselves, we only manage the administration.",
  "order_summary_type_gestion_title": "Management",
  "order_summary_type_title": "Order type",
  "order_summary_workers_title": "Requested Siders",
  "order_summary_workers_wa_warning": "Only Siders you have selected will be able to apply and be placed on the mission. Please confirm with them their availability. If they are not available, we will cancel the mission.",
  "order_workers_filters_button_label": "Filters (%{numberOfFilters})",
  "order_workers_filters_modal_button_delete": "Reset all filters",
  "order_workers_filters_modal_experience_label": "Experience",
  "order_workers_filters_modal_experience_placeholder": "Add a job title",
  "order_workers_filters_modal_isrecommended_false": "Display all Siders",
  "order_workers_filters_modal_isrecommended_label": "Feedback",
  "order_workers_filters_modal_isrecommended_placeholder": "Select a feedback type",
  "order_workers_filters_modal_isrecommended_true": "Only display Siders with a positive feedback",
  "order_workers_filters_modal_last-task_1": "Less than a month",
  "order_workers_filters_modal_last-task_3": "Less than 3 months",
  "order_workers_filters_modal_last-task_all": "Display all Siders",
  "order_workers_filters_modal_last-task_label": "Last task done",
  "order_workers_filters_modal_last-task_placeholder": "Select a period",
  "order_workers_filters_modal_subtitle": "Use filters to refine your search",
  "order_workers_filters_modal_title": "Filters",
  "order_workers_filters_noresults_button_label": "Edit filters",
  "order_workers_filters_noresults_subtitle": "Edit the filters to display more Siders",
  "order_workers_filters_noresults_title": "No result",
  "order_workers_gestion_amount": "Select at least %{numberOfRemainingSlots} more Siders to continue",
  "order_workers_gestion_noresults_subtitle": "Add your own profiles to work with them on this task",
  "order_workers_gestion_noresults_title": "No Siders yet?",
  "order_workers_gestion_profile-add_button_label": "Add a profile",
  "order_workers_gestion_profile-add_modal_error": "This profile already has an account on Side. If you want to work with this profile, please contact Side customer support via the chat or email care@sidetemp.com",
  "order_workers_gestion_profile-add_modal_firstname_label": "First name",
  "order_workers_gestion_profile-add_modal_firstname_placeholder": "John",
  "order_workers_gestion_profile-add_modal_lastname_label": "Last name",
  "order_workers_gestion_profile-add_modal_lastname_placeholder": "Doe",
  "order_workers_gestion_profile-add_modal_phone_label": "Phone number",
  "order_workers_gestion_profile-add_modal_phone_placeholder": "(0)6 07 08 09 10",
  "order_workers_gestion_profile-add_modal_subtitle": "Add the details of the profile you would like to work with, we will handle everything else.",
  "order_workers_gestion_profile-add_modal_title": "Add a profile",
  "order_workers_list_network_label": "My network (%{numberOfSharedWorkers})",
  "order_workers_list_orga_label": "My Siders (%{numberOfOrganisationWorkers})",
  "order_workers_list_selected_label": "Selected (%{numberOfSelectedWorkers})",
  "order_workers_network_noresults_button_label": "Display Siders from my network",
  "order_workers_network_noresults_counter": "%{numberOfWorkers} available Siders",
  "order_workers_network_noresults_subtitle": "Ask for already trained Siders from your network!",
  "order_workers_network_noresults_title": "No Siders yet?",
  "order_workers_search_noresults_subtitle": "Edit your search to display more Siders",
  "order_workers_search_noresults_title": "No result",
  "order_workers_search_placeholder": "Sider's name",
  "order_workers_subtitle": "Work again with Sider you know",
  "order_workers_title": "Siders",
  "order_workers_wa-only_modal_subtitle": "You chose to exclusively open the mission to requested Siders. Only those Siders will be able to apply and work on this mission",
  "order_workers_wa-only_modal_title": "Open the mission to requested Siders only",
  "order_workers_wa-only_modal_warning": "Be aware that this option requires that selected siders are all available for the task. Do not confirm it unless you have spoken with them beforehand. If they are not available, no sider will be selected.",
  "order_workers_wa-only_toggle_amount": "Select at least %{numberOfRemainingSlots} more Siders to use this option",
  "order_workers_wa-only_toggle_label": "Open the mission to requested Siders only",
  "order_workers_wa-only_toggle_tooltip_content_activated": "Only Siders you requested will be able to apply and be selected on this task. Side will not update the selection with other Siders if the one you requested are not available.",
  "order_workers_wa-only_toggle_tooltip_content_deactivated": "By activating this option, only the siders that you have selected will be able to apply to your task. Tick this checkbox only if you have confirmed with those siders that they are available.",
  "order_workers_wa-only_toggle_tooltip_title_activated": "Activated option",
  "order_workers_wa-only_toggle_tooltip_title_deactivated": "Deactivated option",
  "per": "Persian",
  "personal_informations": "Personal informations",
  "personal_informations_email": "",
  "personal_informations_email_placeholder": "youremail@domain.com",
  "personal_informations_firstname": "First name",
  "personal_informations_firstname_placeholder": "Your first name",
  "personal_informations_lastname": "Last name",
  "personal_informations_lastname_placeholder": "Your last name",
  "personal_informations_new_password": "New password",
  "personal_informations_new_password_confirmation": "Confirmation",
  "personal_informations_new_password_confirmation_placeholder": "**********",
  "personal_informations_new_password_placeholder": "**********",
  "personal_informations_phone": "Phone number",
  "personal_informations_phone_placeholder": "06 XX XX XX XX",
  "personal_informations_profile_saved": "Informations saved",
  "planning_download_as": "Download as %{downloadFormat}",
  "planning_select_current_week": "Today",
  "planning_sheet_empty_user": "Selection ongoing",
  "planning_short_week": "W",
  "planning_sider_worked_hours_week": "%{siderName} is working %{workedHoursInWeek} hour%{plural} this week",
  "pol": "Polish",
  "por": "Portuguese",
  "preselect_actions_tooltip_approve": "Approve candidate",
  "preselect_actions_tooltip_download": "Download CV",
  "preselect_actions_tooltip_refuse": "Refuse candidate",
  "preselect_actions_tooltip_upload": "Upload CV",
  "preselect_availabilities_from": "From",
  "preselect_availabilities_until": "Until",
  "preselect_cv_uploaded": "CV successfully uploaded",
  "preselect_no_result_client": "We are preparing your mission and will get back to you very soon with candidates selected by us.",
  "preselect_no_result_logas": "Selection ongoing",
  "preselect_refusal_modal_button": "Refuse this candidate",
  "preselect_refusal_modal_description": "Please tell us why this candidate is not a good fit so we can refine our search. (eg: this candidate has already been presented to me)",
  "preselect_refusal_modal_placeholder": "Explain to us...",
  "preselect_refusal_modal_title": "This candidate is not right for you?",
  "preselect_textarea_hint": "Max 500 characters",
  "preselect_textarea_placeholder": "Type a note...",
  "preselect_tip_no_visible": "Please note, no candidates are visible to your client! To give visibility to your client, you must click on the “eye” icon located to the left of the table.",
  "preselection_h1": "Preselected candidates",
  "preselection_status_approved": "Approved",
  "preselection_status_refused": "Refused",
  "preselection_status_sent": "To validate",
  "preselection_table_header_applicant": "Applicant",
  "preselection_table_header_availabilities": "Availability",
  "preselection_table_header_notes": "Notes",
  "preselection_table_header_status": "Status",
  "preselection_table_header_visibility": "Visibility",
  "que": "Quechua",
  "rename": "Rename",
  "rename_task": "Rename the mission",
  "rum": "Romanian",
  "rus": "Russian",
  "save": "Save",
  "save_quit": "Save and quit",
  "search": "Search",
  "send": "Send",
  "settings_actions_content": "You edited some information, what do you want to do?",
  "settings_actions_discard": "Discard changes",
  "settings_actions_save": "Save",
  "settings_legal_ape": "Code APE",
  "settings_legal_ape_placeholder": "0000A",
  "settings_legal_ape_tip": "the APE code is a 4 digits and 1 letter idenfier of the type of activity of your business. Also called NAF, it can be easily found in the \\\"activity\\\" section on https://www.societe.com",
  "settings_legal_business_name": "Legal entity",
  "settings_legal_business_name_detail": "(if different)",
  "settings_legal_business_name_placeholder": "Legal entity",
  "settings_legal_business_name_tip": "It is the legal entity which will appear on the contracts and will be invoiced",
  "settings_legal_error_ape_format": "The APE code format is 4 digits followed by one letter",
  "settings_legal_error_siret_format": "Wrong format",
  "settings_legal_name": "Company name",
  "settings_legal_name_placeholder": "Company name",
  "settings_legal_siret": "SIRET",
  "settings_legal_siret_placeholder": "000 000 000 00000",
  "settings_legal_siret_tip": "The SIRET is a 14 digits identifier of your company, which can be easily found on https://www.societe.com",
  "settings_legal_subtitle": "This information will be displayed on the contracts ",
  "settings_legal_title": "Legal information",
  "settings_nav_account": "My profile",
  "settings_nav_company_info": "Company profile",
  "settings_nav_legal_info": "Legal information",
  "settings_nav_payment_info": "Payment",
  "settings_nav_payment_info_accounting": "Billing contact",
  "settings_nav_payment_info_billing": "Billing address",
  "settings_nav_payment_info_method": "Payment method",
  "settings_nav_payment_info_remuneration": "Remuneration policy",
  "settings_nav_payment_info_remuneration_compensations": "Allowances and compensations",
  "settings_nav_payment_info_remuneration_hours": "Hour types",
  "settings_nav_team": "Team",
  "settings_payment_accounting_email": "Email address",
  "settings_payment_accounting_email_placeholder": "name@company.com",
  "settings_payment_accounting_firstname": "First name",
  "settings_payment_accounting_firstname_placeholder": "First name",
  "settings_payment_accounting_name": "Last name",
  "settings_payment_accounting_name_placeholder": "Last name",
  "settings_payment_accounting_phone_number": "Phone number",
  "settings_payment_accounting_phone_number_placeholder": "06 00 00 00 00",
  "settings_payment_accounting_subtitle": "This is the contact of the person who will receive the invoice",
  "settings_payment_accounting_title": "Billing contact",
  "settings_payment_api_error": "An error occured, please try again later or contact the customer service.",
  "settings_payment_billing_address": "Address",
  "settings_payment_billing_address_placeholder": "Address\n",
  "settings_payment_billing_city": "City",
  "settings_payment_billing_city_placeholder": "City",
  "settings_payment_billing_country": "Country",
  "settings_payment_billing_country_placeholder": "Select a country",
  "settings_payment_billing_postal_code": "Postal code",
  "settings_payment_billing_postal_code_placeholder": "00000",
  "settings_payment_billing_subtitle": "This information will be issued to issue your invoices",
  "settings_payment_billing_title": "Billing address",
  "settings_payment_billing_tva_intro": "Your billing address is outside France, you need to provide a Intra-Community VAT number",
  "settings_payment_billing_tva_label": "Intra-Community VAT number",
  "settings_payment_billing_tva_placeholder": "XX 0000000000000",
  "settings_payment_billing_tva_tip": "This number allows a good VAT declaration",
  "settings_payment_error_card_check": "There's an issue with this bank card.",
  "settings_payment_error_card_format": "This card number is not valid.",
  "settings_payment_error_city_format": "Wrong format",
  "settings_payment_error_country_format": "Wrong format",
  "settings_payment_error_digits_only_format": "This field can only contain numbers.",
  "settings_payment_error_email_format": "Wrong format",
  "settings_payment_error_exp_date_format": "Please enter a valid date.",
  "settings_payment_error_iban_format": "This IBAN is not valid.",
  "settings_payment_error_phone_number_format": "Wrong format",
  "settings_payment_error_postal_code_format": "The zip code is a 5 digits number",
  "settings_payment_error_tos": "Please accept the term of services.",
  "settings_payment_error_tva_number_format": "Wrong format",
  "settings_payment_method_card_cvv": "CVV",
  "settings_payment_method_card_cvv_placeholder": "213",
  "settings_payment_method_card_desc": "The money will be taken from the account linked to this credit card.",
  "settings_payment_method_card_exp_date": "Expiration date",
  "settings_payment_method_card_exp_date_placeholder": "MM/YY",
  "settings_payment_method_card_label": "Credit card",
  "settings_payment_method_card_modify": "Edit the card",
  "settings_payment_method_card_number": "Card number",
  "settings_payment_method_card_number_placeholder": "0000 - 0000 - 0000 - 0000",
  "settings_payment_method_card_saved": "Saved card",
  "settings_payment_method_cs_desc": "You are responible of the payment of the invoice",
  "settings_payment_method_cs_label": "Bank transfer",
  "settings_payment_method_label": "I would like to pay by...",
  "settings_payment_method_order_form": "I would like to get pre-invoices",
  "settings_payment_method_sepa": "IBAN",
  "settings_payment_method_sepa_confirmation": "By clicking here, you accept \u003ca href=\\\"https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/sepa-mandate-en.pdf\\\" target=\\\"_blank\\\"\u003eStripe SEPA Direct Debit\u003c/a\u003e terms of use.",
  "settings_payment_method_sepa_country": "Country",
  "settings_payment_method_sepa_country_placeholder": "Select a country",
  "settings_payment_method_sepa_currency": "Currency",
  "settings_payment_method_sepa_currency_placeholder": "Select a currency",
  "settings_payment_method_sepa_desc": "The money will be taken from your bank account",
  "settings_payment_method_sepa_euros": "Euros",
  "settings_payment_method_sepa_france": "France",
  "settings_payment_method_sepa_gb": "Great Britain",
  "settings_payment_method_sepa_label": "Direct debit",
  "settings_payment_method_sepa_modify": "Edit the IBAN",
  "settings_payment_method_sepa_placeholder": "XX00 0000 0000 0000 0000 0000 000",
  "settings_payment_method_sepa_pounds": "Pounds",
  "settings_payment_method_sepa_saved": "Saved IBAN",
  "settings_payment_method_stripe": "Payment processing and data storage are entirely handled by our payment partner Stripe - we do not store any data.",
  "settings_payment_method_subtitle": "We need this information to start the selection of your Siders. But no worries, you'll only be charged once the task is done.",
  "settings_payment_method_title": "Payment method",
  "settings_payment_title": "Payment information",
  "settings_personal_email": "Email address",
  "settings_personal_email_placeholder": "name@company.com",
  "settings_personal_firstname": "First name",
  "settings_personal_firstname_placeholder": "First name",
  "settings_personal_lastname": "Last name",
  "settings_personal_lastname_placeholder": "Last name",
  "settings_personal_password_confirm": "Confirm new passoward",
  "settings_personal_password_new": "New passwoard",
  "settings_personal_password_placeholder": "•••••••••••",
  "settings_personal_phonenumber": "Phone number",
  "settings_personal_phonenumber_placeholder": "06 00 00 00 00",
  "settings_personal_subtitle": "These are your credentials that allow you to connect to the platform",
  "settings_personal_title": "My account",
  "settings_profile_title": "Company profile",
  "settings_remuneration_hours_after_months_seniority": "\u003cb\u003eAfter %{months} months\u003c/b\u003e of seniority.",
  "settings_remuneration_hours_before_months_seniority": "\u003cb\u003eup to %{months} months\u003c/b\u003e of seniority.",
  "settings_remuneration_hours_edit_tooltip": "Edit hour type parameters",
  "settings_remuneration_hours_extra_content": "Rate that applies when a Sider works:",
  "settings_remuneration_hours_extra_title": "Overtime",
  "settings_remuneration_hours_help": "You don't find your hour type in the list above. Feel free to contact us on billing@sidetemp.com",
  "settings_remuneration_hours_holiday_content": "Rate that applies when a Sider works on bank holidays.",
  "settings_remuneration_hours_holiday_solidarity": "\u003cb\u003eSolidarity day:\u003c/b\u003e",
  "settings_remuneration_hours_holiday_solidarity_undefined": "unspecified",
  "settings_remuneration_hours_holiday_title": "Bank holidays worked hours",
  "settings_remuneration_hours_hour_threshold": "\u003cb\u003eMore than %{count}h\u003c/b\u003e in a week.",
  "settings_remuneration_hours_in_zti": "When the task location is inside a \u003cb\u003etouristic area\u003c/b\u003e.",
  "settings_remuneration_hours_night_content": "Rate that applies when a Sider works \u003cb\u003ebetween %{startTime} and %{endTime}\u003c/b\u003e.",
  "settings_remuneration_hours_night_title": "Night hours",
  "settings_remuneration_hours_normal_content": "This is the standard hour type rate that applies when no other hour type rate does. The Sider will be paid on the basis of the reference salary without pay increase.",
  "settings_remuneration_hours_normal_title": "Standard hours",
  "settings_remuneration_hours_not_zti": "When the task location is outside a \u003cb\u003etouristic area\u003c/b\u003e.",
  "settings_remuneration_hours_see_holidays": "See all bank holidays",
  "settings_remuneration_hours_subtitle": "You can define here the remuneration policy that applies in your company. If it differs from the default configuration, you can modify the parameters of each hour type.\n",
  "settings_remuneration_hours_sunday_content": "Rate that applies when a Sider works on Sundays.",
  "settings_remuneration_hours_sunday_title": "Sunday hours",
  "settings_remuneration_hours_table_type": "Hour type and conditions",
  "settings_remuneration_hours_table_value": "Rate",
  "settings_remuneration_hours_title": "Hour types",
  "settings_remuneration_hours_types_custom_disclaimer": "Your configuration is very specific and had to be inserted manually (this is the reason why you don't see it here). It has been taken into account though. Should you have any question, feel free to contact us",
  "settings_remuneration_hours_validation_button": "Confirm",
  "settings_remuneration_hours_validation_content": "You need to check and confirm your hour types configuration before being able to post a task.",
  "settings_remuneration_hours_validation_label": "Hour type configuration are correct",
  "settings_remuneration_hours_validation_title": "Confirm your hour types configuration",
  "settings_remuneration_indemnities_add_label": "Which type of benefit do you want to add? ",
  "settings_remuneration_indemnities_add_option_lunch": "Lunch allowance",
  "settings_remuneration_indemnities_add_option_other": "Other",
  "settings_remuneration_indemnities_add_option_transport": "Transportation compensation",
  "settings_remuneration_indemnities_add_placeholder": "Select a type of benefit",
  "settings_remuneration_indemnities_add_title": "Add a new benefit",
  "settings_remuneration_indemnities_bank_holidays_hours_rate_error_max": "the rate cannot exceed +200%",
  "settings_remuneration_indemnities_bank_holidays_hours_rate_error_min": "the rate must be at least 0%",
  "settings_remuneration_indemnities_bank_holidays_rate": "Pay increase (vs standard hours)",
  "settings_remuneration_indemnities_bank_holidays_solidarity": "Select your solidarity day (holiday paid at normal hours rate)",
  "settings_remuneration_indemnities_bank_holidays_solidarity_placeholder": "Solidarity day",
  "settings_remuneration_indemnities_bank_holidays_subtitle": "The rate you will define will apply for every hour worked on a bank Holiday",
  "settings_remuneration_indemnities_bank_holidays_title": "Worked bank holidays hours",
  "settings_remuneration_indemnities_custom_disclaimer": "Your configuration is very specific and had to be inserted manually (this is the reason why you don't see it here). It has been taken into account though. Should you have any question, feel free to contact us",
  "settings_remuneration_indemnities_edit_tooltip": "Edit ",
  "settings_remuneration_indemnities_empty": "No benefit has been defined yet",
  "settings_remuneration_indemnities_extra_hour_increase_error_max": "the rate cannot exceed +200%",
  "settings_remuneration_indemnities_extra_hour_increase_error_min": "the rate must be at least 0%",
  "settings_remuneration_indemnities_extra_hour_min": "You need to define at least one threshold",
  "settings_remuneration_indemnities_extra_hour_threshold_format": "the threshold should be between 35 and 48 hours\n",
  "settings_remuneration_indemnities_extra_hour_threshold_must_be_higher": "This threshold  must be higher than the previous one",
  "settings_remuneration_indemnities_extra_hours_extra_threshold": "Extra hours threshold n°%{number}",
  "settings_remuneration_indemnities_extra_hours_rate": "Pay rate (vs standard hours rate)",
  "settings_remuneration_indemnities_extra_hours_subtitle": "Extra hours rate will apply when a Sider exceeds the weekly amount of working hours",
  "settings_remuneration_indemnities_extra_hours_threshold": "Overtime threshold",
  "settings_remuneration_indemnities_extra_hours_threshold_unit": "worked hours",
  "settings_remuneration_indemnities_extra_hours_title": "Overtime",
  "settings_remuneration_indemnities_help": "You don't manage to configure one of your benefits with our tool? Contact us on billing@sidetemp.com and we will configure it for you!",
  "settings_remuneration_indemnities_lunch_equivalent_amount": "i.e %{amount}€",
  "settings_remuneration_indemnities_lunch_indeminity_option": "Lunch allowance",
  "settings_remuneration_indemnities_lunch_indemnity_label": "Amount of the allowance (per day)",
  "settings_remuneration_indemnities_lunch_indemnity_type": "Lunch allowance",
  "settings_remuneration_indemnities_lunch_max_error": "This value should be lesser than 30",
  "settings_remuneration_indemnities_lunch_min_error": "This value should be greater than 0",
  "settings_remuneration_indemnities_lunch_subtitle": "Rules for lunch allowance",
  "settings_remuneration_indemnities_lunch_ticket_type": "Meal voucher (given by the company)",
  "settings_remuneration_indemnities_lunch_title": "Lunch",
  "settings_remuneration_indemnities_lunch_tr_option": "Meal voucher",
  "settings_remuneration_indemnities_lunch_tr_rate": "Employer's contribution",
  "settings_remuneration_indemnities_lunch_tr_rate_format_error": "Employer's contribution should be a percentage (between 0 and 100)",
  "settings_remuneration_indemnities_lunch_tr_tip": "Meal vouchers have to be given to the employee by the user undertaking. Side will deduce the employee's part from the temp worker's salary and from your invoice. If you can give them yourself, please select the lunch allowance option.",
  "settings_remuneration_indemnities_lunch_tr_value_label": "Value of the meal voucher (per day)",
  "settings_remuneration_indemnities_lunch_type_error": "Please select a type of lunch allowance",
  "settings_remuneration_indemnities_modal_cancel": "Cancel",
  "settings_remuneration_indemnities_modal_delete": "Delete this benefit",
  "settings_remuneration_indemnities_modal_save": "Save",
  "settings_remuneration_indemnities_night_hour_end": "Night hours end time",
  "settings_remuneration_indemnities_night_hour_end_before_start_error": "Night hours end time cannot be before night hours start time",
  "settings_remuneration_indemnities_night_hour_end_error": "Night hours cannot end after 9:00",
  "settings_remuneration_indemnities_night_hour_increase_error": "the rate cannot exceed +200%",
  "settings_remuneration_indemnities_night_hour_rate": "Pay rate (vs standard hours rate)",
  "settings_remuneration_indemnities_night_hour_start": "Night hours start time",
  "settings_remuneration_indemnities_night_hour_start_error": "Night hours cannot start before 18:00",
  "settings_remuneration_indemnities_night_hour_subtitle": "The rate you will define will apply for every hour worked during the night (as you will define it)",
  "settings_remuneration_indemnities_night_hour_title": "Night hours",
  "settings_remuneration_indemnities_option_button": "Add",
  "settings_remuneration_indemnities_other_amount": "Amount",
  "settings_remuneration_indemnities_other_category": "Benefit related to",
  "settings_remuneration_indemnities_other_category_select_placeholder": "Select a category",
  "settings_remuneration_indemnities_other_events_christmas-bonus_indemnity_title": "Christmas bonus",
  "settings_remuneration_indemnities_other_events_indemnity_select_label": "an event",
  "settings_remuneration_indemnities_other_events_indemnity_title": "Event",
  "settings_remuneration_indemnities_other_events_other-bonus_indemnity_title": "Other",
  "settings_remuneration_indemnities_other_events_year-end-bonus_indemnity_title": "End of year bonus",
  "settings_remuneration_indemnities_other_frequency": "Frequency",
  "settings_remuneration_indemnities_other_frequency_daily": "Daily",
  "settings_remuneration_indemnities_other_frequency_monthly": "Monthly",
  "settings_remuneration_indemnities_other_frequency_once": "Once",
  "settings_remuneration_indemnities_other_frequency_select_placeholder": "Select a frequency",
  "settings_remuneration_indemnities_other_frequency_weekly": "Weekly",
  "settings_remuneration_indemnities_other_labor-agreement_holiday-bonus_indemnity_title": "Holiday bonus",
  "settings_remuneration_indemnities_other_labor-agreement_indemnity_select_label": "Collective agreement",
  "settings_remuneration_indemnities_other_labor-agreement_indemnity_title": "Collective agreement",
  "settings_remuneration_indemnities_other_labor-agreement_other-bonus_indemnity_title": "Other",
  "settings_remuneration_indemnities_other_labor-agreement_thirteenth-month-bonus_indemnity_title": "13th month bonus",
  "settings_remuneration_indemnities_other_other_indemnity_select_label": "something else",
  "settings_remuneration_indemnities_other_other_indemnity_title": "Other",
  "settings_remuneration_indemnities_other_other_other-bonus_indemnity_title": "Special bonus",
  "settings_remuneration_indemnities_other_other_parameters_hours_per_day": "The benefit is conditional on the number of worked hours per day",
  "settings_remuneration_indemnities_other_other_parameters_hours_per_day_label": "Threshold (number of worked hours)",
  "settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit": "worked hours",
  "settings_remuneration_indemnities_other_other_parameters_jobtype": "The benefit depends on the type of task",
  "settings_remuneration_indemnities_other_other_parameters_jobtype_label": "Select a type of task",
  "settings_remuneration_indemnities_other_other_parameters_seniority": "The benefit is conditional on the seniority",
  "settings_remuneration_indemnities_other_other_parameters_seniority_threshold": "Threshold",
  "settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit": "days worked for the company (seniority)",
  "settings_remuneration_indemnities_other_other_parameters_switch": "The benefit is conditional on other parameters",
  "settings_remuneration_indemnities_other_other_parameters_zti": "The benefit applies if the task is located in a touristic area",
  "settings_remuneration_indemnities_other_parameters_hours_per_day_error": "This number should be between 1 and 12",
  "settings_remuneration_indemnities_other_parameters_job_types_error": "You need to select at least one type of task",
  "settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0": "this number should be greater than 0\n",
  "settings_remuneration_indemnities_other_performances_diligence-bonus_indemnity_title": "Presence bonus",
  "settings_remuneration_indemnities_other_performances_indemnity_select_label": "Performance",
  "settings_remuneration_indemnities_other_performances_indemnity_title": "Performance",
  "settings_remuneration_indemnities_other_performances_other-bonus_indemnity_title": "Other",
  "settings_remuneration_indemnities_other_performances_productivity-bonus_indemnity_title": "Achievement/performance bonus",
  "settings_remuneration_indemnities_other_performances_target-based-bonus_indemnity_title": "Objectives/performance bonus",
  "settings_remuneration_indemnities_other_subtitle": "Please define the parameters of your benefit",
  "settings_remuneration_indemnities_other_title": "Add a benefit",
  "settings_remuneration_indemnities_other_type": "Name of the benefit",
  "settings_remuneration_indemnities_other_type_error_already_exists": "You cannot add this benefit because one of the same kind already exists.",
  "settings_remuneration_indemnities_other_type_select_placeholder": "Select a type",
  "settings_remuneration_indemnities_other_work-condition_cold-bonus_indemnity_title": "Cold conditions bonus",
  "settings_remuneration_indemnities_other_work-condition_hardship-bonus_indemnity_title": "Hardship allowance\n",
  "settings_remuneration_indemnities_other_work-condition_indemnity_select_label": "Job type",
  "settings_remuneration_indemnities_other_work-condition_indemnity_title": "Job type",
  "settings_remuneration_indemnities_other_work-condition_other-bonus_indemnity_title": "Other",
  "settings_remuneration_indemnities_other_work-condition_risk-bonus_indemnity_title": "Risk bonus",
  "settings_remuneration_indemnities_other_work-condition_unhealthy-bonus_indemnity_title": "Dirty conditions bonus",
  "settings_remuneration_indemnities_per_day": "/day",
  "settings_remuneration_indemnities_per_month": "/month",
  "settings_remuneration_indemnities_seniority_condition_after": "After",
  "settings_remuneration_indemnities_seniority_condition_before": "Before",
  "settings_remuneration_indemnities_seniority_rate": "Pay rate (vs standard hours rate)",
  "settings_remuneration_indemnities_seniority_switch": "the rate depends on the seniority",
  "settings_remuneration_indemnities_seniority_threshold": "Threshold",
  "settings_remuneration_indemnities_seniority_unit": "days worked for the company (seniority)",
  "settings_remuneration_indemnities_subtitle": "If your remuneration policy includes benefits, allowances, compensations or bonuses, please define them here.",
  "settings_remuneration_indemnities_sunday_hours_rate": "Pay increase (vs standard hours)",
  "settings_remuneration_indemnities_sunday_hours_rate_error_max": "the rate cannot exceed +200%",
  "settings_remuneration_indemnities_sunday_hours_rate_error_min": "the rate must be at least 0%",
  "settings_remuneration_indemnities_sunday_hours_subtitle": "The rate you will define will apply for every hour worked on Sundays",
  "settings_remuneration_indemnities_sunday_hours_title": "Sunday hours",
  "settings_remuneration_indemnities_sunday_hours_zti": "A special pay rate applies for tourisitic areas",
  "settings_remuneration_indemnities_table_type": "Benefit and parameters",
  "settings_remuneration_indemnities_table_value": "Value",
  "settings_remuneration_indemnities_threshold_addition": "Add a threshold",
  "settings_remuneration_indemnities_threshold_deletion_tooltip": "Remove this threshold",
  "settings_remuneration_indemnities_threshold_number": "Threshold #",
  "settings_remuneration_indemnities_title": "Benefits",
  "settings_remuneration_indemnities_transport_personal_switch": "",
  "settings_remuneration_indemnities_transport_personal_tip": "",
  "settings_remuneration_indemnities_transport_personal_type": "Personal transportation compensation",
  "settings_remuneration_indemnities_transport_public_rate": "Contribution to a public transportation subscription",
  "settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred": "Your contribution cannot exceed 100%",
  "settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty": "Your contribution should be greater than 50%",
  "settings_remuneration_indemnities_transport_public_type": "Public transportation compensation",
  "settings_remuneration_indemnities_transport_subtitle": "Define here your contribution to the transportation costs of your temp workers",
  "settings_remuneration_indemnities_transport_title": "Transportation",
  "settings_remuneration_indemnities_validation_button": "Confirm",
  "settings_remuneration_indemnities_validation_content": "You need to check and confirm your benefits configuration before being able to post a task.",
  "settings_remuneration_indemnities_validation_label": "Benefits configuration is correct",
  "settings_remuneration_indemnities_validation_title": "Confirm your benefits configuration",
  "settings_remuneration_indemnities_zti_condition": "if task location in in a touristic area",
  "settings_remuneration_non_worked_holidays_help": "Non worked bank holidays are due to temp workers on a basis of 7h maximum paid at normal hours rate.",
  "settings_remuneration_non_worked_holidays_help_link": "See details here.",
  "settings_remuneration_non_worked_holidays_title": "Non worked bank holidays",
  "settings_remuneration_title": "Remuneration policy",
  "settings_submit_success": "Your changes were saved!",
  "settings_team_add_user": "Invite a user",
  "settings_team_add_user_email": "Email",
  "settings_team_add_user_email_placeholder": "Email",
  "settings_team_add_user_firstname": "First name",
  "settings_team_add_user_firstname_placeholder": "First name",
  "settings_team_add_user_lastname": "Last name",
  "settings_team_add_user_lastname_placeholder": "Last name",
  "settings_team_add_user_role": "Role",
  "settings_team_add_user_role_placeholder": "Role",
  "settings_team_add_user_success": "User invited !",
  "settings_team_deactivate_user": "Remove user from your team",
  "settings_team_deactivate_user_dialog_content": "Do you really wish to remove %{name} from your team ? They won't be able to to manage your current missions nor validate your temp workers timesheets.",
  "settings_team_deactivate_user_success": "User removed from your team",
  "settings_team_edit_role": "Change the user's role",
  "settings_team_edit_user": "Change the user's role",
  "settings_team_edit_user_role": "Role",
  "settings_team_edit_user_role_placeholder": "Role",
  "settings_team_edit_user_success": "User updated !",
  "settings_team_profile_description": "Description",
  "settings_team_profile_description_placeholder": "Give a short description of your business",
  "settings_team_profile_media": "Media",
  "settings_team_profile_media_picture": "Use a picture",
  "settings_team_profile_media_upload": "Upload a file",
  "settings_team_profile_media_valid_formats": "Valid file formats: .jpg, .png",
  "settings_team_profile_media_video": "Use a youtube video",
  "settings_team_profile_name": "Company name",
  "settings_team_profile_name_placeholder": "My company",
  "settings_team_profile_saved": "Your changes has been saved",
  "settings_team_profile_upload_logo": "Upload a picture",
  "settings_team_profile_upload_logo_label": "Logo of your company",
  "settings_team_profile_upload_logo_search_tooltip": "Search with google",
  "settings_team_profile_website": "Website",
  "settings_team_profile_website_error": "Your website must be a valid secured URL (https)",
  "settings_team_profile_website_placeholder": "https://",
  "settings_team_profile_youtube_url": "Your video's youtube URL",
  "settings_team_profile_youtube_url_error": "The URL must be a valid youtube link",
  "settings_team_profile_youtube_url_placeholder": "https://youtube.com...",
  "settings_team_reinvited_success": "Link re-sent !",
  "settings_team_resend_activation_link": "Resend activation link",
  "settings_team_role": "Role",
  "settings_team_role_admin": "Admin",
  "settings_team_role_admin_description": "All rights",
  "settings_team_role_collaborator": "Collaborator",
  "settings_team_role_collaborator_description": "Hours validation and Siders evaluation on all tasks",
  "settings_team_role_description_title": "Role description",
  "settings_team_role_missionManager": "User",
  "settings_team_role_missionManager_description": "Configuration of account settings and administration of tasks he manages",
  "settings_team_title": "My team",
  "settings_team_user": "User",
  "settings_title": "Settings",
  "settings_warning_save_dialog_content": "Your changes will be lost if you leave the page without saving.",
  "settings_warning_save_dialog_discard": "Leave",
  "settings_warning_save_dialog_save": "Save",
  "settings_warning_save_dialog_title": "You didn't save your changes!",
  "shift_warning_holiday_FAQ_link": "https://help.side.co/hc/fr/articles/360009843398-Jours-f%C3%A9ri%C3%A9s-travaill%C3%A9s-et-non-travaill%C3%A9s-comment-%C3%A7a-marche",
  "shifts_add_shift": "Create a shift",
  "shifts_break_105_label": "1h45 min",
  "shifts_break_120_label": "2h",
  "shifts_break_135_label": "2h15 min",
  "shifts_break_150_label": "2h30 min",
  "shifts_break_165_label": "2h45 min",
  "shifts_break_180_label": "3h",
  "shifts_break_195_label": "3h15 min",
  "shifts_break_20_label": "20 min",
  "shifts_break_210_label": "3h30 min",
  "shifts_break_225_label": "3h45 min",
  "shifts_break_240_label": "4h",
  "shifts_break_255_label": "4h15 min",
  "shifts_break_270_label": "4h30 min",
  "shifts_break_285_label": "4h45 min",
  "shifts_break_300_label": "5h",
  "shifts_break_30_label": "30 min",
  "shifts_break_45_label": "45 min",
  "shifts_break_60_label": "60 min",
  "shifts_break_75_label": "1h15 min",
  "shifts_break_90_label": "1h30 min",
  "shifts_break_label": "Break",
  "shifts_break_none_label": "None",
  "shifts_break_placeholder": "Duration",
  "shifts_date_label": "Date",
  "shifts_duplicate_modal_subtitle": "Select the days for which you want to copy this shift",
  "shifts_duplicate_modal_success": "The slot(s) have been successfully duplicated.",
  "shifts_duplicate_modal_title": "Copy this shift",
  "shifts_endTime_label": "End",
  "shifts_siders_count": "Nb of Siders",
  "shifts_startTime_label": "Start",
  "shifts_summary_column_break": "Break",
  "shifts_summary_column_date": "Date",
  "shifts_summary_column_total_hours": "Hours total",
  "shifts_summary_column_total_number": "Siders",
  "shifts_summary_time_from": "from",
  "shifts_summary_time_to": "to",
  "shifts_warning_7_consecutive": "You've added shifts for 7 days in a row. We won't be able to select the same person on all of these shifts - only 6 days in a row can be worked.",
  "shifts_warning_breaktime_subtitle": "The law provides for a break of 11 hours between 2 working days (excluding specific agreement). If necessary, we can position two different profiles.",
  "shifts_warning_breaktime_title": "Mandatory time off",
  "shifts_warning_new_year_day_subtitle": "New year's a bank holiday",
  "shifts_warning_new_year_day_title": "New year's day",
  "shifts_warning_public_holiday_period": "This task includes a bank holiday. Bank holidays are paid even though they haven't been worked in case they are part of the usual working days.",
  "shifts_warning_public_holiday_selected": "You've added a shift on a bank holiday. Pay is increased according to your remuneration policy. Check that this is a work day in your company.",
  "sider_profile_card_ongoing": "Ongoing",
  "sider_profile_copy_email": "Copy the Sider's email",
  "sider_profile_email_copied": "The email was successfully copied",
  "sider_profile_panel_educations": "Education",
  "sider_profile_panel_experiences": "Experiences",
  "sider_profile_panel_experiences_industry": "Past experience%{plural} in %{industryName} (%{count})",
  "sider_profile_panel_experiences_other": "Past experience%{plural} (%{count})",
  "sider_profile_panel_hours": "%{numberOfHours} hour%{plural}",
  "sider_profile_panel_months": "%{numberOfMonths} month%{plural}",
  "sider_profile_panel_tasks": "task%{plural}",
  "sider_profile_panel_tasks_company": "Past task%{plural} at %{companyName} (%{count})",
  "sider_profile_panel_tasks_ongoing": "Ongoing task%{plural}",
  "sider_profile_panel_tasks_other": "Past task%{plural} (%{count})",
  "sider_profile_panel_tasks_side": "Side tasks",
  "sider_profile_panel_weeks": "\u003c 1 Month",
  "sider_profile_panel_years": "%{numberOfYears} year%{plural}",
  "sign_in_create_account": "Create a company account",
  "sign_in_email_placeholder": "you@company.com",
  "sign_in_forgot_password": "I forgot my password",
  "sign_in_form_title": "Sign in",
  "sign_in_log_in": "Log in",
  "sign_in_logo_company": "Company",
  "sign_in_no_account_yet": "Don't have a company account yet?",
  "sign_in_or": "or",
  "sign_in_password_placeholder": "Password",
  "sign_in_title": "Sign in to Side",
  "sign_in_with_google": "Log in with google",
  "sign_in_work_on_side": "I am a talent",
  "sign_up_accept": "I accept",
  "sign_up_company_label": "Company name",
  "sign_up_company_message_label": "Do you have a message for our team?",
  "sign_up_company_need_label": "Your need",
  "sign_up_company_need_learn_about_side": "I want to know more about Side",
  "sign_up_company_need_less_than_7_days": "I am looking to recruit quickly (\u003c 7 days)",
  "sign_up_company_need_more_than_7_days": "I am looking to recruit soon (\u003e 7 days)",
  "sign_up_company_need_placeholder": "I want to know more about Side",
  "sign_up_company_placeholder": "Your company's name",
  "sign_up_company_size_label": "Number of employees",
  "sign_up_company_size_placeholder": "Number of employees",
  "sign_up_create_account": "Create a company account",
  "sign_up_email_label": "Email",
  "sign_up_email_placeholder": "you@company.com",
  "sign_up_form_subtitle_second_step": "Tell us a bit more about you and your company before you post a task\n",
  "sign_up_form_title": "Create your company account",
  "sign_up_form_title_second_step": "One last thing...",
  "sign_up_fullname_label": "First and last name",
  "sign_up_fullname_placeholder": "Your first and last name",
  "sign_up_industry_fashion": "Fashion",
  "sign_up_industry_gsa": "Supermarkets",
  "sign_up_industry_gss": "Specialized supermarkets",
  "sign_up_industry_label": "industry",
  "sign_up_industry_logistics": "Logistics/Transports",
  "sign_up_industry_other": "Others",
  "sign_up_industry_placeholder": "Your industry",
  "sign_up_industry_services": "Services",
  "sign_up_industry_tech": "Technology",
  "sign_up_log_in": "Sign in",
  "sign_up_logo_company": "Company",
  "sign_up_no_account_yet": "Already have a company account?",
  "sign_up_or": "or",
  "sign_up_password_label": "Password",
  "sign_up_password_placeholder": "Choose a password",
  "sign_up_phone_label": "Phone number",
  "sign_up_phone_placeholder": "Your phone number",
  "sign_up_size_to": "to",
  "sign_up_temp_worker": "Sign up as a temp worker",
  "sign_up_terms_and_conditions": "the terms and conditions",
  "sign_up_title": "Create your company account",
  "sign_up_with_google": "Create a company account with Google",
  "sign_up_work_on_side": "Work on Side",
  "signup_error_invalid_email": "Use a valid email",
  "slo": "Slovak",
  "slv": "Slovenian",
  "smo": "Samoan",
  "sot": "Sotho",
  "spa": "Spanish",
  "sr_alert_disputed": "You disputed this time entry, it’s currently being reviewed by the Sider.",
  "sr_alert_forgotten": "%{name} changed the hours manually, make sure to double-check this entry.",
  "srp": "Serbian",
  "submit": "Submit",
  "swa": "Swahili",
  "swe": "Swedish",
  "table_header_end_date": "End date",
  "table_header_start_date": "Start date",
  "table_header_status": "Status",
  "table_header_task": "Task",
  "tam": "Tamil",
  "task_shifts_add": "Add shifts",
  "task_shifts_cost_estimation": "Total estimate excluding VAT",
  "task_shifts_dialog_message": "This action would impact the mission slots.",
  "task_shifts_dialog_title": "Are you sure ?",
  "task_shifts_footer_delete_button": "Delete shifts",
  "task_shifts_modal_break_label": "Break",
  "task_shifts_modal_break_placeholder": "None",
  "task_shifts_modal_break_tip_label": "(unpaid)",
  "task_shifts_modal_bulk_selection_all_shifts": "All the shifts of the repetition",
  "task_shifts_modal_bulk_selection_label_delete": "Which shift(s) would you like to delete?",
  "task_shifts_modal_bulk_selection_label_edit": "Which shift(s) would you like to edit?",
  "task_shifts_modal_bulk_selection_this_shift_and_following": "This shift and the following shifts of the repetition",
  "task_shifts_modal_bulk_selection_this_shift_only": "This shift only",
  "task_shifts_modal_creation_title": "Add one or more shifts",
  "task_shifts_modal_date_label": "Date",
  "task_shifts_modal_days_error": "At least one day must be selected.",
  "task_shifts_modal_deletion_title": "Delete one or more shifts",
  "task_shifts_modal_duration_error": "The actual working time must be between 2 and 10 hours.",
  "task_shifts_modal_edition_title": "Edit one or more shifts",
  "task_shifts_modal_label_each": "Each",
  "task_shifts_modal_label_every": "Every",
  "task_shifts_modal_label_until": "Until",
  "task_shifts_modal_option_monthly": "Month",
  "task_shifts_modal_option_weekly": "Week",
  "task_shifts_modal_radio_1_of_the_month": "First",
  "task_shifts_modal_radio_2_of_the_month": "Second",
  "task_shifts_modal_radio_3_of_the_month": "Third",
  "task_shifts_modal_radio_4_of_the_month": "Fourth",
  "task_shifts_modal_radio_last_of_the_month": "Last",
  "task_shifts_modal_radio_of_the_month": "of the month",
  "task_shifts_modal_schedule_end_label": "End time",
  "task_shifts_modal_schedule_start_label": "Start time",
  "task_shifts_modal_siders_label": "Number of Siders",
  "task_shifts_modal_slots_error": "The number of slots must be between 1 and 50.",
  "task_shifts_modal_switch_holidays": "Includes holidays",
  "task_shifts_modal_switch_repeat": "Repeating itself",
  "task_shifts_modal_until_error": "The end date must be later than the start date.",
  "task_shifts_no_shifts_description": "Add your shifts, individually or in series.",
  "task_shifts_no_shifts_title": "No shift at the moment",
  "task_shifts_success_toast_creation": "The slot(s) have been created successfully.",
  "task_shifts_success_toast_deletion": "The slot(s) have been deleted.",
  "task_shifts_success_toast_edition": "The slot(s) have been modified.",
  "task_shifts_table_break": "Break",
  "task_shifts_table_hours": "Schedules",
  "task_shifts_table_shifts": "Shifts",
  "task_shifts_title": "Shifts",
  "task_status_closed": "Over",
  "task_status_done": "Over",
  "task_status_draft": "Draft",
  "task_status_new": "New",
  "task_status_ongoing": "Ongoing",
  "task_status_upcoming": "Upcoming",
  "te_dispute_add_break": "Add a break",
  "te_dispute_break": "Breaks",
  "te_dispute_cancel": "Cancel",
  "te_dispute_did_not_work": "The Sider didn't work",
  "te_dispute_did_not_work_tip_after_confirmed": "You declared that this Sider didn't work on this shift. Side's team has been informed.",
  "te_dispute_did_not_work_tip_confirmation": "You are about to contest all hours reported by the Sider on this shift. This action cannot be undone.",
  "te_dispute_review_info": "Reviewed and confirmed by %{managerName} on %{date} at %{hour}.",
  "te_dispute_send": "Send",
  "te_dispute_shift": "Clock in \u0026 out",
  "te_dispute_shift_end": "End",
  "te_dispute_shift_start": "Start",
  "te_dispute_subtitle": "Edit the incorrect informations.",
  "te_dispute_title": "Time entries",
  "te_tip_disputed_content": "You can’t dispute twice the same time entry.",
  "te_tip_edit_content": "Simply edit the hours if you don’t agree with them.",
  "te_tip_howto_content": "Your edits will be sent to %{name} to accept.",
  "te_tip_review_content": "You disputed this time entry, it’s currently being reviewed.",
  "textarea_counter_label": "character%{s} left",
  "tha": "Thai",
  "top_menu_dropdown_default": "All teammates",
  "top_menu_filter_all": "All",
  "top_menu_filter_all_invoices": "All invoices",
  "top_menu_filter_completed": "Completed",
  "top_menu_filter_draft": "Drafts",
  "top_menu_filter_ongoing": "Ongoing",
  "top_menu_filter_paid_ts": "Paid",
  "top_menu_filter_siders": "All Siders",
  "top_menu_filter_to_be_paid": "Open for review",
  "top_menu_filter_to_review": "Open for review",
  "top_menu_filter_upcoming": "Upcoming",
  "top_menu_filter_validated": "Validated",
  "top_menu_planning": "Planning",
  "ts_attendance_disputed_success": "Attendance was successfully modified",
  "ts_confirm_attendances": "Confirm attendances",
  "ts_confirm_attendances_mobile": "Confirm",
  "ts_edit_form_break_error_after": "Breaks ends after the shift, please edit your entry",
  "ts_edit_form_break_error_before": "Breaks starts before the shift, please edit your entry.",
  "ts_edit_form_break_title": "Entered breaks",
  "ts_edit_form_breaks_add": "Add a break",
  "ts_edit_form_description": "Edit wrong informations.",
  "ts_edit_form_hours_24_hours_error": "",
  "ts_edit_form_hours_label": "Start \u0026 end",
  "ts_edit_form_hours_title": "Entered hours",
  "ts_export_csv": "Export Timesheets as csv",
  "ts_export_csv_error_body": "Please contact our online support for more information.",
  "ts_export_csv_error_title": "The CSV file couldn't be created.",
  "ts_file_import_button": "Import",
  "ts_file_import_dropzone_button": "Upload a file",
  "ts_file_import_dropzone_hint": "Download a csv, maximum 1MB.",
  "ts_file_import_dropzone_text": "or drang and drop here",
  "ts_file_import_modal_button": "Import",
  "ts_file_import_modal_cancel": "Cancel",
  "ts_file_import_modal_column_error": "Missing columns:",
  "ts_file_import_modal_db_att_duplicate_error": "The following lines have already been imported:",
  "ts_file_import_modal_description": "The file must be in csv format and contain the following columns: siderId, startDate, endDate, breakDuration.",
  "ts_file_import_modal_empty_error": "The following lines contain empty cells:",
  "ts_file_import_modal_end_before_start_error": "The following lines contain an end date earlier than the start date:",
  "ts_file_import_modal_file_att_duplicate_error": "The following lines are duplicates of another line in the file, please delete them before reimporting:",
  "ts_file_import_modal_format_error": "The following lines contain invalid formats:",
  "ts_file_import_modal_import": "Import",
  "ts_file_import_modal_import_success": "%{count} attendances have been successfully imported",
  "ts_file_import_modal_shifts_not_found_error": "The following lines do not correspond to any configured shift:",
  "ts_file_import_modal_sider_id_error": "The following lines contain invalid siderIds:",
  "ts_file_import_modal_template_link": "Access the file template",
  "ts_file_import_modal_title": "Import Excel file (.csv only)",
  "ts_file_import_modal_with_error_main_button": "Continue importing",
  "ts_file_import_modal_with_error_secondary_button": "Import another file",
  "ts_file_import_modal_with_error_title": "This file contains errors. Continue importing?",
  "ts_filters_all": "All dates",
  "ts_filters_day": "Day",
  "ts_filters_month": "Month",
  "ts_filters_search_item_empty": "No results found with this query",
  "ts_filters_search_manager": "Search by task manager name",
  "ts_filters_search_sider": "Search by Sider name",
  "ts_filters_status": "Status",
  "ts_filters_type": "Type",
  "ts_filters_type_hours_different_description": "Only Siders who entered mismatching hours will be displayed",
  "ts_filters_type_hours_different_label": "Hours not matching scheduled shift hours",
  "ts_filters_type_hours_similar_description": "Siders who entered matching hours will be displayed",
  "ts_filters_type_hours_similar_label": "Hours matching scheduled shift hours",
  "ts_filters_week": "Week",
  "ts_footer_paragraph": "Select one or several Siders to validate their hours or state that they didn't work",
  "ts_footer_selected_sider": "1 Shift selected",
  "ts_footer_selected_siders": "%{count} shifts selected",
  "ts_footer_sider_didnt_work": "Sider did not work",
  "ts_footer_siders_didnt_work": "Siders did not work",
  "ts_footer_tovalidate_attendance": "Validate 1 attendance",
  "ts_footer_tovalidate_attendances": "Validate %{count} attendances",
  "ts_footer_validate_hours": "Validate hours",
  "ts_hours_validation_faq_label": "Why do I need to confirm attendances?",
  "ts_hours_validation_faq_link": "https://help.side.co/hc/fr/articles/360000325737-Comment-v%C3%A9rifier-les-heures-d%C3%A9clar%C3%A9es-par-mes-Siders-",
  "ts_hours_validation_modal_agreement": "I agree to confirm selected attendances.",
  "ts_hours_validation_modal_attendances_count": "Attendances to confirm",
  "ts_hours_validation_modal_failure": "%{count} attendance could not be validated. We invite you to try to confirm it again in a few minutes.",
  "ts_hours_validation_modal_failure_plural": "%{count} attendances could not be validated. We invite you to try to confirm them again in a few minutes.",
  "ts_hours_validation_modal_submit_button": "Confirm and send",
  "ts_hours_validation_modal_submit_button_mobile": "Confirm",
  "ts_hours_validation_modal_success": "%{count} attendance was validated successfully.",
  "ts_hours_validation_modal_success_plural": "%{count} attendances were validated successfully.",
  "ts_hours_validation_modal_tip_content": "Once a timesheet is confirmed, you won’t be able to edit its hours anymore and you have to pay them",
  "ts_hours_validation_modal_tip_title": "Confirmation",
  "ts_hours_validation_modal_title": "Timesheets confirmation",
  "ts_hours_validation_modal_worked_hours": "Total worked hours",
  "ts_modal_confirm_didntwork_content": "You are about to contest each hours declared on the selected slots",
  "ts_modal_confirm_didntwork_tip": "Your statement will be sent to the Sider for approval. This action cannot be undone.",
  "ts_modal_confirm_didntwork_title": "Confirm Sider(s) didn't work",
  "ts_modal_confirm_submit": "Confirm and submit",
  "ts_modal_confirm_validation_content": "Please confirm that you want to validate the selected shift(s)",
  "ts_modal_confirm_validation_tip": "You will not be able to edit the slots validated. They will show up on your next bill.",
  "ts_modal_confirm_validation_title": "Confirmation",
  "ts_panel_add_break": "Add a break",
  "ts_panel_imported_hours": "Hours imported by the company",
  "ts_panel_scheduled_hours": "Scheduled hours",
  "ts_panel_sider_breaks": "Break%{plural}",
  "ts_panel_sider_didnt_work": "Sider didn't work",
  "ts_panel_sider_hours": "Hours entered by the Sider",
  "ts_panel_submit": "Send my updates",
  "ts_select": "Select",
  "ts_selected_attendances_count": "%{count} attendance%{plural} \u003cspan\u003eselected\u003c/span\u003e",
  "ts_shifts_break": "break",
  "ts_shifts_break_hour": "1 hour break",
  "ts_shifts_break_mins": "mins break",
  "ts_shifts_deadline": "Deadline:",
  "ts_shifts_expected_hours": "%{hours} hours approx.",
  "ts_shifts_hide_entries": "Hide already reviewed time entries",
  "ts_shifts_in": "Shifts in",
  "ts_shifts_mark_seen": "Mark as seen",
  "ts_shifts_no_time_day": "No time entries for %{date}.",
  "ts_shifts_no_time_shift": "No new time entries",
  "ts_shifts_show_entries": "View already reviewed time entries",
  "ts_shifts_unplanned_day": "Unplanned shifts on",
  "ts_shifts_unplanned_in": "Unplanned shifts in",
  "ts_table_add_comment": "Add a comment on this Sider.",
  "ts_table_conflict_company_description": "You just modified the hours declared on this shift. This version is pending until validated by %{siderName}.",
  "ts_table_conflict_company_title": "Validation pending",
  "ts_table_conflict_insider_description": "You just modified the hours declared on this shift. This version is pending until validated by %{siderName}.",
  "ts_table_conflict_insider_title": "Validation pending",
  "ts_table_conflict_sider_description": "%{siderName} refused your rectification for this shift. Please contact support to solve the conflict.",
  "ts_table_conflict_sider_title": "Contested version",
  "ts_table_diff_break_description": "There is a %{delta} difference between the scheduled breaks and what was entered by the Sider.",
  "ts_table_diff_break_label": "%{delta} difference",
  "ts_table_diff_hours_description": "There is a %{delta} difference with the scheduled hours.",
  "ts_table_diff_hours_label": "%{delta} difference",
  "ts_table_edit_comment": "Edit comment",
  "ts_table_head_duration": "Duration",
  "ts_table_head_duration_tag": "Total duration entered by Siders",
  "ts_table_head_mission": "Task",
  "ts_table_head_sider_breaks": "Break",
  "ts_table_head_sider_hours": "Hours entered",
  "ts_table_head_start_end": "Start \u0026 End",
  "ts_table_head_status": "Status",
  "ts_table_no_attendances_description": "Please modify your search query or come back later.",
  "ts_table_no_attendances_title": "No shift to display",
  "ts_table_validated_tag_content": "Shift validated at %{validationDate}.",
  "ts_table_validated_tag_content_validated_by": "Shift validated by %{validatedBy} at %{validationDate}.",
  "ts_table_validated_tag_title": "Validated shift",
  "ts_tag_disputed": "Contested",
  "ts_tag_event_log": "File imported on %{date} at %{time}",
  "ts_tag_external_file_origin": "External file",
  "ts_tag_validated": "Validated",
  "ts_tag_waiting": "To validate",
  "ts_tip_archives_content": "Here is the recap of all the payments sent to Siders. If you don't agree on time entries, you can edit them. As a reminder, payments are all triggered on Tuesdays.",
  "ts_tip_archives_title": "It's been paid!",
  "ts_tip_date_review": "You have until %{date} at 10:00pm to review the timesheets below before payments are triggered!",
  "ts_tip_hours_validation_content": "You have to check and validate all the hours Siders have declared so they can be paid.",
  "ts_tip_hours_validation_title": "Reviewing timesheets",
  "ts_tip_review_content": "Keep you timesheets organised by using the tick\u003ci class=\\\"icon-checkmark\\\"\u003e\u003c/i\u003ewhen you're done with an entry.",
  "ts_tip_review_title": "Mark as seen",
  "ts_tip_unplanned_content": "This section gathers entries from Siders who weren't selected by Side that day or shifts that weren't entered on the platform.",
  "ts_tip_unplanned_title": "Unplanned shifts",
  "ts_to_validate_blank_state_content": "You have no pending timesheet to review",
  "ts_to_validate_blank_state_title": "All good here!",
  "ts_unselect_all_attendances": "Unselect all",
  "ts_update": "Update",
  "ts_validated_blank_state_content": "You don’t have any confirmed timesheets.",
  "ts_validated_blank_state_title": "Nothing to see here!",
  "ts_warning_cicos_content": "Some Siders didn’t log their hours on time. Make sure you check all time entries tagged with ",
  "ts_warning_cicos_tab_tooltip": "Some hours were logged afterwards, make sure to double-check them.",
  "ts_warning_cicos_tag": "Logged afterwards",
  "ts_warning_cicos_tag_tooltip": "These hours weren’t logged the same day.",
  "ts_warning_cicos_title": "Some hours were logged afterwards",
  "tur": "Turkish",
  "ukr": "Ukrainian",
  "undo": "Undo",
  "until": "until",
  "until_date": "until",
  "upcoming_section_button_view_more": "View all upcoming missions (%{numberOfNotDisplayedUpcoming})",
  "upcoming_section_row_status_cell_subtitle_contact_plural": "Contact the %{siderNumber} Siders",
  "upcoming_section_row_status_cell_subtitle_contact_singular": "Contact the Sider",
  "upcoming_section_row_status_cell_subtitle_selection_ongoing": "End scheduled for %{hiringEndDateDay} at %{hiringEndDateHour}",
  "upcoming_section_row_status_cell_title_selection_almost_done": "%{selectedSiderNumber}/%{totalSidersNumberExpected} selected Siders",
  "upcoming_section_row_status_cell_title_selection_done": "Selection done",
  "upcoming_section_row_status_cell_title_selection_ongoing": "Selection ongoing",
  "upcoming_section_row_task_cell_managed_by": "Managed by ",
  "upcoming_section_title": "Upcoming tasks",
  "update_manager": "Change mission manager",
  "urd": "Urdu",
  "use": "Use",
  "uzb": "Uzbek",
  "various_geolocation_multiple_places": " different locations",
  "various_geolocation_online": "Online",
  "various_time_deadline": "Deadline",
  "vie": "Vietnamese",
  "view_invoices": "View invoices \u0026 payments",
  "view_siders": "View Siders",
  "view_task": "View mission",
  "week": "Week",
  "yes": "Yes",
  "zgh": "Tamazight (berber)"
}

export default translation;