import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { GetOrganisationProfileResponse } from "./getOrganisationProfile";
import { apiConf } from "./index";

type PatchOrganisationProfileReq = {
    name?: string;
    description?: string;
    website?: string;
    logoURL?: string;
    youtubeURL?: string;
    coverURL?: string;
};

export async function patchOrganisationProfile(
    req: PatchOrganisationProfileReq,
): Promise<GetOrganisationProfileResponse | null> {
    debug.addBreadcrumb({
        message: `PatchOrganisationProfile`,
        category: `action`,
        data: req,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisations/profile`, {
                method: `PATCH`,
                credentials: "omit",
                body: JSON.stringify(req),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
