import I18n from "i18n-js";
import { z } from "zod";

import {
    attendanceModes,
    attendanceStatus,
    attendanceTypes,
    searchBy,
} from "@lib/api/getAttendances";
import { globalLayoutRoute } from "@routes";
import { allowCompany } from "@routes/authorization";
import { createRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";

import Timesheets from "./index";

const timesheetsSearchSchema = z
    .object({
        status: z.array(z.enum(attendanceStatus)).default(["att.waiting", "att.disputed"]),
        pageNumber: z.number().catch(1),
        type: z.array(z.enum(attendanceTypes)),
        mode: z.enum(attendanceModes),
        startDate: z.string().datetime(),
        endDate: z.string().datetime(),
        searchBy: z.enum(searchBy),
        siderId: z.string(),
        managerId: z.string(),
        siderName: z.string(),
        managerName: z.string(),
    })
    .partial()
    .required({
        status: true,
        pageNumber: true,
    });
export type TimesheetsSearch = z.infer<typeof timesheetsSearchSchema>;
export const timesheetsRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `timesheets`,
    component: Timesheets,
    validateSearch: (search: TimesheetsSearch) => {
        return timesheetsSearchSchema.parse(search);
    },
    beforeLoad: () => {
        if (!allowCompany()) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => I18n.t(`doc_title_timesheets`) };
    },
});
