import { memo } from "react";
import { connect } from "react-redux";

import { setShowSiderProfilePanel } from "../../../store/display";
import { selectWeek } from "../../../store/planning";
import { getSiderProfilePanelDisplay } from "../../../store/selectors/display";
import { getSelectedWeek, getSelectedWeekPlanning } from "../../../store/selectors/planning";
import { getSelectedSider, getSiderIsLoading } from "../../../store/selectors/siders";
import { getSiderById, resetSelectedSider } from "../../../store/siders";
import Planning from "../components/Planning";

const mapStateToProps = (state) => ({
    planning: getSelectedWeekPlanning(state),
    selectedWeek: getSelectedWeek(state),
    selectedSider: getSelectedSider(state),
    isLoading: getSiderIsLoading(state),
    isSiderProfilePanelVisible: getSiderProfilePanelDisplay(state),
});

const mapDispatchToProps = (dispatch) => ({
    selectWeek: (week, year) => dispatch(selectWeek(week, year)),
    getSider: ({ siderId, withOngoing, withTasks, withExperiences, withEducations }) =>
        dispatch(getSiderById(siderId, withOngoing, withTasks, withExperiences, withEducations)),
    setShowSiderProfilePanel: (isVisible, taskCategory) =>
        dispatch(setShowSiderProfilePanel(isVisible, taskCategory)),
    resetSelectedSider: () => dispatch(resetSelectedSider()),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Planning));
