import { getTasksResponse, TasksItem } from "@lib/api";

const normalizeTasks = (data: getTasksResponse["tasks"]): Record<string, TasksItem> => {
    const tasks = {};
    if (!data) return tasks;

    data.forEach((task) => {
        if (
            (Object.values(tasks) as TasksItem[]).findIndex(
                (existingTask) => existingTask.id === task.id,
            ) === -1
        ) {
            tasks[task.id] = task;
        }
    });

    return tasks;
};

export default normalizeTasks;
