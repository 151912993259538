import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { RecurrenceOptions } from "@routes/TaskPosting/ShiftsStep/useShiftAPI";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type DeleteShiftParams = {
    shiftId: string;
    scope?: RecurrenceOptions;
};
export async function deleteShift(params: DeleteShiftParams) {
    debug.addBreadcrumb({
        message: `deleteShift`,
        category: `action`,
        data: {
            id: params?.shiftId,
        },
    });

    const urlParams = new URLSearchParams();
    if (params.scope) {
        urlParams.append(`scope`, params.scope);
    }

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/shifts/${params?.shiftId}?${urlParams.toString()}`,
                {
                    method: `DELETE`,
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return {};
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return {};
    }
}
