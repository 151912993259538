const normalizePossibleUsers = (data) => {
    let possibleUsersToImpersonate = [];
    if (data && data.results) {
        possibleUsersToImpersonate = data.results.map((possibleUserToImpersonate) => ({
            id: possibleUserToImpersonate._id,
            sublabel: possibleUserToImpersonate.companyName,
            label: possibleUserToImpersonate.email,
            complementarylabel: possibleUserToImpersonate.companyId,
            firstName: possibleUserToImpersonate.firstName,
            lastName: possibleUserToImpersonate.lastName,
            isParent: possibleUserToImpersonate.isParent,
        }));
    }

    return possibleUsersToImpersonate;
};

export default normalizePossibleUsers;
