import I18n from "i18n-js";

import { globalLayoutRoute } from "@routes";
import { allowGroup } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import Home from "./index";

export const homeRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `home`,
    component: Home,
    beforeLoad: () => {
        if (!allowGroup()) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => I18n.t(`doc_title_home`) };
    },
});
