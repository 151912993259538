import { combineReducers } from "redux";

import companyReducer from "./company";
import contractsReducer from "./contracts";
import displayReducer from "./display";
import invoicesReducer from "./invoices";
import jobTypesReducer from "./jobTypes";
import logasReducer from "./logas";
import planningReducer from "./planning";
import sidersReducer from "./siders";
import tasksReducer from "./tasks";

const makeRootReducer = () =>
    combineReducers({
        company: companyReducer,
        contracts: contractsReducer,
        display: displayReducer,
        invoices: invoicesReducer,
        jobTypes: jobTypesReducer,
        logas: logasReducer,
        planning: planningReducer,
        siders: sidersReducer,
        tasks: tasksReducer,
    });

export default makeRootReducer;
