import { forwardRef } from "react";
import i18n from "i18n-js";
import { Button, Modal, ModalContent, ModalFooter, ModalHeader } from "sui";

type Props = {
    deleteJobDescription: () => void;
    isDeletePending: boolean;
};
export const DeleteJobDescriptionModal = forwardRef<HTMLDialogElement, Props>(
    ({ deleteJobDescription, isDeletePending }, ref) => (
        <Modal ref={ref}>
            <ModalHeader title={i18n.t(`modal_delete_job_description_title`)} />
            <ModalContent>
                <p>{i18n.t(`modal_delete_job_description_content`)}</p>
            </ModalContent>
            <ModalFooter
                cancelButtonLabel={i18n.t(`cancel`)}
                mainButton={
                    <Button
                        disabled={isDeletePending}
                        loading={isDeletePending}
                        onClick={deleteJobDescription}
                    >
                        {i18n.t(`delete`)}
                    </Button>
                }
            ></ModalFooter>
        </Modal>
    ),
);
