import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type GetOrganisationUsersResponse = {
    users: User[];
};

type User = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    validated: boolean;
    sourceOrganisation: SourceOrganisation;
};

type SourceOrganisation = {
    id: string;
    name: string;
};

export async function getOrganisationUsers(): Promise<GetOrganisationUsersResponse | null> {
    debug.addBreadcrumb({
        message: `GetOrganisationUsers`,
        category: `action`,
        data: {},
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisations/users`, {
                method: `GET`,
                credentials: "omit",
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
