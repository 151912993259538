import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type PutSignatureParams = {
    documentId: string;
    organisationId: string;
};

export function putSignature(params: PutSignatureParams) {
    debug.addBreadcrumb({
        message: `Sign a contracts batch`,
        category: `action`,
        data: params,
    });

    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/document/${params.documentId}/sign`,
            {
                method: `PUT`,
            },
        ),
    );
}
