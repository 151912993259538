import { connect } from "react-redux";

import { getContractsBatches, updateCurrentTab } from "../../../store/contracts";
import {
    getCurrentContractsBatches,
    getCurrentPagination,
    getHasContractsBatches,
    getIsLoading,
} from "../../../store/selectors/contracts";
import ContractsBatches from "../components/ContractsBatches";

const mapStateToProps = (state) => ({
    isLoading: getIsLoading(state),
    pagination: getCurrentPagination(state),
    contractsBatches: getCurrentContractsBatches(state),
    hasContractsBatches: getHasContractsBatches(state),
});

const mapDispatchToProps = (dispatch) => ({
    getContractsBatches: (pageNumber) => dispatch(getContractsBatches(pageNumber)),
    updateCurrentTab: (name) => dispatch(updateCurrentTab(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsBatches);
