import { connect } from "react-redux";

import trackEvent from "../../../lib/trackers";
import { deleteTask } from "../../../store/tasks";
import DeleteModal from "../components/DeleteModal";

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        task: { id, status },
        match,
    } = ownProps;

    return {
        deleteTask: () => {
            trackEvent({
                name: `taskOptionButton`,
                params: {
                    optionClick: `deleteDraft`,
                    page: !match || !match.params.status ? `all` : status,
                    taskStatus: status,
                },
            });
            dispatch(deleteTask(status, id));
        },
    };
};

export default connect(null, mapDispatchToProps)(DeleteModal);
