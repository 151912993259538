import I18n from "i18n-js";
import { z } from "zod";

import { globalLayoutRoute } from "@routes";
import { allowCompany } from "@routes/authorization";
import { createRoute, redirect } from "@tanstack/react-router";

import TaskView from "./components/TaskView";
import TasksListHome from "./containers/TasksListHome";
import TasksListStatus from "./containers/TasksListStatus";
import TasksList from "./index";

export const tasksRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `tasks`,
    component: TasksList,
    beforeLoad: () => {
        if (!allowCompany()) {
            throw redirect({ to: `/` });
        }

        return { getTitle: () => I18n.t(`doc_title_tasks`) };
    },
});
export const tasksListIndexRoute = createRoute({
    getParentRoute: () => tasksRoute,
    path: `/`,
    beforeLoad: () => {
        throw redirect({ to: `/tasks/all` });
    },
});
export const tasksListHomeRoute = createRoute({
    getParentRoute: () => tasksRoute,
    path: `all`,
    component: TasksListHome,
});
const tasksListStatusSearchSchema = z
    .object({
        page: z.number().catch(1),
    })
    .partial();

type TaskListStatusSearch = z.infer<typeof tasksListStatusSearchSchema>;
export const tasksListStatusRoute = createRoute({
    getParentRoute: () => tasksRoute,
    path: `$status`,
    validateSearch: (search: TaskListStatusSearch) => tasksListStatusSearchSchema.parse(search),
    component: TasksListStatus,
});
export const taskViewRoute = createRoute({
    getParentRoute: () => tasksRoute,
    path: `view/$taskId`,
    component: TaskView,
    beforeLoad: () => {
        if (!allowCompany()) {
            throw redirect({ to: `/` });
        }
    },
});
