import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type SignUpReq = {
    email: string;
};

export type SignupRes = {
    email: string;
};

export async function signup(req: SignUpReq): Promise<SignupRes | null> {
    debug.addBreadcrumb({
        message: `SignUp`,
        category: `action`,
        data: {
            email: req.email,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/auth/signup`, {
                method: `POST`,
                credentials: "omit",
                body: JSON.stringify({
                    email: req.email,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
