import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GetLocationsResponse = {
    locations: Location[];
};

type Location = {
    id: string;
    organisationId: string;
    postalCode: string;
    city: string;
    lat: number;
    lng: number;
    country: string;
    confirmed: boolean;
    address: string;
    description: string;
    usedAt?: UsedAt;
    online: boolean;
    favorite: boolean;
    title?: string;
};

type UsedAt = {
    isData: boolean;
    dateString: string;
};
export async function getLocations(params: {
    organisationId: string;
}): Promise<GetLocationsResponse | object> {
    debug.addBreadcrumb({
        message: `Get locations`,
        category: `action`,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/organisations/${params.organisationId}/locations`,
                {
                    method: `GET`,
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return {};
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return {};
    }
}
