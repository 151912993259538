import { useState } from "react";
import clsx from "clsx";
import i18n from "i18n-js";
import { ModalSimple } from "side-ui";
import { IconCross } from "side-ui";

import styles from "./DevicesForm.module.css";

export function EditDevices({ hideModal, selectedDevices, setDevices }) {
    const [state, setState] = useState({
        devices: selectedDevices,
        touched: false,
    });
    function removeDevice(equipment) {
        setState((prevState) => {
            // handles the deletion of a custom equipment which is not yet persisted
            // -> id is set by the backend so id doesn't exist yet if equipment is issuedByTheFrontEnd
            if (equipment.custom && equipment?.issuedByFrontEnd) {
                return {
                    ...prevState,
                    devices: prevState.devices.filter((e) => e?.customId !== equipment.customId),
                    touched: true,
                };
            }

            return {
                ...prevState,
                devices: prevState.devices.filter((e) => e.id !== equipment.id),
                touched: true,
            };
        });
    }

    return (
        <ModalSimple
            hideModal={() => hideModal()}
            title={i18n.t(`job-descriptions_creation_conditions_equipments_other_title`)}
            cancelLabel={i18n.t(`cancel`)}
            validateLabel={i18n.t(`save`)}
            disableButton={!state.touched}
            action={() => {
                setDevices(state.devices);
                hideModal();
            }}
            customClass={styles.modal}
        >
            {state.devices?.map((selectedDevice) => (
                <div key={selectedDevice?.id} className={clsx(styles.edit, styles.devices)}>
                    <div className={styles.title}>
                        {selectedDevice.custom ? selectedDevice.name : i18n.t(selectedDevice?.name)}

                        {selectedDevice?.name?.length ? (
                            <button
                                type='button'
                                title={i18n.t(
                                    `job-descriptions_creation_conditions_equipments_other_delete_button`,
                                )}
                                onClick={() => removeDevice(selectedDevice)}
                            >
                                <IconCross customClass={styles.icon} />
                            </button>
                        ) : null}
                    </div>
                </div>
            ))}
        </ModalSimple>
    );
}
