import I18n from "i18n-js";

import { rootRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import ActivateAccount from "./ActivateAccount";

export const activateAccountRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: `invitation`,
    beforeLoad: () => ({ getTitle: () => I18n.t(`activate_account_title`) }),
    component: ActivateAccount,
});
