import moment from "moment";

import { GetPlanningResponse } from "@lib/api";

import type { Task } from "../types/planning";

const normalizePlanning = (data: GetPlanningResponse): { tasks: Array<Task> } => ({
    tasks:
        data.tasks
            ?.map((task) => ({
                ...task,
                siders:
                    task.siders?.map((sider) => ({
                        ...sider,
                        slots:
                            sider.slots?.map((slot) => ({
                                ...slot,
                                startDate: moment(slot.startDate),
                                endDate: moment(slot.endDate),
                            })) || [],
                    })) || [],
            }))
            ?.sort((taskA, taskB) => taskA.id.localeCompare(taskB.id)) || [],
});

export default normalizePlanning;
