import { connect } from "react-redux";

import { handleError } from "../../../lib/utils/error";
import {
    getHasContractsBatches,
    getTotalOfContractsBatchesToSign,
} from "../../../store/selectors/contracts";
import Contracts from "../components/Contracts";

const mapStateToProps = (state) => ({
    hasContractsBatches: getHasContractsBatches(state),
    contractsBatchesToSignTotal: getTotalOfContractsBatchesToSign(state),
});

const mapDispatchToProps = () => ({
    setError: (error) => handleError(error),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
