import { z } from "zod";

import { debug } from "@side.co/client-debug";

import { apiRequest } from "./apiHelpers/apiRequest";
import { apiConf } from ".";

const reccurenceType = z.enum(["weekly", "monthly"]);

const getShiftRecurrencesResSchema = z.object({
    id: z.string(),
    type: reccurenceType,
    interval: z.number().min(1).max(50),
    until: z
        .string()
        .datetime()
        .transform((value) => new Date(value)),
    days: z.array(z.number().min(0).max(6)),
    withHolidays: z.boolean().catch(false),
    startDate: z
        .string()
        .datetime()
        .transform((value) => new Date(value)),
    setPos: z.array(z.number()).optional(),
});

export async function getShiftRecurrences(recurrenceId: string, signal?: AbortSignal) {
    debug.addBreadcrumb({
        message: "getShiftRecurrences",
    });
    const url = `${apiConf.BACK_URL}/shift-recurrences/${recurrenceId}`;
    return apiRequest({
        url,
        responseSchema: getShiftRecurrencesResSchema,
        fetchOptions: {
            signal,
        },
    });
}
