import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { NormalizedTask } from "../../store/types/task";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";
import { TaskSchema } from "./index";

export async function updateTask(task: Partial<NormalizedTask> & { managerId?: string }) {
    debug.addBreadcrumb({
        message: `Update existing task`,
        category: `action`,
        data: task,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${task.id}`, {
                method: `PATCH`,
                body: JSON.stringify(task),
            }),
        );
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("updateTask"));
        }

        const json = await resp.json();

        return TaskSchema.parse(json);
    } catch (e) {
        debug.catch(e);
        handleError(e, 500);
        return Promise.reject(new Error("updateTask"));
    }
}
