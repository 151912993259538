import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf, Location } from "./index";

export function updateLocation(params: { organisationId: string; location: Location }) {
    debug.addBreadcrumb({
        message: `Update existing location`,
        category: `action`,
        data: params.location,
    });

    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/locations/${params.location?.id}`,
            {
                method: `PATCH`,
                body: JSON.stringify(params.location),
            },
        ),
    );
}
