import { useEffect, useState } from "react";
import i18n from "i18n-js";
import { Overlay } from "side-ui";
import {
    Button,
    Panel,
    PanelCloseButton,
    PanelContent,
    PanelFooter,
    PanelHeader,
    PanelSubtitle,
    PanelTitle,
} from "sui";

import { queries } from "@lib/queries";
import { normalizeJobDescription } from "@lib/queries/normalizeJobDescriptions";
import { useQuery } from "@tanstack/react-query";

import { JobTitleForm } from "./JobTitleForm/JobTitleForm";
import { SubtasksForm } from "./SubtasksForm/SubtasksForm";

import styles from "./JobStep.module.css";

type JobStepProps = {
    jobDescription?: ReturnType<typeof normalizeJobDescription>;
    updateJobDescription: any;
    disableSubmit?: (disabled: boolean) => void;
};
export function JobStep({ jobDescription, updateJobDescription, disableSubmit }: JobStepProps) {
    const [subtasksPanelVisibility, setSubtasksPanelVisibility] = useState(false);
    const [selectedSubtasks, setSelectedSubtasks] = useState(jobDescription?.job?.subtasks ?? []);
    const [jobTitleId, setJobTitleId] = useState(jobDescription?.job?.jobTitle?.id ?? "");

    const { data: initialSubtasks = [], isLoading: isInitialSubtasksLoading } = useQuery(
        queries.jobDescriptionSubtasks.list(jobTitleId),
    );

    useEffect(() => {
        if (jobDescription?.job?.subtasks?.length) {
            setSelectedSubtasks(jobDescription.job.subtasks);
        } else {
            disableSubmit?.(true);
        }
    }, [jobDescription?.job?.subtasks]);

    const formattedSelectedSubtask = selectedSubtasks?.map((subtask) => {
        if (subtask.custom === true) {
            return { name: subtask.name, custom: true };
        }
        return { id: subtask.id, name: subtask.name };
    });

    function unsetJobTitle() {
        updateJobDescription({
            ...jobDescription,
            subtasks: [],
        });

        setSelectedSubtasks([]);
    }

    return (
        <div className={styles.jobStep}>
            <JobTitleForm
                job={jobDescription?.job}
                numberOfTasks={jobDescription?.job?.subtasks?.length}
                openSubtasks={() => setSubtasksPanelVisibility(true)}
                setSelectedJobTitle={({ id }) => {
                    if (id !== jobDescription?.job?.jobTitle?.id) {
                        setJobTitleId(id);
                        updateJobDescription({
                            ...jobDescription,
                            jobTitleId: id,
                            subtasks: [],
                        });

                        setSelectedSubtasks([]);
                    }
                }}
            />
            <Overlay
                isVisible={subtasksPanelVisibility}
                toggleVisibility={() => {
                    setSubtasksPanelVisibility(false);
                    if (selectedSubtasks.length === 0) {
                        unsetJobTitle();
                        return;
                    }

                    updateJobDescription({
                        ...jobDescription,
                        subtasks: formattedSelectedSubtask,
                    });

                    disableSubmit?.(false);
                }}
            />
            <Panel open={subtasksPanelVisibility} className={styles.subtaskPanel}>
                <PanelHeader>
                    <PanelTitle>
                        {i18n.t(`job-descriptions_creation_job_subtasks_title`)}
                        <PanelCloseButton
                            onClick={() => {
                                setSubtasksPanelVisibility(false);
                                if (selectedSubtasks.length === 0) {
                                    unsetJobTitle();
                                    return;
                                }

                                updateJobDescription({
                                    ...jobDescription,
                                    subtasks: formattedSelectedSubtask,
                                });

                                disableSubmit?.(false);
                            }}
                        />
                    </PanelTitle>
                    <PanelSubtitle>
                        {i18n.t(`job-descriptions_creation_job_subtasks_subtitle`)}
                    </PanelSubtitle>
                </PanelHeader>
                <PanelContent className={styles.panelContent}>
                    <div className={styles.content}>
                        <SubtasksForm
                            loadedSubtasks={initialSubtasks}
                            selectedSubtasks={selectedSubtasks}
                            setSelectedSubtasks={setSelectedSubtasks}
                            isLoading={isInitialSubtasksLoading}
                        />
                    </div>
                </PanelContent>
                <PanelFooter style={{ justifyContent: "space-between" }}>
                    <Button
                        intention='secondary'
                        onClick={() => {
                            setSubtasksPanelVisibility(false);
                            unsetJobTitle();
                        }}
                    >
                        {i18n.t(`cancel`)}
                    </Button>
                    <Button
                        disabled={selectedSubtasks.length === 0}
                        onClick={() => {
                            updateJobDescription({
                                ...jobDescription,
                                subtasks: formattedSelectedSubtask,
                            });

                            disableSubmit?.(false);
                            setSubtasksPanelVisibility(false);
                        }}
                    >
                        {i18n.t(`save`)}
                        {selectedSubtasks?.length > 0 && ` (${selectedSubtasks?.length})`}
                    </Button>
                </PanelFooter>
            </Panel>
        </div>
    );
}
