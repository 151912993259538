import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GetCompanyPaymentInfoParams = {
    organisationId: string;
};
export type GetCompanyPaymentInfoResponse = {
    id: string;
    companyId: string;
    billingAddress: BillingAddress;
    accountingInfo: AccountingInfo;
    paymentMethod: PaymentMethod;
    paymentDelay: number;
    clientSecret: string;
};

type PaymentMethod = {
    type: string;
    last4: string;
};

type AccountingInfo = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

type BillingAddress = {
    street: string;
    postalCode: string;
    city: string;
    country: string;
};
export function getCompanyPaymentInfo(params: GetCompanyPaymentInfoParams) {
    debug.addBreadcrumb({
        message: `Get payment informations`,
        category: `action`,
        data: {
            organisationId: params.organisationId,
        },
    });

    return fetch(
        withAuthHeaders(
            `${apiConf.BACK_URL}/organisations/${params.organisationId}/payment-informations`,
        ),
    );
}
