import { uniq } from "lodash";
import { createSelector } from "reselect";

import type { State } from "../types";

import { getContractsBatchesByTask } from "./contracts";

export const getTasksStatuses = (state: State) => state.tasks.statuses;
export const getCurrentStatus = (state: State) => state.tasks.selectedStatus || ``;
export const getTasks = (state: State) => state.tasks.tasks;
export const getPagination = (state: State) => state.tasks.pagination;
export const getPaginationByStatus = (state: State, status: string) =>
    state.tasks.pagination[status];

/**
 * Get the tasks of a specific status
 * @return array of tasks
 */
export const getTasksByStatus = createSelector(
    getTasks,
    getPaginationByStatus,
    getContractsBatchesByTask,
    (tasks, pagination, contractsBatches) => {
        const tasksArray = [] as any[];
        if (pagination && pagination.ids) {
            pagination.ids.forEach((id) => {
                if (tasks[id]) {
                    const task: any = tasks[id];
                    task.contractsBatches = contractsBatches[task.id];
                    tasksArray.push(task);
                }
            });
        }

        return tasksArray;
    },
);

/**
 * Get the tasks by statuses
 * @return object
 */
export const getTasksByStatuses = (state: State) => {
    const tasksByStatuses = {};
    const statuses = getTasksStatuses(state);
    statuses.forEach((status) => {
        tasksByStatuses[status] = getTasksByStatus(state, status);
    });
    return tasksByStatuses;
};

/**
 * Get the pagination by statuses
 * @return object
 */
export const getPaginationByStatuses = (state: State) => {
    const paginationByStatuses = {};
    const statuses = getTasksStatuses(state);
    statuses.forEach((status) => {
        paginationByStatuses[status] = getPaginationByStatus(state, status);
    });
    return paginationByStatuses;
};

/**
 * Get the tasks pagination of the current status
 * @return object pagination
 */
export const getTasksCurrentPagination = createSelector(
    getCurrentStatus,
    getPagination,
    (status, pagination) => pagination[status],
);

/**
 * Get the tasks ids of the current status
 * @return array of string
 */
export const getCurrentTasksIds = createSelector(
    getTasksCurrentPagination,
    (currentPagination) => currentPagination && currentPagination.ids,
);

/**
 * Check if there are tasks to display (all statuses)
 * @return boolean
 */
export const getHasNoTasks = (state: State) => {
    const { pagination } = state.tasks;
    let hasNoTasks = true;
    const tasksArrays = Object.keys(pagination)
        // getHasNoTasks is only used for TasksListHome, so we only need to check for ongoing and upcoming tasks
        .filter((value) => {
            return value === "ongoing" || value === "upcoming";
        })
        .map((value) => {
            return pagination[value].ids;
        });
    tasksArrays.forEach((tasks) => {
        if (tasks && tasks.length) {
            hasNoTasks = false;
        }
    });
    return hasNoTasks;
};

/**
 * Check if all the tasks by statuses have finished loading ("all" tab)
 * @return boolean
 */
export const getTaskHasFinishedLoading = createSelector(getPagination, (pagination) => {
    if (!pagination) return false;
    const tasksLoadingStatus = Object.keys(pagination).map(
        (value) => !!pagination[value].isFetching,
    );
    const tasksLoadingStatusValues = uniq(tasksLoadingStatus);
    const finishedLoading =
        tasksLoadingStatusValues.length === 1 && tasksLoadingStatusValues[0] === false;
    return finishedLoading;
});

/**
 * Get the tasks of the current status
 * @return array
 */
export const getCurrentTasks = createSelector(
    getCurrentTasksIds,
    getTasks,
    getContractsBatchesByTask,
    (tasksIds, tasks, contractsBatches) => {
        const tasksArray =
            Object.keys(tasks).length &&
            tasksIds &&
            tasksIds.reduce((acc, id) => {
                if (tasks[id]) {
                    const task: any = tasks[id];
                    task.contractsBatches = contractsBatches[task.id];

                    return [...acc, tasks[id]];
                }

                return acc;
            }, []);

        return tasksArray && tasksArray.length ? tasksArray : [];
    },
);
