import {
    differenceInCalendarDays,
    differenceInMinutes,
    endOfWeek,
    format,
    startOfWeek,
    subDays,
} from "date-fns";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";

export function setLocale(locale) {
    if (locale === "fr" || !locale) {
        return fr;
    }

    return en;
}

export const formatWeek = (date: Date, localeString?: string) => {
    if (localeString === "fr" || !localeString) {
        return `${format(startOfWeek(date, { weekStartsOn: 1 }), "dd/MM/yyyy", {
            weekStartsOn: 1,
        })} au ${format(endOfWeek(date, { weekStartsOn: 1 }), "dd/MM/yyyy", { weekStartsOn: 1 })}`;
    }

    return `${format(startOfWeek(date, { weekStartsOn: 1 }), "MM/dd/yyyy", {
        weekStartsOn: 1,
    })} to ${format(endOfWeek(date, { weekStartsOn: 1 }), "MM/dd/yyyy", { weekStartsOn: 1 })}`;
};

/**
 * @param {number} totalMinutes sum in minutes
 * @return {string} e.g. 2h 34 minutes
 */
export function convertMinutesToHours(totalMinutes) {
    if (!totalMinutes || Number.isNaN(totalMinutes)) {
        return "";
    }

    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    const hoursStr = hours > 0 ? `${hours}h` : "";
    const minutesStr = remainingMinutes > 0 ? `${remainingMinutes}min` : "";

    if (hours > 0 && remainingMinutes > 0) {
        return hoursStr + " " + minutesStr;
    } else {
        return hoursStr + minutesStr;
    }
}

export function isLongerThan24Hours(startDate, endDate) {
    return differenceInMinutes(endDate, startDate) > 1440;
}

export function shouldSubtractOneDay(initialStartTime, initialEndTime, updatedEndTime) {
    // shift does not span over one calendar day => pass updatedEndTime through
    if (differenceInCalendarDays(updatedEndTime, initialStartTime) !== 1) {
        return updatedEndTime;
    }

    // if new time is more 24 hours, it means user needs to
    // update endtime to the same day, we subtract one day
    if (isLongerThan24Hours(initialStartTime, updatedEndTime)) {
        return subDays(updatedEndTime, 1);
    }

    return updatedEndTime;
}
