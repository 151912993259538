import i18n from "i18n-js";
import { Button, Icon, PicturesStack } from "side-ui";

import { useNavigate } from "@tanstack/react-router";

import { taskFormRoute } from "../route";

import "./WorkersStepEmptyState.scss";

const WorkersStepEmptyState = ({
    searchValue,
    numberOfFilters,
    orderType,
    workers,
    activeTab,
    setActiveTab,
    openFiltersModal,
    openAddProfileModal,
}) => {
    const { taskId } = taskFormRoute.useParams();
    const navigate = useNavigate();

    // Default case searchValue is not empty
    let icon = "Warning";
    let firstLine = "order_workers_search_noresults_title";
    let secondLine = "order_workers_search_noresults_subtitle";
    let action = () => {};
    let actionLabel = "";

    if (searchValue === "") {
        if (numberOfFilters !== 0) {
            icon = "Warning";
            firstLine = "order_workers_filters_noresults_title";
            secondLine = "order_workers_filters_noresults_subtitle";
            action = openFiltersModal;
            actionLabel = "order_workers_filters_noresults_button_label";
        } else if (orderType === "gestion") {
            icon = "UserAdd";
            firstLine = "order_workers_gestion_noresults_title";
            secondLine = "order_workers_gestion_noresults_subtitle";
            action = openAddProfileModal;
            actionLabel = "order_workers_gestion_profile-add_button_label";
        } else if (activeTab === "organisation-siders" && workers?.length > 0) {
            icon = "UserTeam";
            firstLine = "order_workers_network_noresults_title";
            secondLine = "order_workers_network_noresults_subtitle";
            action = () => setActiveTab("shared-siders");
            actionLabel = "order_workers_network_noresults_button_label";
        } else {
            icon = "User";
            firstLine = "order_noresults_title";
            secondLine = "order_noresults_subtitle";
            action = () => navigate({ to: `/taskPosting/$taskId/summary`, params: { taskId } });
            actionLabel = "order_noresults_button_label";
        }
    }

    return (
        <div className='workers-step-empty-state'>
            <div className='workers-step-empty-state__spacer' />

            <div className='workers-step-empty-state__icon'>
                <Icon name={icon} width={24} height={24} />
            </div>

            <div className='workers-step-empty-state__text'>
                <span>{i18n.t(firstLine)}</span>
                <span>{i18n.t(secondLine)}</span>
            </div>

            {workers?.length > 0 &&
                activeTab === "organisation-siders" &&
                orderType === "delegation" && (
                    <div className='workers-step-empty-state__workers'>
                        <div className='workers-step-empty-state__workers__title'>
                            {i18n.t("order_workers_network_noresults_counter", {
                                numberOfWorkers: workers.length,
                            })}
                        </div>
                        <PicturesStack
                            itemsArray={workers.map((w) => ({
                                label: `${w.firstName} ${w.lastName}`,
                                id: w.id,
                                pictureUrl: w.pictureUrl,
                            }))}
                            maxNumberOfPictures={5}
                        />
                    </div>
                )}

            {action && (
                <Button action={action} color='blue'>
                    {i18n.t(actionLabel)}
                </Button>
            )}

            <div className='workers-step-empty-state__spacer' />
        </div>
    );
};

export default WorkersStepEmptyState;
