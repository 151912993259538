import { z } from "zod";

import { debug } from "@side.co/client-debug";

import { apiRequest } from "./apiHelpers/apiRequest";
import { apiConf } from ".";

const replacementSchema = z.object({
    name: z.string(),
    position: z.string(),
    justification: z.string(),
});

const recruitmentSchema = z.object({
    name: z.string(),
    date: z
        .string()
        .datetime({ offset: true })
        .transform((val) => new Date(val)),
    position: z.string(),
});

const motiveSchema = z
    .object({
        id: z.string(),
        reason: z.enum([
            "activityIncrease",
            "replacement",
            "waitingRecruitment",
            "temporaryContract",
            "seasonalActivity",
        ]),
        justification: z.string().catch(""),
        replacements: z.array(replacementSchema).optional(),
        recruitments: z.array(recruitmentSchema).optional(),
        organisationId: z.string(),
    })
    .strict();

const getCompanyMotivesResSchema = z
    .object({
        motives: z.array(motiveSchema).catch([]),
    })
    .strict()
    .transform((val) => val.motives);

export async function getCompanyMotives(signal?: AbortSignal) {
    debug.addBreadcrumb({
        message: `getCompanyMotives`,
    });
    const url = `${apiConf.BACK_URL}/motives`;
    return apiRequest({
        url,
        responseSchema: getCompanyMotivesResSchema,
        fetchOptions: {
            signal,
        },
    });
}
