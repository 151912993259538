import i18n from "i18n-js";
import { InputNumber, ModalSimple, ModalsService, SimpleSelect } from "side-ui";
import { Formik } from "formik";

import { holidaysHoursValidationSchema, submitHolidaysHours } from "./remunerationFormsValidation";
import HoursSeniorityIncrease from "./HoursSeniorityIncrease";
import { normalizeNumber } from "../../../../lib/utils/inputsNormalizers";
import "./IndemnityForm.scss";

const onFieldChange = (fieldName, value, prevValue, setFieldValue) => {
    let newValue = value;
    switch (fieldName) {
        case "increase":
            newValue = normalizeNumber(value, prevValue);
            break;
        default:
            break;
    }
    setFieldValue(fieldName, newValue);
};

const HolidaysHoursForm = (props) => {
    const { holidaysHours, updateHourType } = props;

    const initialVal = {
        increase: `${holidaysHours.increase}`,
        seniority: holidaysHours.seniority
            ? {
                  checked: true,
                  daysThreshold: `${holidaysHours.seniority.daysThreshold}`,
                  increase: `${holidaysHours.seniority.increase}`,
              }
            : {
                  checked: false,
                  daysThreshold: "",
                  increase: "",
              },
        solidarity: holidaysHours.solidarity,
    };

    return (
        <Formik
            initialValues={initialVal}
            onSubmit={(values, actions) =>
                submitHolidaysHours(values, actions, initialVal, updateHourType)
            }
            validationSchema={holidaysHoursValidationSchema()}
            validateOnChange={false}
            validateOnBlur={true}
            render={({ values, errors, touched, handleBlur, submitForm, setFieldValue }) => (
                <ModalSimple
                    action={submitForm}
                    validateLabel={i18n.t(`settings_remuneration_indemnities_modal_save`)}
                    cancelLabel={i18n.t(`settings_remuneration_indemnities_modal_cancel`)}
                    title={i18n.t(`settings_remuneration_indemnities_bank_holidays_title`)}
                    subtitle={i18n.t(`settings_remuneration_indemnities_bank_holidays_subtitle`)}
                    hideModal={() => ModalsService.closeModal(`HOLIDAYS_HOURS_FORM`)}
                >
                    <div className='indemnity'>
                        <div className='indemnity__section'>
                            <InputNumber
                                id='increase'
                                onChange={(e) =>
                                    onFieldChange(
                                        "increase",
                                        e.target.value,
                                        values.increase,
                                        setFieldValue,
                                    )
                                }
                                label={i18n.t(
                                    `settings_remuneration_indemnities_bank_holidays_rate`,
                                )}
                                placeholder={50}
                                unit='%'
                                onBlur={handleBlur}
                                smallInput={true}
                                value={values.increase}
                                error={touched.increase && errors.increase}
                                customClass='indemnity__small-input'
                            />
                        </div>
                        <div className='indemnity__section'>
                            <HoursSeniorityIncrease />
                        </div>
                        <div className='indemnity__section'>
                            <div className='indemnity__field row'>
                                <div className='indemnity__field__label'>
                                    {i18n.t(
                                        `settings_remuneration_indemnities_bank_holidays_solidarity`,
                                    )}
                                </div>
                                <SimpleSelect
                                    id='solidarity'
                                    onChange={(e) =>
                                        onFieldChange(
                                            "solidarity",
                                            e.target.value,
                                            values.solidarity,
                                            setFieldValue,
                                        )
                                    }
                                    placeholder={i18n.t(
                                        `settings_remuneration_indemnities_bank_holidays_solidarity_placeholder`,
                                    )}
                                    options={[
                                        { id: "armistice", value: "armistice", label: "Armistice" },
                                        {
                                            id: "assomption",
                                            value: "assomption",
                                            label: "Assomption",
                                        },
                                        {
                                            id: "fete-de-la-victoire",
                                            value: "fete-de-la-victoire",
                                            label: "Fête de la victoire",
                                        },
                                        {
                                            id: "fete-nationale-francaise",
                                            value: "fete-nationale-francaise",
                                            label: "Fête nationale française",
                                        },
                                        {
                                            id: "jeudi-de-l-ascension",
                                            value: "jeudi-de-l-ascension",
                                            label: "Jeudi de l'ascension",
                                        },
                                        {
                                            id: "jour-de-l-an",
                                            value: "jour-de-l-an",
                                            label: "Jour de l'an",
                                        },
                                        {
                                            id: "lundi-de-pentecote",
                                            value: "lundi-de-pentecote",
                                            label: "Lundi de pentecôte",
                                        },
                                        {
                                            id: "lundi-de-paques",
                                            value: "lundi-de-paques",
                                            label: "Lundi de Pâques",
                                        },
                                        { id: "noel", value: "noel", label: "Noël" },
                                        { id: "toussaint", value: "toussaint", label: "Toussaint" },
                                    ]}
                                    error={touched.solidarity && errors.solidarity}
                                    value={values.solidarity}
                                    customClass='indemnity__half-input'
                                    smallInput={true}
                                />
                            </div>
                        </div>
                    </div>
                </ModalSimple>
            )}
        />
    );
};

export default HolidaysHoursForm;
