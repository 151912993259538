import clsx from "clsx";
import i18n from "i18n-js";
import { Add, Button, DocumentLines, fonts } from "sui";

import styles from "./Blank.module.css";

export function Blank({ openModal }: { openModal: () => void }) {
    return (
        <div className={styles.blank}>
            <div className={styles.block}>
                <div className={styles.icon}>
                    <DocumentLines />
                </div>
                <h1 className={clsx(styles.title, fonts.sans32Medium)}>
                    {i18n.t(`task_shifts_no_shifts_title`)}
                </h1>
                <h3 className={clsx(styles.description, fonts.sans18Regular)}>
                    {i18n.t(`task_shifts_no_shifts_description`)}
                </h3>
                <Button icon={<Add />} iconDisposition='left' onClick={openModal}>
                    {i18n.t(`task_shifts_add`)}
                </Button>
            </div>
        </div>
    );
}
