import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type AddSiderProfileParams = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
};
export async function addSiderProfile(params: AddSiderProfileParams) {
    debug.addBreadcrumb({
        message: `addSiderProfile`,
        category: `action`,
        data: {
            firstName: params?.firstName,
            lastName: params?.lastName,
            phoneNumber: params?.phoneNumber,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisation/siders`, {
                method: `POST`,
                body: JSON.stringify({
                    firstName: params?.firstName,
                    lastName: params?.lastName,
                    phoneNumber: params?.phoneNumber,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("addSiderProfile"));
        }

        return resp;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("addSiderProfile"));
    }
}
