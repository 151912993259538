import type { State } from "../types";

export const getCompanyLegalInfoData = (state: State) => state.company.legalInfo;
export const getCompanyLegalInfoStatus = (state: State) => state.company.legalInfo?.isLoading;
export const getCompanyPaymentInfoData = (state: State) => state.company.paymentInfo;
export const getCompanyPaymentInfoStatus = (state: State) => state.company.paymentInfo?.isLoading;
export const getWorkLegalStatus = (state: State) => state.company.workLegalStatus;
export const getCompanyRemunerationInfoData = (state: State) => state.company.remunerationInfo;
export const getIndemnities = (state: State) =>
    state.company.remunerationInfo?.indemnities || { lunch: [], transportation: [], other: [] };
export const getCompanyRemunerationInfoStatus = (state: State) =>
    state.company.remunerationInfo?.isLoading;
export const areHoursTypesConfirmed = (state: State) =>
    !!state.company.remunerationInfo?.hoursTypesConfirmation;
export const areIndemnitiesConfirmed = (state: State) =>
    !!state.company.remunerationInfo?.indemnitiesConfirmation;
