import { useEffect, useState } from "react";
import i18n from "i18n-js";

import { useTimer } from "../../../routes/JobDescriptions/hooks/useTimer";

const AddressDescription = ({ description, submitAddress }) => {
    // derive state for when in edit mode
    const [addressDescription, setAddressDescription] = useState(description);
    const [state, setState] = useState({ touched: false });

    // re-render when description ref changes
    useEffect(() => {
        if (description) {
            setAddressDescription(description);
        }
    }, [description]);

    // Since there's no submit on this component, we don't want to update the store on each keystroke
    // BUT we still want the state to update in real time (not debounced)
    // here we make use of a timer to achieve this
    useTimer(
        {
            timing: 300,
            callback: () => {
                if (state.touched) {
                    submitAddress({ description: addressDescription });
                }
            },
        },
        [addressDescription],
    );

    return (
        // @swap with SIDEUI's textarea when bug is fixed
        <div className='sui-textarea-wrapper'>
            <div className='sui-textarea'>
                <div className='sui-textarea__inner'>
                    <label htmlFor='address-description' className='input-label'>
                        {i18n.t(`location-form_workplace_label2`)}
                    </label>
                    <textarea
                        id='address-description'
                        name='description'
                        placeholder={i18n.t(`location-form_workplace_placeholder2`)}
                        className='sui-textarea__input'
                        onChange={({ currentTarget }) => {
                            setAddressDescription(currentTarget.value);
                            setState({ touched: true });
                        }}
                        value={addressDescription}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddressDescription;
