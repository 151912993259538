import { useEffect, useState } from "react";
import clsx from "clsx";
import i18n from "i18n-js";
import { TextArea } from "sui";

import { useTimer } from "../../hooks/useTimer";

import styles from "./SkillsStep.module.css";

export function ExperiencesForm({ experiences, setExperiences }) {
    const [experience, setExperience] = useState(experiences);

    useEffect(() => {
        setExperience(experiences);
    }, [experiences]);

    // Since there's no submit on this component, we don't want to update the store on each keystroke
    // BUT we still want the state to update in real time (not debounced)
    // here we make use of a timer to achieve this
    useTimer(
        {
            timing: 800,
            callback: () => setExperiences(experience),
        },
        [experience],
    );

    return (
        <div className={clsx(styles.section, "experiences")}>
            <TextArea
                label={i18n.t("job-descriptions_creation_skills_experience_label")}
                onChange={({ target }) => setExperience(target.value)}
                placeholder="Ex. Nous recherchons de préférence des profils connaissant l'industrie de..."
                value={experience}
            ></TextArea>
        </div>
    );
}
