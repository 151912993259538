import { SearchableDropdown, Searchbar } from "side-ui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import CompanyMembersSearchResults from "./CompanyMembersSearchResults";
import CompanySidersSearchResults from "./CompanySidersSearchResults";

const CompanySearch = ({
    companyMembers,
    onOptionClick,
    options,
    selectedOption,
    searchTerm,
    handleSearchTermChange,
    searchPlaceholder,
    selectItem,
    touched,
}) => {
    const { data: companySidersData } = useQuery(queries.company.companySiders(searchTerm));
    const companySiders = companySidersData?.siders || [];

    return (
        <div className='timesheets__filters__search__wrapper'>
            <SearchableDropdown
                id='search-filter'
                options={options}
                onOptionClick={onOptionClick}
                selectedOption={selectedOption}
                withTooltip={false}
                className='timesheets__filter__search'
            />

            <span className='spacer' />

            <div className='timesheets__filter timesheets__filters__search__container'>
                <Searchbar
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    onFocus={() => null} // no op to avoid clearing value on first focus
                    placeholder={searchPlaceholder}
                    className='timesheets__filters__search__input sui-btn'
                    iconPosition=''
                />

                {selectedOption.objectID === "sider" && (
                    <CompanySidersSearchResults
                        companySiders={companySiders}
                        searchTerm={searchTerm}
                        selectItem={selectItem}
                        touched={touched}
                    />
                )}

                {selectedOption.objectID === "manager" && (
                    <CompanyMembersSearchResults
                        companyMembers={companyMembers}
                        searchTerm={searchTerm}
                        selectItem={selectItem}
                        touched={touched}
                    />
                )}
            </div>
        </div>
    );
};

export default CompanySearch;
