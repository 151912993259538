import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type GetFullFeedbackParams = { feedbackId: string };
export type GetFullFeedbackResponse = {
    _id: string;
    taskId: string;
    organisationId: string;
    typeId: string;
    managerId: string;
    siderId: string;
    comment: string;
    pro: number;
    perf: number;
    workAgain?: number;
};

export async function getFullFeedback(
    params: GetFullFeedbackParams,
): Promise<GetFullFeedbackResponse> {
    debug.addBreadcrumb({
        message: `Load full feedback`,
        category: `action`,
        data: {
            feedbackId: params.feedbackId,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/v1/feedback/${params.feedbackId}`),
        );

        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("GetFullFeedback"));
        }
        const json = await resp.json();
        return json;
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("GetFullFeedback"));
    }
}
