import { connect } from "react-redux";

import { debug } from "@side.co/client-debug";

import * as backend from "../../../lib/api";
import { handleError } from "../../../lib/utils/error";
import { setPossibleUsers, setUserToImpersonate } from "../../../store/logas";
import normalizePossibleUsers from "../../../store/normalizers/logas";
import {
    getPossibleUsersToImpersonate,
    getSelectedUserToImpersonate,
} from "../../../store/selectors/logas";
import LogAs from "../components/LogAs";

const mapStateToProps = (state) => ({
    selectedUserToImpersonate: getSelectedUserToImpersonate(state),
    possibleUsersToImpersonate: getPossibleUsersToImpersonate(state),
});

const mapDispatchToProps = (dispatch) => ({
    searchUser: async (searchFieldValue) => {
        let res;
        try {
            res = await backend.searchUser({
                query: searchFieldValue,
            });
        } catch (e) {
            handleError(e.toString());
        }
        if (res) {
            if (res.status >= 400) {
                debug.log(`API call error: ${res.statusText}`, {
                    level: `warning`,
                    extra: {
                        res: JSON.stringify(res),
                    },
                });
                handleError(res.statusText);
                return;
            }
            const json = await res.json();
            const normalizedData = normalizePossibleUsers(json);
            if (json) {
                dispatch(setPossibleUsers(normalizedData));
            }
        }
    },
    setError: (error) => handleError(error),
    setSelectedUserToImpersonate: (value) => dispatch(setUserToImpersonate(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogAs);
