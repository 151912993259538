import { useEffect } from "react";
import i18n from "i18n-js";
import { Textarea } from "side-ui";

import { useFormInput } from "@lib/hooks/form";
import { useTimer } from "@routes/JobDescriptions/hooks/useTimer";

import styles from "./ContextGoalsForm.module.css";

export const ContextGoalsForm = ({ context, setContext }) => {
    const { formInput: formContext, handleChange: setFormContext } = useFormInput(
        context,
        () => null,
    );

    useTimer(
        {
            timing: 800,
            callback: () => setContext(formContext.value),
        },
        [formContext.value],
    );

    useEffect(() => {
        setFormContext(context);
    }, [context]);

    useEffect(() => {
        setContext(formContext.value);
    }, [formContext.value]);

    return (
        <div className={styles.form}>
            <Textarea
                id='context-goals'
                label={i18n.t(`job-descriptions_creation_conditions_context_goals_title`)}
                placeholder={i18n.t(
                    `job-descriptions_creation_conditions_context_goals_placeholder`,
                )}
                height={120}
                value={formContext.value}
                onChange={(value) => setFormContext(value)}
                small={false}
                required={true}
            />
        </div>
    );
};
