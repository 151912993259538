import { useEffect, useRef } from "react";

// sometimes we need to trigger an action based on a timing
// e.g when use has finished typing, call a callback
export function useTimer({ timing = 800, callback }, deps) {
    const timerRef = useRef(null);

    useEffect(() => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            if (!callback) {
                throw new Error("[useTimer error] Please provide a callback value");
            }
            callback();
        }, timing);

        return () => clearTimeout(timerRef.current);
    }, [...deps]);
}
