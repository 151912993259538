import i18n from "i18n-js";
import { connect } from "react-redux";
import { ModalsService, ToastsService } from "side-ui";

import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import * as backend from "../../../lib/api";
import trackEvent from "../../../lib/trackers";
import { handleError } from "../../../lib/utils/error";
import { getContractsBatches } from "../../../store/contracts";
import ContestationModal from "../components/ContestationModal";

const mapDispatchToProps = (dispatch, ownProps) => ({
    submit: async (dataToSend) => {
        const organisationId = localStorage.getItem(`side_team_activeOrganisationId`) || ``;
        const { contractsBatch } = ownProps;
        let res;
        try {
            res = await backend.sendContestation({
                organisationId,
                documentId: dataToSend.documentId,
                data: {
                    description: dataToSend.description,
                    managerId: localStorage.getItem(`Meteor.userId`) || ``,
                },
            });
            trackEvent({
                name: `teamcontract - company confirmed contestation`,
                params: {
                    organisationId: localStorage.getItem("side_team_activeOrganisationId"),
                    contractId: contractsBatch.documentId,
                    taskId: contractsBatch.taskId,
                    status: contractsBatch.status,
                },
            });
        } catch (e) {
            handleError(e.toString());
        }
        if (res) {
            if (res.status >= 400) {
                debug.log(`API call error: ${res.statusText}`, {
                    level: `warning`,
                    extra: {
                        res: JSON.stringify(res),
                    },
                });
                handleError(res.statusText);
                return;
            }
            const json = await res.json();
            if (json) {
                ToastsService.addToast({
                    id: `contestationSent`,
                    icon: `Checkmark`,
                    content: i18n.t("contract_contestation_confirmation_toast"),
                    type: `confirmation`,
                });
                router.navigate({ to: `/contracts` });
                dispatch(getContractsBatches(2));
                ModalsService.closeModal(`CONTRACTS_CONTESTATION`);
            }
        }
    },
});

export default connect(null, mapDispatchToProps)(ContestationModal);
