import fetch from "isomorphic-fetch";

import env from "./env";

export type TrackEventParams = {
    name: string;
    client?: string;
    params: {
        [key: string]: unknown;
    };
};

const trackEvent = (params: TrackEventParams) => {
    const logasId = localStorage.getItem(`side_team_logasId`)
        ? localStorage.getItem(`Meteor.userId`)
        : undefined;

    const data = {
        client: params.client || `orga`,
        eventName: params.name,
        params: {
            userId:
                localStorage.getItem(`side_team_logasId`) || localStorage.getItem(`Meteor.userId`),
            logAs: {
                state: !!logasId,
                impersonatorId: logasId || ``,
            },
            ...params.params,
        },
    };

    return fetch(`${env.ANALYTICS_URL}${env.DOMAIN}`, {
        method: `POST`,
        headers: {
            "Content-Type": `application/json`,
        },
        body: JSON.stringify(data),
    });
};

export default trackEvent;
