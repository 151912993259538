import moment from "moment";

import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import * as backend from "../lib/api";
import checkResForError from "../lib/utils/checkResForError";
import { handleError } from "../lib/utils/error";
import isAuth from "../lib/utils/isAuth";

import normalizeInvoices from "./normalizers/invoices";
import type { Action, State } from "./types/invoices";
import type { Dispatch, GetState } from "./types";

//------------------------------------
// Constants
//------------------------------------

export const SET_INVOICES_LOADING_STATUS = `SET_INVOICES_LOADING_STATUS` as const;
export const SET_INVOICES_STARTDATES = `SET_INVOICES_STARTDATES` as const;
export const LOAD_INVOICES = `LOAD_INVOICES` as const;
export const SET_INVOICES_SELECTED_DATE = `SET_INVOICES_SELECTED_DATE` as const;
export const SET_INVOICES_SELECTED_END_DATE = `SET_INVOICES_SELECTED_END_DATE` as const;
export const SET_INVOICES_PAGE_COUNT = `SET_INVOICES_PAGE_COUNT` as const;
export const SET_FIRST_PAYMENT_DATE = `SET_FIRST_PAYMENT_DATE` as const;

//------------------------------------
// Actions
//------------------------------------

export const pendingRequest = (state: boolean): Action => ({
    type: SET_INVOICES_LOADING_STATUS,
    state,
});

export const setStartDates = (dates: Array<string>): Action => ({
    type: SET_INVOICES_STARTDATES,
    dates,
});

export const loadInvoices = (startDate: string, data: object): Action => ({
    type: LOAD_INVOICES,
    startDate,
    data,
});

export const setSelectedDate = (date: string): Action => ({
    type: SET_INVOICES_SELECTED_DATE,
    date,
});

export const setSelectedEndDate = (date?: string): Action => ({
    type: SET_INVOICES_SELECTED_END_DATE,
    date,
});

export const setFirstPaymentDate = (date: string): Action => ({
    type: SET_FIRST_PAYMENT_DATE,
    date,
});

export const setPageCount = (count: number): Action => ({
    type: SET_INVOICES_PAGE_COUNT,
    count,
});

//------------------------------------
// Specialized Action Creator
//------------------------------------
export const setInitialData = (oldestInvoice: string) =>
    async function (dispatch: Dispatch) {
        // We're getting the total number of months since the first (oldest) invoices
        // so we can know how many pages we have
        const today = new Date();
        const oldestMonth = new Date(oldestInvoice);
        oldestMonth.setDate(today.getDate() - 1);
        oldestMonth.setHours(0);
        oldestMonth.setMinutes(0);
        const totalPages = (moment().diff(oldestMonth, "month") + 1) / 6;

        // We loop from the current month to the oldest invoice to get the list of all the months
        const dates = [] as string[];
        for (let i = 0; i <= totalPages; i += 1) {
            const month = moment().subtract(i * 6, `months`);
            dates.push(moment(month).format(`YYYY-MM`));
        }

        dispatch(setStartDates(dates));
        dispatch(setPageCount(Math.ceil(totalPages)));
        if (oldestInvoice && oldestInvoice !== `1970-01-01T00:00:00Z`) {
            dispatch(setFirstPaymentDate(oldestInvoice));
        }
    };

export const getInvoices = (endDate: string, startDate: string) =>
    async function (dispatch: Dispatch, getState: GetState) {
        const {
            invoices: { startDates },
        } = getState();

        if (!isAuth()) {
            debug.warn(`User doesn't have auth info in localStorage`);
            if (window.location.pathname !== "/signin") {
                router.navigate({ to: `/signin` });
            }
            return;
        }

        // Start fecthing invoices
        dispatch(pendingRequest(true));
        let res;
        try {
            res = await backend.getInvoices({
                endDate,
                startDate,
            });
        } catch (e) {
            debug.catch(e);
            handleError(e.toString());
            dispatch(pendingRequest(false));
        }
        if (res) {
            if (checkResForError(res)) {
                dispatch(pendingRequest(false));
                return;
            }
            const json = await res.json();
            const normalized = normalizeInvoices(json);

            if (!startDates.length) {
                dispatch(setInitialData(normalized.firstPaymentDate));
            }
            dispatch(setSelectedDate(endDate));
            dispatch(setSelectedEndDate());
            dispatch(loadInvoices(endDate, normalized.invoices));
            dispatch(pendingRequest(false));
        }
    };

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    startDates: [],
    selectedStartDate: ``,
    selectedEndDate: ``,
    firstPaymentDate: undefined,
    invoicesByStartDate: {},
    isLoading: false,
    pagination: {
        count: 1,
    },
};

const invoicesReducer = (state: State = initialState, action: Action) => {
    switch (action.type) {
        case SET_INVOICES_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.state,
            };
        case SET_INVOICES_STARTDATES:
            return {
                ...state,
                startDates: action.dates,
            };
        case LOAD_INVOICES:
            return {
                ...state,
                invoicesByStartDate: {
                    ...state.invoicesByStartDate,
                    [action.startDate || moment().format(`YYYY-MM`)]: action.data,
                },
            };
        case SET_INVOICES_SELECTED_DATE:
            return {
                ...state,
                selectedStartDate: action.date
                    ? moment(action.date).format(`YYYY-MM`)
                    : moment().format(`YYYY-MM`),
            };
        case SET_INVOICES_SELECTED_END_DATE:
            return {
                ...state,
                selectedEndDate: action.date && moment(action.date).format(`YYYY-MM`),
            };
        case SET_FIRST_PAYMENT_DATE:
            return {
                ...state,
                firstPaymentDate: action.date,
            };
        case SET_INVOICES_PAGE_COUNT:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    count: action.count,
                },
            };
        default:
            return state;
    }
};

export default invoicesReducer;
