const normalizePaymentInfo = (data) => {
    const paymentInfo = {
        wantOrderForm: data.wantOrderForm,
        billingAddress: data.billingAddress,
        tvaNumber: data.tvaNumber,
        accountingInfo: data.accountingInfo,
        paymentMethod: {
            type: data.paymentMethod?.type,
            last4: data.paymentMethod?.last4,
            expirationDate: data.paymentMethod?.expirationDate,
            country: data.paymentMethod?.country,
            currency: data.paymentMethod?.currency,
            tos: false,
        },
        clientSecret: data.clientSecret,
        sourceId: data.sourceId,
        paymentMethodId: data.paymentMethodId,
    };

    return paymentInfo;
};

export default normalizePaymentInfo;
