import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type GoogleActivateUserReq = {
    userId: string;
    code: string;
    token: string;
};
type LoginRes = {
    token: string;
    email: string;
    userId: string;
    organisationId: string;
    organisationType: string;
    firstName: string;
    lastName: string;
};

export async function googleActivateUser(req: GoogleActivateUserReq): Promise<LoginRes | null> {
    debug.addBreadcrumb({
        message: `GoogleActivateUser`,
        category: `action`,
        data: req,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/auth/users/${req.userId}/google/activate`, {
                method: `PUT`,
                credentials: "omit",
                body: JSON.stringify({
                    code: req.code,
                    token: req.token,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
