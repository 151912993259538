import { handleError } from "../../lib/utils/error";

import "./Error.scss";

const Error = (props) => {
    const { error = "", hideError } = props;

    handleError(error);
    // reset error state after toast disappears, in case more errors occur later
    setTimeout(() => {
        hideError();
    }, 5000);

    return <></>;
};

export default Error;
