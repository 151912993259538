import clsx from "clsx";
import { fonts } from "sui";

import styles from "./CheckDayBox.module.css";

export interface CheckDayBoxProps extends Omit<React.ComponentPropsWithoutRef<"input">, "type"> {
    checked?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    label: string;
    error?: boolean;
}

export function CheckDayBox({ className, label, error, ...props }: CheckDayBoxProps) {
    return (
        <div className={clsx(styles.container, className, error && styles.error)}>
            <input className={styles.input} type='checkbox' {...props} />
            <div className={clsx(styles.checkDayBox, fonts.sans18Medium)}>{label}</div>
        </div>
    );
}
