import { useMemo } from "react";
import { getYear } from "date-fns";
import { DatePicker } from "sui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import styles from "./ShiftDatePicker.module.css";

type ShiftDatePickerProps = {
    label: string;
    disabled?: boolean;
    selected: Date;
    fromDate?: Date;
    toDate?: Date;
    error?: string | boolean;
    onSelect: (date: Date) => void;
};
export function ShiftDatePicker({
    label,
    disabled,
    selected,
    fromDate,
    toDate,
    error,
    onSelect,
}: ShiftDatePickerProps) {
    const { data: holidaysN } = useQuery(queries.holiday.list(getYear(new Date())));
    const { data: holidaysN1 } = useQuery(queries.holiday.list(getYear(new Date()) + 1));
    const holidays = useMemo(
        () => [...(holidaysN || []), ...(holidaysN1 || [])].map(({ startDate }) => startDate),
        [holidaysN, holidaysN1],
    );

    return (
        <div className={styles.datePicker}>
            <DatePicker
                label={label}
                selected={selected}
                onSelect={onSelect}
                month={selected}
                size='small'
                portal={false}
                required
                disabled={disabled}
                fromDate={fromDate}
                toDate={toDate}
                error={error}
                modifiers={{
                    holidays,
                    weekEnd: { dayOfWeek: [0, 6] },
                }}
                modifiersClassNames={{
                    holidays: styles.holidays,
                    weekEnd: styles.weekEnd,
                }}
                fixedWeeks={true}
            />
        </div>
    );
}
