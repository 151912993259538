import { connect } from "react-redux";

import { renameTask } from "../../../store/tasks";
import RenameTask from "../components/RenameModal";

const mapStateToProps = (state, ownProps) => ({
    taskId: ownProps.task.id,
    taskName: ownProps.task.name,
    jobtitle: ownProps.task.jobTitle,
    status: ownProps.task.status,
    match: ownProps.match, // for trackers
});

const mapDispatchToProps = (dispatch) => ({
    renameTask: (taskId, value) => dispatch(renameTask(taskId, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RenameTask);
