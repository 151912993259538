import TimesheetsTableRow from "./TimesheetsTableRow";

const TimesheetsTableBody = ({
    attendances,
    companyName,
    selectAttendance,
    selectedAttendances,
    setSiderDidntWork,
    disputeAttendance,
    readOnly,
}) => (
    <div className='timesheets-table__wrapper'>
        <div className='timesheets-table__body'>
            {Object.keys(attendances).map((key, index) => (
                <TimesheetsTableRow
                    key={index} // eslint-disable-line
                    attendances={attendances[key]}
                    startDate={key} // key is a date
                    selectedAttendances={selectedAttendances}
                    selectAttendance={selectAttendance}
                    setSiderDidntWork={setSiderDidntWork}
                    disputeAttendance={disputeAttendance}
                    companyName={companyName}
                    readOnly={readOnly}
                />
            ))}
        </div>
    </div>
);

export default TimesheetsTableBody;
