import { forwardRef } from "react";
import clsx from "clsx";
import i18n from "i18n-js";
import { Button, Done, Modal, ModalContent, ModalFooter, ModalHeader } from "sui";

import { OganisationCompletionStatus } from "@lib/api";
import { useNavigate } from "@tanstack/react-router";

import styles from "./OrganisationCompletionStatusModal.module.css";

type Props = { missingDetails?: OganisationCompletionStatus };

export const OrganisationCompletionStatusModal = forwardRef<HTMLDialogElement, Props>(function (
    { missingDetails },
    ref,
) {
    const navigate = useNavigate();

    const completionStatus = {
        profile: missingDetails?.includes("profile"),
        legalInformations: missingDetails?.includes("legalInformations"),
        paymentInformations: missingDetails?.includes("paymentInformations"),
        hoursTypesInformations: missingDetails?.includes("remunerationPolicy.hourTypes"),
        indemnitiesInformations: missingDetails?.includes("remunerationPolicy.indemnities"),
    };

    // replace by switch case and routes for nav, not urls
    const actionUrl = (() => {
        if (completionStatus.profile) {
            return "settings/profile";
        } else if (completionStatus.legalInformations) {
            return "settings/legal";
        } else if (completionStatus.paymentInformations) {
            return "settings/payment";
        } else if (completionStatus.hoursTypesInformations) {
            return "settings/remuneration";
        } else if (completionStatus.indemnitiesInformations) {
            return "settings/remuneration";
        } else {
            return "settings";
        }
    })();

    const hash = (() => {
        if (completionStatus.hoursTypesInformations) {
            return "hours-types";
        } else if (completionStatus.indemnitiesInformations) {
            return "indemnities";
        }
        return "";
    })();

    function handleClickOnNext() {
        navigate({ to: `/${actionUrl}`, hash });
        if (ref && "current" in ref) {
            ref.current?.close();
        }
    }

    if (!ref || !("current" in ref)) return null;

    return (
        <Modal ref={ref}>
            <ModalHeader
                title={i18n.t("guard_modal_title")}
                description={i18n.t(`guard_modal_subtitle`)}
            />
            <ModalContent>
                {Object.entries(completionStatus).map(([key, status]) => (
                    <div
                        key={key}
                        className={clsx(styles.item, !status ? styles.valid : styles.invalid)}
                    >
                        <div className={styles.key}>{i18n.t(`details_completion_${key}`)}</div>
                        <div className={styles.value}>
                            {!status ? <Done /> : i18n.t("guard_modal_incomplete")}
                        </div>
                    </div>
                ))}
            </ModalContent>
            <ModalFooter
                mainButton={
                    <Button type='button' onClick={handleClickOnNext}>
                        {i18n.t(`guard_modal_submit`)}
                    </Button>
                }
                cancelButtonLabel={i18n.t(`action_cancel`)}
            />
        </Modal>
    );
});
