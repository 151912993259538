export const sortByDateASC = (dates) => {
    if (!dates || !dates.length) return [];

    return dates.sort((prevDate, nextDate) => {
        if (prevDate > nextDate) return 1;
        if (prevDate < nextDate) return -1;

        return 0;
    });
};

/**
 * Tests if shifts days are consecutive
 * @param {array<Number>} sortedDates sorted dates (milliseconds timestamp)
 * @returns {Array<Array<Number>>} returns a matrix with consecutive days grouped -> [[], [], []]
 */
export function getConsecutiveDates(sortedDates) {
    if (!sortedDates?.length) return [];

    const A_DAY_IN_MILLISECONDS = 86400000;
    let i = 0;

    return sortedDates.reduce(
        (acc, currentDate) => {
            const currentArray = acc[i];
            const prevDate = currentArray ? currentArray[currentArray.length - 1] : 0;

            // if distance between the 2 is greater than a day in millisecond
            // it's not consecutive, increment i and potentially populate the next sub array
            if (currentDate - prevDate > A_DAY_IN_MILLISECONDS) {
                i += 1;
            }

            if (!acc[i]) {
                acc[i] = [];
            }

            // check if it doesn't already exists before adding it
            if (!acc[i].includes(currentDate)) {
                acc[i].push(currentDate);
            }

            return acc;
        },
        [[]],
    );
}
