import { Formik } from "formik";
import i18n from "i18n-js";
import { InputNumber, ModalSimple, ModalsService } from "side-ui";

import { normalizeNumber } from "../../../../lib/utils/inputsNormalizers";

import {
    initialOtherParametersFromValue,
    initialOtherParametersValue,
    OtherParameters,
    shouldToggleBeChecked,
} from "./otherParameters/OtherParameters";
import { indemnityTransportSchema, submitIndemnityTransport } from "./remunerationFormsValidation";

import "./IndemnityForm.scss";

const onFieldChange = (fieldName, value, _, setFieldValue) => {
    let newValue = value;
    switch (fieldName) {
        case "publicCoverRate":
            newValue = normalizeNumber(value);
            break;
        default:
            break;
    }
    setFieldValue(fieldName, newValue);
};

const IndemnityTransport = (props) => {
    const { updateIndemnity, deleteIndemnity, indemnity } = props;

    const initOtherParameters =
        indemnity && indemnity.otherParameters
            ? initialOtherParametersFromValue(indemnity.otherParameters)
            : initialOtherParametersValue;

    const otherParametersToggled = shouldToggleBeChecked(initOtherParameters);

    const initialVal = indemnity
        ? {
              id: indemnity.id,
              publicCoverRate: indemnity.publicCoverRate,
              otherParameters: initOtherParameters,
          }
        : {
              publicCoverRate: "",
              otherParameters: initOtherParameters,
          };

    return (
        <Formik
            initialValues={initialVal}
            onSubmit={(values, actions) =>
                submitIndemnityTransport(values, actions, updateIndemnity)
            }
            validationSchema={indemnityTransportSchema}
            validateOnChange={false}
            validateOnBlur={true}
            render={({
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                submitForm,
                setFieldValue,
            }) => (
                <ModalSimple
                    action={submitForm}
                    validateLabel={i18n.t(`settings_remuneration_indemnities_modal_save`)}
                    cancelLabel={i18n.t(`settings_remuneration_indemnities_modal_cancel`)}
                    title={i18n.t(`settings_remuneration_indemnities_add_option_transport`)}
                    subtitle={i18n.t(`settings_remuneration_indemnities_transport_subtitle`)}
                    hideModal={() => ModalsService.closeModal(`TRANSPORT_INDEMNITY`)}
                >
                    <form onSubmit={handleSubmit} className='indemnity'>
                        <div className='indemnity__section'>
                            <div className='indemnity__field row'>
                                <InputNumber
                                    id='publicCoverRate'
                                    label={i18n.t(
                                        `settings_remuneration_indemnities_transport_public_rate`,
                                    )}
                                    placeholder={50}
                                    unit='%'
                                    onChange={(e) =>
                                        onFieldChange(
                                            "publicCoverRate",
                                            e.target.value,
                                            values.publicCoverRate,
                                            setFieldValue,
                                        )
                                    }
                                    onBlur={handleBlur}
                                    smallInput={true}
                                    value={values.publicCoverRate}
                                    error={touched.publicCoverRate && errors.publicCoverRate}
                                    customClass='indemnity__small-input'
                                />
                            </div>
                        </div>
                        <div className='indemnity__section'>
                            <OtherParameters toggled={otherParametersToggled} />
                        </div>
                    </form>
                </ModalSimple>
            )}
        />
    );
};

export default IndemnityTransport;
