import { connect } from "react-redux";

import { handleError } from "../../../../lib/utils/error";
import { deleteIndemnity, updateIndemnity } from "../../../../store/company";

import IndemnityLunch from "./IndemnityLunch";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    setError: (error) => handleError(error),
    updateIndemnity: (data) => dispatch(updateIndemnity(data)),
    deleteIndemnity: (id) => dispatch(deleteIndemnity("lunch", id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndemnityLunch);
