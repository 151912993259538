import I18n from "i18n-js";

export const getBillingCountryOptions = () => [
    { label: I18n.t(`country_france`), value: "FR" },
    ...Object.entries({
        south_africa: "ZA",
        albania: "AL",
        algeria: "DZ",
        germany: "DE",
        andorra: "AD",
        angola: "AO",
        antigua_and_barbuda: "AG",
        saudi_arabia: "SA",
        argentina: "AR",
        armenia: "AM",
        australia: "AU",
        austria: "AT",
        azerbaijan: "AZ",
        bahamas: "BS",
        bahrain: "BH",
        bangladesh: "BD",
        barbados: "BB",
        belgium: "BE",
        belize: "BZ",
        benin: "BJ",
        bhutan: "BT",
        belarus: "BY",
        myanmar: "MM",
        bolivia: "BO",
        bosnia_and_herzegovina: "BA",
        botswana: "BW",
        brazil: "BR",
        brunei: "BN",
        bulgaria: "BG",
        burkina_faso: "BF",
        burundi: "BI",
        cambodia: "KH",
        cameroon: "CM",
        canada: "CA",
        cape_verde: "CV",
        chile: "CL",
        china: "CN",
        cyprus: "CY",
        colombia: "CO",
        comoros: "KM",
        congo_brazzaville: "CG",
        congo_kinshasa: "CD",
        north_korea: "KP",
        south_korea: "KR",
        costa_rica: "CR",
        croatia: "HR",
        cuba: "CU",
        denmark: "DK",
        djibouti: "DJ",
        dominica: "DM",
        egypt: "EG",
        united_arab_emirates: "AE",
        ecuador: "EC",
        eritrea: "ER",
        spain: "ES",
        estonia: "EE",
        united_states: "US",
        ethiopia: "ET",
        fiji: "FJ",
        finland: "FI",
        gabon: "GA",
        gambia: "GM",
        georgia: "GE",
        ghana: "GH",
        greece: "GR",
        grenada: "GD",
        guatemala: "GT",
        guinea: "GN",
        guinea_bissau: "GW",
        equatorial_guinea: "GQ",
        guyana: "GY",
        haiti: "HT",
        honduras: "HN",
        hungary: "HU",
        cook_islands: "CK",
        marshall_islands: "MH",
        solomon_islands: "SB",
        india: "IN",
        indonesia: "ID",
        iraq: "IQ",
        iran: "IR",
        ireland: "IE",
        iceland: "IS",
        israel: "IL",
        italy: "IT",
        jamaica: "JM",
        japan: "JP",
        jordan: "JO",
        kazakhstan: "KZ",
        kenya: "KE",
        kyrgyzstan: "KG",
        kiribati: "KI",
        kuwait: "KW",
        kosovo: "XK",
        laos: "LA",
        lesotho: "LS",
        latvia: "LV",
        lebanon: "LB",
        liberia: "LR",
        libya: "LY",
        liechtenstein: "LI",
        lithuania: "LT",
        luxembourg: "LU",
        macedonia: "MK",
        madagascar: "MG",
        malaysia: "MY",
        malawi: "MW",
        maldives: "MV",
        mali: "ML",
        malta: "MT",
        morocco: "MA",
        mauritius: "MU",
        mauritania: "MR",
        mexico: "MX",
        micronesia: "FM",
        moldova: "MD",
        monaco: "MC",
        mongolia: "MN",
        montenegro: "ME",
        mozambique: "MZ",
        namibia: "NA",
        nauru: "NR",
        nepal: "NP",
        nicaragua: "NI",
        niger: "NE",
        nigeria: "NG",
        norway: "NO",
        new_zealand: "NZ",
        oman: "OM",
        uganda: "UG",
        uzbekistan: "UZ",
        pakistan: "PK",
        palau: "PW",
        palestine: "PS",
        panama: "PA",
        papua_new_guinea: "PG",
        paraguay: "PY",
        netherlands: "NL",
        peru: "PE",
        philippines: "PH",
        poland: "PL",
        portugal: "PT",
        qatar: "QA",
        romania: "RO",
        united_kingdom: "GB",
        russia: "RU",
        rwanda: "RW",
        saint_kitts_and_nevis: "KN",
        san_marino: "SM",
        saint_vincent_and_the_grenadines: "VC",
        saint_lucia: "LC",
        el_salvador: "SV",
        samoa: "WS",
        sao_tome_and_principe: "ST",
        senegal: "SN",
        serbia: "RS",
        seychelles: "SC",
        sierra_leone: "SL",
        singapore: "SG",
        slovakia: "SK",
        slovenia: "SI",
        somalia: "SO",
        sudan: "SD",
        south_sudan: "SS",
        sri_lanka: "LK",
        sweden: "SE",
        switzerland: "CH",
        suriname: "SR",
        swaziland: "SZ",
        syria: "SY",
        tajikistan: "TJ",
        tanzania: "TZ",
        chad: "TD",
        czech_republic: "CZ",
        thailand: "TH",
        togo: "TG",
        tonga: "TO",
        trinidad_and_tobago: "TT",
        tunisia: "TN",
        turkmenistan: "TM",
        turkey: "TR",
        tuvalu: "TV",
        ukraine: "UA",
        uruguay: "UY",
        vanuatu: "VU",
        vatican: "VA",
        venezuela: "VE",
        vietnam: "VN",
        yemen: "YE",
        zambia: "ZM",
        zimbabwe: "ZW",
    })
        .map(([countryName, ISOCode]) => ({
            label: I18n.t(`country_${countryName}`),
            value: ISOCode,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
];
