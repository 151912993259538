import clsx from "clsx";
import i18n from "i18n-js";
import { Add, Button, fonts, Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { OrganisationType } from "@lib/hooks";
import { normalizeJobDescriptions } from "@lib/queries/normalizeJobDescriptions";
import { useJobDescriptions } from "@routes/JobDescriptions/hooks/useJobDescriptions";
import { User } from "@store/types/user";

import { JobDescriptionCard } from "../JobDescriptionCard/JobDescriptionCard";

import styles from "./JobDescriptionList.module.css";
type Props = {
    title?: string;
    description?: string;
    jobDescriptions: ReturnType<typeof normalizeJobDescriptions>;
    organisationType: OrganisationType;
    jobDescriptionsOrigin: OrganisationType;
    unauthorized: boolean;
    unauthorizedTitle: string;
    unauthorizedDescription: string;
    user: User;
    showJobDescriptionCreation: () => void;
};

export const JobDescriptionList = ({
    title,
    description,
    unauthorized,
    unauthorizedTitle,
    unauthorizedDescription,
    jobDescriptions,
    organisationType,
    jobDescriptionsOrigin,
    user,
    showJobDescriptionCreation,
}: Props) => {
    // Job description creation is only allowed for the current account.
    const isCurrentAccount = organisationType === jobDescriptionsOrigin;
    const { jobDescription: selectedJobDescription, isCreatePending } = useJobDescriptions();

    return (
        <section className={styles.listContainer}>
            {title && <h2 className={clsx(fonts.sans20Medium, styles.title)}>{title}</h2>}
            {description && (
                <p className={clsx(fonts.sans18Regular, styles.description)}>{description}</p>
            )}
            <div className={styles.list}>
                {isCurrentAccount && (
                    <div className={styles.create}>
                        <Tooltip placement='top'>
                            <TooltipTrigger>
                                <Button
                                    onClick={showJobDescriptionCreation}
                                    icon={<Add />}
                                    iconDisposition='left'
                                    loading={isCreatePending}
                                    disabled={unauthorized}
                                >
                                    {i18n.t(`job-descriptions_create_button`)}
                                </Button>
                            </TooltipTrigger>
                            {unauthorized && (
                                <TooltipContent
                                    type='info'
                                    intention='error'
                                    label={unauthorizedTitle}
                                >
                                    {unauthorizedDescription}
                                </TooltipContent>
                            )}
                        </Tooltip>
                    </div>
                )}

                {jobDescriptions.map((jobDescription) => (
                    <JobDescriptionCard
                        key={jobDescription.id}
                        jobDescription={jobDescription}
                        isSelected={jobDescription.id === selectedJobDescription?.id}
                        displayId={user?.isInsider}
                    />
                ))}
            </div>
        </section>
    );
};
