import fetch from "isomorphic-fetch";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

type DownloadInvoicesParams = { startDate?: string; endDate?: string };
export function downloadInvoices(params: DownloadInvoicesParams) {
    return fetch(
        withAuthHeaders(
            withURLParam(`${apiConf.BACK_URL}/v1/organisations/invoices/download`, {
                startDate: params.startDate || "",
                endDate: params.endDate || "",
            }),
        ),
    );
}
