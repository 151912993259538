import fetch from "isomorphic-fetch";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export async function deleteShifts(params: { ids: string[] }) {
    debug.addBreadcrumb({
        message: `deleteShifts`,
        category: `action`,
        data: {
            ids: params.ids,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/shifts`, {
                method: `DELETE`,
                body: JSON.stringify(params),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("deleteShifts"));
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("deleteShifts"));
    }
}
