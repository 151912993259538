import { debug } from "@side.co/client-debug";

import * as backend from "../lib/api";
import { handleError } from "../lib/utils/error";
import getWeekStart from "../lib/utils/getWeekStart";

import normalizePlanning from "./normalizers/planning";
import type { Action, State, Task } from "./types/planning";
import type { Dispatch, GetState } from "./types";

//------------------------------------
// Constants
//------------------------------------

export const LOAD_WEEK_PLANNING = `LOAD_WEEK_PLANNING` as const;
export const SET_WEEK_PLANNING = `SET_WEEK_PLANNING` as const;
export const SET_WEEK = `SET_WEEK` as const;

//------------------------------------
// Helpers
//------------------------------------

const getWeekID = ({ week, year }): string => `we_${year}_${week}`;

//------------------------------------
// Actions
//------------------------------------

export const loadWeekPlanning = (week: number, year: number) => ({
    type: LOAD_WEEK_PLANNING,
    week,
    year,
});

export const setWeekPlanning = (week: number, year: number, tasks: Task[]) => ({
    type: SET_WEEK_PLANNING,
    week,
    year,
    tasks,
});

export const setCurrentWeek = (week: number, year: number) => ({
    type: SET_WEEK,
    week,
    year,
});

export const getWeekPlanning = (week: number, year: number) =>
    function (dispatch: Dispatch, getState: GetState) {
        const locale = localStorage.getItem(`side_team_locale`) || `fr`;
        return new Promise((resolve) => {
            const weekId = getWeekID({ week, year });
            const {
                planning: { weeks },
            } = getState();
            if (weeks[weekId]) return resolve(true);
            dispatch(loadWeekPlanning(week, year));
            return resolve(
                backend
                    .getPlanning({
                        week,
                        year,
                        locale,
                    })
                    .then((res) => res.json())
                    .then((data) => normalizePlanning(data))
                    .then((data) => dispatch(setWeekPlanning(week, year, data?.tasks)))
                    .catch((e) => {
                        debug.catch(e);
                        handleError(e.toString());
                    }),
            );
        });
    };

export const selectWeek = (week: number, year: number) =>
    function (dispatch: Dispatch) {
        dispatch(setCurrentWeek(week, year));
        dispatch(getWeekPlanning(week, year)).then(() => {
            // After selected week is fetched, get -2 <=> +2 weeks
            [-2, -1, 1, 2].forEach((n) => {
                const weekStart = getWeekStart(week + n, year);
                dispatch(getWeekPlanning(weekStart.isoWeek(), weekStart.isoWeekYear()));
            });
        });
    };

//------------------------------------
// Actions Handlers
//------------------------------------

const ACTION_HANDLERS = {
    [LOAD_WEEK_PLANNING]: (state, action) => ({
        selectedWeek: state.selectedWeek,
        weeks: {
            ...state.weeks,
            [getWeekID(action)]: {
                isLoading: true,
                tasks: [],
            },
        },
    }),
    [SET_WEEK_PLANNING]: (state, action) => ({
        selectedWeek: state.selectedWeek,
        weeks: {
            ...state.weeks,
            [getWeekID(action)]: {
                isLoading: false,

                tasks: action.tasks,
            },
        },
    }),
    [SET_WEEK]: (state, action) => ({
        selectedWeek: getWeekID(action),
        weeks: state.weeks,
    }),
};

//------------------------------------
// Reducer
//------------------------------------

const initialState = {
    selectedWeek: "",
    weeks: {},
};

const planningReducer = (state: State = initialState, action: Action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};

export default planningReducer;
