import { useState } from "react";
import { differenceInMinutes, format, isAfter, isBefore, isEqual } from "date-fns";
import i18n from "i18n-js";
import {
    Button,
    IconBolt,
    IconChevronRightXL,
    IconClockXL,
    IconCrossXL,
    SimpleHeader,
    Tip,
} from "side-ui";
import { Tag, User } from "sui";

import trackEvent from "@lib/trackers";
import { getLocale } from "@lib/utils/getLocale";

import { filterColors, setStatusLabel, sumShiftBreaks } from "../../../../utils";
import { convertMinutesToHours } from "../../../../utils/dates";

import AttendanceEdit from "./AttendancesEdit/AttendanceEdit";

import "./TimesheetsTablePanel.scss";

const TimesheetsTablePanel = ({
    siderName,
    history,
    attendanceId,
    closePanel,
    taskName,
    status,
    slotDuration,
    slotConflict,
    breakConflict,
    selectAttendance,
    setSiderDidntWork,
    disputeAttendance,
    companyName,
    shiftId,
    readOnly,
}) => {
    const locale = getLocale();
    const currentVersion = history[history.length - 1];
    const shiftVersion = history.find(({ origin }) => origin === "shift");
    const [attendanceEditState, setAttendanceEditState] = useState({
        startDate: new Date(currentVersion?.startDate),
        endDate: new Date(currentVersion?.endDate),
        breaks: currentVersion?.breaks,
    });
    const [formState, setFormState] = useState({
        dirty: false,
        error: "",
    });
    // handle edge case when insider added a version to a dispute between sider and company
    // we should prevent company from adding a new dispute => only validation is possible
    const fullDisputeFlow = ["shift", "sider", "company", "insider"];
    const origins = history.map(({ origin }) => origin);
    const preventDisputeAgain = fullDisputeFlow.every((origin) => origins.includes(origin));

    function lookForBreakErrors({ startDate, endDate }, breaks) {
        if (breaks && breaks.length) {
            breaks.forEach((b) => {
                if (isBefore(new Date(b.startDate), new Date(startDate))) {
                    setFormState((prevState) => ({
                        ...prevState,
                        error: "before",
                    }));
                } else if (isAfter(new Date(b.endDate), new Date(endDate))) {
                    setFormState((prevState) => ({
                        ...prevState,
                        error: "after",
                    }));
                } else {
                    // remove error if any; otherwise let React do nothing
                    setFormState((prevState) => ({
                        ...prevState,
                        error: "",
                    }));
                }
            });
        }
    }

    function setAttendanceHours(hours) {
        if (areHoursDirty(attendanceEditState, hours)) {
            setAttendanceEditState((prevState) => {
                setFormState((prev) => ({
                    ...prev,
                    dirty: true,
                }));

                return {
                    ...prevState,
                    ...hours,
                };
            });
            // passing hours instead of state.start/endDate because state update is asynchronous
            // and is thus not yet updated
            lookForBreakErrors(hours, attendanceEditState.breaks);
        }

        trackEvent({
            name: "timesheet - edits attendance",
            params: {
                organisationId: localStorage.getItem("side_team_activeOrganisationId"),
                organisationName: companyName,
                shiftId,
            },
        });
    }

    function setAttendanceBreaks(breaks) {
        if (areBreaksDirty(attendanceEditState.breaks, breaks)) {
            setAttendanceEditState((prevState) => {
                setFormState((prev) => ({
                    ...prev,
                    dirty: true,
                }));

                return {
                    ...prevState,
                    breaks,
                };
            });

            lookForBreakErrors({ ...attendanceEditState }, breaks);
        }

        trackEvent({
            name: "timesheet - edits attendance",
            params: {
                organisationId: localStorage.getItem("side_team_activeOrganisationId"),
                organisationName: companyName,
                shiftId,
            },
        });
    }

    function initialVersionDuration() {
        if (!currentVersion) {
            return 0;
        }

        const siderSlot = differenceInMinutes(
            new Date(currentVersion?.endDate),
            new Date(currentVersion?.startDate),
        );

        return convertMinutesToHours(siderSlot - sumShiftBreaks(currentVersion.breaks)) || 0;
    }

    return (
        <section className='timesheets-table__panel'>
            <SimpleHeader className='timesheets-table__panel__header'>
                <div className='timesheets-table__panel__header__informations__wrapper'>
                    <div className='timesheets-table__panel__header__title__wrapper'>
                        <p className='timesheets-table__panel__title'>{siderName}</p>

                        <p className='timesheets-table__panel__subtitle'>
                            {new Date(currentVersion.startDate).toLocaleDateString(locale.code)}
                            &nbsp;-&nbsp;
                            {taskName}
                        </p>
                    </div>

                    <div className='timesheets-table__panel__header__skiplink'>
                        <button onClick={closePanel} type='button'>
                            <IconCrossXL customClass='icon-close' />
                        </button>
                    </div>
                </div>
                <div className='timesheets-table__panel__header__tags'>
                    <Tag color={filterColors[status]}>{setStatusLabel(status)}</Tag>
                </div>
            </SimpleHeader>

            <>
                <ul className='timesheets-table__panel__list'>
                    <li className='timesheets-table__panel__list__item'>
                        <span
                            className='
              timesheets-table__panel__list__item__origin
              timesheets-table__panel__list__item__section
              '
                        >
                            <span className='timesheets-table__panel__list__item__origin__icon'>
                                <IconBolt />
                            </span>

                            <span>
                                <span className='timesheets-table__panel__list__item__origin__date'>
                                    {i18n.t("ts_panel_scheduled_hours")}
                                </span>
                                &nbsp;-&nbsp;
                                <span className='timesheets-table__panel__list__item__origin__type'>
                                    {slotDuration}
                                </span>
                            </span>
                        </span>

                        <span
                            className={`
                timesheets-table__panel__list__item__time
                timesheets-table__panel__list__item__section
              `}
                        >
                            <span className='timesheets-table__panel__list__item__time__icon'>
                                <IconClockXL height={24} width={24} />
                            </span>

                            <span className='timesheets-table__panel__list__item__time__wrapper'>
                                <span className='timesheets-table__panel__list__item__time__start'>
                                    {format(new Date(shiftVersion.startDate), "HH:mm", { locale })}
                                </span>

                                <IconChevronRightXL customClass='timesheets-table__panel__list__item--chevron' />

                                <span className='timesheets-table__panel__list__item__time__end'>
                                    {format(new Date(shiftVersion.endDate), "HH:mm", { locale })}
                                </span>
                            </span>
                            {shiftVersion.breaks && (
                                <span className='timesheets-table__panel__list__item__time__break'>
                                    ({convertMinutesToHours(sumShiftBreaks(shiftVersion.breaks))})
                                </span>
                            )}
                        </span>
                    </li>

                    {currentVersion ? (
                        <li
                            className='
                timesheets-table__panel__list__item
                timesheets-table__panel__list__item--edit
              '
                        >
                            <span
                                className='
                timesheets-table__panel__list__item__origin
                timesheets-table__panel__list__item__section
                '
                            >
                                <span className='timesheets-table__panel__list__item__origin__icon'>
                                    <User className='timesheets-table__panel__list__item__origin__icon__user' />
                                </span>

                                <span>
                                    <span className='timesheets-table__panel__list__item__origin__date'>
                                        {i18n.t("ts_panel_sider_hours")}
                                    </span>
                                    &nbsp;-&nbsp;
                                    <span className='timesheets-table__panel__list__item__origin__type'>
                                        {initialVersionDuration()}
                                    </span>
                                </span>
                            </span>

                            <span
                                className='
                timesheets-table__panel__list__item--edit__wrapper
                timesheets-table__panel__list__item__section
                '
                            >
                                <AttendanceEdit
                                    currentVersion={attendanceEditState}
                                    editAttendanceHours={setAttendanceHours}
                                    editAttendanceBreaks={setAttendanceBreaks}
                                    slotConflict={slotConflict}
                                    breakConflict={breakConflict}
                                    readOnly={readOnly || preventDisputeAgain}
                                />
                            </span>

                            {formState.error ? (
                                <span
                                    className='
                    timesheets-table__panel__list__item--edit__wrapper
                    timesheets-table__panel__list--error
                  '
                                >
                                    <Tip
                                        small={true}
                                        isWarning={true}
                                        content={i18n.t(
                                            `ts_edit_form_break_error_${formState.error}`,
                                        )}
                                    />
                                </span>
                            ) : null}

                            <span
                                className='
                timesheets-table__panel__list__item__section
                timesheets-table__panel__list__item__section__submit
                '
                            >
                                <Button
                                    action={() => {
                                        // disable action if loggedAs or has error
                                        if (!readOnly || formState.error) {
                                            disputeAttendance(
                                                attendanceId,
                                                { ...attendanceEditState },
                                                "timesheet - confirms attendance edit", // tracker name
                                            );
                                            closePanel();
                                        }
                                    }}
                                    color='blue'
                                    fullWidth={true}
                                    disabled={
                                        !formState.dirty ||
                                        Boolean(formState.error) ||
                                        readOnly ||
                                        preventDisputeAgain
                                    }
                                >
                                    {i18n.t("ts_panel_submit")}
                                </Button>
                            </span>
                        </li>
                    ) : null}

                    {currentVersion ? (
                        <li
                            className='
                timesheets-table__panel__list__item
                timesheets-table__panel__list__item--siderdidntwork
              '
                        >
                            <Button
                                action={() => {
                                    if (!readOnly || !preventDisputeAgain) {
                                        selectAttendance(attendanceId);
                                        setSiderDidntWork();
                                    }
                                }}
                                color='red'
                                fullWidth={true}
                                disabled={readOnly || preventDisputeAgain}
                            >
                                {i18n.t("ts_panel_sider_didnt_work")}
                            </Button>
                        </li>
                    ) : null}
                </ul>
            </>
        </section>
    );
};

export default TimesheetsTablePanel;

function areBreaksDirty(prevBreaks = [] as any[], breaks) {
    if (prevBreaks.length !== breaks.length) {
        return true;
    }

    return prevBreaks.some((prevBreak, index) => {
        const breakItem = breaks[index];
        return (
            new Date(prevBreak.startDate).toISOString() !==
                new Date(breakItem.startDate).toISOString() ||
            new Date(prevBreak.endDate).toISOString() !== new Date(breakItem.endDate).toISOString()
        );
    });
}

function areHoursDirty(prevHours, { startDate, endDate }) {
    return !isEqual(prevHours.startDate, startDate) || !isEqual(prevHours.endDate, endDate);
}
