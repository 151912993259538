import { useEffect } from "react";
import { Loader } from "side-ui";

import Pagination from "../../../components/Pagination";
import { contractsBatchesArchivesRoute } from "../route";

import ContractsBatchesList from "./ContractsBatchesList";

import "./ContractsBatches.scss";

const ContractsBatchesArchives = ({
    contractsBatches,
    isLoading,
    pagination,
    getContractsBatches,
    updateCurrentTab,
}) => {
    useEffect(() => {
        updateCurrentTab(`signed`);
    }, []);

    const { page = 1 } = contractsBatchesArchivesRoute.useSearch();
    useEffect(() => {
        getContractsBatches(page);
    }, [page]);

    if (isLoading) {
        return (
            <div className='loader--centered'>
                <Loader />
            </div>
        );
    }

    return (
        <div className='contracts-batches contracts-batches--archives'>
            <ContractsBatchesList contractsBatches={contractsBatches} />
            {pagination.pageCount > 1 && (
                <Pagination
                    pageCount={pagination.pageCount}
                    path='/contracts/archives'
                    page='contractsArchives'
                />
            )}
        </div>
    );
};

export default ContractsBatchesArchives;
