import fetch from "isomorphic-fetch";

import { clearLogas } from "@lib/utils/clearLogas";
import { handleError } from "@lib/utils/error";
import { router } from "@routes";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type GetUserInfoResponse = {
    firstName: string;
    lastName: string;
    organisation: Organisation;
    hasConversationNotification: boolean;
    pendingInvoiceNumber: number;
    isAdmin: boolean;
    isInsider: boolean;
    role: string;
    email: string;
    phoneNumber: string;
};

type Organisation = {
    id: string;
    name: string;
    logoUrl: string;
};

export async function getUserInfo(): Promise<GetUserInfoResponse> {
    debug.addBreadcrumb({
        message: `GetUserInfo`,
        data: { ...localStorage },
    });

    try {
        const resp = await fetch(withAuthHeaders(`${apiConf.BACK_URL}/v1/users/me`));

        const json = await resp.json();

        debug.setUser({
            // Reminder:
            // Meteor.userId is the user currently logged in
            // side_team_logasId exists if the user logged in is an Insider
            // and is the value of the impersonated user
            token: localStorage.getItem(`Meteor.loginToken`),
            organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
            id: localStorage.getItem(`side_team_logasId`) || localStorage.getItem(`Meteor.userId`),
            logas: localStorage.getItem(`side_team_logasId`)
                ? localStorage.getItem(`Meteor.userId`)
                : undefined,
        });

        if (resp.status >= 400) {
            handleError(json, resp.status);

            debug.log(`API call error: ${resp.status}`, {
                extra: {
                    res: json.error,
                    storage: JSON.stringify({ ...localStorage }),
                },
            });
            clearLogas();
            router.navigate({ to: "/" });
            return Promise.reject(new Error("GetUserInfo"));
        }

        return json;
    } catch (e) {
        debug.catch(e);
        handleError(e, 500);
        return Promise.reject(new Error("GetUserInfo"));
    }
}
