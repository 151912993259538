import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { debugMiddleware } from "@side.co/client-debug";

import makeRootReducer from "./reducer";

export default (initialState = {}) => {
    // ======================================================
    // Middleware Configuration
    // ======================================================
    const middleware = [thunk, debugMiddleware];

    // ======================================================
    // Store Enhancers
    // ======================================================

    const enhancers = [];
    const composeEnhancers = composeWithDevTools;

    // ======================================================
    // Store Instantiation and HMR Setup
    // ======================================================
    const store = createStore(
        makeRootReducer(),
        initialState,
        composeEnhancers(applyMiddleware(...middleware), ...enhancers),
    );
    return store;
};
