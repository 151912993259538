import i18n from "i18n-js";
import { InformationFilled, Label, Toggle } from "sui";

import styles from "./RequestedSiders.module.css";

export function RequestedSiders({
    requestedSidersOnly = false,
    selectedSidersLength = 0,
    totalRequestedSiders = 0,
    handleToggleSwitchChange,
}) {
    const sidersSelectionRemainingCount = totalRequestedSiders - selectedSidersLength;
    const shouldBeDisabled = sidersSelectionRemainingCount > 0;

    return (
        <div className={styles.container}>
            <Toggle
                disabled={shouldBeDisabled}
                onChange={handleToggleSwitchChange}
                id='requested-siders-only'
                checked={requestedSidersOnly}
            />

            <div>
                <Label
                    htmlFor='requested-siders-only'
                    icon={<InformationFilled className={styles.infoIcon} />}
                    tooltip={
                        requestedSidersOnly
                            ? i18n.t("order_workers_wa-only_toggle_tooltip_content_activated")
                            : i18n.t("order_workers_wa-only_toggle_tooltip_content_deactivated")
                    }
                >
                    {i18n.t("order_workers_wa-only_modal_title")}
                </Label>
                {sidersSelectionRemainingCount > 0 && (
                    <p
                        className='work-again-only__informations__help'
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: i18n.t("order_workers_wa-only_toggle_amount", {
                                numberOfRemainingSlots: sidersSelectionRemainingCount,
                            }),
                        }}
                    />
                )}
            </div>
        </div>
    );
}
