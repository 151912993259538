import fetch from "isomorphic-fetch";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

type GetInvoicesParams = { startDate?: string; endDate?: string };
export type GetInvoicesResponse = {
    firstPaymentDate: string;
    invoices: Invoice[];
};

type Invoice = {
    id: string;
    referenceNumber: string;
    type: string;
    sideInvoiceUrl: string;
    amount: number;
    currency: string;
    createdAt: string;
    weekStartDate: string;
    weekEndDate: string;
    paid?: any;
};
export function getInvoices(params: GetInvoicesParams) {
    return fetch(
        withAuthHeaders(
            withURLParam(`${apiConf.BACK_URL}/v1/organisations/invoices`, {
                startDate: params.startDate || "",
                endDate: params.endDate || "",
            }),
        ),
    );
}
