import { connect } from "react-redux";

import { getManagerIdFilter } from "../../../store/selectors/display";
import { getCurrentTasks, getTasksCurrentPagination } from "../../../store/selectors/tasks";
import { backendTasksStatusMap, getTasks, selectStatus } from "../../../store/tasks";
import TasksListStatus from "../components/TasksListStatus";

const mapStateToProps = (state, ownProps) => ({
    items: getCurrentTasks(state, ownProps),
    pagination: getTasksCurrentPagination(state, ownProps),
    managerIdFilter: getManagerIdFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
    getTasks: (status, pageNumber, limit) => {
        dispatch(selectStatus(backendTasksStatusMap[status]));
        dispatch(getTasks(backendTasksStatusMap[status], pageNumber, limit));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksListStatus);
