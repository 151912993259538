import { connect } from "react-redux";

import { handleError } from "../../../lib/utils/error";
import { regenerateDocuments, signContracts } from "../../../store/contracts";
import { getContractsBatch, getSignatureError } from "../../../store/selectors/contracts";
import ContractsSignature from "../components/ContractsSignature";

const mapStateToProps = (state) => {
    const { pathname } = window.location;
    const id = pathname.split("/")[2];
    return {
        contractsBatch: getContractsBatch(state, id),
        signError: getSignatureError(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    regenerateDocument: (docId) => dispatch(regenerateDocuments([docId])),
    signContracts: (docId) => dispatch(signContracts(docId)),
    setError: (error, title) => handleError(error, title),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsSignature);
