import { createSelector } from "reselect";

import type { State } from "../types";
import type { ContractsBatch, Pagination } from "../types/contracts";

export const getTotalOfContractsBatchesToSign = (state: State): number =>
    state.contracts.pagination.toSign.contractsBatchesTotal;
export const getAllContractsBatches = (state: State) => state.contracts.contractsBatches;
export const getHasContractsBatches = (state: State): boolean =>
    state.contracts.hasContractsBatches;
export const getIsLoading = (state: State): boolean => state.contracts.isLoading;
export const getPagination = (state: State): Pagination => state.contracts.pagination;
export const getCurrentTab = (state: State): string => state.contracts.currentTab;
export const getContractsBatch = (state: State, id: string): object =>
    state.contracts.contractsBatches[id];
export const getNumberOfContractsBatchesToSign = (state: State): number =>
    state.contracts.toSignCount;
export const getSignatureError = (state: State): boolean => state.contracts.signError;

/**
 * Get the the current pagination obj
 * @return obj
 */
export const getCurrentPagination = createSelector(
    getPagination,
    getCurrentTab,
    (pagination, tab) => pagination[tab],
);

export const getCurrentContractsBatches = createSelector(
    getCurrentPagination,
    getAllContractsBatches,
    (pagination, contractsBatches): Array<ContractsBatch> => {
        const contractsBatchesIds = pagination.pages[pagination.currentPageNumber];
        return contractsBatchesIds && contractsBatchesIds.map((id) => contractsBatches[id]);
    },
);

export const getContractsBatchesByTask = createSelector(
    getAllContractsBatches,
    (contractsBatches) =>
        Object.values(contractsBatches).reduce((acc, value) => {
            if (!acc[value.taskId]) {
                acc[value.taskId] = [];
            }

            acc[value.taskId].push(value);
            return acc;
        }, {}),
);
