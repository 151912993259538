import { string, z } from "zod";

import { debug } from "@side.co/client-debug";

import { apiRequest } from "./apiHelpers/apiRequest";
import { apiConf } from "./index";
const replacementSchema = z.object({
    name: z.string(),
    position: z.string(),
    justification: z.string(),
});

const recruitmentSchema = z.object({
    name: z.string(),
    date: z.string().datetime({ offset: true }),
    position: z.string(),
});

const motiveSchema = z
    .object({
        reason: z.enum([
            "activityIncrease",
            "replacement",
            "waitingRecruitment",
            "temporaryContract",
            "seasonalActivity",
        ]),
        justification: z.string(),
        replacements: z.array(replacementSchema).optional(),
        recruitments: z.array(recruitmentSchema).optional(),
    })
    .strict();

type PostMotive = z.infer<typeof motiveSchema>;

const postMotiveResSchema = motiveSchema
    .extend({
        id: z.string(),
        organisationId: string(),
    })
    .strict();

export async function postCompanyMotive(params: PostMotive, signal?: AbortSignal) {
    debug.addBreadcrumb({
        message: `postCompanyMotive`,
    });
    const url = `${apiConf.BACK_URL}/motives`;
    const validatedParams = motiveSchema.parse(params);
    return apiRequest({
        url,
        responseSchema: postMotiveResSchema,
        fetchOptions: {
            method: `POST`,
            body: JSON.stringify(validatedParams),
            signal,
        },
    });
}
