import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const SubtaskSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export type Subtask = z.infer<typeof SubtaskSchema>;

const EquipmentSchema = z.object({
    id: z.string(),
    name: z.string(),
    group: z.enum(["Safety", "Other"]),
});
export type Equipment = z.infer<typeof EquipmentSchema>;

export const RiskSchema = z.object({
    id: z.string(),
    group: z.string(),
    name: z.string(),
    value: z.string().optional(),
});
export type Risk = z.infer<typeof RiskSchema>;

export const LanguageSchema = z.object({
    id: z.string(),
    level: z.string(),
    ISOCode: z.string(),
});
export type Language = z.infer<typeof LanguageSchema>;

export const ToolSchema = z.object({
    id: z.string(),
    name: z.string(),
    level: z.string().optional(),
});
export type Tool = z.infer<typeof ToolSchema>;

export const LicenceSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export type Licence = z.infer<typeof LicenceSchema>;

const LocationSchema = z.object({
    id: z.string(),
    city: z.string(),
    country: z.string(),
    lat: z.number(),
    lng: z.number(),
    postalCode: z.string(),
    address: z.string(),
    description: z.string(),
    favorite: z.boolean(),
    motorized: z.boolean(),
    remote: z.boolean(),
    title: z.string().nullable(),
});
export type Location = z.infer<typeof LocationSchema>;

export const HoursPricingSchema = z.object({
    hourType: z.string(),
    hourlyRate: z.number(),
    coefficient: z.number(),
    increaseRate: z.number().optional(),
});
export type HoursPricing = z.infer<typeof HoursPricingSchema>;

const PricingSchema = z.object({
    delegation: z.array(HoursPricingSchema),
    gestion: z.array(HoursPricingSchema),
});
export type Pricing = z.infer<typeof PricingSchema>;

const ReplacementSchema = z.object({
    name: z.string(),
    position: z.string(),
    justification: z.string(),
});
export type Replacement = z.infer<typeof ReplacementSchema>;

const RecruitmentSchema = z.object({
    name: z.string(),
    date: z
        .string()
        .datetime({ offset: true })
        .transform((val) => new Date(val)),
    position: z.string(),
});
export type Recruitment = z.infer<typeof RecruitmentSchema>;

const MotiveSchema = z.object({
    reason: z.enum([
        "activityIncrease",
        "replacement",
        "waitingRecruitment",
        "temporaryContract",
        "seasonalActivity",
    ]),
    justification: z.string().optional(),
    replacements: z.array(ReplacementSchema).optional(),
    recruitments: z.array(RecruitmentSchema).optional(),
});
export type Motive = z.infer<typeof MotiveSchema>;

const ShiftSchema = z.object({
    id: z.string(),
    startDate: z
        .string()
        .datetime({ offset: true })
        .transform((val) => new Date(val)),
    endDate: z
        .string()
        .datetime({ offset: true })
        .transform((val) => new Date(val)),
    breakDuration: z.number(),
    slots: z.number(),
    recurrenceId: z.string().optional(),
});
export type Shift = z.infer<typeof ShiftSchema>;

const RequestedSiderSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    pictureURL: z.string().nullable(),
    status: z.string(),
});
export type RequestedSider = z.infer<typeof RequestedSiderSchema>;

const ManagerSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
});
export type Manager = z.infer<typeof ManagerSchema>;

const TaskTypeSchema = z.enum(["delegation", "gestion"]);
export type TaskType = z.infer<typeof TaskTypeSchema>;

const TaskStatusSchema = z.enum(["draft", "submitted"]);
export type TaskStatus = z.infer<typeof TaskStatusSchema>;

const TaskProgressSchema = z.enum(["draft", "ongoing", "upcoming", "done"]);
export type TaskProgress = z.infer<typeof TaskProgressSchema>;

const SelectionStatusSchema = z.enum(["new", "ongoing", "ready", "closed"]);

export const ExecutiveStatusSchema = z.enum(["", "worker", "executive", "ETAM"]);
export type ExecutiveStatus = z.infer<typeof ExecutiveStatusSchema>;

export const TaskSchema = z.object({
    id: z.string(),
    type: TaskTypeSchema,
    name: z.string(),
    jobTitle: z.string().optional(),
    status: TaskStatusSchema,
    progressStep: TaskProgressSchema,
    requestedSiders: z.array(RequestedSiderSchema).optional(),
    requestedSidersOnly: z.boolean(),
    selectionStatus: SelectionStatusSchema,
    pricing: PricingSchema,
    hourlyRate: z.number().optional(),
    comRate: z.number(),
    location: LocationSchema.optional(),
    manager: ManagerSchema.optional(),
    missionInformation: z.string().optional(),
    motive: MotiveSchema.optional(),
    shifts: z
        .array(ShiftSchema)
        .optional()
        .transform((shifts) =>
            (shifts ?? []).sort((a, b) => {
                const aDate = a.startDate.getTime();
                const bDate = b.startDate.getTime();
                return aDate - bDate;
            }),
        ),
    motiveId: z.string().optional(),
    subtasks: z.array(SubtaskSchema).optional(),
    equipments: z.array(EquipmentSchema).optional(),
    risks: z.array(RiskSchema).optional(),
    languages: z.array(LanguageSchema).optional(),
    tools: z.array(ToolSchema).optional(),
    licences: z.array(LicenceSchema).optional(),
    purpose: z.string().optional(),
    dressCode: z.string().optional(),
    workConditions: z.string().optional(),
    experiences: z.string().optional(),
    migrated: z.boolean(),
    executiveStatus: ExecutiveStatusSchema.optional(),
});
export type Task = z.infer<typeof TaskSchema>;

export async function getTask(taskId: string) {
    debug.addBreadcrumb({
        message: `Get specific existing task`,
        data: taskId,
    });
    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/tasks/${taskId}`, {
                method: `GET`,
            }),
        );
        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("getTask"));
        }

        const json = await resp.json();

        return TaskSchema.parse(json);
    } catch (e) {
        debug.catch(e);
        handleError(e, 500);
        return Promise.reject(new Error("getTask"));
    }
}
