import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { GetOrganisationUsersResponse } from "./getOrganisationUsers";
import { apiConf } from "./index";

export async function deactivateUser(id: string): Promise<GetOrganisationUsersResponse | null> {
    debug.addBreadcrumb({
        message: `DeactivateUser`,
        category: `action`,
        data: {
            id,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisations/users/${id}`, {
                method: `DELETE`,
                credentials: "omit",
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
