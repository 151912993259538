import React from "react";
import i18n from "i18n-js";
import {
    BlockFeedback,
    Button,
    HeadingWithIcon,
    Loader,
    ModalsService,
    SimpleSelect,
    ValidationBlock,
} from "side-ui";

import FormCard from "../../../components/FormCard";

import RemunerationTableSection from "./partials/RemunerationTableSection";
import HoursTypes from "./remuneration-forms/HoursTypesContainer";
import IndemnityLunch from "./remuneration-forms/IndemnityLunchContainer";
import IndemnityTransport from "./remuneration-forms/IndemnityTransportContainer";

import "./RemunerationInformation.scss";

const INDEMNITIES_MODALS = {
    lunch: "LUNCH_INDEMNITY",
    transport: "TRANSPORT_INDEMNITY",
};

class RemunerationInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndemnityType: undefined,
            newIndemnityError: undefined,
        };
    }

    componentDidMount() {
        const { getCompanyRemunerationInfo } = this.props;
        getCompanyRemunerationInfo();
    }

    componentDidUpdate(prevProps) {
        const { remunerationInfo } = this.props;

        if (prevProps.remunerationInfo.indemnities !== remunerationInfo.indemnities) {
            this.setState({ selectedIndemnityType: undefined });
        }
    }

    getIndemnityConditions(indemnity) {
        const liElems = [];

        if (indemnity.otherParameters) {
            if (indemnity.otherParameters.seniority) {
                liElems.push(
                    i18n.t(`settings_remuneration_indemnities_seniority_condition_after`, {
                        duration: `${indemnity.otherParameters.seniority.daysThreshold} ${i18n.t(
                            `settings_remuneration_indemnities_seniority_unit`,
                        )}`,
                    }),
                );
            }

            if (indemnity.otherParameters.hoursPerDay) {
                liElems.push(
                    i18n.t(`settings_remuneration_indemnities_seniority_condition_after`, {
                        duration: `${indemnity.otherParameters.hoursPerDay.threshold}h${i18n.t(
                            `settings_remuneration_indemnities_per_day`,
                        )}`,
                    }),
                );
            }

            if (indemnity.otherParameters.zti) {
                liElems.push(
                    i18n.t(`settings_remuneration_indemnities_other_other_parameters_zti`),
                );
            }

            if (indemnity.otherParameters.taskType) {
                liElems.push(
                    i18n.t(`settings_remuneration_indemnities_other_other_parameters_jobtype`),
                );
            }
        }

        return liElems.length !== 0
            ? {
                  key: "conditions",
                  content: (
                      <ul>
                          {liElems.map((el, idx) => (
                              <li key={idx}>{el}</li>
                          ))}
                      </ul>
                  ),
                  value: " ",
              }
            : null;
    }

    getLunchSections() {
        const { remunerationInfo } = this.props;

        let lunchSections = [];

        if (remunerationInfo.indemnities) {
            const lunchIndemnities = remunerationInfo.indemnities.lunch;

            lunchSections = lunchIndemnities.map((indemnity) => {
                let content = "";
                if (indemnity.type === "ticket") {
                    content = i18n.t(`settings_remuneration_indemnities_lunch_ticket_type`);
                } else if (indemnity.type === "indemnity") {
                    content = i18n.t(`settings_remuneration_indemnities_lunch_indemnity_type`);
                }

                const items = [
                    {
                        key: "lunch_title",
                        content,
                        value: `${indemnity.amount}€`,
                    },
                ];

                const conditions = this.getIndemnityConditions(indemnity);
                if (conditions) {
                    items.push(conditions);
                }

                return {
                    key: `title_lunch`,
                    title: i18n.t(`settings_remuneration_indemnities_lunch_title`),
                    action: () => {
                        ModalsService.openModal({
                            id: INDEMNITIES_MODALS.lunch,
                            content: <IndemnityLunch indemnity={indemnity} />,
                        });
                    },
                    items,
                };
            });
        }

        return lunchSections;
    }

    getTransportationSections() {
        const { remunerationInfo } = this.props;

        let transportationSections = [];

        if (remunerationInfo.indemnities) {
            const transportationIndemnities = remunerationInfo.indemnities.transportation;

            transportationSections = transportationIndemnities.map((indemnity) => {
                const content = i18n.t(`settings_remuneration_indemnities_transport_public_type`);

                const items = [
                    {
                        key: "transportation_title",
                        content,
                        value: `${indemnity.publicCoverRate}%`,
                    },
                ];

                const conditions = this.getIndemnityConditions(indemnity);
                if (conditions) {
                    items.push(conditions);
                }

                return {
                    key: `title_transport`,
                    title: i18n.t(`settings_remuneration_indemnities_transport_title`),
                    action: () => {
                        ModalsService.openModal({
                            id: INDEMNITIES_MODALS.transport,

                            content: <IndemnityTransport indemnity={indemnity} />,
                        });
                    },
                    items,
                };
            });
        }

        return transportationSections;
    }

    getSections() {
        const lunchSections = this.getLunchSections();
        const transportationSections = this.getTransportationSections();

        const indemnitiesSections = [...lunchSections, ...transportationSections];

        return indemnitiesSections.map((section, i) => (
            <RemunerationTableSection
                key={i}
                tooltipContent={i18n.t(`settings_remuneration_indemnities_edit_tooltip`)}
                {...section}
            />
        ));
    }

    getNewIndemnityContent() {
        const { selectedIndemnityType } = this.state;

        switch (selectedIndemnityType) {
            case "lunch":
                return <IndemnityLunch indemnity={undefined} />;
            case "transport":
                return <IndemnityTransport indemnity={undefined} />;
            default:
                return null;
        }
    }

    setNewIndemnityOption(option) {
        const { remunerationInfo } = this.props;
        let setError = false;

        switch (option) {
            case "lunch": {
                if (
                    remunerationInfo.indemnities &&
                    remunerationInfo.indemnities.lunch &&
                    remunerationInfo.indemnities.lunch.length !== 0
                ) {
                    setError = true;
                }
                break;
            }
            case "transport": {
                if (
                    remunerationInfo.indemnities &&
                    remunerationInfo.indemnities.transportation &&
                    remunerationInfo.indemnities.transportation.length !== 0
                ) {
                    setError = true;
                }
                break;
            }
            default:
                break;
        }

        if (setError) {
            this.setState({
                newIndemnityError: i18n.t(
                    `settings_remuneration_indemnities_other_type_error_already_exists`,
                ),
                selectedIndemnityType: undefined,
            });
            return;
        }

        this.setState({
            selectedIndemnityType: option,
            newIndemnityError: undefined,
        });
    }

    render() {
        const { isLoading, confirmIndemnities, areIndemnitiesConfirmed, remunerationInfo } =
            this.props;

        const { selectedIndemnityType, newIndemnityError } = this.state;

        if (isLoading || !remunerationInfo.indemnities || !remunerationInfo.hoursTypes) {
            return <Loader />;
        }

        const sections = this.getSections();

        if (!(remunerationInfo.indemnities || {}).valid) {
            return (
                <div className='settings-remuneration-information'>
                    <h2 className='settings__heading'>{i18n.t(`settings_remuneration_title`)}</h2>
                    {}
                    <HoursTypes remunerationInfo={remunerationInfo} />
                    <div id='indemnities' data-hash='indemnities' className='settings__section'>
                        <HeadingWithIcon
                            icon='DollarSign'
                            label={i18n.t(`settings_remuneration_indemnities_title`)}
                        />
                        <p className='settings__teasing'>
                            {i18n.t(`settings_remuneration_indemnities_subtitle`)}
                        </p>
                        <BlockFeedback
                            type='info'
                            content={i18n.t(`settings_remuneration_indemnities_custom_disclaimer`)}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className='settings-remuneration-information'>
                <h2 className='settings__heading'>{i18n.t(`settings_remuneration_title`)}</h2>
                <HoursTypes remunerationInfo={remunerationInfo} />
                <div id='indemnities' data-hash='indemnities' className='settings__section'>
                    <HeadingWithIcon
                        icon='DollarSign'
                        label={i18n.t(`settings_remuneration_indemnities_title`)}
                    />
                    <p className='settings__teasing'>
                        {i18n.t(`settings_remuneration_indemnities_subtitle`)}
                    </p>
                    {!areIndemnitiesConfirmed && (
                        <ValidationBlock
                            title={i18n.t(`settings_remuneration_indemnities_validation_title`)}
                            content={i18n.t(`settings_remuneration_indemnities_validation_content`)}
                            checkboxLabel={i18n.t(
                                `settings_remuneration_indemnities_validation_label`,
                            )}
                            buttonLabel={i18n.t(
                                `settings_remuneration_indemnities_validation_button`,
                            )}
                            buttonAction={() => confirmIndemnities()}
                        />
                    )}
                    <div className='remuneration-table'>
                        <div className='remuneration-table__thead'>
                            <div className='remuneration-table__tr'>
                                {sections.length !== 0 ? (
                                    <>
                                        <div className='remuneration-table__th'>
                                            {i18n.t(`settings_remuneration_indemnities_table_type`)}
                                        </div>
                                        <div className='remuneration-table__th'>
                                            {i18n.t(
                                                `settings_remuneration_indemnities_table_value`,
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className='remuneration-table__th remuneration-table__empty'>
                                        {i18n.t(`settings_remuneration_indemnities_empty`)}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='remuneration-table__tbody'>
                            {sections.length !== 0 && sections}
                        </div>
                    </div>
                    <FormCard title={i18n.t(`settings_remuneration_indemnities_add_title`)}>
                        <SimpleSelect
                            id='addIndemnity'
                            label={i18n.t(`settings_remuneration_indemnities_add_label`)}
                            placeholder={i18n.t(
                                `settings_remuneration_indemnities_add_placeholder`,
                            )}
                            onChange={(e) => this.setNewIndemnityOption(e.target.value)}
                            options={[
                                {
                                    id: "lunch",
                                    value: "lunch",
                                    label: i18n.t(
                                        `settings_remuneration_indemnities_add_option_lunch`,
                                    ),
                                },
                                {
                                    id: "transport",
                                    value: "transport",
                                    label: i18n.t(
                                        `settings_remuneration_indemnities_add_option_transport`,
                                    ),
                                },
                            ]}
                            value={selectedIndemnityType}
                            customClass='select-indemnity-type'
                            error={newIndemnityError}
                            smallInput={true}
                        />
                        <Button
                            color='blue'
                            iconBefore='PlusXL'
                            disabled={!selectedIndemnityType}
                            action={() => {
                                if (!newIndemnityError) {
                                    ModalsService.openModal({
                                        id:
                                            selectedIndemnityType &&
                                            INDEMNITIES_MODALS[selectedIndemnityType],
                                        content: this.getNewIndemnityContent(),
                                    });
                                }
                            }}
                        >
                            {i18n.t(`settings_remuneration_indemnities_option_button`)}
                        </Button>
                    </FormCard>
                    <FormCard>
                        <p>{i18n.t(`settings_remuneration_indemnities_help`)}</p>
                    </FormCard>
                </div>
            </div>
        );
    }
}

export default RemunerationInformation;
