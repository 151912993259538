import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type DeleteTaskParams = { taskId: string };
export function deleteTask(params: DeleteTaskParams) {
    debug.addBreadcrumb({
        message: `Deletes task`,
        category: `action`,
        data: {
            taskId: params.taskId,
        },
    });

    return fetch(
        withAuthHeaders(`${apiConf.BACK_URL}/tasks/${params.taskId}`, { method: `DELETE` }),
    );
}
