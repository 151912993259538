import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const StepsSchema = z.array(
    z.enum([
        "profile",
        "remunerationPolicy.hourTypes",
        "remunerationPolicy.indemnities",
        "legalInformations",
        "paymentInformations",
    ]),
);

export type OganisationCompletionStatus = z.infer<typeof StepsSchema>;

const OrganisationCompletionStatusSchema = z.object({
    complete: StepsSchema,
    incomplete: StepsSchema,
});

const message = "GetOrganisationCompletionStatus";
export async function getOrganisationCompletionStatus() {
    debug.addBreadcrumb({
        message,
        category: `action`,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisation/completion`, {
                method: `GET`,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error(message));
        }

        return OrganisationCompletionStatusSchema.parse(await resp.json());
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error(message));
    }
}
