import { useState } from "react";
import i18n from "i18n-js";
import { ModalSimple, Select } from "side-ui";

import { useFormInput } from "../../../../lib/hooks/form";

import "./EditUserModal.scss";

const EditUserModal = ({ user, editUser, hideModal }) => {
    const { formInput: role, handleChange: setRole } = useFormInput(user.role, () => null);
    const [loading, setLoading] = useState(false);

    const roleOptions = [
        {
            id: 1,
            value: "admin",
            label: i18n.t("settings_team_role_admin"),
        },
        {
            id: 2,
            value: "missionManager",
            label: i18n.t("settings_team_role_missionManager"),
        },
        {
            id: 3,
            value: "collaborator",
            label: i18n.t("settings_team_role_collaborator"),
        },
    ];

    return (
        <ModalSimple
            action={() => {
                setLoading(true);
                editUser({
                    role: role.value,
                });
            }}
            validateLabel={i18n.t(`confirm`)}
            cancelLabel={i18n.t(`cancel`)}
            className='team-settings-team-edit-user'
            title={i18n.t(`settings_team_edit_user`)}
            hideModal={hideModal}
            disableButton={!role.dirty}
            loading={loading}
        >
            <div className='team-settings-team-edit-user__line'>
                <Select
                    id='role'
                    name='role'
                    label={i18n.t("settings_team_edit_user_role")}
                    options={roleOptions}
                    selectedItem={roleOptions.find((opt) => opt.value === role.value)?.label}
                    chevronIcon={true}
                    onChange={(_, __, value) => {
                        setRole(value);
                    }}
                    placeholder={i18n.t("settings_team_edit_user_role_placeholder")}
                />
            </div>
        </ModalSimple>
    );
};

export default EditUserModal;
