import { useState } from "react";
import i18n from "i18n-js";
import { IconInformation, InputText, ModalSimple, Select } from "side-ui";

import { useFormInput } from "../../../../lib/hooks/form";

import "./AddUserModal.scss";

const AddUserModal = ({ addUser, hideModal }) => {
    const { formInput: email, handleChange: setEmail } = useFormInput("", (value) => {
        if (
            !value
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                )
        ) {
            return i18n.t(`signup_error_invalid_email`);
        }
        return null;
    });
    const { formInput: firstName, handleChange: setFirstName } = useFormInput("", (value) =>
        value === "" ? i18n.t(`error_field_is_required`) : null,
    );
    const { formInput: lastName, handleChange: setLastName } = useFormInput("", (value) =>
        value === "" ? i18n.t(`error_field_is_required`) : null,
    );
    const { formInput: role, handleChange: setRole } = useFormInput("admin", () => null);
    const [loading, setLoading] = useState(false);

    const roleOptions = [
        {
            id: 1,
            value: "admin",
            label: i18n.t("settings_team_role_admin"),
        },
        {
            id: 2,
            value: "missionManager",
            label: i18n.t("settings_team_role_missionManager"),
        },
        {
            id: 3,
            value: "collaborator",
            label: i18n.t("settings_team_role_collaborator"),
        },
    ];

    return (
        <ModalSimple
            action={() => {
                setLoading(true);
                addUser({
                    email: email.value,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    role: role.value,
                });
            }}
            validateLabel={i18n.t(`confirm`)}
            cancelLabel={i18n.t(`cancel`)}
            className='team-settings-team-add-user'
            title={i18n.t(`settings_team_add_user`)}
            disableButton={email.error ? true : false}
            hideModal={hideModal}
            loading={loading}
        >
            <div className='team-settings-team-add-user__line'>
                <InputText
                    label={i18n.t("settings_team_add_user_firstname")}
                    placeholder={i18n.t("settings_team_add_user_firstname_placeholder")}
                    value={firstName.value}
                    name='firstName'
                    id='firstName'
                    smallInput={true}
                    required={true}
                    onChange={(event) => {
                        setFirstName(event.target.value);
                    }}
                />
                <InputText
                    label={i18n.t("settings_team_add_user_lastname")}
                    placeholder={i18n.t("settings_team_add_user_lastname_placeholder")}
                    value={lastName.value}
                    name='lastName'
                    id='lastName'
                    smallInput={true}
                    required={true}
                    onChange={(event) => {
                        setLastName(event.target.value);
                    }}
                />
            </div>
            <div className='team-settings-team-add-user__line'>
                <InputText
                    label={i18n.t("settings_team_add_user_email")}
                    placeholder={i18n.t("settings_team_add_user_email_placeholder")}
                    value={email.value}
                    name='email'
                    id='email'
                    smallInput={true}
                    required={true}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                />
                <div className='team-settings-team-add-user__label'>
                    <div className='team-settings-team-add-user__role-inputs__input__label'>
                        {i18n.t("settings_team_add_user_role")}
                        <div className='team-settings-team-add-user__role-inputs__input__label__icon'>
                            <IconInformation />
                        </div>
                        <div className='team-settings-team-add-user__role-inputs__input__label__tooltip'>
                            <div className='team-settings-team-add-user__role-inputs__input__label__tooltip__title'>
                                {i18n.t(`settings_team_role_description_title`)}
                            </div>

                            <ul className='team-settings-team-add-user__role-inputs__input__label__tooltip__list'>
                                <li>
                                    {`${i18n.t(`settings_team_role_admin`)} `}
                                    <span>{i18n.t(`settings_team_role_admin_description`)}</span>
                                </li>
                                <li>
                                    {`${i18n.t(`settings_team_role_missionManager`)} `}
                                    <span>
                                        {i18n.t(`settings_team_role_missionManager_description`)}
                                    </span>
                                </li>
                                <li>
                                    {`${i18n.t(`settings_team_role_collaborator`)} `}
                                    <span>
                                        {i18n.t(`settings_team_role_collaborator_description`)}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Select
                        id='role'
                        name='role'
                        options={roleOptions}
                        selectedItem={roleOptions.find((opt) => opt.value === role.value)?.label}
                        chevronIcon={true}
                        onChange={(_, __, value) => {
                            setRole(value);
                        }}
                        placeholder={i18n.t("settings_team_add_user_role_placeholder")}
                    />
                </div>
            </div>
        </ModalSimple>
    );
};

export default AddUserModal;
