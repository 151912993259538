import { normalizeNumber } from "../../../../../lib/utils/inputsNormalizers";

export const onFieldChange = (fieldPath, fieldName, value, otherParameters, setFieldValue) => {
    let newValue = value;

    switch (fieldName) {
        case "daysThreshold":
        case "threshold":
            newValue = normalizeNumber(value);
            break;
        case "jobTypes":
            if (value.checked === true) {
                newValue = [...otherParameters.taskType.jobTypes, value.id];
            } else {
                newValue = otherParameters.taskType.jobTypes.filter((id) => id !== value.id);
            }
            break;
        default:
            break;
    }

    const newOther = {
        ...otherParameters,
        [fieldPath]: {
            ...otherParameters[fieldPath],
            [fieldName]: newValue,
        },
    };
    setFieldValue("otherParameters", newOther);
};
