import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";

import { apiConf, withAuthHeaders } from "./index";

const permanentContactsSchema = z.object({
    jobName: z.string(),
    location: z.object({
        address: z.string(),
        city: z.string(),
        postalCode: z.string(),
        country: z.string(),
        lat: z.number(),
        lon: z.number(),
    }),
    salary: z
        .object({ min: z.number(), max: z.number() })
        .optional()
        .catch(() => ({ min: 0, max: 0 })),
    commentary: z.string().optional(),
    startDate: z.string().datetime({ offset: false }),
    availablePositions: z.number().min(1),
});

type PostPermanentContracts = z.infer<typeof permanentContactsSchema>;

export async function postPermanentContracts(params: PostPermanentContracts): Promise<void> {
    debug.addBreadcrumb({
        message: `Post permanent contract request`,
        category: `action`,
        data: params,
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/permanent-contracts/requests`, {
                method: `POST`,
                body: JSON.stringify(params),
            }),
        );

        if (resp.status >= 400) {
            handleError(resp.error, resp.status);
            return Promise.reject(new Error("PostPermanentContracts"));
        }
        return Promise.resolve();
    } catch (e) {
        handleError(e, 500);
        return Promise.reject(new Error("PostPermanentContracts"));
    }
}
