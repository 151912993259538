import fetch from "isomorphic-fetch";

import { withAuthHeaders, withURLParam } from "./apiHelpers";
import { apiConf } from "./index";

type GetTasksParams = {
    offset?: number;
    limit?: number;
    status?: string;
    managerId?: string;
    noLimit?: boolean;
};

export type getTasksResponse = {
    tasks: TasksItem[];
    total: number;
    limit: number;
    offset: number;
};

export type TasksItem = {
    id: string;
    startDate: string;
    endDate: string;
    name: string;
    type: string;
    jobTitle: string;
    status: string;
    selectionStatus: string;
    hiredSidersCount: number;
    location: Location;
    manager: Manager;
    migrated: boolean;
};

type Manager = {
    id: string;
    firstName: string;
    lastName: string;
};

type Location = {
    id: string;
    city: string;
    country: string;
    lat: number;
    lng: number;
    postalCode: string;
    address: string;
    description: string;
    favorite: boolean;
    motorized: boolean;
    remote: boolean;
    title?: any;
};
export function getTasks(params: GetTasksParams) {
    return fetch(
        withAuthHeaders(
            withURLParam(`${apiConf.BACK_URL}/me/tasks`, {
                ...(params.noLimit
                    ? {}
                    : {
                          offset: params.offset || 0,
                          limit: params.limit || "",
                      }),
                status: params.status || "",
                ...(params.managerId ? { managerId: params.managerId } : {}),
            }),
        ),
    );
}
