import { globalLayoutRoute } from "@routes";
import { createRoute } from "@tanstack/react-router";

import Horizon from "./index";

export const horizonRoute = createRoute({
    getParentRoute: () => globalLayoutRoute,
    path: `horizon`,
    component: Horizon,
});
