import { connect } from "react-redux";

import App from "./App";

const mapStateToProps = (state) => {
    const { errorDisplay } = state.display;

    return {
        errorDisplay,
    };
};

export default connect(mapStateToProps, null)(App);
