import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";
import sha256Hash from "../utils/sha256";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

export type CreateAccountReq = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    companyName: string;
    companySize: string;
    need: "less-than-7-days" | "more-than-7-days" | "learn-about-side";
    message?: string;
    utm_campaign?: string;
    utm_medium?: string;
    utm_source?: string;

    googleLogin: boolean;
};

export type CreateAccountRes = {
    token: string;
    userId: string;
    organisationId: string;
    firstName: string;
    lastName: string;
};

export async function createAccount(req: CreateAccountReq): Promise<CreateAccountRes | null> {
    debug.addBreadcrumb({
        message: `CreateAccount`,
        category: `action`,
        data: {
            email: req.email,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/auth/account`, {
                method: `POST`,
                credentials: "omit",
                body: JSON.stringify({
                    email: req.email,
                    password: sha256Hash(req.password),
                    firstName: req.firstName,
                    lastName: req.lastName,
                    phoneNumber: req.phoneNumber,
                    companyName: req.companyName,
                    companySize: req.companySize,
                    need: req.need,
                    message: req.message,
                    utm_campaign: req.utm_campaign,
                    utm_medium: req.utm_medium,
                    utm_source: req.utm_source,
                    googleLogin: req.googleLogin,
                }),
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
