import env from "../env";

// action can be `new` or `edit`
// returned urls should look like:
// creation: TODO
// duplication: baseUrl/new/typeId?baseParams&duplicate=true&taskId=task_id
// edition: baseUrl/edit/typeid/taskid?baseParams

const getJobPostingToken = () => {
    const lsToken = localStorage.getItem(`Meteor.loginToken`) || ``;
    const lsUid = localStorage.getItem(`Meteor.userId`) || ``;
    const jobPostingToken = btoa(`${lsUid}:${lsToken}`);
    return jobPostingToken;
};

const getJobPostingUrl = (action, taskId, typeId, isDuplication, taskName) => {
    const locale = localStorage.getItem(`side_team_locale`) || ``;
    const logasId = localStorage.getItem(`side_team_logasId`) || ``;
    const activeOrganisationId = localStorage.getItem(`side_team_activeOrganisationId`) || ``;
    const token = getJobPostingToken();

    let baseUrl = `${env.JOB_POSTING_URL || ``}${env.DOMAIN || ``}/${action}/${typeId}`;
    let baseParams = `?token=${token}&locale=${
        locale || `fr`
    }&organisationId=${activeOrganisationId}`;

    if (taskName) {
        baseParams += `&alias=${taskName}`;
    }

    if (action === `edit`) {
        baseUrl += `/${taskId}`;
    }

    if (isDuplication) {
        baseParams += `&duplicate=true&taskId=${taskId}`;
    }

    baseUrl += baseParams;

    if (logasId) {
        baseUrl += `&userId=${logasId}`;
    }
    return baseUrl;
};

export default getJobPostingUrl;
