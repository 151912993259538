import fetch from "isomorphic-fetch";

import { debug } from "@side.co/client-debug";

import { handleError } from "../utils/error";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

type UploadOrganisationPictureResp = {
    URL: string;
};

export async function uploadOrganisationPicture(
    formData: FormData,
    pictureType: string,
): Promise<UploadOrganisationPictureResp | null> {
    debug.addBreadcrumb({
        message: `UploadOrganisationPicture`,
        category: `action`,
        data: {
            pictureType,
        },
    });

    try {
        const resp = await fetch(
            withAuthHeaders(`${apiConf.BACK_URL}/me/organisations/picture/${pictureType}`, {
                method: `POST`,
                credentials: "omit",
                body: formData,
            }),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return null;
        }

        return resp.json();
    } catch (e) {
        handleError(e, 500);
    }

    return null;
}
